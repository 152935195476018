import { Dropdown, Menu, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { useDataImportRunsById } from "../../../api";
import { AdminTableFooter, Failed, LoaderWithPerfTimings, Ok, Table } from "../../../components";
import { COLORS, KEY_LABEL, SQL_DATASOURCES } from "../../../constants";
import { EAContext, DataImportSourceInfo } from "../../../types";
import { ExtAdminContext } from "../ExtAdmin";

const Wrapper = styled.div`
  .statusIcon {
    position: relative;
    top: 3px;
    margin-right: 3px;
  }

  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }

  & .ant-table {
    background: transparent;

    .ant-table-header .ant-table-thead tr th {
      background: transparent;
      color: rgb(217, 217, 217);
      text-align: center;
    }

    .ant-table-body td {
      text-align: center;
    }
  }
`;

const Status = styled.div`
  white-space: nowrap;
`;

const RowCols = styled.div`
  display: flex;

  div {
    text-align: right;
    flex: 1;
  }
`;

const MenuItem = styled.div`
  margin: 7.5px 15px;
  max-width: 300px;
`;

const ExecutionTime = styled.span`
  display: block;
  color: ${COLORS.black25};
  margin-bottom: 7.5px;
`;

export const DataImportRunsSubTable = ({
  dataImportId,
  sourceInfo,
}: {
  dataImportId: string;
  sourceInfo: DataImportSourceInfo;
}) => {
  const { domain } = useContext(ExtAdminContext) as EAContext;

  const [dataImportRunPageOffset, setDataImportRunPageOffset] = useState<number>(0);

  const [page, setPage] = React.useState(0);

  const PAGE_SIZE_FOR_SUBTABLE = 20;
  const { data: dataImportRunResponse, isFetching: isDataImportRunFetching } =
    useDataImportRunsById({
      data_import_id: dataImportId,
      domain: domain,
      page_size: PAGE_SIZE_FOR_SUBTABLE,
      offset: dataImportRunPageOffset,
    });

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setDataImportRunPageOffset(page * PAGE_SIZE_FOR_SUBTABLE);
  };

  const columns = [
    {
      title: KEY_LABEL["run_complete_dt"],
      dataIndex: "run_complete_dt",
      key: "run_complete_dt",
      width: 160,
      render: d => {
        if (d) {
          const date = moment(d);
          return date.format("MM/DD/YY hh:mm A");
        } else {
          return "-";
        }
      },
    },
    {
      title: KEY_LABEL["run_status_type"],
      dataIndex: "run_status_type",
      key: "run_status_type",
      render: (d, record) => {
        return (
          <Status>
            {d === "OK" ? (
              <>
                <Ok className="statusIcon" /> {d}
              </>
            ) : (
              <Tooltip title={record.error_description}>
                <Failed className="statusIcon" /> {d}
              </Tooltip>
            )}
          </Status>
        );
      },
    },
    {
      title: KEY_LABEL["num_rows"],
      dataIndex: "num_rows",
      key: "num_rows",
      render: (data, record) => {
        return (
          <RowCols>
            <div>{record.run_status_type === "OK" ? data : "-"}</div>
            {record.run_status_type === "OK" ? (
              <div style={{ whiteSpace: "nowrap" }}>({record.num_columns} cols)</div>
            ) : (
              ""
            )}
          </RowCols>
        );
      },
    },
    {
      title: KEY_LABEL["trigger_description"],
      dataIndex: "trigger_description",
      key: "trigger_description",
    },
    (dataImportRunResponse?.data_import_runs.length &&
      SQL_DATASOURCES.includes(
        dataImportRunResponse?.data_import_runs[0].data_source_type ?? ""
      ) && {
        title: KEY_LABEL["data_source_details"],
        render: (d, record) => {
          return record.display_data_source_details_as_code ? (
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem>
                    <ExecutionTime>{`Execution Time: ${record.run_duration_sec}`}</ExecutionTime>
                    {record.data_source_details}
                  </MenuItem>
                </Menu>
              }
              placement="bottomRight"
            >
              <a>SQL Query</a>
            </Dropdown>
          ) : (
            record.data_source_details
          );
        },
      }) ||
      {},
  ];

  const locale = {
    emptyText: isDataImportRunFetching ? (
      <LoaderWithPerfTimings name="DataImportRuns.locale" />
    ) : (
      "No Data Imports"
    ),
  };

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={
          dataImportRunResponse?.data_import_runs
            ? dataImportRunResponse?.data_import_runs.map((d, i) => ({ ...d, key: i }))
            : []
        }
        loading={false}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
      />
      <AdminTableFooter
        page={page}
        pageSize={PAGE_SIZE_FOR_SUBTABLE}
        onPageChange={page => setPageAndOffset(page)}
        data={
          dataImportRunResponse?.data_import_runs.map(x => [x.data_import_name ?? "", true]) ?? []
        }
        totalRowCount={dataImportRunResponse?.total_records ?? 0}
      />
    </Wrapper>
  );
};
