import { CloudTableSourceInfo } from "@coeff/api";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import { Button, Paper, PaperContent, PaperContentIndent, PaperProps } from "../../components";

import { SourceInfoFormFields } from "./SourceInfoFormFields";

export type SourceInfoErrors = Partial<Record<keyof CloudTableSourceInfo, string>>;

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &.disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`;

type Props = {
  authToken: string;
  loading?: boolean;
  variant: PaperProps["variant"];
  sourceInfo?: Partial<CloudTableSourceInfo>;
  onChange: (sourceInfo: Partial<CloudTableSourceInfo>) => void;
  onSubmit: (sourceInfo: CloudTableSourceInfo) => void;
  setTopLevelError: (error: unknown) => void;
};

const RANGE_REGEX = /^(.*!)?[a-zA-Z]+[0-9]*(:[a-zA-Z]+[0-9]*)?$/;

export const SourceInfoForm: React.FC<Props> = props => {
  const { variant, onChange, onSubmit, sourceInfo, authToken, loading, setTopLevelError } = props;

  const [errors, setErrors] = React.useState<SourceInfoErrors>({});

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const validate = (sourceInfo: Partial<CloudTableSourceInfo>) => {
    let errors: SourceInfoErrors = {};

    if (sourceInfo?.google_sheets_tab_id === undefined) {
      errors = {
        ...errors,
        google_sheets_tab_id: "required",
      };
    }

    if (sourceInfo?.google_sheets_is_specific_range === undefined) {
      errors = {
        ...errors,
        google_sheets_is_specific_range: "required",
      };
    }

    if (sourceInfo?.google_sheets_is_specific_range) {
      if (sourceInfo.google_sheets_range === undefined || sourceInfo?.google_sheets_range === "") {
        errors = {
          ...errors,
          google_sheets_range: "required",
        };
      } else if (!sourceInfo.google_sheets_range.match(RANGE_REGEX)) {
        errors = {
          ...errors,
          google_sheets_range: "invalid_range",
        };
      }
    }

    setErrors(errors);

    return errors;
  };

  const handleChange = (sourceInfo: Partial<CloudTableSourceInfo>) => {
    validate(sourceInfo);

    onChange(sourceInfo);
  };

  const handleSubmit = (sourceInfo: Partial<CloudTableSourceInfo>) => {
    const errors = validate(sourceInfo);

    setIsSubmitted(true);

    if (!Object.keys(errors).length) {
      onSubmit(sourceInfo as CloudTableSourceInfo);
    }
  };

  return (
    <Paper variant={variant}>
      <StyledPaperContent size="small">
        <SourceInfoFormFields
          mode="create"
          disabled={loading}
          authToken={authToken}
          errors={errors}
          isSubmitted={isSubmitted}
          sourceInfo={sourceInfo}
          onChange={sourceInfo => handleChange(sourceInfo)}
          setTopLevelError={setTopLevelError}
        />

        {sourceInfo?.google_drive_file_id ? (
          <PaperContentIndent>
            <StyledButton
              block
              type="primary"
              onClick={() => handleSubmit(sourceInfo)}
              // Using a class instead of disabled attribute specifically to
              // fix issues with clicking at the same time as the range input
              // is being blurred, which would otherwise disable the button
              // and prevent the click getting through.
              className={clsx({ disabled: loading })}
            >
              {isSubmitted ? "Retry" : "Next"}
            </StyledButton>
          </PaperContentIndent>
        ) : null}
      </StyledPaperContent>
    </Paper>
  );
};
