import styled from "styled-components";

type Variant = "error" | "warning" | "info" | "success" | "default";

export type PaperProps = {
  variant?: Variant;
  background?: string;
};

export const Paper = styled.div<PaperProps>`
  line-height: 1.3;
  border-radius: 5px;

  ${({ variant, theme }) => {
    switch (variant) {
      case "success": {
        return `background: ${theme.colors.greenBg3}`;
      }
      case "error": {
        return `background: ${theme.colors.redBg}`;
      }
      case "warning": {
        return `background: ${theme.colors.yellowBg}`;
      }
      case "info": {
        return `background: ${theme.colors.coeblue01}`;
      }
      case "default": {
        return `background: ${theme.colors.black4};`;
      }
      default: {
        return `background: ${theme.colors.black4};`;
      }
    }
  }}

  ${({ background }) => (background ? `background: ${background};` : "")}
`;

type PaperContentProps = {
  size?: "xxsmall" | "xsmall" | "small" | "none";
};

export const PaperContent = styled.div<PaperContentProps>`
  padding: ${({ size }) => {
    switch (size) {
      case "xxsmall": {
        return "4px 6px";
      }
      case "xsmall": {
        return "8px 10px";
      }
      case "small": {
        return "10px 12px";
      }
      case "none": {
        return "0";
      }
      default: {
        return "16px";
      }
    }
  }};
`;

type PaperContentIndentProps = {
  size?: "none" | "default";
  leftOnly?: boolean;
};

export const PaperContentIndent = styled.div<PaperContentIndentProps>`
  ${({ size, leftOnly }) => {
    switch (size) {
      case "none": {
        return "";
      }
      case "default":
      default: {
        return leftOnly
          ? `
          margin-left: 24px;
        `
          : `
          margin-left: 24px;
          margin-right: 24px;
        `;
      }
    }
  }};
`;
