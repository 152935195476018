import { DataImportStatus } from "@coeff/api";

import { isAxiosErrorWithMessage } from "./axios";
import { instanceofImportStatus } from "./dataImportHelpers";
import { isGoogleApiError } from "./googleApi";

export enum AuthTokenErrorType {
  SSO_AUTH_FAILED = "SSOAuthFailedError",
  UNKNOWN = "Unknown",
}

export type AuthBroadcastChannelMessage = {
  error: {
    type: AuthTokenErrorType;
  };
};

export class DataImportStatusError extends Error {
  public importStatus: DataImportStatus;

  constructor(importStatus: DataImportStatus) {
    super(importStatus.message ?? importStatus.error_type);

    this.name = "DataImportStatusError";

    this.importStatus = importStatus;

    // See https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class MissingDataSourceError extends Error {
  constructor() {
    super("Missing Data Source");

    // See https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export const getErrorTitle = (error: unknown, fallback: string) => {
  if (isGoogleApiError(error) && error.result.error.code === 403) {
    return "Permission Denied";
  }

  return (
    (error instanceof DataImportStatusError
      ? error.importStatus.title
      : instanceofImportStatus(error)
      ? error.title
      : fallback) ?? fallback
  );
};

export const getErrorMessage = (error: unknown, fallback: string) => {
  if (isGoogleApiError(error) && error.result.error.code === 403) {
    return "Unable to access the source sheet. Check your permissions and try again.";
  }

  return isAxiosErrorWithMessage(error)
    ? error.response.data.message
    : error instanceof DataImportStatusError
    ? error.importStatus.message
    : instanceofImportStatus(error)
    ? error.message
    : fallback;
};

export class SSOAuthFailedError extends Error {
  constructor() {
    super("SSO authentication failed. Login and try again.");

    this.name = "SSOAuthFailedError";

    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export enum BroadcastChannelName {
  AUTH = "auth",
}

export const errorToAuthErrorMessage = (error: unknown): AuthBroadcastChannelMessage => {
  return {
    error: {
      type:
        error instanceof SSOAuthFailedError
          ? AuthTokenErrorType.SSO_AUTH_FAILED
          : AuthTokenErrorType.UNKNOWN,
    },
  };
};
