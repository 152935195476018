import { DataSourceType } from "@coeff/api";
import * as React from "react";
import styled from "styled-components";

import { Header } from "../routes/Dashboard/components/Header";

import { ImportTypeIcon } from "./ImportTypeIcon";

const Container = styled.div`
  padding: 20px 30px;
`;
const StyledHeader = styled(Header)`
  h1 {
    margin: 0 10px;
  }
`;
const Content = styled.div<{ $width: "wide" | "narrow" }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: ${props => (props.$width === "wide" ? "960px" : "600px")};

  > p {
    margin: 0;
  }
`;

function SettingsLayout({
  children,
  title,
  importTypeIcon,
  width = "wide",
}: {
  children: React.ReactNode;
  title: string;
  importTypeIcon: DataSourceType;
  width?: "wide" | "narrow";
}) {
  return (
    <Container>
      <StyledHeader>
        <ImportTypeIcon type={importTypeIcon} />
        <h1>{title}</h1>
      </StyledHeader>
      <Content $width={width}>{children}</Content>
    </Container>
  );
}

export { SettingsLayout };
