import { PlayCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import React, { useState, useEffect, ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  ImportTypeIcon,
  Steps,
  Button,
  Input,
  Typography,
  Alert,
  CoeLogo,
  Lock,
  WheelIcon,
  ErrorMessage,
  Loader,
  FlexColumn,
} from "../components";
import { COLORS } from "../constants";
import {
  DataSourceTypeDisplayNames,
  getBaseUrl,
  sentryCapture,
  useApiContext,
  useTrack,
} from "../utils";

const WholePageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const PageFooter = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  bottom: 0;
  padding: 30px;
  width: 100%;
  max-width: 970px;
`;

const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.coeblue};
  margin: 8px 0px;
  padding: 20px;
  border-radius: 6px;
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;

  label {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
`;

const ConnectorInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0;
`;

const CoeLogoContainer = styled.div`
  padding-left: 16px;
`;

const StyledParagraph = styled.div`
  color: ${COLORS.black45};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const StyledInfoCard = styled.div`
  background: white;
  border-radius: 6px;
  padding: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormLayout = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  .leftSection {
    flex: 1;
  }

  .rightSection {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 32px;

    .rightSection {
      minwidth: 280px;
      width: 280px;
      display: flex;
    }
  }
`;

const HelperVideoThump = styled.div`
  background-image: url("../static/looker-video-thumb.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  width: 280px;
  height: 158px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

const StyledAlert = styled(Alert)`
  margin: 12px 0;
  padding: 15px;
  background-color: #f2e3ff !important;
`;

const LOOKER_STEPS = [
  `1. From the Looker home page, click "Admin" and scroll down to "Users.”`,
  `2. Click on the user's profile account`,
  `3. Locate the "API Keys" field and click "Edit Keys”`,
  `4. Click the "New API Key" button to generate a new Client ID and Secret`,
];

const INVITE_EXPIRED = {
  title: "Looker Invite Link Expired",
  message: `The Looker invitation link has expired. Please request a new invitation to connect your Looker account.`,
};

const INVITE_COMPLETED = {
  title: "Looker Invite completed",
  message: `The Looker invitation has been completed.`,
};

const withLayout = (component: ReactElement, footer?: ReactElement) => {
  return (
    <WholePageLayout>
      <Container>
        <PageHeader>
          <CoeLogoContainer>
            <CoeLogo />
          </CoeLogoContainer>

          <StyledParagraph>
            Coefficient let’s teams easily work with company data within Sheets.
          </StyledParagraph>
        </PageHeader>

        {component}
      </Container>
      {footer}
    </WholePageLayout>
  );
};

const HelpCard = () => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <FlexColumn className="rightSection">
      <Typography
        style={{ fontWeight: 700, lineHeight: "24px", fontSize: "18px" }}
        color={"textPrimary"}
      >
        Need help?
      </Typography>
      <StyledParagraph>
        <Typography
          style={{ lineHeight: "14px", fontSize: "18px", marginTop: 6 }}
          fontWeight={600}
          color={"textPrimary"}
        >
          Watch a step-by-step video
        </Typography>
      </StyledParagraph>

      {!playVideo && (
        <HelperVideoThump>
          <Button
            type="primary"
            onClick={() => setPlayVideo(true)}
            style={{ borderRadius: 50 }}
            icon={<PlayCircleFilled />}
          ></Button>
        </HelperVideoThump>
      )}
      {playVideo && (
        <iframe
          width={280}
          height={158}
          style={{ border: "none", borderRadius: 6 }}
          src="https://www.youtube.com/embed/B1Bv2yz-zaE?autoplay=1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      )}

      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <WheelIcon />
        <Button type="link" noPadding>
          <a
            href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
            target="_blank"
          >
            Help Guide
          </a>
        </Button>
      </div>
    </FlexColumn>
  );
};

type UserDetails = {
  is_expired: boolean;
  is_completed: boolean;
  user_name: string;
};

export const RequestLookerSetup: React.FC = () => {
  const { apiClient: api } = useApiContext();
  const location = useLocation();
  const uuid = new URLSearchParams(location.search).get("uuid");

  // Form state
  const [baseUrl, setBaseUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [formError, setFormError] = useState<{
    title: string;
    message: string;
  }>();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const track = useTrack();
  const history = useHistory();

  useEffect(() => {
    if (formError) {
      setFormError(undefined);
    }
  }, [baseUrl, clientId, clientSecret]);

  useEffect(() => {
    document.title = "Looker Setup - Coefficient";
    track("looker_setup_viewed", {
      looker_request_access_uuid: uuid!,
    });
  }, []);

  const {
    data: userDetails,
    isLoading,
    isError,
  } = useQuery<UserDetails>({
    queryKey: [QueryCacheKey.LOOKER_REQUEST_INFO_BY_UUID, uuid],
    queryFn: async () => {
      if (!uuid) {
        throw new Error("UUID is required");
      }

      const response = await api.lookerFetchUrlBasedAuthDetails({
        looker_fetch_url_based_auth_details_request: {
          attached_uuid: uuid,
        },
      });

      return response.data;
    },
    cacheTime: 0,
    staleTime: 0,
    retry: 2,
    enabled: Boolean(uuid),
    onError: () => {
      message.error("Failed to fetch user details");
    },
  });

  const validationMutation = useMutation({
    mutationFn: async () => {
      const credentials = {
        url: getBaseUrl(baseUrl),
        client_id: clientId,
        client_secret: clientSecret,
      };

      const response = await api.lookerCheckConnection({
        looker_check_connection_request: { credentials },
      });

      if (response.data.error) {
        setFormError({
          title: response.data.title || "Could not verify Client ID and Secret",
          message:
            response.data.source_error ||
            "Could not verify credentials. Please check your inputs and try again.",
        });
        throw new Error("Failed to validate connection");
      }

      const lookerMeta = await api.lookerGetDataSourceOwnerMetaData({
        looker_get_data_source_owner_meta_data_request: { credentials },
      });

      if (!lookerMeta.data.is_admin || lookerMeta.data.already_configured) {
        await api.createDataSource({
          create_data_source_request: {
            data_source: {
              credentials: { ...credentials, remote_auth_url_uuid: uuid! },
              data_source_type: "looker",
              data_source_name: DataSourceTypeDisplayNames.looker,
            },
          },
        });

        return {
          isAdmin: false,
        };
      }

      return { isAdmin: true };
    },
    onSuccess: ({ isAdmin }) => {
      history.push("./complete", {
        uuid,
        baseUrl,
        clientId,
        clientSecret,
        isAdmin,
        name: userDetails?.user_name,
      });
    },
    onError: (error: Error | { title: string; message: string }) => {
      message.error(error.message || "Failed to validate connection");
      sentryCapture({ error, name: "LookerRequestSetup: validationMutation" });
    },
  });

  const validateConnection = () => {
    if (!baseUrl || !clientId || !clientSecret) {
      setFormError({
        title: "Missing fields",
        message: "Please fill out all fields to continue",
      });
      return;
    }

    validationMutation.mutate();
  };

  if (!uuid || isError || userDetails?.is_expired) {
    return withLayout(<ErrorMessage {...INVITE_EXPIRED} />);
  }

  if (isLoading) {
    return withLayout(<Loader />);
  }

  if (userDetails?.is_completed) {
    return withLayout(
      <Alert
        type="success"
        message={INVITE_COMPLETED.title}
        description={INVITE_COMPLETED.message}
      />
    );
  }

  const steps = [
    {
      title: (
        <Typography
          style={{ fontWeight: 700, lineHeight: "22px", fontSize: "16px" }}
          color={!currentStep ? "textPrimary" : "textSecondary"}
        >
          Enter Your Base URL
        </Typography>
      ),
      description:
        currentStep === 0 ? (
          <StyledStepContainer>
            <div>
              <Typography fontSize="14px" lineHeight={"17px"}>
                Looker Base URL
              </Typography>
              <Input
                placeholder="https://your-instance.looker.com"
                value={baseUrl}
                onChange={e => setBaseUrl(e.target.value)}
                status={formError && !baseUrl ? "error" : undefined}
                required
                disabled={validationMutation.isLoading}
              />
              <Typography fontSize="12px" lineHeight={"14px"} color="textSecondary">
                The URL you use to access your Looker account
              </Typography>
              {formError && !baseUrl && <Typography color="error">URL is required</Typography>}
            </div>

            <ButtonContainer>
              <Button
                style={{ margin: 0 }}
                type="primary"
                disabled={!baseUrl}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                Next
              </Button>
            </ButtonContainer>
          </StyledStepContainer>
        ) : (
          <>
            <Button
              style={{ margin: 0, justifyContent: "flex-start" }}
              type="link"
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              Edit
            </Button>
          </>
        ),
    },
    {
      title: (
        <Typography
          style={{ fontWeight: 700, lineHeight: "22px", fontSize: "16px" }}
          color={currentStep === 1 ? "textPrimary" : "textSecondary"}
        >
          Enter Client ID & Secret
        </Typography>
      ),
      description:
        currentStep === 1 ? (
          <StyledStepContainer>
            <Typography fontSize="14px" lineHeight={"20px"} fontWeight={500}>
              You must be a Looker admin to access your client ID & secret in Looker.
            </Typography>
            <StyledInfoCard>
              <FlexRow>
                <Typography fontSize="14px" lineHeight={"20px"} fontWeight={800}>
                  How to find your client ID and secret in Looker
                </Typography>
                <Button style={{ margin: 0 }} type="link">
                  watch video
                </Button>
              </FlexRow>
              <Typography fontSize="14px" lineHeight={"20px"} fontWeight={500}>
                {LOOKER_STEPS.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </Typography>
            </StyledInfoCard>

            <div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <Typography fontSize="14px" lineHeight={"17px"}>
                  Client ID
                </Typography>
                <Typography color="textLink" lineHeight={"22px"} fontSize={"12px"}>
                  <a
                    tabIndex={-1}
                    href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
                    target="_blank"
                  >
                    How to find
                  </a>
                </Typography>
              </FlexRow>
              <Input
                value={clientId}
                status={formError && !clientId ? "error" : undefined}
                onChange={e => setClientId(e.target.value)}
                disabled={validationMutation.isLoading}
              />
              {formError && !clientId && (
                <Typography color="error">Client ID is required</Typography>
              )}
            </div>

            <div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <Typography fontSize="14px" lineHeight={"17px"}>
                  Client Secret
                </Typography>
                <Typography color="textLink" lineHeight={"22px"} fontSize={"12px"}>
                  <a
                    tabIndex={-1}
                    href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
                    target="_blank"
                  >
                    How to find
                  </a>
                </Typography>
              </FlexRow>
              <Input
                type="password"
                value={clientSecret}
                status={formError && !clientSecret ? "error" : undefined}
                onChange={e => setClientSecret(e.target.value)}
                disabled={validationMutation.isLoading}
              />
              {formError && !clientSecret && (
                <Typography color="error">Client Secret is required</Typography>
              )}
            </div>

            <ButtonContainer>
              <Button
                style={{ margin: 0, minWidth: 90 }}
                type="primary"
                disabled={validationMutation.isLoading}
                onClick={() => validateConnection()}
              >
                {validationMutation.isLoading ? <Loader color="white" /> : "Connect"}
              </Button>
            </ButtonContainer>
          </StyledStepContainer>
        ) : (
          <noscript />
        ),
    },
  ];

  return withLayout(
    <>
      <StyledAlert
        message={`${userDetails.user_name} shared this page with you to help connect Looker to Sheets using Coefficient`}
        description="Connecting to Looker requires your client ID & secret (that only admins can access). Enter it below and we’ll notify John once it’s complete."
      />
      <ConnectorInfo>
        <ImportTypeIcon type="looker" />
        <div>
          <Typography fontWeight={700} fontSize={"28px"} lineHeight={"34px"}>
            Connect to Looker
          </Typography>
          <Typography fontSize="14px" lineHeight={"18px"}>
            <StyledParagraph>
              Connect to Looker using your URL, Client ID & Secret below
            </StyledParagraph>
          </Typography>
        </div>
      </ConnectorInfo>
      <Content>
        {formError && <ErrorMessage {...formError} />}
        <FormLayout>
          <Form className="leftSection">
            <FormGroup>
              <Steps direction="vertical" size="small" current={currentStep} items={steps} />
            </FormGroup>
          </Form>
          <HelpCard />
        </FormLayout>
      </Content>
    </>,
    <PageFooter>
      <Lock fill={COLORS.black85} />
      <Typography isEllipsedContentTooltip>
        Coefficient encrypts your credentials and never stores your source data. Learn more
      </Typography>
    </PageFooter>
  );
};
