import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface NotFoundProps {
  locale: string;
}

const NotFound = ({ locale }: NotFoundProps) => {
  return (
    <div>
      <h1>404</h1>
      <h3>The Requested Page Does Not Exist</h3>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

const mapStateToProps = ({ app: { locale } }) => ({
  locale,
});

const route = connect(mapStateToProps)(NotFound);
export { route as NotFound };
