import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  LinkOutlined,
} from "@ant-design/icons";
import { DataSource, DataSourceType } from "@coeff/api";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";

import { Button, CoeLogo, LoaderWithPerfTimings, Typography } from "../../components";
import { SecurityInfo } from "../../components/SecurityInfo";
import { COLORS } from "../../constants";
import { VCRMessageEnvelope } from "../../types";
import { AssetsHost, useApiContext, useTrack } from "../../utils";

import { NoVNCContainer } from "./NoVNCContainer";

const Layout = styled.div`
  line-height: 1.3;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  background: ${COLORS.grayBg};
  align-items: center;
`;

const WholePageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 100px 1fr 100px;
`;

const LoaderImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
`;

const PageHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
`;

const PageFooter = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

const VCRContainer = styled.div`
  margin-left: 130px;
  margin-right: 130px;
  border-radius: 6px;
  border: 2px solid #dddddd;
  overflow: hidden;
`;

const CoeLogoContainer = styled.div`
  padding-left: 16px;
`;

const FullScreenCenter = styled.div`
  background: ${COLORS.grayBg};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled(Input)`
  max-width: 440px;
  margin-bottom: 20px;
`;

export const WebScraperAuthSession: React.FC = () => {
  const { apiClient: api } = useApiContext();

  // parent window comms
  const [isWindowOpened, setIsWindowOpened] = React.useState(true);
  const [isParentWindowClosed, setIsParentWindowClosed] = React.useState(false);

  // VCR Frame overlay params
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isAuthComplete, setIsAuthComplete] = React.useState(false);
  const [isAuthSuccess, setIsAuthSuccess] = React.useState(false);
  const [isAuthInProgress, setIsAuthInProgress] = React.useState(false);
  const [isMagicLinkDetected, setIsMagicLinkDetected] = React.useState(false);

  const [isAuthSessionForceStopped, setIsAuthSessionForceStopped] = React.useState(false);
  const isAuthSessionForceStoppedRef = useRef(isAuthSessionForceStopped);

  const [cloudLoaderTitleText, setCloudLoaderTitleText] = React.useState<string>(
    "Establishing secure connection..."
  );
  // sidecar comms params
  const [websockifyUrl, setWebsockifyUrl] = React.useState<string>("");
  const [vcrPassword, setVcrPassword] = React.useState<string>("");
  const [socket, setSocket] = React.useState<any>(null);

  // misc
  const [dataSourceType, setDataSourceType] = React.useState<DataSourceType>("amplitude");
  const [magicLink, setMagicLink] = React.useState<string>("");
  const { url: baseUrl } = useRouteMatch();
  const [activePollingInterval, setActivePollingInterval] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const track = useTrack();

  const initWindowStatusPolling = () => {
    const intervalId = setInterval(() => {
      if (!isWindowOpened || isParentWindowClosed) {
        return;
      }

      if (!window.opener || window.opener.closed) {
        setIsParentWindowClosed(true);
        return;
      }
    }, 2000);
    setActivePollingInterval(intervalId);
  };

  useEffect(() => {
    isAuthSessionForceStoppedRef.current = isAuthSessionForceStopped;
  }, [isAuthSessionForceStopped]);

  const initParentMessageListener = () => {
    window.addEventListener("message", event => {
      const eventData = event.data as VCRMessageEnvelope;

      if (eventData.messageType !== undefined) {
        console.log("Event", event);
      }

      const { messageType } = eventData;

      if (messageType === "auth_props" && eventData.dataSourceType) {
        setDataSourceType(eventData.dataSourceType);
        if (eventData.authUrl) {
          getVCRInstanceInfo(eventData.authUrl);
        }
        // TODO: Add tracking
      } else if (messageType === "force_close_session" && eventData.errorMessage) {
        setIsAuthSessionForceStopped(true);
        setErrorMessage(eventData.errorMessage);
      }
    });
  };

  const setupSocketWithVCRSideCar = (vcrHostApiUrl: string) => {
    // Connect to the socket and emit a message
    // TODO:  Don't emit this event for all urls. Only emit for amplitude.com
    console.log(vcrHostApiUrl);
    const socketLocal = io(vcrHostApiUrl);
    setSocket(socketLocal);
    socketLocal.connect();
    socketLocal.emit("message", { message: "connected from webapp" });
    socketLocal.emit("identify_webapp_tab", {});

    socketLocal.on("auth_started", () => {
      setIsAuthInProgress(true);
    });

    socketLocal.on("auth_successful", async ({ email, userS3ProfileFolder }) => {
      console.log("Auth successful", email, userS3ProfileFolder);
      try {
        await api.vcrTerminateAuthSession();
        const resp = await api.createDataSource({
          create_data_source_request: {
            data_source: {
              data_source_type: dataSourceType,
              data_source_name: "Amplitude",
              credentials: {},
              web_scraper_session_metadata: {
                amplitude_user_email: email,
                s3_profile_folder: userS3ProfileFolder,
              },
            },
          },
        });

        const { data_source } = resp.data;
        console.log("Data source created", data_source);
        postAuthCompleteMessage(true, data_source);
      } catch (e) {
        console.error("Error creating data source", e);
        postAuthCompleteMessage(false, undefined);
      } finally {
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    });

    socketLocal.on("magic_link_detected", () => {
      setIsMagicLinkDetected(true);
      track("web_scraper_onetime_login_link_modal_shown", { conn_data_source: "amplitude" });
    });
  };

  const postAuthRequestMessage = () => {
    console.log("Sending Request Auth Props message");
    window.opener.postMessage({ messageType: "request_auth_props" } as VCRMessageEnvelope, "*");
  };

  const postAuthCompleteMessage = (success: boolean, dataSource?: DataSource) => {
    window.opener.postMessage(
      { messageType: "auth_complete", success, dataSource } as VCRMessageEnvelope,
      "*"
    );
    setIsAuthComplete(true);
    setIsAuthSuccess(success);
  };

  const availableWidthForVCRSession = () => {
    return (
      window.innerWidth -
      2 * 130 - // margin
      2 * 2 // border
    );
  };

  const availableHeightForVCRSession = () => {
    return (
      window.innerHeight -
      2 * 100 - // header + footer
      2 * 2 // border
    );
  };
  const getVCRInstanceInfo = async (auth_url: string) => {
    try {
      if (isAuthSessionForceStoppedRef.current) {
        return;
      }
      console.log("Getting Auth Info");
      const { data: instance_info } = await api.vcrAuthGetInstanceInfo({
        vcr_auth_get_instance_info_request: {
          screen_width: availableWidthForVCRSession(),
          screen_height: availableHeightForVCRSession(),
        },
      });
      console.log(`Got instance info`, instance_info);
      console.log(`isAuthSessionForceStopped: ${isAuthSessionForceStoppedRef.current}`);
      if (isAuthSessionForceStoppedRef.current) {
        return;
      }
      try {
        setupSocketWithVCRSideCar(instance_info.api_url);

        try {
          const { data: resp } = await api.vcrStartAuthSession({
            vcr_start_auth_session_request: {
              ...instance_info,
              auth_url,
            },
          });
          setWebsockifyUrl(instance_info.websockify_url);
          setVcrPassword(instance_info.pwd);
          setLoading(false);
        } catch (e) {
          setErrorMessage("Error starting cloud browser session");
          // TODO: track error
          return;
        }
      } catch (e) {
        if (!isAuthSessionForceStoppedRef.current) {
          setErrorMessage("Error connecting to cloud browser");
        }
        // TODO: track error
        return;
      }
    } catch (e) {
      console.log("Error creating cloud instance", e);
      if (!isAuthSessionForceStoppedRef.current) {
        setErrorMessage("Error creating cloud instance");
      }
      // TODO: track error
      return;
    }
  };

  const sendMagicLinkToSidecar = () => {
    if (magicLink) {
      socket.emit("insert_magic_link", { url: magicLink });
      setIsMagicLinkDetected(false);
      track("web_scraper_onetime_login_link_submitted", { conn_data_source: "amplitude" });
    }
  };

  useEffect(() => {
    document.title = "Connect Amplitude...";

    if (!window.opener) {
      setIsWindowOpened(false);
      return;
    }

    initWindowStatusPolling();
    initParentMessageListener();
    postAuthRequestMessage();
    setTimeout(() => {
      setCloudLoaderTitleText("Launching Cloud Browser...");
    }, 3000);
  }, []);

  useEffect(() => {
    return () => {
      if (activePollingInterval !== undefined) {
        clearInterval(activePollingInterval);
      }
    };
  }, [activePollingInterval]);

  const VCRFrame = () => {
    if (!isWindowOpened) {
      return (
        <FullScreenCenter>
          <div style={{ color: COLORS.red }}>
            <CloseCircleFilled style={{ fontSize: "2.85em" }} />
          </div>
          <Typography variant="h4">This window is not launched from coefficient addon.</Typography>

          <Typography variant="h6" color="textSecondary" gutterBottom={2}>
            Please close this window and launch from the addon to initiate secure cloud browser.
          </Typography>
        </FullScreenCenter>
      );
    }

    if (errorMessage) {
      return (
        <FullScreenCenter>
          <div style={{ color: COLORS.red }}>
            <CloseCircleFilled style={{ fontSize: "2.85em" }} />
          </div>
          <Typography variant="h4">{errorMessage}</Typography>

          <Typography variant="h6" color="textSecondary" gutterBottom={2}>
            Please close this window and retry.
          </Typography>
        </FullScreenCenter>
      );
    }

    if (isMagicLinkDetected) {
      return (
        <FullScreenCenter>
          <div>
            <LinkOutlined
              style={{ fontSize: "2.85em", transform: "scaleX(-1)", marginBottom: "20px" }}
            />
          </div>
          <Typography variant="h4" fontWeight={"bold"}>
            One time login link required
          </Typography>
          <Typography variant="h6" color="textSecondary">
            You should receive an email with a one-time login link.
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom={2}>
            Copy and paste that link here and click Next.
          </Typography>
          <StyledInput
            type="url"
            placeholder="Paste link here"
            onChange={e => setMagicLink(e.currentTarget.value)}
            value={magicLink}
          />
          <Button
            style={{ paddingLeft: "85px", paddingRight: "85px" }}
            type="primary"
            onClick={sendMagicLinkToSidecar}
            disabled={!magicLink}
          >
            Next
          </Button>
        </FullScreenCenter>
      );
    }

    if (isAuthComplete) {
      return (
        <FullScreenCenter>
          {isAuthSuccess ? (
            <div style={{ color: COLORS.green }}>
              <CheckCircleFilled style={{ fontSize: "2.85em" }} />
            </div>
          ) : (
            <div style={{ color: COLORS.red }}>
              <CloseCircleFilled style={{ fontSize: "2.85em" }} />
            </div>
          )}

          <Typography style={{ marginBottom: "6px" }} variant="h4" fontWeight={"bold"}>
            Login {isAuthSuccess ? "successful" : "failed"}
          </Typography>
          <Typography variant="h6" color={"textSecondary"} style={{ marginBottom: "6px" }}>
            This tab will automatically close and you’ll be redirected to your spreadsheet.
          </Typography>
        </FullScreenCenter>
      );
    }

    if (isAuthInProgress) {
      return (
        <FullScreenCenter>
          <LoaderImageWrapper>
            <LoaderWithPerfTimings name="WebScraper.Auth" size="large" />
          </LoaderImageWrapper>
          <Typography variant="h4" fontWeight={"bold"}>
            Logging In
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Please wait while we authorize with Amplitude.
          </Typography>
        </FullScreenCenter>
      );
    }

    if (isParentWindowClosed) {
      return (
        <FullScreenCenter>
          <div style={{ color: COLORS.orange }}>
            <ExclamationCircleFilled style={{ fontSize: "2.85em" }} />
          </div>
          <Typography style={{ marginBottom: "6px" }} variant="h4">
            Oops! Communication to addon is closed.
          </Typography>
          <Typography variant="h6" color={"textSecondary"} style={{ marginBottom: "6px" }}>
            We are unable to communicate to the coefficient addon. please close this window and
            relaunch from the addon again.
          </Typography>
        </FullScreenCenter>
      );
    }

    return (
      <>
        <Layout>
          {loading ? (
            <div
              style={{
                margin: "0rem auto 0rem",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 220,
                  height: 220,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 30,
                }}
              >
                <img src={`${AssetsHost}/cloud-loader.gif`} alt="Logo" />
              </div>
              <Typography variant="h5" fontWeight={"bold"} style={{ color: COLORS.black35 }}>
                {cloudLoaderTitleText}
              </Typography>
              <Typography variant="h6" style={{ color: COLORS.black35 }}>
                This might take 10+ seconds to load
              </Typography>
            </div>
          ) : (
            <NoVNCContainer url={websockifyUrl} password={vcrPassword} />
          )}
        </Layout>
      </>
    );
  };

  return (
    <WholePageLayout>
      <PageHeader>
        <CoeLogoContainer>
          <Link to={`${baseUrl}/spreadsheets`}>
            <CoeLogo />
          </Link>
        </CoeLogoContainer>
        <Typography
          align={"center"}
          fontWeight={"bold"}
          style={{ position: "absolute", width: "-webkit-fill-available" }}
        >
          Authorize Coefficient to connect to Amplitude by logging into your account
        </Typography>
      </PageHeader>
      <VCRContainer>
        <div style={{ padding: "8px", background: "#3053FF" }}>
          <Typography style={{ color: "white" }}>Coefficient cloud browser instance</Typography>
        </div>
        <VCRFrame />
      </VCRContainer>
      <PageFooter>
        <div style={{ maxWidth: "282px" }}>
          <SecurityInfo />
        </div>
      </PageFooter>
    </WholePageLayout>
  );
};
