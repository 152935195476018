import { DataSourceType, DateFilterType, FilterOperandType, FilterOperatorType } from "@coeff/api";

export const FILTER_OPERATOR_GROUPS: Array<{
  dataKey: string;
  dataType: FilterOperandType;
  label: string;
  types: string[];
  defaultOperatorType: FilterOperatorType;
}> = [
  {
    dataKey: "number",
    dataType: "number",
    label: "Number",
    types: [
      "number",
      "int",
      "bigint",
      "tinyint",
      "float",
      "double",
      "decimal",
      "percent",
      "currency",
    ],
    defaultOperatorType: "number_eq",
  },
  {
    dataKey: "text",
    dataType: "text",
    label: "Text",
    types: [
      "string",
      "text",
      "varchar",
      "address",
      "anyType",
      "base64",
      "combobox",
      "complexvalue",
      "email",
      "id",
      "phone",
      "reference",
      "textarea",
      "url",
    ],
    defaultOperatorType: "text_is_exactly",
  },
  {
    dataKey: "date",
    dataType: "date",
    label: "Date",
    types: ["date", "datetime", "time"],
    defaultOperatorType: "date_is_within",
  },
  {
    dataKey: "boolean",
    dataType: "boolean",
    label: "Boolean",
    types: ["boolean", "bool"],
    defaultOperatorType: "boolean_is_true",
  },
  {
    dataKey: "picklist",
    dataType: "text",
    label: "Picklist",
    types: ["picklist", "enumeration"],
    defaultOperatorType: "picklist_is_one_of",
  },
  {
    dataKey: "multipicklist",
    dataType: "number",
    label: "Multipicklist",
    types: ["multipicklist"],
    defaultOperatorType: "multipicklist_has_any_one_of",
  },
];

export const FILTER_OPERATORS: Array<{
  dataKey: FilterOperatorType;
  label: string;
  operandType: FilterOperandType;
  group: string;
  hideValue: boolean;
  onlySupportedBy?: DataSourceType[];
  notSupportedBy?: DataSourceType[];
}> = [
  {
    dataKey: "number_eq",
    label: "is equal to",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_gt",
    label: "greater than",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_gte",
    label: "greater or equal to",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_lt",
    label: "less than",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_lte",
    label: "less or equal to",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_ne",
    label: "is not equal to",
    operandType: "number",
    group: "number",
    hideValue: false,
  },
  {
    dataKey: "number_is_not_empty",
    label: "is not empty",
    group: "number",
    operandType: "number",
    hideValue: true,
  },
  {
    dataKey: "number_is_empty",
    label: "is empty",
    group: "number",
    operandType: "number",
    hideValue: true,
  },
  {
    dataKey: "text_is_not_empty",
    label: "is not empty",
    operandType: "text",
    group: "text",
    hideValue: true,
  },
  {
    dataKey: "text_is_empty",
    label: "is empty",
    operandType: "text",
    group: "text",
    hideValue: true,
  },
  {
    dataKey: "text_contains",
    label: "contains",
    operandType: "text",
    group: "text",
    hideValue: false,
    notSupportedBy: ["hubspot"],
  },
  {
    dataKey: "text_is_exactly",
    label: "is exactly",
    operandType: "text",
    group: "text",
    hideValue: false,
  },
  {
    dataKey: "text_does_not_contain",
    label: "does not contain",
    operandType: "text",
    group: "text",
    hideValue: false,
    notSupportedBy: ["hubspot"],
  },
  {
    dataKey: "date_is_within",
    label: "is within",
    operandType: "date",
    group: "date",
    hideValue: false,
  },
  {
    dataKey: "date_is_before",
    label: "is before",
    operandType: "date",
    group: "date",
    hideValue: false,
  },
  {
    dataKey: "date_is_after",
    label: "is after",
    operandType: "date",
    group: "date",
    hideValue: false,
  },
  {
    dataKey: "date_is_on_or_before",
    label: "is on or before",
    operandType: "date",
    group: "date",
    hideValue: false,
  },
  {
    dataKey: "date_is_on_or_after",
    label: "is on or after",
    operandType: "date",
    group: "date",
    hideValue: false,
  },
  {
    dataKey: "date_is_empty",
    label: "is empty",
    group: "date",
    operandType: "date",
    hideValue: true,
  },
  {
    dataKey: "date_is_not_empty",
    label: "is not empty",
    group: "date",
    operandType: "date",
    hideValue: true,
  },
  {
    dataKey: "boolean_is_true",
    label: "is true",
    group: "boolean",
    operandType: "boolean",
    hideValue: true,
    onlySupportedBy: [
      "salesforce",
      "mysql",
      "postgres",
      "redshift",
      "snowflake",
      "hubspot",
      "shopify",
    ],
  },
  {
    dataKey: "boolean_is_false",
    label: "is false",
    group: "boolean",
    operandType: "boolean",
    hideValue: true,
    onlySupportedBy: [
      "salesforce",
      "mysql",
      "postgres",
      "redshift",
      "snowflake",
      "hubspot",
      "shopify",
    ],
  },
  {
    dataKey: "boolean_is_not_empty",
    label: "is not empty",
    group: "boolean",
    operandType: "boolean",
    hideValue: true,
    onlySupportedBy: [
      "salesforce",
      "mysql",
      "postgres",
      "redshift",
      "snowflake",
      "hubspot",
      "shopify",
    ],
  },
  {
    dataKey: "boolean_is_empty",
    label: "is empty",
    group: "boolean",
    operandType: "boolean",
    hideValue: true,
    onlySupportedBy: [
      "salesforce",
      "mysql",
      "postgres",
      "redshift",
      "snowflake",
      "hubspot",
      "shopify",
    ],
  },
  {
    dataKey: "picklist_is_not_empty",
    label: "is not empty",
    group: "picklist",
    operandType: "text",
    hideValue: true,
    onlySupportedBy: ["salesforce", "hubspot"],
  },
  {
    dataKey: "picklist_is_empty",
    label: "is empty",
    group: "picklist",
    operandType: "text",
    hideValue: true,
    onlySupportedBy: ["salesforce", "hubspot"],
  },
  {
    dataKey: "picklist_is_one_of",
    label: "is one of",
    group: "picklist",
    operandType: "text",
    hideValue: false,
    onlySupportedBy: ["salesforce", "hubspot"],
  },
  {
    dataKey: "picklist_is_equal_to",
    label: "is equal to",
    group: "picklist",
    operandType: "text",
    hideValue: false,
    onlySupportedBy: ["youtube_analytics"],
  },
  {
    dataKey: "picklist_is_not_one_of",
    label: "is not one of",
    group: "picklist",
    operandType: "text",
    hideValue: false,
    onlySupportedBy: ["salesforce", "hubspot"],
  },
  {
    dataKey: "multipicklist_is_not_empty",
    label: "is not empty",
    group: "multipicklist",
    operandType: "text",
    hideValue: true,
    onlySupportedBy: ["salesforce"],
  },
  {
    dataKey: "multipicklist_is_empty",
    label: "is empty",
    group: "multipicklist",
    operandType: "text",
    hideValue: true,
    onlySupportedBy: ["salesforce"],
  },
  {
    dataKey: "multipicklist_has_any_one_of",
    label: "has one of",
    group: "multipicklist",
    operandType: "text",
    hideValue: false,
    onlySupportedBy: ["salesforce"],
  },
  {
    dataKey: "multipicklist_does_not_have_any_of",
    label: "does not have one of",
    group: "multipicklist",
    operandType: "text",
    hideValue: false,
    onlySupportedBy: ["salesforce"],
  },
];

export const DATE_FILTER_OPTIONS: Array<{
  dataKey: string;
  label: string;
  dateFilterTypes: DateFilterType[];
  onlyForFilterTypes?: FilterOperatorType[];
}> = [
  { dataKey: "today", label: "Today", dateFilterTypes: ["today"] },
  { dataKey: "tomorrow", label: "Tomorrow", dateFilterTypes: ["tomorrow"] },
  { dataKey: "yesterday", label: "Yesterday", dateFilterTypes: ["yesterday"] },
  { dataKey: "current_week", label: "Current Week", dateFilterTypes: ["current_week"] },
  { dataKey: "current_month", label: "Current Month", dateFilterTypes: ["current_month"] },
  { dataKey: "current_year", label: "Current Year", dateFilterTypes: ["current_year"] },
  {
    dataKey: "last",
    label: "Last",
    dateFilterTypes: ["last_x_days", "last_x_weeks", "last_x_months", "last_x_years"],
  },
  {
    dataKey: "next",
    label: "Next",
    dateFilterTypes: ["next_x_days", "next_x_weeks", "next_x_months", "next_x_years"],
  },
  {
    dataKey: "exact_dates",
    label: "Exact Dates",
    dateFilterTypes: ["exact_dates"],
    onlyForFilterTypes: ["date_is_within"],
  },
  {
    dataKey: "exact_date",
    label: "Exact Date",
    dateFilterTypes: ["exact_date"],
    onlyForFilterTypes: [
      "date_is_before",
      "date_is_after",
      "date_is_on_or_before",
      "date_is_on_or_after",
    ],
  },
];
