import { CloudTable } from "@coeff/api";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ShareWithTeam, Button, Paper, PaperContent } from "../../components";
import { FREE_DOMAINS } from "../../constants/free-domains";
import { AppState } from "../../store";
import { ScheduleFormFields } from "../CreateCloudTable/ScheduleFormFields";
import { SourceInfoFormFields } from "../CreateCloudTable/SourceInfoFormFields";

import { CloudTableTitle } from "./CloudTableTitle";

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormActions = styled.div`
  margin: 8px 32px 0 32px;
`;

type Props = {
  authToken: string;
  isLoadingUpdateCloudTable?: boolean;
  isLoadingRunCloudTable?: boolean;
  initialData: CloudTable;
  onDelete: () => void;
  onSubmit: (
    cloudTable: CloudTable,
    options: { isSourceInfoDirty: boolean }
  ) => Promise<CloudTable>;
  setTopLevelError: (error: unknown) => void;
};

export const CloudTableDetailsForm: React.FC<Props> = ({
  authToken,
  initialData,
  onDelete,
  onSubmit,
  isLoadingUpdateCloudTable,
  isLoadingRunCloudTable,
  setTopLevelError,
}) => {
  const [cloudTable, setCloudTable] = React.useState<CloudTable>({ ...initialData });

  const [isSourceInfoDirty, setIsSourceInfoDirty] = React.useState(false);

  const handleChange = (cloudTable: CloudTable) => {
    setCloudTable(cloudTable);

    if (JSON.stringify(cloudTable.source_info) !== JSON.stringify(initialData.source_info)) {
      setIsSourceInfoDirty(true);
    }
  };

  const handleSubmit = async (cloudTable: CloudTable) => {
    setCloudTable(cloudTable);

    const response = await onSubmit(cloudTable, { isSourceInfoDirty });

    setCloudTable(response);

    setIsSourceInfoDirty(false);
  };

  const email = useSelector<AppState, string>(state => state.app.email);

  const domain = email.split("@")[1];

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(cloudTable);
      }}
    >
      <CloudTableTitle
        cloudTable={cloudTable}
        onChange={value => handleSubmit(value)}
        onDelete={onDelete}
      />

      <Paper variant={cloudTable.options?.schedule ? "success" : "default"}>
        <StyledPaperContent size="small">
          <ScheduleFormFields
            disabled={isLoadingRunCloudTable}
            value={cloudTable.options?.schedule}
            onChange={schedule =>
              handleChange({ ...cloudTable, options: { ...cloudTable.options, schedule } })
            }
            isIndented={true}
          />
        </StyledPaperContent>
      </Paper>

      <Paper>
        <StyledPaperContent size="small">
          <SourceInfoFormFields
            mode="update"
            authToken={authToken}
            disabled={isLoadingRunCloudTable}
            sourceInfo={cloudTable.source_info}
            onChange={source_info =>
              handleChange({
                ...cloudTable,
                source_info: source_info as CloudTable["source_info"],
              })
            }
            setTopLevelError={setTopLevelError}
          />
        </StyledPaperContent>
      </Paper>

      {FREE_DOMAINS.has(domain) ? null : (
        <ShareWithTeam
          disabled={isLoadingRunCloudTable}
          value={cloudTable.is_shared_with_domain}
          onChange={value => {
            handleChange({ ...cloudTable, is_shared_with_domain: value });
          }}
        />
      )}

      <FormActions>
        <Button
          disabled={isLoadingUpdateCloudTable || isLoadingRunCloudTable}
          block
          htmlType="submit"
          type="primary"
        >
          {isLoadingUpdateCloudTable || isLoadingRunCloudTable
            ? isSourceInfoDirty
              ? "Refreshing..."
              : "Saving..."
            : isSourceInfoDirty
            ? "Save and Refresh"
            : "Save"}
        </Button>
      </FormActions>
    </Form>
  );
};
