import { SubscriptionPlanType } from "@coeff/api";
import { Tag as TagAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";
import { planNameFromType } from "../utils";

const StyledTag = styled(TagAD)`
  border-radius: 5px;

  &.ant-tag-orange {
    background-color: #feedcb;
    color: #d17a1d;
    border-color: #d17a1d;
  }

  &.ant-tag-blue {
    background-color: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
    color: white;
  }

  &.ant-tag-green {
    background-color: ${COLORS.greenBg};
    color: ${COLORS.green};
  }

  &.ant-tag-red {
    background-color: ${COLORS.redBg};
    color: ${COLORS.red};
  }

  &.no-border {
    border: none;
  }

  &.planType {
    color: ${COLORS.black85};
    font-weight: 500;
    padding: 0px 5px;
    line-height: 1.5;
  }

  &.planType.plus {
    color: white;
  }
`;

export const Tag = StyledTag;

export const PlanTag = ({ planType }: { planType?: SubscriptionPlanType }) => {
  return planType ? (
    <StyledTag
      className={`planType ${planType}`}
      color={
        planType === "starter" ? COLORS.coeblue : planType === "pro" ? COLORS.yellow : COLORS.black8
      }
    >
      {planNameFromType(planType)}
    </StyledTag>
  ) : null;
};
