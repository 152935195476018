import { Avatar as AvatarAD, AvatarProps } from "antd";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

type Props = {
  iconColor?: string;
  iconSize: string;
  backgroundColor?: string;
} & AvatarProps;

const StyledAvatar = styled(AvatarAD)<
  AvatarProps & {
    $iconColor?: string;
    $iconSize: string;
    $backgroundColor?: string;
  }
>`
  && {
    background-color: ${props => props.$backgroundColor ?? COLORS.black4};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      width: ${props => props.$iconSize};
      height: ${props => props.$iconSize};
      path {
        fill: ${props => props.$iconColor && props.$iconColor};
      }
    }
  }
`;

export const Avatar: React.FC<Props> = props => {
  const { iconColor, iconSize, backgroundColor, ...rest } = props;

  return (
    <StyledAvatar
      $iconSize={iconSize}
      $iconColor={iconColor}
      $backgroundColor={backgroundColor}
      {...rest}
    />
  );
};
