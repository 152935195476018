import { Alert, AlertType, AlertRunStatus } from "@coeff/api";

import { columnIndexToColumnLetter } from "./sheets";

export const enum RunStatusLevel {
  FAILED = "FAILED",
  PARTIAL_SUCCESS = "PARTIAL_SUCCESS",
  SUCCESS = "SUCCESS",
}

export const CHANGED_DATA_VARIABLE_REGEX = /\{\{(newrow|changedrow)\.([A-Za-z]{1,3})\}\}/i;

export const getAlertChangedDataVariablePrefix = (alert: Alert): string => {
  return alert.alert_type === AlertType.NewRowsAppended ||
    alert.alert_type === AlertType.NewRowsWithId
    ? "newrow"
    : alert.alert_type === AlertType.MonitorChange ||
      alert.alert_type === AlertType.MonitorCondition
    ? "changedrow"
    : "";
};

export const getChangedDataVariableColumn = (variable: string): string | undefined => {
  const match = CHANGED_DATA_VARIABLE_REGEX.exec(variable)?.[2];

  CHANGED_DATA_VARIABLE_REGEX.lastIndex = 0;

  return match;
};

export const columnIndexToChangedDataVariable = (alert: Alert, columnIndex: number): string => {
  const prefix = getAlertChangedDataVariablePrefix(alert);

  return `{{${prefix}.${columnIndexToColumnLetter(columnIndex, { zeroBased: true })}}}`;
};

export const getAlertRunStatusCounts = (
  runStatus: AlertRunStatus | undefined
): {
  totalFailed: number;
  totalSent: number;
  emailsFailed: number;
  slacksFailed: number;
  emailsSent: number;
  slacksSent: number;
} => {
  return {
    totalFailed: (runStatus?.num_emails_failed ?? 0) + (runStatus?.num_slack_notifs_failed ?? 0),
    totalSent: (runStatus?.num_emails_sent ?? 0) + (runStatus?.num_slack_notifs_sent ?? 0),
    emailsFailed: runStatus?.num_emails_failed ?? 0,
    slacksFailed: runStatus?.num_slack_notifs_failed ?? 0,
    emailsSent: runStatus?.num_emails_sent ?? 0,
    slacksSent: runStatus?.num_slack_notifs_sent ?? 0,
  };
};

export const getAlertRunStatusLevel = (runStatus: AlertRunStatus | undefined): RunStatusLevel => {
  if (!runStatus) {
    return RunStatusLevel.SUCCESS;
  }

  if (runStatus.error_type) {
    return RunStatusLevel.FAILED;
  }

  if (runStatus.warning_type) {
    return RunStatusLevel.PARTIAL_SUCCESS;
  }

  const runCounts = getAlertRunStatusCounts(runStatus);

  if (runCounts.totalFailed > 0) {
    if (runCounts.totalSent > 0) {
      return RunStatusLevel.PARTIAL_SUCCESS;
    }

    return RunStatusLevel.FAILED;
  }

  return RunStatusLevel.SUCCESS;
};

export const getRunStatusLevelBackgroundColor = ({
  runStatusLevel,
  isCurrentRunResponse,
}: {
  runStatusLevel: RunStatusLevel;
  isCurrentRunResponse: boolean;
}) => {
  switch (runStatusLevel) {
    case RunStatusLevel.FAILED:
      return "#FFF6F7";
    case RunStatusLevel.PARTIAL_SUCCESS:
      return "#FFFDF8";
    case RunStatusLevel.SUCCESS:
      return isCurrentRunResponse ? "#EFFEF7" : "white";
  }
};

export const getRunStatusLevelFormatted = (runStatusLevel: RunStatusLevel) => {
  switch (runStatusLevel) {
    case RunStatusLevel.FAILED:
      return "Failed";
    case RunStatusLevel.PARTIAL_SUCCESS:
      return "Warning";
    case RunStatusLevel.SUCCESS:
      return "Success";
  }
};
