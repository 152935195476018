import React, { useState } from "react";
import styled from "styled-components";

import { CLOUD_TABLE_ICON_COLORS, CLOUD_TABLE_ICON_NAMES } from "../constants";

import { TemplateIcons, Vector } from "./Icons";

const IconContainer = styled.div<{ opacity: string }>`
  position: relative;
  margin: 6px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: ${props => props.opacity};
  cursor: pointer;
`;

const ColorContainer = styled.div<{ color: string }>`
  position: relative;
  margin: 6px;
  background-color: ${props => props.color};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  width: 220px;
  flex-wrap: wrap;
`;

const StyledVector = styled(Vector)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

type Props = {
  color?: string;
  icon?: string;
  onColorChange: (color: string) => void;
  onIconChange: (icon: string) => void;
};

export const IconPicker: React.FC<Props> = ({
  color,
  icon,
  onColorChange,
  onIconChange,
}: Props) => {
  const [selectedColor, setSelectedColor] = useState(color);

  const [selectedIcon, setSelectedIcon] = useState(icon);

  const [hoveredIconsByName, setHoveredIconsByName] = useState({});

  const onColorClick = (colorHex: string) => {
    setSelectedColor(colorHex);
    onColorChange(colorHex);
  };

  const onIconClick = (iconName: string) => {
    setSelectedIcon(iconName);
    onIconChange(iconName);
  };

  return (
    <>
      <StyledDiv>
        {Object.entries(CLOUD_TABLE_ICON_COLORS).map(([_, colorHex]) => (
          <ColorContainer color={colorHex} onClick={() => onColorClick(colorHex)}>
            {colorHex === selectedColor && <StyledVector />}
          </ColorContainer>
        ))}
      </StyledDiv>

      <StyledDiv>
        {Object.entries(CLOUD_TABLE_ICON_NAMES).map(([_, icon_name]) => {
          let opacity = "100%";

          const TemplateIcon = React.createElement(TemplateIcons[icon_name], {});

          if (icon_name !== selectedIcon) {
            opacity = "30%";
          }

          if (hoveredIconsByName[icon_name]) {
            opacity = "65%";
          }

          return (
            <IconContainer
              opacity={opacity}
              onMouseEnter={() =>
                setHoveredIconsByName(prevState => ({ ...prevState, [icon_name]: true }))
              }
              onMouseLeave={() =>
                setHoveredIconsByName(prevState => ({ ...prevState, [icon_name]: false }))
              }
              onClick={() => onIconClick(icon_name)}
            >
              {TemplateIcon}
            </IconContainer>
          );
        })}
      </StyledDiv>
    </>
  );
};
