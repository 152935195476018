import { AxiosError } from "axios";

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (
    typeof error === "object" &&
    error !== null &&
    error.hasOwnProperty("request") &&
    error.hasOwnProperty("response")
  );
};

export type AxiosErrorWithReason = AxiosError & {
  response: NonNullable<AxiosError["response"] & { data: { reason: string } }>;
};

export type AxiosErrorWithMessage = AxiosError & {
  response: NonNullable<AxiosError["response"] & { data: { message: string } }>;
};

export const isAxiosErrorWithReason = (error: unknown): error is AxiosErrorWithReason => {
  return (
    isAxiosError(error) &&
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data !== null &&
    error.response.data.hasOwnProperty("reason")
  );
};

export const isAxiosErrorWithMessage = (error: unknown): error is AxiosErrorWithMessage => {
  return (
    isAxiosError(error) &&
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data !== null &&
    error.response.data.hasOwnProperty("message")
  );
};

export const isAxiosErrorDataSourceNeedsReauth = (
  error: unknown
): error is AxiosErrorWithReason => {
  return isAxiosErrorWithReason(error) && error.response.data.reason === "data_source_needs_reauth";
};

export const isAxiosErrorWithSSOAuthFailedMessage = (
  error: unknown
): error is AxiosErrorWithMessage => {
  return isAxiosErrorWithMessage(error) && error.response.data.message === "SSO_AUTH_FAILED";
};
