import styled from "styled-components";

import { COLORS } from "../../../constants";

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    font-weight: 800;
    font-size: 28px;
    line-height: 56px;
  }

  .details {
    flex: 1;
    margin-left: 1rem;

    .ant-breadcrumb {
      margin-bottom: 4px;

      .ant-breadcrumb-link {
        font-weight: 600;
      }
    }

    h1 {
      line-height: 1;
      margin: 0px;
      font-weight: 700;
    }

    .refreshDate {
      color: ${COLORS.black45};
    }
  }
`;
