import RFB from "@novnc/novnc/core/rfb";
import React, { useEffect, useRef } from "react";

type NoVNCContainerProps = {
  url: string;
  password: string;
};

export const NoVNCContainer: React.FC<NoVNCContainerProps> = ({ url, password }) => {
  const noVNCContainerRef = useRef(null);

  useEffect(() => {
    let rfb;

    if (noVNCContainerRef.current) {
      rfb = new RFB(noVNCContainerRef.current, url, {
        credentials: {
          password: password,
        },
      });

      rfb.scaleViewport = true; // Optionally scale the viewport to fit the size of the container
      rfb.resizeSession = true; // Optionally resize the session to fit the size of the container
    }

    return () => {
      if (rfb) {
        rfb.disconnect();
      }
    };
  }, [url, password]);

  return (
    <div ref={noVNCContainerRef} style={{ width: "100%", height: "100%", border: "none" }}></div>
  );
};
