import { ACTIONS, API_ROUTES } from "../constants";
import { UserExtraData } from "../types";

import { AppThunkAction } from ".";

export const updateUserSettings =
  (body: UserExtraData): AppThunkAction<Promise<void>> =>
  async (dispatch, __, { api }) => {
    const { headers, status, ...response } = await api.request({
      method: "POST",
      url: API_ROUTES.user_extras,
      body,
    });

    dispatch({ type: ACTIONS.SAVE_USER_SETTINGS_SUCCESS });
  };

export const getUserSettings =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, __, { api }) => {
    const { headers, status, ...response } = await api.request({
      method: "GET",
      url: `${API_ROUTES.user_extras}?user_extra_type=user_settings`,
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({ type: ACTIONS.FETCH_USER_SETTINGS_SUCCESS, payload: response });
  };
