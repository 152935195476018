import { ExclamationCircleFilled } from "@ant-design/icons";
import { DefaultApi, DeleteLookerAppInfoRequest, UpsertLookerAppInfoRequest } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Ajv, { JSONSchemaType } from "ajv";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  Checkbox,
  FlexRow,
  Form,
  FormItem,
  Input,
  LoaderWithPerfTimings,
  message,
  Modal,
  SettingsLayout,
  Typography,
} from "../components";
import { COLORS } from "../constants";
import { getBaseUrl, sentryCapture, useApiContext, useTrack } from "../utils";

const HelperText = styled.div`
  color: ${COLORS.black45};
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px -15px -10px -15px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

const StyledAlert = styled(Alert)<{ $background?: string; $marginBottom?: string }>`
  &.ant-alert-with-description {
    max-width: fit-content;
    padding: 10px;
    .ant-alert-icon {
      svg {
        height: 18px;
      }
    }
    ${({ $background }) => ($background ? `background-color: ${$background};` : "")}
    ${({ $marginBottom }) => ($marginBottom ? `margin-bottom: ${$marginBottom};` : "")}
`;

const StyledDeleteButton = styled(Button)`
  max-width: fit-content;
`;

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-body {
      padding: 5px;
    }
  }
`;

const ajv = new Ajv();
const schema: JSONSchemaType<UpsertLookerAppInfoRequest> = {
  type: "object",
  properties: {
    base_url: { type: "string" },
    client_id: { type: "string" },
    client_secret: { type: "string" },
    domain: { type: "string", nullable: true },
    url_based_auth_uuid: { type: "string", nullable: true },
  },
  required: ["base_url", "client_id", "client_secret"],
};

const validate = ajv.compile(schema);

const fetchLookerAppInfo = async (api: DefaultApi) => {
  const response = await api.listLookerAppInfo();
  return response?.data?.apps_list;
};

const deleteLookerAppInfo = async (
  api: DefaultApi,
  delete_looker_app_info_request: DeleteLookerAppInfoRequest
) => {
  await api.deleteLookerAppInfo({ delete_looker_app_info_request });
};

const quantify = (count: number, singular: string, multiple: (n: number) => string): string =>
  count === 1 ? singular : multiple(count);

export const LookerOAuthSettings = () => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);

  const [form] = useForm<UpsertLookerAppInfoRequest>();

  const track = useTrack();

  const [errorMessage, setErrorMessage] = React.useState("");
  const location = useLocation();
  const history = useHistory();

  const redirectOnAdd = new URLSearchParams(location.search).get("back_on_add");

  React.useEffect(() => {
    document.title = "Looker OAuth Settings - Coefficient Workspace";
    track("workspace_looker_oauth_settings_viewed");
  }, []);

  const { apiClient: api } = useApiContext();

  const {
    data: lookerAppInfo,
    refetch,
    isLoading,
  } = useQuery(
    [QueryCacheKey.LOOKER_APP_INFO, location.search],
    async () => {
      const apps = await fetchLookerAppInfo(api);
      const guid = new URLSearchParams(location.search).get("app_guid");

      if (apps && guid) {
        const givenapp = apps.find(app => app.client_app_guid === guid);
        return givenapp;
      }

      return apps?.[0];
    },
    {
      cacheTime: 0,
      staleTime: 0,
      onSuccess: data => {
        if (data) {
          form.setFieldsValue({
            base_url: data.base_url,
            client_id: data.client_id,
            client_secret: "___________",
          });
        }
      },
    }
  );

  const checkConnectionMutation = useMutation(
    async (data: UpsertLookerAppInfoRequest) => {
      const response = await api.lookerCheckConnection({
        looker_check_connection_request: {
          credentials: {
            url: getBaseUrl(data.base_url),
            client_id: data.client_id,
            client_secret: data.client_secret,
          },
        },
      });

      if (response.data.error) {
        const error = {
          title: response.data.title || "Could not verify Client ID and Secret",
          message:
            response.data.source_error ||
            "Could not verify credentials. Please check your inputs and try again.",
        };
        setErrorMessage(`${error.title}: ${error.message}`);
        throw new Error(error.message);
      }

      const meta = await api.lookerGetDataSourceOwnerMetaData({
        looker_get_data_source_owner_meta_data_request: {
          credentials: {
            url: getBaseUrl(data.base_url),
            client_id: data.client_id,
            client_secret: data.client_secret,
          },
        },
      });

      if (!meta.data.is_admin) {
        return {
          data: { valid: false, error: `Admin client id secret are required to configure OAuth` },
        };
      }

      if (meta.data.already_configured) {
        return {
          data: {
            valid: false,
            error: `Looker OAuth is already configured for the domain. Please delete the existing configuration to add a new one`,
          },
        };
      }

      return response;
    },
    {
      onSuccess: response => {
        if (response.data?.valid) {
          upsertAppInfoMutation.mutate(
            form.getFieldsValue(["base_url", "client_id", "client_secret"])
          );
        } else {
          setErrorMessage(response.data?.error || "Invalid credentials");
        }
      },
      onError: (error: Error) => {
        message.error("Failed to verify credentials");
        sentryCapture({
          error,
          name: "LookerOAuthSettings: POST /api/data_sources/looker/check_connection",
        });
      },
    }
  );

  const upsertAppInfoMutation = useMutation(
    (json: UpsertLookerAppInfoRequest) =>
      api.upsertLookerAppInfo({
        upsert_looker_app_info_request: {
          base_url: getBaseUrl(json.base_url),
          client_id: json.client_id,
          client_secret: json.client_secret,
        },
      }),
    {
      onSuccess: () => {
        message.success("Saved successfully");
        setShowSuccessModal(true);
        refetch();
        if (redirectOnAdd) {
          history.goBack();
        }
      },
    }
  );

  const deleteAppInfoMutation = useMutation(
    (deleteRequest: DeleteLookerAppInfoRequest) => deleteLookerAppInfo(api, deleteRequest),
    {
      onSuccess: () => {
        message.success("Deleted successfully");
        const guid = new URLSearchParams(location.search).get("app_guid");
        if (guid) {
          history.goBack();
        }
      },
    }
  );

  if (isLoading) {
    return (
      <SettingsLayout title="Looker OAuth Settings" importTypeIcon="looker">
        <LoaderWithPerfTimings name="LookerOAuthSettings" />
      </SettingsLayout>
    );
  }

  const handleOnFinish = (data: UpsertLookerAppInfoRequest) => {
    const valid = validate(data);
    if (valid) {
      checkConnectionMutation.mutate(data);
    }
  };

  const dataImportCount = lookerAppInfo?.data_import_count ?? 0;
  const dataSourceCount = lookerAppInfo?.data_source_count ?? 0;
  const qImports = quantify(dataImportCount, "1 import", n => `${n} imports`);
  const qConnections = quantify(dataSourceCount, "1 connection", n => `${n} connections`);

  return (
    <SettingsLayout title="Looker OAuth Settings" importTypeIcon="looker">
      {errorMessage && (
        <StyledAlert
          showIcon
          icon={<ExclamationCircleFilled />}
          type="error"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      <Form
        layout="vertical"
        onFinish={handleOnFinish}
        onBeforeInput={() => setErrorMessage("")}
        wrapperCol={{ span: 14 }}
        initialValues={{
          base_url: lookerAppInfo?.base_url || "",
          client_id: lookerAppInfo?.client_app_guid || "",
          client_secret: lookerAppInfo ? "abcdef" : "",
        }}
        disabled={
          Boolean(lookerAppInfo) ||
          isLoading ||
          upsertAppInfoMutation.isLoading ||
          deleteAppInfoMutation.isLoading
        }
        form={form}
      >
        {lookerAppInfo ? (
          <StyledAlert
            showIcon
            icon={<ExclamationCircleFilled />}
            type="info"
            message="Looker OAuth has been configured for your domain"
            description="Connect to Looker in the Coefficient sidebar in your spreadsheet."
            $marginBottom="30px"
          />
        ) : (
          <HelperText>
            Allows users from your domain to connect to Looker using OAuth. You must be a Looker
            admin to configure.{" "}
            <a
              href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
              target="_blank"
              onClick={() => {
                track("help_center_clicked", {
                  event_from: "workspace_looker_oauth_settings",
                  import_data_source: "looker",
                });
              }}
            >
              Help guide
            </a>
          </HelperText>
        )}
        <FormItem
          label="Base URL"
          name="base_url"
          rules={[{ required: true, message: "URL is required" }]}
        >
          <Input placeholder="https://example.cloud.looker.com" />
        </FormItem>
        <FormItem
          label="Client ID"
          name="client_id"
          rules={[{ required: true, message: "Client ID is required" }]}
        >
          <Input placeholder="Client ID" />
        </FormItem>
        <FormItem
          label="Client Secret"
          name="client_secret"
          rules={[{ required: true, message: "Client Secret is required" }]}
        >
          <Input type="password" placeholder="Client Secret" />
        </FormItem>
        {!lookerAppInfo && (
          <FlexRow gap={1}>
            {redirectOnAdd && (
              <Button
                type="text"
                onClick={() => history.goBack()}
                style={{ marginLeft: 10 }}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </FlexRow>
        )}
      </Form>
      {lookerAppInfo && (
        <StyledDeleteButton
          htmlType="button"
          onClick={() => {
            setDeleteConfirmed(false);
            setShowDeleteModal(true);
          }}
          loading={deleteAppInfoMutation.isLoading}
        >
          Delete
        </StyledDeleteButton>
      )}
      <StyledModal open={showSuccessModal} onCancel={() => setShowSuccessModal(false)}>
        <StyledAlert
          showIcon
          banner
          type="success"
          message="Saved successfully"
          description={`Make sure Looker OAuth is working properly by clicking "Test connection" in the
          Coefficient Sidebar in your spreadsheet`}
          $background="white"
        />
      </StyledModal>
      <Modal open={showDeleteModal} onCancel={() => setShowDeleteModal(false)}>
        <Typography fontWeight="bold" variant="h6" gutterBottom>
          Please Confirm
        </Typography>
        <Typography color="textSecondary">
          Are you sure you want to delete this Looker configuration?
        </Typography>

        {(dataImportCount > 0 || dataSourceCount > 0) && (
          <StyledAlert
            showIcon
            banner
            icon={<ExclamationCircleFilled />}
            type="warning"
            message="Warning!"
            description={`${qConnections} and ${qImports} will stop refreshing if this configuration is deleted.`}
          />
        )}
        <StyledCheckbox
          onChange={() => setDeleteConfirmed(prev => !prev)}
          checked={deleteConfirmed}
        >
          Got it, please proceed
        </StyledCheckbox>
        <Footer>
          <Button
            type="text"
            disabled={deleteAppInfoMutation.isLoading}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="text"
            danger
            disabled={!deleteConfirmed || deleteAppInfoMutation.isLoading}
            onClick={() => {
              if (lookerAppInfo?.client_app_guid && deleteConfirmed) {
                deleteAppInfoMutation.mutate({ client_app_guid: lookerAppInfo?.client_app_guid });
              }
              setShowDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </Footer>
      </Modal>
    </SettingsLayout>
  );
};
