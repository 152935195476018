import React from "react";
import styled from "styled-components";

import {
  Button,
  PaperContent,
  Paper,
  PaperContentIndent,
  SharedUser,
  TextWithIcon,
  Switch,
  Typography,
  ShareWithTeamModal,
} from "../../components";

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledPaperContentIndent = styled(PaperContentIndent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  isLoading?: boolean;
  initialValue: boolean | undefined;
  onSubmit: (data: boolean | undefined) => void;
  buttonLabel: string;
};

export const ShareWithTeamForm: React.FC<Props> = props => {
  const { initialValue: value, isLoading, onSubmit, buttonLabel } = props;

  const [isShared, setIsShared] = React.useState(value);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Paper variant={isShared ? "info" : "default"}>
        <StyledPaperContent size="small">
          <TextWithIcon
            icon={<SharedUser />}
            title={<Typography fontWeight="bold">Share with your team</Typography>}
            action={
              <Switch
                disabled={isLoading}
                size="small"
                checked={isShared}
                onChange={checked => setIsShared(checked)}
              />
            }
          />

          <StyledPaperContentIndent>
            <Typography fontWeight={600} onClick={() => setIsModalOpen(true)}>
              <a>Learn more</a>
            </Typography>

            <Button block disabled={isLoading} type="primary" onClick={() => onSubmit(isShared)}>
              {buttonLabel}
            </Button>
          </StyledPaperContentIndent>
        </StyledPaperContent>
      </Paper>

      <ShareWithTeamModal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
