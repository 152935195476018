import { CloudTable } from "@coeff/api";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PartialCloudTable, useGoogleDriveAuthToken } from "../../api";
import {
  Button,
  CenterAlign,
  DateIcon,
  GoogleAuthModal as GoogleAuthModal,
  LoaderWithPerfTimings,
  Paper,
  PaperContent,
  SharedUser,
  Source,
  SourceFile,
  Typography,
} from "../../components";
import { COLORS, FREE_DOMAINS } from "../../constants";
import { AppState } from "../../store";
import {
  MissingDataSourceError,
  getCloudTableIconDefaults,
  isAxiosErrorDataSourceNeedsReauth,
} from "../../utils";

import { CreateCloudTableFormStep } from "./CreateCloudTable";
import { Metadata } from "./Metadata";
import { MetadataForm } from "./MetadataForm";
import { Schedule } from "./Schedule";
import { ScheduleForm } from "./ScheduleForm";
import { ShareWithTeamForm } from "./ShareWithTeamForm";
import { SourceInfo } from "./SourceInfo";
import { SourceInfoForm } from "./SourceInfoForm";

const Form = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:before {
    content: " ";
    position: absolute;
    width: 4px;
    background: ${({ theme }) => theme.colors.black4};
    top: 0;
    bottom: 0;
    left: 18px;
    z-index: -1;
  }
`;

const DisabledStep = styled(PaperContent)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 8px;
`;

const DisabledStepText = styled.div`
  margin-top: 1px;
`;

type Props = {
  step: CreateCloudTableFormStep;
  cloudTable: PartialCloudTable;
  isSubmitting: boolean;
  setStep: (step: CreateCloudTableFormStep) => void;
  onStepSubmit: (cloudTable: PartialCloudTable) => void;
  onChange: (cloudTable: PartialCloudTable) => void;
  isFetchingPreviewData: boolean;
  setTopLevelError: (error: unknown) => void;
};

export const CreateCloudTableForm: React.FC<Props> = ({
  step,
  setStep,
  cloudTable,
  onChange,
  onStepSubmit,
  isSubmitting,
  isFetchingPreviewData,
  setTopLevelError,
}) => {
  const history = useHistory();

  const {
    authToken,
    isLoading: isLoadingAuthToken,
    error: errorAuthToken,
  } = useGoogleDriveAuthToken();

  const email = useSelector<AppState, string>(state => state.app.email);

  const domain = email.split("@")[1];

  const handleModalClose = () => {
    if (errorAuthToken) {
      history.push("/dashboard/datasets");
    }
  };

  if (isLoadingAuthToken) {
    return (
      <CenterAlign>
        <LoaderWithPerfTimings name="CreateCloudTableForm.authToken" size="large" />
      </CenterAlign>
    );
  }

  return (
    <>
      {errorAuthToken &&
      !(errorAuthToken instanceof MissingDataSourceError) &&
      !isAxiosErrorDataSourceNeedsReauth(errorAuthToken) ? (
        <Paper>
          <PaperContent>
            <Typography fontWeight="bold" gutterBottom>
              Error
            </Typography>

            <Typography gutterBottom>Unable to load dataset</Typography>

            <Button noPadding type="link" onClick={() => history.push("/dashboard/datasets")}>
              Back to Datasets
            </Button>
          </PaperContent>
        </Paper>
      ) : authToken ? (
        <Form>
          {step === CreateCloudTableFormStep.SOURCE_INFO ? (
            <SourceInfoForm
              variant="info"
              authToken={authToken}
              sourceInfo={cloudTable.source_info}
              loading={isFetchingPreviewData}
              setTopLevelError={setTopLevelError}
              onChange={sourceInfo =>
                onChange({
                  ...cloudTable,
                  source_info: {
                    ...cloudTable.source_info,
                    ...sourceInfo,
                  },
                })
              }
              onSubmit={source_info =>
                onStepSubmit({
                  ...cloudTable,
                  ...getCloudTableIconDefaults(source_info),
                  cloud_table_name:
                    cloudTable.cloud_table_name ?? source_info.google_sheets_tab_name,
                  source_info,
                })
              }
            />
          ) : step > CreateCloudTableFormStep.SOURCE_INFO && cloudTable.source_info ? (
            <SourceInfo
              disabled={isSubmitting}
              sourceInfo={cloudTable.source_info}
              onEditClick={() => setStep(CreateCloudTableFormStep.SOURCE_INFO)}
            />
          ) : (
            <Paper>
              <DisabledStep size="small">
                <Source />
                <DisabledStepText>Source Sheet</DisabledStepText>
              </DisabledStep>
            </Paper>
          )}
          {step === CreateCloudTableFormStep.METADATA ? (
            <MetadataForm variant="info" initialData={cloudTable} onSubmit={onStepSubmit} />
          ) : step > CreateCloudTableFormStep.METADATA ? (
            <Metadata
              disabled={isSubmitting}
              cloudTable={cloudTable as CloudTable}
              onEditClick={() => setStep(CreateCloudTableFormStep.METADATA)}
            />
          ) : (
            <Paper>
              <DisabledStep size="small">
                <SourceFile />
                <DisabledStepText>Name & Description</DisabledStepText>
              </DisabledStep>
            </Paper>
          )}
          {step === CreateCloudTableFormStep.SCHEDULE ? (
            <ScheduleForm
              initialData={cloudTable.options?.schedule}
              isLoading={isSubmitting}
              buttonLabel={
                FREE_DOMAINS.has(domain)
                  ? isSubmitting
                    ? "Creating Dataset..."
                    : "Create Dataset"
                  : "Next"
              }
              onSubmit={schedule =>
                onStepSubmit({
                  ...cloudTable,
                  options: {
                    ...cloudTable.options,
                    schedule,
                  },
                })
              }
            />
          ) : step > CreateCloudTableFormStep.SCHEDULE ? (
            <Schedule
              disabled={isSubmitting}
              schedule={cloudTable.options?.schedule}
              onEditClick={() => setStep(CreateCloudTableFormStep.SCHEDULE)}
            />
          ) : (
            <Paper>
              <DisabledStep size="small">
                <DateIcon fill={COLORS.black85} />
                <DisabledStepText>Refresh Schedule</DisabledStepText>
              </DisabledStep>
            </Paper>
          )}

          {!FREE_DOMAINS.has(domain) ? (
            step === CreateCloudTableFormStep.SHARING ? (
              <ShareWithTeamForm
                isLoading={isSubmitting}
                initialValue={cloudTable.is_shared_with_domain}
                onSubmit={value => onStepSubmit({ ...cloudTable, is_shared_with_domain: value })}
                buttonLabel={isSubmitting ? "Creating Dataset..." : "Create Dataset"}
              />
            ) : (
              <Paper>
                <DisabledStep size="small">
                  <SharedUser />
                  <DisabledStepText>Sharing</DisabledStepText>
                </DisabledStep>
              </Paper>
            )
          ) : null}
        </Form>
      ) : null}

      <GoogleAuthModal
        redirectPath="/datasets/create"
        open={
          errorAuthToken instanceof MissingDataSourceError ||
          isAxiosErrorDataSourceNeedsReauth(errorAuthToken)
        }
        onCancel={() => handleModalClose()}
      />
    </>
  );
};
