import { Alert as AlertAD } from "antd";
import { AlertProps } from "antd/lib/alert";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledAlert = styled(AlertAD)`
  border-radius: 5px;
  line-height: 1.3;

  &.ant-alert-error {
    background-color: ${COLORS.redBg};
  }

  &.ant-alert-info {
    background-color: ${COLORS.coeblue};
    border-color: ${COLORS.coeblue};
  }

  &.ant-alert-warning {
    background-color: ${COLORS.yellowBg4};
    border-color: ${COLORS.yellowBg4};
  }

  &.ant-alert-with-description {
    &.ant-alert-no-icon {
      padding: 5px 15px;
    }

    .ant-alert-message {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
`;

export const Alert = (props: AlertProps) => <StyledAlert {...props} />;

export const InlineAlert = styled(Alert)`
  display: flex;
  align-items: center;
  border-radius: 0;

  .ant-alert-content {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &.ant-alert-with-description {
    padding: 6px 12px;
  }

  .ant-alert-description {
    line-height: 1.3;
  }
`;
