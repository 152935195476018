import { QueryClient } from "@tanstack/react-query";

export enum QueryCacheKey {
  DATA_IMPORTS = "DATA_IMPORTS",
  DATA_IMPORT_RUNS = "DATA_IMPORT_RUNS",
  DATA_SOURCES = "DATA_SOURCES",
  DATA_SOURCE = "DATA_SOURCE",
  CLOUD_TABLES = "CLOUD_TABLES",
  CLOUD_TABLE = "CLOUD_TABLE",
  CLOUD_TABLE_PREVIEW_DATA = "CLOUD_TABLE_PREVIEW_DATA",
  CLOUD_TABLE_DATA = "CLOUD_TABLE_DATA",
  GOOGLE_DRIVE_AUTH_TOKEN = "GOOGLE_DRIVE_AUTH_TOKEN",
  DOMAIN_USERS = "DOMAIN_USERS",
  DOMAIN_INVITATIONS = "DOMAIN_INVITATIONS",
  BILLING_DETAILS = "BILLING_DETAILS",
  GOOGLE_API_CURRENT_USER = "GOOGLE_API_CURRENT_USER",
  GOOGLE_API_SPREADSHEET_DATA = "GOOGLE_API_SPREADSHEET_DATA",
  ADMIN_EXT_SETTINGS = "ADMIN_EXT_SETTINGS",
  SNOWFLAKE_APP_INFO = "SNOWFLAKE_APP_INFO",
  LOOKER_APP_INFO = "LOOKER_APP_INFO",
  NETSUITE_APP_INFO = "NETSUITE_APP_INFO",
  WRITEBACKS_SCHEMA = "WRITEBACKS_SCHEMA",
  DATA_IMPORT_RUN_BY_ID = "DATA_IMPORT_RUN_BY_ID",
  USER_SSO_INFO = "USER_SSO_INFO",
  ALERTS = "ALERTS",
  ALERT_RUN_HISTORY = "ALERT_RUN_HISTORY",
  VERIFY_OAUTH_TOKEN = "VERIFY_OAUTH_TOKEN",
  LOOKER_REQUEST_INFO_BY_UUID = "LOOKER_REQUEST_INFO_BY_UUID",
}

export const initQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        networkMode: "always",
        staleTime: 1000 * 60 * 5,
      },
      mutations: {
        networkMode: "always",
      },
    },
  });
};
