import { ModalProps } from "antd";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";
import { DataSourceTypeDisplayNames } from "../utils";

import { Alert } from "./Alert";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { Typography } from "./Typography";

const StyledAlert = styled(Alert)`
  &.ant-alert-with-description {
    margin-top: 20px;
    padding: 10px;

    .ant-alert-icon {
      margin-right: 6px;

      svg {
        height: 18px;
      }
    }

    .ant-alert-message {
      margin-bottom: 6px;
    }
  }
`;

const ModalActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const ModalButton = styled(Button)<{ $color: string }>`
  &.ant-btn-link {
    padding: 4px 0;
    color: ${props => props.$color};
  }
`;

type Props = ModalProps & {
  isLoading?: boolean;
  dataImportCount: number;
  dataSourceCount: number;
  dataSourceType: string;
};

const quantify = (count: number, singular: string, multiple: (n: number) => string): string =>
  count === 1 ? singular : multiple(count);

export const ConfirmDeleteDataSourceAppInfoModal = (props: Props) => {
  const { dataImportCount, dataSourceCount, dataSourceType, isLoading, onCancel, onOk } = props;
  const qImports = quantify(dataImportCount, "1 import", n => `${n} imports`);
  const qConnections = quantify(dataSourceCount, "1 connection", n => `${n} connections`);

  return (
    <Modal {...props}>
      <Typography fontWeight="bold" variant="h6" gutterBottom>
        Please confirm
      </Typography>
      <Typography>
        {`Are you sure you want to delete your team's ${DataSourceTypeDisplayNames[dataSourceType]} OAuth configuration?`}
      </Typography>

      {(dataImportCount > 0 || dataSourceCount > 0) && (
        <StyledAlert
          type="warning"
          showIcon
          message="Warning!"
          description={`${qConnections} and ${qImports} will stop refreshing if this configuration is deleted.`}
        />
      )}

      <ModalActions>
        <ModalButton $color={COLORS.coeblue5} disabled={isLoading} type="link" onClick={onCancel}>
          Cancel
        </ModalButton>

        <ModalButton $color={COLORS.red} disabled={isLoading} type="link" onClick={onOk}>
          {isLoading ? "Deleting..." : "Delete"}
        </ModalButton>
      </ModalActions>
    </Modal>
  );
};
