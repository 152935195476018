import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { EllipsizedText } from "./EllipsizedText";

const TagsWrap = styled.div`
  height: auto;
  overflow: hidden;
  max-height: 54px;
  transition: max-height 0.3s ease;
`;

const Tag = styled.span`
  background: #d4ebff;
  display: inline-block;
  margin: 2px;
  padding: 3px 5px;
  border-radius: 5px;
  color: ${COLORS.black85};
  max-width: 100px;
  font-weight: 500;
`;

const ShowAllLink = styled.span`
  display: inline-block;
  color: ${COLORS.black85};
  cursor: pointer;
  font-weight: 500;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

type TagsProps = {
  tags: string[];
};

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(true);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current?.scrollHeight !== undefined) {
      setShowAll(divRef.current?.scrollHeight <= 84 ? false : true);
    }
  }, [tags]);

  return (
    <>
      <TagsWrap data-testid="tags" ref={divRef} style={{ maxHeight: expanded ? "1000px" : "84px" }}>
        {tags.map((tag, index) => (
          <Tag key={index} className="coeff__tag">
            <EllipsizedText text={tag} />
          </Tag>
        ))}
      </TagsWrap>
      {showAll && (
        <ShowAllLink onClick={() => setExpanded(!expanded)}>
          + show {expanded ? "less" : "all"}
        </ShowAllLink>
      )}
    </>
  );
};
