import React, { useRef } from "react";
import styled from "styled-components";

import { useSentryMountUnmountTransaction } from "../utils";

const Container = styled.div`
  iframe {
    display: none;
  }
`;

export const EventAttributionIframe = ({
  email,
  event_name,
  event_props,
}: {
  email: string;
  event_name: string;
  event_props?: object;
}) => {
  useSentryMountUnmountTransaction({ name: "EventAttributionIframe" });

  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Create URL with properly encoded parameters
  const createIframeSrc = () => {
    const baseUrl = "https://coefficient.io/addon_event_frame";
    const params = new URLSearchParams();

    params.append("email", email);
    params.append("event_name", event_name);

    if (event_props) {
      params.append("event_props", JSON.stringify(event_props));
    }

    return `${baseUrl}?${params.toString()}`;
  };

  return (
    <Container>
      <iframe ref={iframeRef} src={createIframeSrc()} />
    </Container>
  );
};
