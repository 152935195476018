import { DataImportSchedule } from "@coeff/api";
import moment from "moment-timezone";
import * as React from "react";

import { WEEKDAYS } from "../constants";

import { capitalize, getTimeZone, scheduledHourToTime } from ".";

export const getTZAdjustedHour = (schedule: DataImportSchedule) => {
  if (schedule.tz_name && schedule.hour && schedule.tz_name !== getTimeZone()) {
    const localOffset = moment().utcOffset();

    const scheduleOffset = moment.tz(schedule.tz_name).utcOffset();

    const diffInHours = (localOffset - scheduleOffset) / 60;

    return Math.round(schedule.hour + diffInHours + 24) % 24;
  }

  return schedule.hour ?? 0;
};

export const getScheduleSummaryText = (
  schedule: DataImportSchedule | null | undefined,
  snapshot_schedule?: DataImportSchedule | null,
  options?: { capitalize?: boolean; prefixText?: string; skipPrefix?: boolean }
): React.ReactChild | null => {
  let snapshotText = "";
  if (snapshot_schedule) {
    snapshotText =
      snapshot_schedule.repeat_type === "weekly"
        ? "with weekly snapshots"
        : snapshot_schedule.repeat_type === "monthly"
        ? "with monthly snapshots"
        : "";
  }

  const prefixText = options?.skipPrefix ? "" : options?.prefixText ?? "Refreshes ";

  if (!schedule) {
    return null;
  }

  let scheduleText =
    schedule.repeat_type === "hourly"
      ? `${prefixText}${
          !schedule.every_n_hours || schedule.every_n_hours === 1
            ? "hourly"
            : `every ${schedule.every_n_hours} hours`
        }`
      : schedule.repeat_type === "weekly"
      ? `${prefixText}${schedule
          .days_of_week!.map(d => WEEKDAYS[d].shortLabel)
          .join(", ")} at ${scheduledHourToTime(getTZAdjustedHour(schedule))}`
      : `${prefixText}${schedule.repeat_type} at ${scheduledHourToTime(
          getTZAdjustedHour(schedule)
        )}`;

  if (options?.capitalize) {
    scheduleText = capitalize(scheduleText);
  }

  return (
    <>
      <span>{scheduleText}</span>
      {snapshotText && (
        <>
          {" "}
          <span>{snapshotText}</span>
        </>
      )}
    </>
  );
};

export const getDefaultSchedule = ({
  uniqueId,
  baseHour,
}: {
  uniqueId: number;
  baseHour: number;
}): DataImportSchedule => {
  return {
    repeat_type: "daily",
    hour: baseHour + (uniqueId % 6),
    days_of_week: [1],
    tz_name: getTimeZone(),
  };
};
