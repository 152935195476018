import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import styled from "styled-components";

import {
  Loader,
  Option,
  PlanTag,
  Select as CoeffSelect,
  LoaderWithPerfTimings,
} from "../../../components";
import { Tooltip as BaseTooltip } from "../../../components";
import { ACTIVITY_AVATAR_COLORS, COLORS } from "../../../constants";
import { formatNumberToUnits, getRefreshedDate, useTrack } from "../../../utils";
import { Header } from "../components";
import { useDashboardContext } from "../Dashboard";

const Container = styled.div`
  padding: 20px 30px;
`;

const MainSubHeader = styled.div`
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  line-height: 15px;
  color: #8c8c8c;
  margin: 0;
`;

const ActivityRow = styled.div`
  width: 960px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(110px, auto);
  gap: 20px;
`;

const ActivityCard = styled.div`
  background: #fafafa;
  border-radius: 8px;
  padding: 24px;

  span {
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    margin: 18px 0 0;
  }
`;

const ActivityDetails = styled.div`
  display: flex;
  margin-top: 50px;
`;

const UserActivity = styled.div`
  width: 620px;
  margin-right: 30px;
`;

const Toolbar = styled.div`
  display: flex;
  margin-bottom: 15px;

  h3 {
    flex: 1;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
`;

const Select = styled(CoeffSelect)`
  && {
    width: 190px;

    .ant-select-selector {
      align-items: center;
      width: 100%;
      border: none;
      background: none;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    .ant-select-arrow {
      opacity: 1;
    }

    &:not(.ant-select-focused) {
      .ant-select-selector {
        border-color: ${COLORS.black15};
      }
    }
  }
`;

const ActivityList = styled.div``;

const ActivityListItem = styled.div`
  height: 72px;
  background: #fafafa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

interface ActivityAvatarProps {
  avatarColor: string;
}

const ActivityAvatar = styled.div<ActivityAvatarProps>`
  div {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props: ActivityAvatarProps) => props.avatarColor};
    border-radius: 50%;
    margin: 16px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
    }
  }
`;

const ActivityDescription = styled.div`
  flex: 1.5;

  h4 {
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #262626;
    padding: 3px;
    margin-left: 6px;

    background: #ffe393;
    border-radius: 4px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8c8c8c;
    margin: 0;
  }
`;

const ActivityStat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  span:first-of-type {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #262626;
    margin-bottom: 2px;
  }

  span:last-of-type {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8c8c8c;
  }
`;

const ShowAction = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
`;

const ImportsCreated = styled.div``;

const ImportsCreatedChart = styled.div`
  .recharts-legend-wrapper {
    bottom: auto !important;
    top: 0px !important;
    left: auto !important;
    right: -100% !important;

    ul {
      text-align: left !important;

      li.recharts-legend-item {
        display: block !important;

        svg {
          border-radius: 50%;
          width: 8px;
          height: 8px;
          position: relative;
          top: -1px;

          path {
            d: path("M 0 0 h 32 v 32 h -32 Z");
          }
        }

        .recharts-legend-item-text {
          color: ${COLORS.black85}!important;
        }
      }
    }
  }
`;

const ChartColors = [
  "#3053ff",
  "#13c2c2",
  "#722ed1",
  "#faad14",
  "#eb2f96",
  "#00aeff",
  "#ff6d00",
  "#3abd82",
  "#ff2e00",
  "#7bccf6",
];

type ActivityProps = {
  domains_in_domain_family: string[];
};

const Activity = ({ domains_in_domain_family }: ActivityProps) => {
  const track = useTrack();

  const location = useLocation();
  const { getTeamActivity } = useDashboardContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [activity, setActivity] = useState<any>({});
  const [selectedUserSort, setSelectedUserSort] = useState<string>("imports");
  const [showAll, setShowAll] = useState<boolean>(false);

  const [chartData, setChartData] = useState<{ name: string; value: number }[]>([]);

  useEffect(() => {
    const init = async () => {
      const teamActivity = await getTeamActivity();
      setActivity(teamActivity);
      setLoading(false);
    };

    init();

    document.title = "Team Activity - Coefficient Workspace";

    const searchParams = new URLSearchParams(location.search);
    const eventFrom = searchParams.get("from");

    track("workspace_team_activity_viewed", { event_from: eventFrom ?? undefined });
  }, []);

  useEffect(() => {
    const activities = [...(activity.users_activities ?? [])];

    activities.sort((a, b) => b.total_imports_created - a.total_imports_created);

    const data = activities.map(a => ({
      name: a.user.display_name,
      value: a.total_imports_created,
    }));

    const aggregatedData = data.reduce((agg, a, i) => {
      if (i < 5) {
        agg = [...agg, a];
      } else {
        const othersEntry = agg.find(d => d.name === "Others");
        if (othersEntry) {
          agg.pop();
          agg = [...agg, { name: "Others", value: a.value + othersEntry.value }];
        } else {
          agg = [...agg, { name: "Others", value: a.value }];
        }
      }

      return agg;
    }, [] as { name: string; value: number }[]);

    setChartData(aggregatedData);
  }, [activity.users_activities]);

  const sortAndFilterUsersActivities = data => {
    let activities = [...data];
    activities.sort((a, b) => {
      if (selectedUserSort === "imports") {
        return b.total_imports_created - a.total_imports_created;
      } else if (selectedUserSort === "date") {
        return a.last_import_refreshed_dt && !b.last_import_refreshed_dt
          ? -1
          : !a.last_import_refreshed_dt && b.last_import_refreshed_dt
          ? 1
          : moment(b.last_import_refreshed_dt).diff(a.last_import_refreshed_dt);
      } else {
        return a.user.display_name.toLowerCase().localeCompare(b.user.display_name.toLowerCase());
      }
    });

    if (!showAll) {
      activities = activities.slice(0, 5);
    }

    return activities;
  };

  const userActivities = activity.users_activities ?? [];
  const sortedUsersActivity = sortAndFilterUsersActivities(userActivities);
  const getDomainFamilyString = domains => {
    if (domains.length === 1) {
      return domains[0];
    } else {
      return `${domains.slice(0, -1).join(", ")} and ${domains[domains.length - 1]}`;
    }
  };
  return (
    <Container>
      <Header>
        <div style={{ display: "block" }}>
          <h1 style={{ marginBottom: "-10px" }}>Team Activity</h1>
          {domains_in_domain_family.length > 0 && (
            <MainSubHeader>From {getDomainFamilyString(domains_in_domain_family)}</MainSubHeader>
          )}
        </div>
      </Header>
      <ActivityRow>
        <ActivityCard>
          <span>Imports Created</span>
          <h3>
            {loading ? (
              <LoaderWithPerfTimings name="Activity" />
            ) : (
              formatNumberToUnits(activity.total_imports_created, "", 1).replace(".0", "")
            )}
          </h3>
        </ActivityCard>
        <ActivityCard>
          <span>Rows Imported</span>
          <h3>
            {loading ? (
              <Loader />
            ) : (
              formatNumberToUnits(activity.total_rows_imported, "", 1).replace(".0", "")
            )}
          </h3>
        </ActivityCard>
        <ActivityCard>
          <span>Rows Exported</span>
          <h3>
            {loading ? (
              <Loader />
            ) : (
              formatNumberToUnits(activity.total_rows_exported, "", 1).replace(".0", "")
            )}
          </h3>
        </ActivityCard>
      </ActivityRow>
      <ActivityDetails>
        <UserActivity>
          <Toolbar>
            <h3>User Activity</h3>
            <Select
              onChange={filter => setSelectedUserSort(String(filter))}
              value={selectedUserSort}
            >
              <Option value="imports">Total Imports Created</Option>
              <Option value="date">Last Import Refreshed</Option>
              <Option value="alpha">Alphabetical</Option>
            </Select>
          </Toolbar>
          <ActivityList>
            {sortedUsersActivity.map((ua, i) => (
              <ActivityListItem key={i}>
                <ActivityAvatar
                  avatarColor={ACTIVITY_AVATAR_COLORS[i % ACTIVITY_AVATAR_COLORS.length]}
                >
                  <div>
                    <span>
                      {ua.user.display_name
                        .split(" ")
                        .map(n => n.substring(0, 1).toUpperCase())
                        .join("")}
                    </span>
                  </div>
                </ActivityAvatar>
                <ActivityDescription>
                  <BaseTooltip
                    variant="light"
                    getPopupContainer={triggerNode => triggerNode}
                    title={
                      <div style={{ paddingLeft: "3px", fontWeight: 500 }}>{ua.user.email}</div>
                    }
                  >
                    <h4>{ua.user.display_name}</h4>
                  </BaseTooltip>
                  {ua.user?.plan_type && ["starter", "pro"].includes(ua.user.plan_type) && (
                    <PlanTag planType={ua.user.plan_type} />
                  )}
                  <p>
                    {ua.last_import_refreshed_dt
                      ? `Last import refreshed ${getRefreshedDate(
                          moment.utc(ua.last_import_refreshed_dt).local()
                        )}`
                      : null}
                  </p>
                </ActivityDescription>
                <ActivityStat>
                  <span>
                    {formatNumberToUnits(ua.total_imports_created, "", 1).replace(".0", "")}
                  </span>
                  <span>Imports created</span>
                </ActivityStat>
                <ActivityStat>
                  <span>
                    {formatNumberToUnits(ua.total_rows_imported, "", 1).replace(".0", "")}
                  </span>
                  <span>Rows imported</span>
                </ActivityStat>
              </ActivityListItem>
            ))}
          </ActivityList>
          {userActivities.length > 5 && (
            <ShowAction onClick={() => setShowAll(!showAll)}>{`Show ${
              showAll ? "less" : "all"
            } users`}</ShowAction>
          )}
        </UserActivity>
        <ImportsCreated>
          <Toolbar>
            <h3>Imports Created</h3>
          </Toolbar>
          <ImportsCreatedChart>
            <PieChart width={200} height={200}>
              <Pie
                data={chartData}
                cx={80}
                cy={100}
                innerRadius={55}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={ChartColors[index % ChartColors.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ImportsCreatedChart>
        </ImportsCreated>
      </ActivityDetails>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    domains_in_domain_family: state.app.domains_in_domain_family,
  };
};

const route = withRouter(connect(mapStateToProps)(Activity));
export { route as Activity };
