import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Alert, AlertRun, AlertType } from "@coeff/api";
import {
  getAlertRunStatusCounts,
  getAlertRunStatusLevel,
  getRunStatusLevelFormatted,
  RunStatusLevel,
} from "@coeff/utils";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import moment from "moment";
import React, { ReactNode, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../../../../api";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  NewWindowNew,
  Tag,
  LoaderWithPerfTimings,
  FlexRow,
  Modal,
  ErrorMessage,
  EmailIconCircle,
  SlackIconCircle,
  Typography,
  FlexColumn,
  Table,
  Tooltip,
  Paper,
  PaperContent,
  WarningMessage,
} from "../../../../components";
import { AlertDescription } from "../../../../components/AlertDescription";
import { COLORS } from "../../../../constants";
import { getScheduleSummaryText, sentryCapture, useApiContext, useTrack } from "../../../../utils";
import { useDashboardContext } from "../../Dashboard";

import { AlertDetailsSidebar } from "./AlertDetailsSidebar";

const ALERT_DETAILS_SIDEBAR_WIDTH = 350;

const Container = styled.div`
  padding: 20px 30px;
  line-height: 1.3;
  height: 100%;
  width: 100%;
  max-width: calc(100% - ${ALERT_DETAILS_SIDEBAR_WIDTH}px);
  overflow: scroll;

  && .ant-table-header table thead tr th {
    padding: 0 8px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  height: 24px;
  border: none;
  font-weight: 500;

  .text {
    color: ${COLORS.black85};
    font-size: 14px;
  }
`;

const IconContainer = styled.div`
  display: flex;

  .overlap-icon {
    position: relative;
    right: 12px;
  }
`;

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-body {
    padding: 8px;
    padding-top: 0px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const AlertDetailsPage = () => {
  const { apiClient } = useApiContext();

  const track = useTrack();

  const { spreadsheets } = useDashboardContext();

  const { alertId, ssId } = useParams<{ alertId: string; ssId: string }>();

  const [page, setPage] = React.useState(0);

  const [selectedAlertRun, setSelectedAlertRun] = React.useState<AlertRun>();

  const {
    data: alertResponse,
    isLoading: isLoadingAlert,
    isError: isErrorAlert,
  } = useQuery([QueryCacheKey.ALERTS, alertId], () => apiClient.getAlert({ alert_id: alertId }), {
    onError: error => {
      sentryCapture({ error });
    },
  });

  const {
    data: alertRunHistoryResponse,
    isLoading: isLoadingRunHistory,
    isError: isErrorRunHistory,
  } = useQuery(
    [QueryCacheKey.ALERT_RUN_HISTORY, alertId],
    () =>
      apiClient.getAlertRunHistory({
        alert_id: alertId,
        offset: 100 * page,
      }),
    {
      keepPreviousData: true,
      onError: error => {
        sentryCapture({ error });
      },
    }
  );

  const alert = alertResponse?.data.alert;

  const alertRuns = alertRunHistoryResponse?.data.alert_runs ?? [];

  const spreadsheet = spreadsheets?.find(ss => ss.google_sheets_file_id === ssId);

  useEffect(() => {
    if (alert && spreadsheet) {
      track("workspace_alert_viewed", {
        alert_id: alert.alert_id,
        ss_id: spreadsheet.google_sheets_file_id,
        ss_name: spreadsheet.google_sheets_file_name,
      });
    }
  }, [alert, spreadsheet]);

  const onOpenSheetClick = (alert: Alert) => {
    track("workspace_open_sheet_clicked", {
      event_from: "alert_details",
      alert_id: alert.alert_id,
      ss_id: spreadsheet?.google_sheets_file_id,
      ss_name: spreadsheet?.google_sheets_file_name,
    });

    if (spreadsheet) {
      window.open(`${spreadsheet.spreadsheet_url}?gid=${alert.containing_table_tab_id}`, "_blank");
    }
  };

  const getAlertTriggerText = (alertRun: AlertRun): ReactNode => {
    switch (alertRun.alert_type) {
      case AlertType.Snapshot: {
        return getScheduleSummaryText(alertRun.schedule, undefined, {
          skipPrefix: true,
          capitalize: true,
        });
      }
      case AlertType.NewRowsAppended: {
        return "When a new row is added";
      }
      case AlertType.NewRowsWithId: {
        return "When a new row is added with a new ID";
      }
      case AlertType.MonitorChange: {
        return "When cell values change";
      }
      case AlertType.MonitorCondition: {
        return "When cell values change";
      }
      case undefined: {
        return "";
      }
    }
  };

  return (
    <>
      <Container>
        {isErrorAlert || isErrorRunHistory ? (
          <div>
            <Paper variant="error">
              <PaperContent size="small">
                <FlexRow style={{ justifyContent: "space-between" }}>
                  <span>There was an error fetching your alert</span>

                  <Button type="text" onClick={() => history.back()}>
                    Back
                  </Button>
                </FlexRow>
              </PaperContent>
            </Paper>
          </div>
        ) : isLoadingAlert ||
          isLoadingRunHistory ||
          alert === undefined ||
          alertRunHistoryResponse === undefined ? (
          <div style={{ margin: "5rem auto 2rem", textAlign: "center" }}>
            <LoaderWithPerfTimings name="AlertDetails" size="large" />
          </div>
        ) : (
          <>
            <FlexColumn gap={3}>
              <Breadcrumb style={{ marginBottom: 0 }}>
                <BreadcrumbItem>
                  <Link to={{ pathname: `/dashboard/spreadsheets` }}>Spreadsheets</Link>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <Link
                    to={{
                      pathname: `/dashboard/spreadsheets/${spreadsheet?.google_sheets_file_id}`,
                      search: window.location.search,
                    }}
                  >
                    {spreadsheet?.google_sheets_file_name}
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>

              <FlexRow style={{ justifyContent: "space-between" }}>
                <FlexRow gap={2} style={{ marginBottom: 8 }}>
                  <IconContainer style={{ maxWidth: 50, marginTop: 2 }}>
                    {alert.send_info.email_enabled && <EmailIconCircle style={{ minWidth: 32 }} />}

                    {alert.send_info.slack_enabled && (
                      <SlackIconCircle
                        style={{ minWidth: 32 }}
                        className={clsx({ "overlap-icon": alert.send_info.email_enabled })}
                      />
                    )}
                  </IconContainer>

                  <div>
                    <FlexRow gap={2}>
                      <Typography fontSize="28px" fontWeight={800}>
                        {alert.alert_name}
                      </Typography>

                      {alert.options?.is_paused ? (
                        <StyledTag className="no-border" color="orange">
                          Paused
                        </StyledTag>
                      ) : null}
                    </FlexRow>

                    <AlertDescription alert={alert} variant="body2" showPaused={false} />
                  </div>
                </FlexRow>

                {spreadsheet?.spreadsheet_url && (
                  <div>
                    <Button
                      type="primary"
                      style={{ height: "40px" }}
                      target="_blank"
                      onClick={() => onOpenSheetClick(alert)}
                    >
                      <FlexRow gap={1.5}>
                        <span style={{ marginTop: 2 }}>Open Sheet</span>
                        <NewWindowNew />
                      </FlexRow>
                    </Button>
                  </div>
                )}
              </FlexRow>

              <Subtitle style={{ marginBottom: 12 }}>
                <Typography fontWeight="bold">Run History</Typography>

                <Typography variant="body3" color="textSecondary" fontWeight="normal">
                  Displaying last 30 days
                </Typography>
              </Subtitle>
            </FlexColumn>

            <Table
              style={{ overflow: "scroll" }}
              scroll={{ x: "auto", y: "auto" }}
              loading={false}
              dataSource={alertRuns}
              pagination={
                alertRunHistoryResponse.data.total_count > alertRunHistoryResponse.data.page_size
                  ? {
                      position: ["bottomCenter"],
                      size: "small",
                      pageSize: alertRunHistoryResponse.data.page_size,
                      showSizeChanger: false,
                      onChange: page => setPage(page),
                    }
                  : false
              }
              columns={[
                {
                  title: "Status",
                  render: (_, alertRun) => {
                    const statusLevel = getAlertRunStatusLevel(alertRun.alert_run_status);

                    return (
                      <>
                        <FlexRow>
                          <StyledTag
                            style={{
                              color:
                                statusLevel === RunStatusLevel.PARTIAL_SUCCESS
                                  ? COLORS.orange2
                                  : undefined,
                            }}
                            color={
                              statusLevel === RunStatusLevel.FAILED
                                ? "red"
                                : statusLevel === RunStatusLevel.PARTIAL_SUCCESS
                                ? "orange"
                                : "green"
                            }
                          >
                            {statusLevel === RunStatusLevel.SUCCESS ? (
                              <CheckCircleFilled />
                            ) : (
                              <ExclamationCircleFilled />
                            )}

                            <span className="text">{getRunStatusLevelFormatted(statusLevel)}</span>
                          </StyledTag>

                          {alertRun.alert_run_status?.error_type ||
                          alertRun.alert_run_status?.warning_type ? (
                            <a onClick={() => setSelectedAlertRun(alertRun)}>View Details</a>
                          ) : null}
                        </FlexRow>
                      </>
                    );
                  },
                },
                {
                  title: "Time",
                  render: (_, alertRun) => {
                    if (alertRun.run_complete_dt) {
                      const date = moment(alertRun.run_complete_dt);

                      return date.format("MMM DD, YYYY h:mmA");
                    }

                    return "";
                  },
                },
                {
                  title: "Alert Trigger",
                  dataIndex: "alert_type",
                  key: "alert_type",
                  render: (_, alertRun) => {
                    return getAlertTriggerText(alertRun);
                  },
                },
                {
                  title: "Successful Alerts",
                  onCell: () => ({ width: 120 }),
                  render: (_, alertRun) => {
                    if (alertRun.alert_run_status === undefined) {
                      return "-";
                    }

                    const runCounts = getAlertRunStatusCounts(alertRun.alert_run_status);

                    if (runCounts.totalFailed === 0 && runCounts.totalSent === 0) {
                      return "-";
                    }

                    const breakdownDescription: Array<string> = [];

                    if (runCounts.slacksSent > 0) {
                      breakdownDescription.push(
                        `${runCounts.slacksSent} Slack alert${
                          runCounts.slacksSent === 1 ? "" : "s"
                        } sent`
                      );
                    }

                    if (runCounts.emailsSent > 0) {
                      breakdownDescription.push(
                        `${runCounts.emailsSent} email alert${
                          runCounts.emailsSent === 1 ? "" : "s"
                        } sent`
                      );
                    }

                    return (
                      <Tooltip title={breakdownDescription.join(", ")}>
                        {breakdownDescription.length ? (
                          <a>{runCounts.totalSent}</a>
                        ) : (
                          <div>{runCounts.totalSent}</div>
                        )}
                      </Tooltip>
                    );
                  },
                },
                {
                  title: "Failed Alerts",
                  onCell: () => ({ width: 100 }),
                  render: (_, alertRun) => {
                    if (alertRun.alert_run_status === undefined) {
                      return "-";
                    }

                    const runCounts = getAlertRunStatusCounts(alertRun.alert_run_status);

                    if (runCounts.totalFailed === 0 && runCounts.totalSent === 0) {
                      return "-";
                    }

                    const breakdownDescription: Array<string> = [];

                    if (runCounts.slacksFailed > 0) {
                      breakdownDescription.push(
                        `${runCounts.slacksFailed} Slack alert${
                          runCounts.slacksFailed === 1 ? "" : "s"
                        } failed`
                      );
                    }

                    if (runCounts.emailsFailed > 0) {
                      breakdownDescription.push(
                        `${runCounts.emailsFailed} email alert${
                          runCounts.emailsFailed === 1 ? "" : "s"
                        } failed`
                      );
                    }

                    return (
                      <Tooltip title={breakdownDescription.join(", ")}>
                        {breakdownDescription.length ? (
                          <a>{runCounts.totalFailed}</a>
                        ) : (
                          <div>{runCounts.totalFailed}</div>
                        )}
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
          </>
        )}
      </Container>

      {alert ? <AlertDetailsSidebar alert={alert} /> : null}

      <StyledModal
        visible={Boolean(selectedAlertRun)}
        title={selectedAlertRun?.alert_run_status?.warning_type ? "Alert Warning" : "Alert Failed"}
        closable
        onCancel={() => setSelectedAlertRun(undefined)}
        onOk={() => setSelectedAlertRun(undefined)}
      >
        {selectedAlertRun?.alert_run_status?.warning_type ? (
          <WarningMessage
            title={selectedAlertRun?.alert_run_status?.title}
            message={selectedAlertRun?.alert_run_status?.message}
          />
        ) : (
          <ErrorMessage
            title={selectedAlertRun?.alert_run_status?.title}
            message={selectedAlertRun?.alert_run_status?.message}
          />
        )}

        <ModalFooter>
          <Button type="text" onClick={() => setSelectedAlertRun(undefined)}>
            Close
          </Button>
        </ModalFooter>
      </StyledModal>
    </>
  );
};
