import { SendCoworkerInvitationRequest } from "@coeff/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useDomainUsers, useInvitations, useSendCoworkerInvitation } from "../../../api/users";
import {
  Button,
  CopyIcon,
  LoaderWithPerfTimings,
  message,
  ShareCoefficientLargeIcon,
  TextArea,
} from "../../../components";
import { TagsInput } from "../../../components/TagsInput";
import { AppState } from "../../../store";
import { useTrack } from "../../../utils";
import { Header } from "../components";
import { useDashboardContext } from "../Dashboard";

const Title = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const PageHeader = styled.div`
  display: flex;
  width: 344px;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 28px;
  font-weight: bold;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 460px;
  gap: 16px;
`;

const FormActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
`;

const StyledTagsInput = styled(TagsInput)`
  flex-direction: column;
  padding: 6px 12px;

  input {
    flex: unset;
    width: 100%;
  }
`;

export const InviteCoworkers: React.FC = () => {
  const track = useTrack();

  const { eventFrom } = useParams<{ eventFrom: string }>();

  const { coeffUserEmail } = useDashboardContext();

  const { domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const [emailText, setEmailText] = useState<string>(
    "Hey! I’ve been loving this tool called Coefficient to get our live company data into Google Sheets & Excel. I think you'll love it too. Try it out!"
  );

  const [emailList, setEmailList] = useState<string[]>([]);

  const [btnDisableFlag, setBtnDisableFlag] = useState<boolean>(false);

  const [submitStatus, setSubmitStatus] = useState<string>("ok");

  const { data: domainUsersData, isLoading } = useDomainUsers();

  const domain = coeffUserEmail ? coeffUserEmail?.split("@")[1] : "";

  const { data: domainInvitations } = useInvitations(domain);

  const maxEmail = 10;

  const numberOfSecondsInTwentyFourHours = 24 * 60 * 60;

  const isValidEmail = (str: string) =>
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(str);

  const emailToCoworkersSender = useSendCoworkerInvitation();

  useEffect(() => {
    document.title = "Invite Coworkers - Coefficient Workspace";
    track("invite_coworkers_viewed", { event_from: eventFrom });
  }, []);

  const sendInviteActions = () => {
    setSubmitStatus("pending");
    sendEmailToCoworker();
    setTimeout(() => setSubmitStatus("ok"), 3000);
    setEmailList([]);
  };

  const sendEmailToCoworker = () => {
    let invalidInput = emailList.length <= 0;

    emailList.forEach(tag => {
      const error = validateDomainEmail(tag);
      if (error) {
        invalidInput = true;
      }
    });

    if (invalidInput) {
      message.error(
        "The email ID(s) provided are invalid or already invited. Please try modifying the list."
      );
      return;
    }

    const latestInvitations = domainInvitations
      ? domainInvitations.host_invitations.filter(
          obj => obj.created_dt + numberOfSecondsInTwentyFourHours > Date.now() / 1000
        )
      : [];

    const allowedNumberOfEmails =
      latestInvitations.length < maxEmail ? maxEmail - latestInvitations.length : 0;

    if (emailList.length > allowedNumberOfEmails) {
      message.error(
        `You can only send invitations to ${maxEmail} users in a day. You have ${allowedNumberOfEmails} invitations left.`
      );
      return;
    }

    const emailRequest: SendCoworkerInvitationRequest = {
      recipient_emails: emailList,
      domain_user_count: domainUsersData ? domainUsersData.num_of_users : 1,
      message: emailText,
    };

    emailToCoworkersSender.mutate(emailRequest, {
      onSuccess: () => {
        message.success("Invites sent");
        track("invite_coworkers_emails_sent", {
          emails_invited: emailList,
        });
      },
      onError: () => {
        message.error("Failed to send invites");
      },
    });
  };

  const handleEmailChange = (tagList: string[]) => {
    setEmailList(tagList);

    const hasError = tagList.some(tag => validateDomainEmail(tag));

    setBtnDisableFlag(hasError);
  };

  const validateDomainEmail = (email: string) => {
    if (!isValidEmail(email)) {
      return "Please provide a valid email.";
    }

    if (!coeffUserEmail || !domains_in_domain_family.includes(email.split("@")[1])) {
      return "Email ID doesn't belong to the same domain.";
    }

    if (
      !isLoading &&
      (domainUsersData?.user_emails.includes(email) || domainUsersData?.host_user.email === email)
    ) {
      return "This user is already registered with Coefficient.";
    }

    if (domainInvitations?.invitee_emails.includes(email)) {
      return "This user has already been invited to join Coefficient.";
    }

    return undefined;
  };

  const copyToClipboard = () => {
    const elm = document.getElementById("tobecopiedtext");

    if (elm) {
      elm.style.display = "block";

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elm);
      selection?.removeAllRanges();
      selection?.addRange(range);
      document.execCommand("Copy");

      elm.style.display = "none";

      message.success("Link copied");

      track("invite_coworkers_link_copied");
    } else {
      message.success("Link copied failed");
    }
  };

  return (
    <Container>
      <Header>
        <h1>Invite</h1>
      </Header>
      <InnerContainer>
        <Title>
          <ShareCoefficientLargeIcon />
          <h3 style={{ fontWeight: "bold" }}>Invite your coworkers to Coefficient</h3>
        </Title>
        <Form>
          <StyledTagsInput
            placeholder="Enter emails"
            onChange={tags => handleEmailChange(tags)}
            validator={validateDomainEmail}
            tags={emailList}
            max={maxEmail}
          />

          <TextArea
            value={emailText}
            placeholder="Include a message (optional)"
            onChange={e => setEmailText(e.target.value)}
            maxLength={1000}
            rows={3}
          />
        </Form>

        <FormActions>
          <StyledButton
            disabled={btnDisableFlag}
            className="sendInvite"
            block
            type="primary"
            size="middle"
            onClick={sendInviteActions}
          >
            {submitStatus === "pending" ? (
              <LoaderWithPerfTimings name="InviteCoworkers.button" color="white" />
            ) : (
              "Send Invites"
            )}
          </StyledButton>

          <StyledButton block type="text" onClick={() => copyToClipboard()}>
            <CopyIcon style={{ marginTop: "10px" }} /> Copy invite link
          </StyledButton>
        </FormActions>
      </InnerContainer>
      <div id="tobecopiedtext" style={{ display: "none" }}>
        <p>
          <a
            href={`https://coefficient.io/get-started?referral_id=${domainUsersData?.host_user.user_id}&invitation_by=link`}
            target="_blank"
          >
            https://coefficient.io/get-started?referral_id={domainUsersData?.host_user.user_id}
            &invitation_by=link
          </a>
        </p>
      </div>
    </Container>
  );
};
