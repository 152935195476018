import { Table as TableAD } from "antd";
import styled from "styled-components";

import { COLORS } from "../constants";

export const Table = styled(TableAD)`
  .ant-table {
    font-size: 13px;
    line-height: 16px;
  }

  .ant-table-thead {
    tr {
      th {
        font-weight: bold;

        &.center {
          text-align: center;
        }
      }
    }
  }

  .ant-table-header {
    position: sticky;
    top: 0px;
    z-index: 100;

    .ant-table-thead {
      tr {
        th {
          background: ${COLORS.black4}!important;
          color: ${COLORS.black}!important;
          padding: 0px;
          height: 44px;

          .ant-table-column-title {
            display: flex;
            align-items: center;
            min-width: 0px;
          }

          &:hover {
            background: ${COLORS.black6}!important;

            .ant-table-filter-trigger-container {
              .ant-table-filter-trigger {
                .anticon-caret-down {
                  visibility: visible;
                }
              }
            }
          }
          .ant-table-filter-trigger {
            display: flex;
            flex: 0 0 auto;
            margin: 0px !important;
            padding: 8px 8px !important;
          }

          .ant-table-column-sorters {
            padding: 0px 12px;

            .ant-table-column-sorter {
              display: none;
            }
          }

          .ant-table-filter-trigger-container {
            left: 0px;
            background: transparent !important;

            .ant-table-filter-trigger {
              width: 100%;

              .anticon-caret-down {
                right: 0px;
                left: auto;
                color: ${COLORS.white};
                visibility: hidden;
              }

              .anticon-arrow-down,
              .anticon-arrow-up {
                right: 0px;
                left: auto;
                color: ${COLORS.white};
              }
            }
          }
        }

        th.ant-table-column-sort {
          background-color: ${COLORS.coeblue4};
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      cursor: ${props => (props.onRow !== undefined ? "pointer" : "default")};
      td {
        padding: 0px 8px !important;
        height: 54px;

        &.center {
          text-align: center;
        }
      }

      &.ant-table-expanded-row {
        td {
          background: #f3f9ff !important;
        }
      }
    }
  }
` as typeof TableAD;
