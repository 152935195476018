import { Breadcrumb as BreadcrumbAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledWrap = styled.div`
  .ant-breadcrumb {
    margin: 0px 0px 8px;

    .ant-breadcrumb-link {
      color: ${COLORS.black45};
      font-weight: 500;

      a {
        color: ${COLORS.coeblue4};
      }
    }
  }
`;

export const Breadcrumb = props => (
  <StyledWrap>
    <BreadcrumbAD {...props} />
  </StyledWrap>
);

export const BreadcrumbItem = props => (
  <>
    <BreadcrumbAD.Item {...props} />
  </>
);

export const BreadcrumbSeparator = props => (
  <>
    <BreadcrumbAD.Separator {...props} />
  </>
);
