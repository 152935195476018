import { ModalProps } from "antd";
import React from "react";
import styled from "styled-components";

import { Button } from "./Button";
import { Modal } from "./Modal";
import { Typography } from "./Typography";

const ModalActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

type Props = ModalProps & {
  isLoading?: boolean;
};

export const ConfirmDeleteCloudTableModal: React.FC<Props> = props => {
  const { onCancel, onOk, isLoading } = props;

  return (
    <Modal {...props}>
      <Typography fontWeight="bold" variant="h6" gutterBottom>
        Are you sure?
      </Typography>

      <Typography>
        You will not be able to recover this dataset once it has been deleted.
      </Typography>

      <ModalActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>

        <Button disabled={isLoading} type="primary" onClick={onOk}>
          {isLoading ? "Deleting..." : "Yes, Delete"}
        </Button>
      </ModalActions>
    </Modal>
  );
};
