import { CaretDownOutlined } from "@ant-design/icons";
import { Input, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import styled from "styled-components";

import { SortDirection } from "../../../actions";
import { Table } from "../../../components";
import { IMPORT_TYPE_NAMES } from "../../../constants";
import { CAContext } from "../../../types";
import { CoeffAdminContext } from "../CoeffAdmin";

const { Text } = Typography;

const DontBreak = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
`;

const defaultSortOrder: SortDirection = SortDirection.ascend;

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "s",
    m: "%dm",
    mm: "%dm",
    h: "%dh",
    hh: "%dh",
    d: "%dd",
    dd: "%dd",
    M: "%dM",
    MM: "%dM",
    y: "%dy",
    yy: "%dy",
  },
});

export const Imports = () => {
  const { dataImports, loading, users } = useContext(CoeffAdminContext) as CAContext;

  const getTagColor = (type: string) => {
    return type === "csv" ? "orange" : type === "google_sheets" ? "green" : "blue";
  };

  const columns = [
    {
      title: "Import",
      dataIndex: "data_import_name",
      key: "data_import_name",
      showSorterTooltip: false,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search"
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      onFilter: (value, record) =>
        record["data_import_name"].search(new RegExp(value.split(" ").join("|"), "i")) >= 0,
      sorter: (a, b) => a.data_import_name?.localeCompare(b.data_import_name),
      width: 200,
      render: importName => (
        <div>
          <Tooltip title={importName}>
            <DontBreak>
              <Text strong>{importName}</Text>
            </DontBreak>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "user_id",
      key: "user_id",
      width: 128,
      showSorterTooltip: false,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search"
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      onFilter: (value, record) => {
        const user = users?.find(user => user.user_id === record.user_id);
        return user
          ? (user.first_name + " " + user.last_name).search(
              new RegExp(value.split(" ").join("|"), "i")
            ) >= 0
          : false;
      },
      sorter: (a, b) => {
        const userA = users?.find(user => user.user_id === a.user_id);
        const userB = users?.find(user => user.user_id === b.user_id);
        return userA && userB ? userA.first_name?.localeCompare(userB.first_name) : 0;
      },
      render: userId => {
        const user = users?.find(user => user.user_id === userId);

        return (
          <div>
            {user && (
              <Tooltip title={user.email}>
                <DontBreak>
                  <Text>{user.email.split("@")[0]}</Text>
                </DontBreak>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "data_source_type",
      key: "data_source_type",
      showSorterTooltip: false,
      sorter: (a, b) => a.data_source_type?.localeCompare(b.data_source_type),
      width: 100,
      render: (type: string) => (
        <Tag color={getTagColor(type)}>{IMPORT_TYPE_NAMES[type] || type}</Tag>
      ),
    },
    {
      title: "File",
      dataIndex: "google_sheets_file_name",
      key: "google_sheets_file_name",
      sorter: (a, b) => a.google_sheets_file_name?.localeCompare(b.google_sheets_file_name),
      width: 150,
      showSorterTooltip: false,
      defaultSortOrder,
      render: fileName => (
        <div>
          <Tooltip title={fileName}>
            <DontBreak>
              <Text>{fileName}</Text>
            </DontBreak>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Tab",
      dataIndex: "google_sheets_tab_name",
      key: "google_sheets_tab_name",
      sorter: (a, b) => a.google_sheets_tab_name?.localeCompare(b.google_sheets_tab_name),
      width: 120,
      showSorterTooltip: false,
      render: tabName => (
        <div>
          <Tooltip title={tabName}>
            <DontBreak>
              <Text>{tabName}</Text>
            </DontBreak>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Row, Col",
      dataIndex: "counts",
      key: "counts",
      showSorterTooltip: false,
      render: counts => `${counts.rows || "-"}, ${counts.columns || "-"}`,
      width: 100,
    },
    // {
    //   title: "Columns",
    //   dataIndex: "total_columns",
    //   key: "total_columns",
    //   sorter: (a, b) => a.total_columns - b.total_columns,
    //   width: 100,
    // },
    // {
    //   title: "Rows",
    //   dataIndex: "total_rows",
    //   key: "total_rows",
    //   sorter: (a, b) => a.total_rows - b.total_rows,
    //   width: 100,
    // },
    {
      title: "Filters",
      dataIndex: "total_filters",
      key: "total_filters",
      showSorterTooltip: false,
      sorter: (a, b) => a.total_filters - b.total_filters,
      width: 90,
    },

    {
      title: "Created",
      dataIndex: "created_dt",
      key: "created_dt",
      showSorterTooltip: false,
      sorter: (a, b) => moment.utc(a.created_dt).diff(moment.utc(b.created_dt)),
      width: 100,
      render: d => {
        if (d) {
          const date = moment(d);
          return <Tooltip title={date.format("MMM DD, YYYY hh:mmA")}>{date.fromNow(true)}</Tooltip>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Last Run",
      dataIndex: "last_run_dt",
      key: "last_run_dt",
      showSorterTooltip: false,
      sorter: (a, b) => moment.utc(a.last_run_dt).diff(moment.utc(b.last_run_dt)),
      width: 100,
      render: d => {
        if (d) {
          const date = moment(d);
          return <Tooltip title={date.format("MMM DD, YYYY hh:mmA")}>{date.fromNow(true)}</Tooltip>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Next Run",
      dataIndex: "next_scheduled_run_dt",
      key: "next_scheduled_run_dt",
      showSorterTooltip: false,
      sorter: (a, b) =>
        moment.utc(a.next_scheduled_run_dt).diff(moment.utc(b.next_scheduled_run_dt)),
      width: 100,
      render: d => {
        if (d) {
          const date = moment(d);
          return <Tooltip title={date.format("MMM DD, YYYY hh:mmA")}>{date.fromNow(true)}</Tooltip>;
        } else {
          return "-";
        }
      },
    },
  ];

  const dataSource = dataImports.map(dataImport => ({
    key: dataImport.data_import_id,
    counts: {
      columns: dataImport.total_columns,
      rows: dataImport.total_rows,
    },
    refs: {
      importId: dataImport.data_import_id,
      userId: dataImport.user_id,
      fileId: dataImport.google_sheets_file_id,
      tabId: dataImport.google_sheets_tab_id,
    },
    ...dataImport,
  }));

  const locale = {
    emptyText: "No Data Imports",
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
        expandedRowRender={row => (
          <div>
            <Tag>
              <strong>Import id:</strong> {row.refs.importId}
            </Tag>
            <Tag>
              <strong>User id:</strong> {row.refs.userId}
            </Tag>
            <Tag>
              <strong>File id:</strong> {row.refs.fileId}
            </Tag>
            <Tag>
              <strong>Tab id:</strong> {row.refs.tabId}
            </Tag>
          </div>
        )}
      />
    </div>
  );
};
