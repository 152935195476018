import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { config } from "../config";
import { SSOAuthFailedError, isAxiosErrorWithSSOAuthFailedMessage } from "../utils";

import { setMicrosoftOAuthTokenHeader } from "./client";

type AuthParams = {
  oauthToken: string;
  openIdToken: string;
  coeffAuthToken: string;
  msOauthToken: string;
  upgradeToken: string;
  domain: string;
};

export class DEPRECATED_ApiManager {
  client: AxiosInstance;
  domain: string;
  pageSize?: number;
  offset?: number;

  constructor(params: {
    publicClientApp: PublicClientApplication;
    authParams: AuthParams;
    onUnauthorizedError: (error: unknown) => void;
  }) {
    const { publicClientApp, authParams, onUnauthorizedError } = params;

    this.client = axios.create({
      baseURL: config.BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.domain = "";

    if (authParams.openIdToken && authParams.oauthToken) {
      this.client.defaults.headers.common["GOOGLE-OPENID-TOKEN"] = authParams.openIdToken;
      this.client.defaults.headers.common["GOOGLE-OAUTH-TOKEN"] = authParams.oauthToken;
    }

    if (authParams.coeffAuthToken) {
      this.client.defaults.headers.common["COEFF-AUTH-TOKEN"] = authParams.coeffAuthToken;
    }

    if (authParams.msOauthToken) {
      this.client.defaults.headers.common["MICROSOFT-OAUTH-TOKEN"] = authParams.msOauthToken;
    }

    if (authParams.domain) {
      this.domain = authParams.domain;
    }

    this.client.interceptors.request.use(async config => {
      await setMicrosoftOAuthTokenHeader(publicClientApp, config);

      const params = new URLSearchParams(window.location.search);

      const coeffAuthToken = params.get("COEFF-AUTH-TOKEN");
      if (coeffAuthToken) {
        config.headers["COEFF-AUTH-TOKEN"] = coeffAuthToken;
      }

      const msOauthToken = params.get("MICROSOFT-OAUTH-TOKEN");
      if (msOauthToken) {
        config.headers["MICROSOFT-OAUTH-TOKEN"] = msOauthToken;
      }

      const oauthToken = params.get("GOOGLE-OAUTH-TOKEN");
      if (oauthToken) {
        config.headers["GOOGLE-OAUTH-TOKEN"] = oauthToken;
      }

      const openIdToken = params.get("GOOGLE-OPENID-TOKEN");
      if (openIdToken) {
        config.headers["GOOGLE-OPENID-TOKEN"] = openIdToken;
      }

      config.params = config.params || {};

      config.params["tz_name"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (this.domain) {
        config.params["domain"] = this.domain;
      }

      if (authParams.upgradeToken) {
        config.params["ut"] = authParams.upgradeToken;
      }

      return config;
    });

    this.client.interceptors.response.use(
      ({ data, headers, status }) => {
        if (data instanceof Blob) {
          return { data, headers, status };
        } else {
          // FIXME: Don't spread data with the response config.
          return { ...data, headers, status };
        }
      },
      config => {
        if (isAxiosErrorWithSSOAuthFailedMessage(config)) {
          onUnauthorizedError(new SSOAuthFailedError());
        }

        return Promise.reject({ error: config.response.data, status: config.response.status });
      }
    );
  }

  request = <R = AxiosResponse["data"]>({
    body,
    method,
    token,
    url,
    responseType,
  }: {
    body?: object;
    method: string;
    token?: string;
    url: string;
    responseType?: AxiosRequestConfig["responseType"];
  }): Promise<Pick<AxiosResponse, "headers" | "status"> & R> => {
    const options: AxiosRequestConfig = {
      method,
      url,
      withCredentials: true,
    };

    if (responseType) {
      options["responseType"] = responseType;
    }

    if (token) {
      options["headers"] = { Authorization: `Bearer ${token}` };
    }

    if (["POST", "PUT"].includes(method) && body) {
      options["data"] = body;
    }

    return this.client(options);
  };

  setDomainParam = (domain: string) => (this.domain = domain);
}
