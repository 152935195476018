import loadScript from "load-script";

import { config } from "../config";

type GoogleApiError = {
  result: {
    error: {
      code: number;
      message: string;
      status: string;
    };
  };
};

export const isGoogleApiError = (error: unknown): error is GoogleApiError => {
  return (
    typeof error === "object" &&
    error !== null &&
    error.hasOwnProperty("result") &&
    typeof error["result"] === "object" &&
    error["result"] !== null &&
    error["result"].hasOwnProperty("error") &&
    typeof error["result"].error === "object" &&
    error["result"].error !== null &&
    error["result"].error.hasOwnProperty("code") &&
    error["result"].error.hasOwnProperty("message") &&
    error["result"].error.hasOwnProperty("status")
  );
};

export class GoogleApi {
  private scriptLoadedPromise: Promise<void>;

  private pickerLoaded = false;

  private clientLoaded = false;

  constructor() {
    this.scriptLoadedPromise = new Promise<void>((resolve, reject) => {
      loadScript("https://apis.google.com/js/api.js", (err: unknown) => {
        err ? reject(err) : resolve();
      });
    });
  }

  private loadPicker = async () => {
    await this.scriptLoadedPromise;

    return new Promise<void>((resolve, reject) => {
      gapi.load("picker", () => {
        this.pickerLoaded = true;

        resolve();
      });
    });
  };

  private loadClient = async () => {
    await this.scriptLoadedPromise;

    return new Promise<void>((resolve, reject) => {
      gapi.load("client", async () => {
        await gapi.client.init({
          clientId: config.GOOGLE_CLIENT_ID,
          apiKey: config.GOOGLE_DEVELOPER_API_KEY,
          scope: "profile https://www.googleapis.com/auth/spreadsheets",
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
          ],
        });
        resolve();
      });
    });
  };

  public openPicker = async (props: {
    authToken: string;
    onSelect: (files: google.picker.DocumentObject[]) => void;
    onCancel?: () => void;
  }): Promise<void> => {
    if (!this.pickerLoaded) {
      await this.loadPicker();
    }

    const DriveView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.spreadsheet")
      // @ts-ignore
      .setLabel("My Drive");

    const SharedDriveView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.spreadsheet")
      .setEnableTeamDrives(true);

    const SharedWithMeView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.spreadsheet")
      .setOwnedByMe(false)
      // @ts-ignore
      .setLabel("Shared with Me");

    const RecentView = new google.picker.DocsView(google.picker.ViewId.RECENTLY_PICKED)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.spreadsheet")
      // @ts-ignore
      .setLabel("Recent");

    const StarredView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setStarred(true)
      .setMimeTypes("application/vnd.google-apps.spreadsheet")
      // @ts-ignore
      .setLabel("Starred");

    new google.picker.PickerBuilder()
      .addView(DriveView)
      .addView(SharedDriveView)
      .addView(SharedWithMeView)
      .addView(StarredView)
      .addView(RecentView)
      .hideTitleBar()
      .setSelectableMimeTypes("application/vnd.google-apps.spreadsheet")
      .setOAuthToken(props.authToken)
      .setDeveloperKey(config.GOOGLE_DEVELOPER_API_KEY)
      .setCallback((response: google.picker.ResponseObject) => {
        const { ACTION, DOCUMENTS } = google.picker.Response;

        const { PICKED, CANCEL } = google.picker.Action;

        if (response[ACTION] === PICKED) {
          const files = response[DOCUMENTS];

          props.onSelect(files);
        } else if (response[ACTION] === CANCEL) {
          props.onCancel?.();
        }
      })
      .setSize(980, 660)
      .build()
      .setVisible(true);
  };

  public getSpreadsheetData = async (authToken: string, spreadsheetId: string) => {
    if (!this.clientLoaded) {
      await this.loadClient();
    }

    const response = await gapi.client.sheets.spreadsheets.get({
      access_token: authToken,
      spreadsheetId,
    });

    return response;
  };
}
