import queryString from "query-string";

import { ACTIONS, API_ADMIN_ROUTES } from "../constants";

import { AppThunkAction, SortColumn } from ".";

export const getDomains =
  (params?: Record<string, string | number>): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

    try {
      const url = params
        ? `${API_ADMIN_ROUTES.domains}?${queryString.stringify(params)}`
        : `${API_ADMIN_ROUTES.domains}?page_size=100`;
      const { domains } = await api.request({
        method: "GET",
        url: url,
      });

      dispatch({ type: ACTIONS.SET_DOMAINS, domains });

      return domains;
    } catch (error) {
      return error;
    }
  };

export const getDomainUsers =
  (domain: string): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

    try {
      const { domain: domainInfo, users } = await api.request({
        method: "GET",
        url: `${API_ADMIN_ROUTES.users}?domain=${domain}`,
      });

      dispatch({ type: ACTIONS.SET_DOMAIN, domain: domainInfo });
      dispatch({ type: ACTIONS.SET_DOMAIN_USERS, users });

      return { domain: domainInfo, users };
    } catch (error) {
      return error;
    }
  };

export const getDomainImports =
  (domain: string): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    if (domain) {
      dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

      try {
        const { data_imports } = await api.request({
          method: "GET",
          url: `${API_ADMIN_ROUTES.dataImports}?domain=${domain}`,
        });

        dispatch({ type: ACTIONS.SET_DOMAIN_IMPORTS, dataImports: data_imports });

        return data_imports;
      } catch (error) {
        return error;
      }
    } else {
      dispatch({ type: ACTIONS.SET_DOMAIN_IMPORTS, dataImports: [] });
    }
  };

export const getDomainAlerts =
  (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filter?: string
  ): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    if (domain) {
      dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

      api.setDomainParam(domain);

      const { alerts, total_records: totalAlertsCount } = await api.request({
        method: "GET",
        url: `${API_ADMIN_ROUTES.alerts}?page_size=${pageSize}&filters=${filter}&offset=${offset}&sort_by=${sortColumn.columnKey}&sort_order=${sortColumn.value}&domain=${domain}`,
      });
      dispatch({ type: ACTIONS.SET_DOMAIN_ALERTS, alerts, totalAlertsCount });

      return alerts;
    } else {
      dispatch({ type: ACTIONS.SET_DOMAIN_ALERTS, alerts: [], totalAlertsCount: 0 });
    }
  };

export const getQuickstarts =
  (email: string): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    if (email) {
      try {
        const { quickstarts } = await api.request({
          method: "GET",
          url: `${API_ADMIN_ROUTES.quickstarts}?email=${email}`,
        });

        return quickstarts;
      } catch (error) {
        return [];
      }
    } else {
      return [];
    }
  };
