import { combineReducers } from "redux";

import { app } from "./app";
import { billing } from "./billing";
import { coeffAdmin } from "./coeffAdmin";
import { extAdmin } from "./extAdmin";
import { userSettings } from "./userSettings";

export const rootReducer = () => {
  return combineReducers({
    app,
    billing,
    coeffAdmin,
    extAdmin,
    userSettings,
  });
};
