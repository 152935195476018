import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div<Props>`
  white-space: nowrap;
  display: inline-block;
  padding: 0px 8px;
  border-radius: 4px;
  font-size: 12px;

  ${({ variant, theme }) => {
    switch (variant) {
      case "info": {
        return `
            background-color: ${theme.colors.seafoamBg};
            color: ${theme.colors.seafoam};
        `;
      }
      case "error": {
        return `
            background-color: ${theme.colors.redBg};
        `;
      }
      default: {
        return "";
      }
    }
  }}
`;

type Props = {
  variant?: "info" | "error";
};

export const Badge: React.FC<PropsWithChildren<Props>> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

const StyledBetaBadge = styled(Container)`
  padding: 3px;
  background: #eeeeee;
  color: #262626;

  svg {
    width: 11px !important;
    height: 12px !important;
    margin: 0px !important;
    position: relative;
    top: 1px;
  }
`;

export const BetaBadge = ({ hideIcon, ...props }: { hideIcon?: boolean; style?: object }) => (
  <StyledBetaBadge {...props}>BETA</StyledBetaBadge>
);
