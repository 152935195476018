import { InfoCircleFilled } from "@ant-design/icons";
import { AddonPlatform, DashboardDataImport, DashboardSpreadsheet } from "@coeff/api";
import { History } from "history";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import {
  ImportTypeIcon,
  InputSearch,
  Option,
  FilledSelect,
  GoogleSheetsIcon,
  DownArrow,
  LoaderWithPerfTimings,
  ExcelSheetsIcon,
} from "../../../components";
import { COLORS } from "../../../constants";
import {
  DataSourceTypeDisplayNames,
  getRefreshedDate,
  orderByDate,
  useTrack,
} from "../../../utils";
import { Header } from "../components";
import { useDashboardContext } from "../Dashboard";

import { SpreadsheetDetails } from "./SpreadsheetDetails";

const Container = styled.div`
  padding: 20px 30px;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .coeff__input-search {
    margin: 0;
    max-width: 360px;
  }
`;

const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SheetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(340px, 1fr));
  grid-auto-rows: minmax(210px, auto);
  gap: 20px;

  @media (max-width: 1420px) {
    grid-template-columns: repeat(2, minmax(340px, 1fr));
  }
`;

const SheetCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px 20px 0px;

  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex: 1;

  div {
    flex: 1;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  span {font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  svg {
    float: right:
  }
`;

const CardFooter = styled.div`
  display: flex;
  margin-bottom: 15px;

  .ant-avatar {
    margin: 0 3px 0 0;
    height: 40px;
    width: 40px;

    svg {
      height: 40px;
      width: 40px;
    }
  }
`;

const ErrorFooter = styled.div`
  background: ${COLORS.redBg};
  margin: auto -20px;
  padding: 5px 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: bold;

  .anticon {
    color: ${COLORS.red};
    margin-right: 8px;
  }
`;

const ExtraDataSourcesUsed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${COLORS.coeblue4};
  color: ${COLORS.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

const ShowAction = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
`;

const StyledInputSearch = styled(InputSearch)`
  width: 100%;
  max-width: 360px;
  margin-right: 8px;
`;

const renderSheetCard = (sheet: DashboardSpreadsheet, history: History<unknown>, url: string) => {
  const sortedImports = orderByDate([...sheet.data_imports], "last_run_dt");

  const mostRecentImport = sortedImports[0];

  const sheetDataSourcesUsed = [...new Set(sheet.data_imports.map(di => di.data_source_type))];

  const onClick = () => history.push(`${url}/${sheet.google_sheets_file_id}`);

  const errorImports = sheet.data_imports.filter(
    di => di.last_import_status?.status_level === "error"
  );

  return (
    <SheetCard key={sheet.google_sheets_file_id} onClick={onClick}>
      <CardHeader>
        <div>
          <h4>{sheet.google_sheets_file_name}</h4>

          {mostRecentImport?.last_run_dt && (
            <span>
              Last refreshed {getRefreshedDate(moment.utc(mostRecentImport.last_run_dt).local())}
            </span>
          )}
        </div>

        {sheet.spreadsheet_platform === AddonPlatform.GoogleSheets ? (
          <GoogleSheetsIcon />
        ) : (
          <ExcelSheetsIcon />
        )}
      </CardHeader>

      <CardFooter>
        {sheetDataSourcesUsed.slice(0, 6).map(ds => (
          <ImportTypeIcon key={ds} type={ds} />
        ))}
        {sheetDataSourcesUsed.length > 6 && (
          <ExtraDataSourcesUsed>+{sheetDataSourcesUsed.length - 6}</ExtraDataSourcesUsed>
        )}
      </CardFooter>

      {errorImports.length > 0 && (
        <ErrorFooter>
          <InfoCircleFilled />
          {errorImports.length} Import{errorImports.length > 1 ? "s" : ""} Failed
        </ErrorFooter>
      )}
    </SheetCard>
  );
};

export const Spreadsheets = () => {
  const track = useTrack();

  const { dataSourcesBySpreadsheet, dataSourcesUsed, spreadsheets, fetchingSheets } =
    useDashboardContext();

  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedDataSourceFilter, setSelectedDataSourceFilter] = useState("all");

  const [selectedSpreadsheetSort, setSelectedSpreadsheetSort] = useState("date");

  const [showAll, setShowAll] = useState<boolean>(false);

  let spreadsheetsToShow = spreadsheets ?? [];

  useEffect(() => {
    document.title = "Spreadsheets - Coefficient Workspace";
    track("workspace_spreadsheets_viewed");
  }, []);

  if (searchValue) {
    spreadsheetsToShow = spreadsheetsToShow.filter(s =>
      (s.google_sheets_file_name ?? "").toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  if (selectedDataSourceFilter !== "all") {
    spreadsheetsToShow = spreadsheetsToShow.filter(s =>
      dataSourcesBySpreadsheet![s.google_sheets_file_id].includes(selectedDataSourceFilter)
    );
  }

  spreadsheetsToShow = spreadsheetsToShow
    .filter(spreadsheet => spreadsheet.data_imports.length > 0)
    .sort((a, b) => {
      if (selectedSpreadsheetSort === "date") {
        const aSortedImports = orderByDate(a.data_imports, "last_run_dt");
        const bSortedImports = orderByDate(b.data_imports, "last_run_dt");

        const firstAImport = aSortedImports[0];
        const firstBImport = bSortedImports[0];

        if (firstAImport === undefined || firstBImport === undefined) {
          return 0;
        }

        return firstAImport.last_run_dt && firstBImport.last_run_dt
          ? moment(firstBImport.last_run_dt).diff(firstAImport.last_run_dt)
          : firstAImport.last_run_dt
          ? -1
          : firstBImport.last_run_dt
          ? 1
          : 0;
      } else {
        return (a.google_sheets_file_name ?? "")
          .toLowerCase()
          .localeCompare((b.google_sheets_file_name ?? "").toLowerCase());
      }
    });

  return (
    <Switch>
      <Route exact path={path}>
        <Container>
          <Header>
            <h1>Spreadsheets</h1>
          </Header>

          {fetchingSheets && (
            <div style={{ margin: "5rem auto 2rem", textAlign: "center" }}>
              <LoaderWithPerfTimings name="Spreadsheets" size="large" />
            </div>
          )}

          {!fetchingSheets && (
            <div style={{ maxWidth: "1200px" }}>
              <Toolbar>
                <div style={{ flex: "1" }}>
                  <StyledInputSearch
                    onChange={e => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>

                <ToolbarActions>
                  <FilledSelect
                    suffixIcon={<DownArrow />}
                    onChange={filter => setSelectedDataSourceFilter(String(filter))}
                    value={selectedDataSourceFilter}
                  >
                    <Option value="all">All Data Sources</Option>
                    {(dataSourcesUsed ?? []).map((ds, i) => (
                      <Option key={i} value={ds}>
                        {DataSourceTypeDisplayNames[ds]}
                      </Option>
                    ))}
                  </FilledSelect>

                  <FilledSelect
                    suffixIcon={<DownArrow />}
                    onChange={sort => setSelectedSpreadsheetSort(String(sort))}
                    value={selectedSpreadsheetSort}
                    defaultValue="all"
                  >
                    <Option value="date">Sort: Last Refreshed</Option>
                    <Option value="alpha">Sort: Alphabetical</Option>
                  </FilledSelect>
                </ToolbarActions>
              </Toolbar>

              <SheetGrid>
                {spreadsheetsToShow.map(
                  (sheet, i) => (showAll || i < 15) && renderSheetCard(sheet, history, url)
                )}
              </SheetGrid>

              <div style={{ margin: "1rem auto" }}>
                {spreadsheetsToShow.length > 15 && (
                  <ShowAction onClick={() => setShowAll(!showAll)}>
                    Show {showAll ? "less" : "all"} sheets
                  </ShowAction>
                )}
              </div>
            </div>
          )}
        </Container>
      </Route>

      <Route path={`${path}/:ssId`}>
        <SpreadsheetDetails />
      </Route>
    </Switch>
  );
};
