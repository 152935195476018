import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "src/store";
import styled from "styled-components";

import { Button, Typography, CenterAlign, BulletCheckList, Datasets } from ".";

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PlaceholderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  align-items: center;
  text-align: center;
`;

const StyledBulletCheckList = styled(BulletCheckList)`
  gap: 8px;
  margin-bottom: 8px;
  text-align: left;
`;

type Props = {
  onCreateClick: () => void;
};

export const EmptyCloudTables: React.FC<Props> = ({ onCreateClick }) => {
  const history = useHistory();

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const adminEmails = clientFlags?.admin_emails ?? [];

  return (
    <Placeholder>
      <CenterAlign>
        <Datasets />
      </CenterAlign>

      <PlaceholderContent>
        <Typography fontWeight={600} variant="h5">
          Turn your spreadsheet into a<br /> reusable dataset
        </Typography>

        <StyledBulletCheckList
          items={[
            <>Easily import from datasets into multiple Sheets</>,
            <>Share with your team for a unified source of truth</>,
            <>Better performance for frequently accessed data</>,
          ]}
        />

        <div>
          <Button
            disabled={clientFlags?.cloud_table_disabled}
            type="primary"
            onClick={() => onCreateClick()}
          >
            New Dataset
          </Button>
        </div>

        {clientFlags?.cloud_table_disabled ? (
          clientFlags.is_gsuite_domain_admin ? (
            <Typography variant="body3" color="textSecondary">
              Datasets are currently disabled for this workspace. To enable, go to{" "}
              <a onClick={() => history.push("/dashboard/settings")}>Workspace Settings</a>
            </Typography>
          ) : (
            <Typography variant="body3" color="textSecondary">
              Datasets are currently disabled for this workspace. <br />
              {adminEmails.length !== 1 ? (
                <>
                  Contact one of{" "}
                  <Typography inline underline tooltipDelay={0} title={`${adminEmails.join(", ")}`}>
                    your admins
                  </Typography>
                </>
              ) : (
                <>
                  Contact{" "}
                  <Typography inline underline tooltipDelay={0} title={`${adminEmails.join(", ")}`}>
                    your admin
                  </Typography>
                </>
              )}{" "}
              to request access.
            </Typography>
          )
        ) : null}
      </PlaceholderContent>
    </Placeholder>
  );
};
