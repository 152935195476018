import { Pagination } from "antd";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item,
  button.ant-pagination-item-link {
    border: 1px solid transparent;
  }

  .ant-pagination-disabled {
    pointer-events: none;
  }
`;

const TextSecondary = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.black45};
`;

const TableFooter = styled.div`
  display: flex;
`;

const TableFooterContent = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
`;

type Props = {
  page: number;
  pageSize: number;
  data: Array<Array<string | number | boolean | undefined>>;
  totalRowCount: number;
  onPageChange: (page: number) => void;
};

export const AdminTableFooter: React.FC<Props> = ({
  page,
  pageSize,
  totalRowCount,
  data,
  onPageChange,
}) => {
  return (
    <TableFooter>
      <TableFooterContent>
        <TextSecondary>
          Showing {page * pageSize + 1} - {page * pageSize + data.length} of {totalRowCount}
        </TextSecondary>

        <PaginationContainer>
          <StyledPagination
            current={page + 1}
            onChange={page => onPageChange(page - 1)}
            showQuickJumper={false}
            showSizeChanger={false}
            defaultPageSize={pageSize}
            total={totalRowCount}
          />
        </PaginationContainer>

        <div></div>
      </TableFooterContent>
    </TableFooter>
  );
};
