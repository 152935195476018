import { Layout } from "antd";
import * as React from "react";
import styled from "styled-components";

interface DefaultLayoutProps {
  children: React.ReactChild | React.ReactChild[];
}

interface DefaultLayoutState {}

const { Content } = Layout;

const StyledContent = styled(Content)``;

export class DefaultLayout extends React.Component<DefaultLayoutProps, DefaultLayoutState> {
  render = () => {
    const { children } = this.props;

    return (
      <Layout style={{ background: "#fff", height: "100%", width: "100%" }}>
        <StyledContent>{children}</StyledContent>
      </Layout>
    );
  };
}
