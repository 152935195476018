import { useRef, useEffect } from "react";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledDrawerWrapper = styled.aside<{ $open: boolean }>`
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  transition: all 0.3s ease-in-out;
  transform: ${props => (props.$open ? `translate(0%)` : `translate(100%)`)};
  z-index: 1000;

  .content {
    position: relative;
    background: ${COLORS.white};
    border-left: 1px solid ${COLORS.black15};
    width: 300px;
    height: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    box-shadow: ${props =>
      props.$open ? `-32px 0px 40px 0px ${COLORS.black6}` : `-32px 0px 40px 0px transparent`};
  }
`;

type DrawerProps = {
  open: boolean;
  closeOnClickAway: boolean;
  setOpen: (open: boolean) => void;
};

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  open,
  setOpen,
  closeOnClickAway,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // Close the side drawer or dialog box
        if (closeOnClickAway && open) {
          setOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, open, setOpen, closeOnClickAway]);

  return (
    <StyledDrawerWrapper $open={open} ref={ref}>
      <div className="content">{children}</div>
    </StyledDrawerWrapper>
  );
};
