import { Config } from ".";

declare const __FLASK_DEV_SERVER_BASE_URL__: string;

const osUsername = (process.env.USER ?? "LOCAL").replace(/[^a-zA-Z0-9]/g, "_").toUpperCase();

export const dev: Config = {
  BASE_URL: __FLASK_DEV_SERVER_BASE_URL__,
  SENTRY_DSN: "https://31e7e23fc06d400a90c55cc7ab48809e@o404721.ingest.sentry.io/5720781",
  GOOGLE_CLIENT_ID: "835967182188-i7lrd1egai2p5808eg30b16s8qplr4ko.apps.googleusercontent.com",
  GOOGLE_DEVELOPER_API_KEY: "AIzaSyCjllJaU3sWjato8ZsKIyW5DNPXe_cOepI",
  SALESFORCE_CONNECTED_APP_CONSUMER_KEY:
    "3MVG9LBJLApeX_PCzOK2ZVrcYiu4V9IYVWKbE1uucDYLV5cnXgABVMA3kal4fPRWVWcVHbv1V4u2Is7KD2oQQ",
  STRIPE_API_KEY:
    "pk_test_51IK1n5GLbIh3iVB0e3vDaxRkslgE3ab7oEcOcsdfATCa8NjBMSUU58Gwdezx1UYG5uvDz9sGz7rgfNVEaP585aj200zBEPNhPJ",
  OAUTH_REDIRECT_URL_BASE: "https://dev.coefficient.io/api/oauth2_proxy",
  MS_IDP_CLIENT_ID: "8c692676-d9e5-416e-90d9-2837a56b3023",
  MS_UI_ENDPOINT: "https://localhost:3000",
  // Append the OS username to the integration name so that each developer can register an
  // independent Snowflake security integration in our test Snowflake instance (without
  // clobbering each other's integrations)
  SNOWFLAKE_SECURITY_INTEGRATION_NAME: `COEFFICIENT_DEV_${osUsername}`,
  ZENDESK_CHAT_KEY: "ece9a193-78ba-4042-a625-9026480535d0",
  ZENDESK_MESSENGER_KEY: "a36735de-b6ec-4be7-a806-0fd3192c4534",
};
