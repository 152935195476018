import {
  CloudTable,
  CloudTableSourceInfo,
  DefaultApi,
  DefaultApiUpdateCloudTableRequest,
  GetVirtualImportDataRequest,
} from "@coeff/api";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { DataImportStatusError } from "../utils";
import { useApiContext } from "../utils/contexts";

import { QueryCacheKey } from ".";

export type PartialCloudTable = Partial<
  Omit<CloudTable, "source_info"> & { source_info: Partial<CloudTableSourceInfo> }
>;

export const fetchCloudTables = async (api: DefaultApi) => {
  const response = await api.getCloudTables();
  return response.data.cloud_tables;
};

export const fetchCloudTable = async (api: DefaultApi, cloud_table_id: string) => {
  const response = await api.getCloudTable({ cloud_table_id });
  return response.data.cloud_table;
};

export const fetchCloudTablePreviewData = async (api: DefaultApi, cloudTable: CloudTable) => {
  const response = await api.runCloudTablePreviewRun({
    run_cloud_table_preview_run_request: { cloud_table: cloudTable },
  });

  return response.data;
};

export const fetchCloudTableData = async (
  api: DefaultApi,
  cloud_table_id: string,
  get_virtual_import_data_request: GetVirtualImportDataRequest
) => {
  const response = await api.getCloudTableData({
    cloud_table_id,
    get_virtual_import_data_request,
  });

  return response.data;
};

export const useCloudTables = ({ enabled, select }: UseQueryOptions<Array<CloudTable>> = {}) => {
  const { apiClient: api } = useApiContext();

  return useQuery([QueryCacheKey.CLOUD_TABLES], () => fetchCloudTables(api), { enabled, select });
};

export const useCloudTable = (id: string, { enabled }: UseQueryOptions = {}) => {
  const { apiClient: api } = useApiContext();

  return useQuery(
    [QueryCacheKey.CLOUD_TABLES, QueryCacheKey.CLOUD_TABLE, id],
    () => fetchCloudTable(api, id),
    {
      enabled,
    }
  );
};

export const useCloudTablePreviewData = (
  cloudTable: CloudTable,
  { enabled }: UseQueryOptions = {}
) => {
  const { apiClient: api } = useApiContext();

  return useQuery(
    [QueryCacheKey.CLOUD_TABLE_PREVIEW_DATA, cloudTable.source_info],
    () => fetchCloudTablePreviewData(api, cloudTable),
    {
      enabled:
        enabled &&
        cloudTable.source_info?.google_drive_file_id !== undefined &&
        cloudTable.source_info?.google_sheets_tab_id !== undefined &&
        cloudTable.source_info?.google_sheets_is_specific_range !== undefined &&
        !(
          cloudTable.source_info.google_sheets_is_specific_range &&
          !cloudTable.source_info.google_sheets_range
        ),
    }
  );
};

export const useCloudTableData = (
  params: { cloudTable?: CloudTable; options: GetVirtualImportDataRequest },
  { enabled, keepPreviousData }: UseQueryOptions = {}
) => {
  const { apiClient: api } = useApiContext();

  const { cloudTable, options } = params;

  return useQuery(
    [QueryCacheKey.CLOUD_TABLE_DATA, cloudTable?.source_info, options],

    () => fetchCloudTableData(api, cloudTable?.cloud_table_id!, options),
    {
      enabled,
      keepPreviousData,
    }
  );
};

export const useCreateCloudTableMutation = () => {
  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (cloudTable: CloudTable) => {
    const response = await api.createCloudTable({
      create_cloud_table_request: { cloud_table: cloudTable },
    });
    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryCacheKey.CLOUD_TABLES]);
    },
  });
};

export const useUpdateCloudTableMutation = () => {
  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (params: { cloud_table_id: string; cloud_table: CloudTable }) => {
    const response = await api.updateCloudTable({
      cloud_table_id: params.cloud_table_id,
      create_cloud_table_request: { cloud_table: params.cloud_table },
    });

    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryCacheKey.CLOUD_TABLES]);
    },
  });
};

export const useRunCloudTableMutation = () => {
  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (cloud_table_id: string) => {
    const response = await api.runCloudTable({ cloud_table_id });

    if (response.data.cloud_table.last_import_status?.status_level === "error") {
      throw new DataImportStatusError(response.data.cloud_table.last_import_status);
    }

    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: response => {
      queryClient.invalidateQueries([QueryCacheKey.CLOUD_TABLE_DATA]);

      queryClient.invalidateQueries([
        QueryCacheKey.CLOUD_TABLES,
        QueryCacheKey.CLOUD_TABLE,
        response.cloud_table.cloud_table_id,
      ]);
    },
  });
};

export const useDeleteCloudTableMutation = () => {
  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (cloud_table_id: string) => {
    const response = await api.deleteCloudTable({ cloud_table_id });

    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryCacheKey.CLOUD_TABLES]);
    },
  });
};
