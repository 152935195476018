import { CloudTable, CloudTableSourceInfo } from "@coeff/api";
import moment from "moment";

import { CLOUD_TABLE_ICON_COLORS, CLOUD_TABLE_ICON_NAMES } from "../constants";

import { getStringHashCode } from "./hash";

export const getDuplicateValues = (
  values: Array<string | number | boolean>
): Set<string | number | boolean> => {
  const seen = new Set<string | number | boolean>();

  const duplicates = new Set<string | number | boolean>();

  for (const value of values) {
    if (seen.has(value)) {
      duplicates.add(value);
    }

    seen.add(value);
  }

  return duplicates;
};

export const getCloudTableSpreadsheetSheetUrl = (fileId: string, tabId?: number): string => {
  return `https://docs.google.com/spreadsheets/d/${fileId}/edit#gid=${tabId}`;
};

export const searchCloudTableText = (cloudTable: CloudTable, text: string) => {
  const searchableText = [cloudTable.cloud_table_name, cloudTable.description]
    .join("")
    .toLowerCase()
    .replace(/ /g, "");

  const searchText = text.toLowerCase().replace(/ /g, "");

  return searchableText.includes(searchText);
};

export const sortCloudTablesByKey = (a: CloudTable, b: CloudTable, key: keyof CloudTable) => {
  switch (key) {
    case "last_run_dt": {
      return moment.utc(b.last_run_dt).diff(moment.utc(a.last_run_dt));
    }
    case "cloud_table_name": {
      return a.cloud_table_name.localeCompare(b.cloud_table_name);
    }
    default:
      return 0;
  }
};

export const getCloudTableIconDefaults = (
  sourceInfo: CloudTableSourceInfo
): { icon_name?: string; icon_background_color?: string } => {
  const sourceInfoHash = getStringHashCode(JSON.stringify(sourceInfo));

  const iconNames = Object.keys(CLOUD_TABLE_ICON_NAMES);

  const nameIndex = sourceInfoHash % iconNames.length;

  const backgroundColors = Object.keys(CLOUD_TABLE_ICON_COLORS);

  const backgroundIndex = sourceInfoHash % backgroundColors.length;

  return {
    icon_name: CLOUD_TABLE_ICON_NAMES[iconNames[nameIndex]],
    icon_background_color: CLOUD_TABLE_ICON_COLORS[backgroundColors[backgroundIndex]],
  };
};
