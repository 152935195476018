import { DataImportStatus, DataImport } from "@coeff/api";

export const getSpreadsheetSheetUrl = (dataImport: DataImport): string | undefined => {
  const { google_sheets_file_id, google_sheets_tab_id } = dataImport.target_info ?? {};

  if (google_sheets_file_id && google_sheets_tab_id) {
    return `https://docs.google.com/spreadsheets/d/${google_sheets_file_id}/edit#gid=${google_sheets_tab_id}`;
  }

  return;
};

export function instanceofImportStatus(value: unknown): value is DataImportStatus {
  return (
    typeof value === "object" &&
    value !== null &&
    value.hasOwnProperty("status_level") &&
    value.hasOwnProperty("status_dt") &&
    value.hasOwnProperty("message") &&
    value.hasOwnProperty("title")
  );
}
