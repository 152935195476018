import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message, ModalProps } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import { COLORS } from "../constants";
import { isValidHttpUrl, useApiContext } from "../utils";

import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { HelpIcon } from "./Icons";
import { Input } from "./Input";
import { Modal } from "./Modal";
import { Steps } from "./Steps";
import { Typography } from "./Typography";

const ModalActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const ModalButton = styled(Button)<{ $color: string }>`
  &.ant-btn-link {
    padding: 4px 0;
    color: ${props => props.$color};
  }

  &.ant-btn-primary {
    background-color: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
    color: ${COLORS.white};

    &:active,
    &:hover,
    &:focus {
      background-color: ${COLORS.coeblue6};
      border-color: ${COLORS.coeblue6};
      color: ${COLORS.white};
      box-shadow: none;
    }
  }
`;

const StyledParagraph = styled.p`
  color: ${COLORS.black45};
`;

const StyledContent = styled.div`
  label {
    font-weight: bold;
  }

  .title {
    padding-right: 40px !important;
  }

  .description {
    color: ${COLORS.black45};
  }
`;

const StyledButton = styled(Button)`
  margin: 0;
  font-weight: 10;
  width: 160px;
`;

const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.coeblue};
  margin: 8px 0px;
  padding: 16px;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 24px;
`;

const ConfirmCheckbox = styled(Checkbox)`
  && {
    margin: 20px 0px 0px;
  }
`;

type Props = ModalProps & {
  isLoading?: boolean;
  accountId: string;
  externalURL?: string;
  onSuccess: () => void;
};

export const NetSuiteRESTLetConfigureModal = (props: Props) => {
  const { isLoading, accountId, externalURL, onCancel, onSuccess } = props;

  const [userEnteredExternalURL, setUserEnteredExternalURL] = React.useState(externalURL);

  const [userConfirmNetsuiteConfigured, setUserConfirmedNetsuiteConfigured] =
    useState<boolean>(false);

  const [userConfirmNetsuiteConfiguredHighlight, setUserConfirmedNetsuiteConfiguredHighlight] =
    useState<boolean>(false);

  const [userConfirmUnzipRestlet, setUserConfirmUnzipRestlet] = useState<boolean>(false);

  const [userConfirmUnzipRestletHighlight, setuserConfirmUnzipRestletHighlight] =
    useState<boolean>(false);

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);

  const upsertAppInfoMutation = useMutation(
    () =>
      api.upsertNetSuiteAppInfo({
        upsert_net_suite_app_info_request: {
          account_id: accountId,
          external_url: (userEnteredExternalURL || "").trim(),
        },
      }),
    {
      onSuccess: () => {
        message.success("NetSuite OAuth configured successfully");

        onSuccess();

        queryClient.invalidateQueries([QueryCacheKey.NETSUITE_APP_INFO]);
        queryClient.invalidateQueries([QueryCacheKey.NETSUITE_APP_INFO, accountId]);
      },
      onError: () => message.error("Something went wrong. Please try again."),
    }
  );

  const renderSteps = () => {
    return (
      <Steps
        direction="vertical"
        size="small"
        current={currentStep}
        items={[
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === undefined ? "textPrimary" : "textSecondary"}
              >
                Download zip file with script (.js)
              </Typography>
            ),
            description:
              currentStep === undefined ? (
                <StyledStepContainer>
                  <StyledParagraph>
                    Download and unzip the .js file that you will use in the next step.
                  </StyledParagraph>

                  <StyledButton
                    type="ghost"
                    style={{
                      paddingBottom: 0,
                      background: "white",
                      color: "black",
                    }}
                    href="https://coeff.s3.us-west-2.amazonaws.com/public/netsuite/CoefficientRESTLet_1.4.0.js.zip"
                  >
                    Download (.zip)
                  </StyledButton>

                  <ConfirmCheckbox
                    className={userConfirmUnzipRestletHighlight ? "error" : ""}
                    checked={userConfirmUnzipRestlet}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      e.stopPropagation();
                      const checked = e.target.checked;
                      if (userConfirmUnzipRestletHighlight && checked) {
                        setuserConfirmUnzipRestletHighlight(false);
                      }
                      setUserConfirmUnzipRestlet(e.target.checked);
                    }}
                  >
                    <Typography fontWeight={500}>I have unzipped the .zip file</Typography>
                  </ConfirmCheckbox>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        if (!userConfirmUnzipRestlet) {
                          setuserConfirmUnzipRestletHighlight(true);
                          return;
                        }
                        setCurrentStep(1);
                      }}
                    >
                      Next
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <>
                  {currentStep && (
                    <StyledButton
                      style={{ justifyContent: "flex-start" }}
                      type="link"
                      onClick={() => {
                        setCurrentStep(undefined);
                      }}
                    >
                      Revisit
                    </StyledButton>
                  )}
                </>
              ),
          },
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === 1 ? "textPrimary" : "textSecondary"}
              >
                Configure in NetSuite
              </Typography>
            ),
            description:
              currentStep === 1 ? (
                <StyledStepContainer>
                  <StyledParagraph>
                    Refer to our detailed instructions to configure in NetSuite.
                  </StyledParagraph>

                  <StyledButton
                    type="ghost"
                    style={{
                      width: 240,
                      paddingBottom: 0,
                      background: "white",
                      color: "black",
                    }}
                    href="https://coefficient.io/help-center/netsuite/updateRESTLet"
                    target="_blank"
                    icon={<HelpIcon />}
                  >
                    Open Configuration Steps
                  </StyledButton>

                  <ConfirmCheckbox
                    className={userConfirmNetsuiteConfiguredHighlight ? "error" : ""}
                    checked={userConfirmNetsuiteConfigured}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      e.stopPropagation();
                      const checked = e.target.checked;
                      if (userConfirmNetsuiteConfiguredHighlight && checked) {
                        setUserConfirmedNetsuiteConfiguredHighlight(false);
                      }
                      setUserConfirmedNetsuiteConfigured(e.target.checked);
                    }}
                  >
                    <Typography fontWeight={500}>I have configured in NetSuite</Typography>
                  </ConfirmCheckbox>
                  <Typography
                    style={{ marginLeft: "24px", fontSize: "12px" }}
                    color="textSecondary"
                  >
                    NetSuite OAuth will not work properly until the script is configured in
                    NetSuite.
                  </Typography>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        if (!userConfirmNetsuiteConfigured) {
                          setUserConfirmedNetsuiteConfiguredHighlight(true);
                          return;
                        }
                        setCurrentStep(2);
                      }}
                    >
                      Next
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <>
                  {(currentStep ?? 0) > 1 && (
                    <StyledButton
                      type="link"
                      style={{ justifyContent: "flex-start" }}
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      Revisit
                    </StyledButton>
                  )}
                </>
              ),
          },
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === 2 ? "textPrimary" : "textSecondary"}
              >
                Provide the external URL
              </Typography>
            ),
            description:
              currentStep === 2 ? (
                <StyledStepContainer>
                  <label>External URL</label>
                  <Input
                    placeholder="ex: https://coeff13813.restlets.api.netsuite.com/app..."
                    value={userEnteredExternalURL}
                    onChange={e => setUserEnteredExternalURL(e.target.value)}
                  />
                  <StyledParagraph>
                    Paste the external URL that was generated in NetSuite.
                  </StyledParagraph>
                </StyledStepContainer>
              ) : (
                <noscript />
              ),
          },
        ]}
      />
    );
  };

  return (
    <Modal {...props} width={600}>
      <StyledContent>{renderSteps()}</StyledContent>

      <ModalActions>
        <ModalButton $color={COLORS.coeblue5} disabled={isLoading} type="link" onClick={onCancel}>
          Cancel
        </ModalButton>

        <ModalButton
          $color={COLORS.white}
          disabled={isLoading || !isValidHttpUrl(userEnteredExternalURL || "")}
          type="primary"
          onClick={() => upsertAppInfoMutation.mutate()}
        >
          {isLoading ? "Saving..." : "Save"}
        </ModalButton>
      </ModalActions>
    </Modal>
  );
};
