import React from "react";

import { useDataImportRun } from "../../../api";
import { LoaderWithPerfTimings } from "../../../components";

type SQLQueryTooltipProps = {
  dataImportRunId: string;
};

export const SQLQueryTooltip: React.FC<SQLQueryTooltipProps> = ({ dataImportRunId }) => {
  const { data: dataImportRun, isFetching: isDataImportRunFetching } =
    useDataImportRun(dataImportRunId);

  return (
    <>
      {isDataImportRunFetching || !dataImportRun ? (
        <LoaderWithPerfTimings name="DataImportRuns.locale" />
      ) : (
        dataImportRun.data_source_details
      )}
    </>
  );
};
