import { Radio as RadioAD } from "antd";
import { RadioProps } from "antd/lib/radio";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledRadio = styled(RadioAD)`
  .ant-radio-inner {
    border-width: 2px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: ${COLORS.coeblue4};

      &:after {
        background-color: ${COLORS.coeblue4};
      }
    }

    &:after {
      animation-duration: 0s !important;
    }
  }

  &.error {
    .ant-radio {
      .ant-radio-inner {
        border-color: ${COLORS.red};
      }
    }
  }
`;

export const Radio = ({ children, ...props }: RadioProps) => (
  <StyledRadio {...props}>{children}</StyledRadio>
);
