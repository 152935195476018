import { useMsal } from "@azure/msal-react";
import { FeatureFlag } from "@coeff/api";
import React, { useEffect } from "react";
import { useGate } from "statsig-react";
import styled from "styled-components";

import { CoeLogo, Typography, MSLoginButton } from "../components";
import { config } from "../config";
import { API_ROUTES, COLORS } from "../constants";
import { publicClientAppScopes } from "../utils";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  background-image: url("../static/login-gradient-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  text-align: center;

  #signInBtn {
    transform: scale(1.1);
  }

  a {
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.black85};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  margin-bottom: 24px;
`;

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;
`;

const StyledLogo = styled(CoeLogo)`
  width: 240px;
  margin-bottom: 24px;
`;

export const Login: React.FC = () => {
  const { instance: publicClientApp } = useMsal();

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: config.GOOGLE_CLIENT_ID,
      ux_mode: "redirect",
      login_uri: `${config.BASE_URL}${API_ROUTES.authCallBack}${
        location.search ? location.search : ""
      }`,
    });

    google.accounts.id.renderButton(document.getElementById("signInBtn")!, {
      type: "standard",
      theme: "outline",
      size: "large",
    });
  }, []);

  const handleMSLoginClick = () => {
    publicClientApp.loginRedirect({
      scopes: publicClientAppScopes,
    });
  };

  return (
    <Container>
      <div>
        <StyledLogo id="coeff__logo" />
      </div>

      <Content>
        <Typography variant="h1" fontWeight={800}>
          Sign in
        </Typography>

        <LoginOptions>
          <>
            <div>
              <Typography gutterBottom>Using your Google Account</Typography>
              <div id="signInBtn"></div>
            </div>

            <>
              <Typography variant="body2" fontWeight={600}>
                OR
              </Typography>

              <div>
                <Typography gutterBottom>Using your Microsoft Account</Typography>
                <MSLoginButton onClick={() => handleMSLoginClick()} />
              </div>
            </>
          </>
        </LoginOptions>
      </Content>

      <div>
        <Typography variant="body3" fontWeight={500}>
          Having trouble accessing your account?
        </Typography>

        <a href="mailto:support@coefficient.io">Contact Support</a>
      </div>
    </Container>
  );
};
