import {
  AnalyticsEvent,
  AnalyticsEventNameType,
  AnalyticsEventPlatformType,
  DefaultApi,
} from "@coeff/api";
import { v4 as uuidv4 } from "uuid";

import { getCurrentReplayId } from "../utils";

const LOCAL_STORAGE_KEY = "anonymousUserId";

export const getAnonymousUserId = () => {
  let id = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!id) {
    id = uuidv4();
    localStorage.setItem(LOCAL_STORAGE_KEY, id);
  }

  return id;
};

export const clearAnonymousUserId = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const analyticsTrackAnonymous = async (
  api: DefaultApi,
  eventName: AnalyticsEventNameType,
  props: AnalyticsEvent & { temp_user_key: string }
) => {
  const event: AnalyticsEvent = {
    event_name: eventName,
    platform: AnalyticsEventPlatformType.Webapp,
    user_agent: navigator.userAgent,
    replay_id: getCurrentReplayId(),
    ...props,
  };

  const response = await api.analyticsTrackAnonymousEvents({
    analytics_track_anonymous_events_request: { events: [event] },
  });

  return response.data;
};
