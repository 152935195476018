import { CloudTable, DataImportErrorType } from "@coeff/api";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import { Copy, Check, AlertExclamation } from "..";

import { TableHeaderCell } from "./shared";

const TextEllipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TableHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  svg {
    flex-shrink: 0;
  }
`;

const TableHeaderCellWithActions = styled(TableHeaderCell)`
  .icon {
    width: 16px;
    height: 16px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    width: 16px;
  }

  .icon-container .icon {
    visibility: hidden;
  }

  .icon-container.visible .icon {
    visibility: visible;
  }

  &:hover {
    cursor: pointer;

    .icon {
      visibility: visible;
    }
  }
`;

type Props = {
  cloudTable?: CloudTable;
  columnIndex: number;
  headers: Array<string | number | boolean>;
  duplicateHeaders: Set<string | number | boolean>;
};

export const DataImportTableHeader: React.FC<Props> = ({
  cloudTable,
  headers,
  columnIndex,
  duplicateHeaders,
}) => {
  const [headerClickedTimeout, setHeaderClickedTimeout] = React.useState<number>();

  const [headerClickedIndex, setHeaderClickedIndex] = React.useState<number>();

  const handleHeaderClick = (index: number) => {
    navigator.clipboard.writeText(String(headers[index]));

    setHeaderClickedIndex(index);

    const timeoutId = setTimeout(() => {
      setHeaderClickedIndex(undefined);
    }, 1000);

    if (headerClickedTimeout) {
      clearTimeout(headerClickedTimeout);
    }

    setHeaderClickedTimeout(timeoutId as unknown as number);
  };

  const value = headers[columnIndex];

  const emptyHeaderErrorTypes: Array<DataImportErrorType> = [
    DataImportErrorType.CloudTableEmptyColumnNames,
    DataImportErrorType.CloudTableDataNotDetected,
  ];

  const isEmptyError =
    cloudTable?.last_import_status?.error_type &&
    emptyHeaderErrorTypes.includes(cloudTable?.last_import_status?.error_type) &&
    value === "";

  const isDuplicateError =
    cloudTable?.last_import_status?.error_type ===
      DataImportErrorType.CloudTableDuplicateColumnNames && duplicateHeaders.has(value);

  return (
    <TableHeaderCellWithActions
      title={String(value)}
      onClick={() => handleHeaderClick(columnIndex)}
    >
      <TableHeaderContent>
        {isEmptyError || isDuplicateError ? <AlertExclamation /> : null}
        <TextEllipsis>{value}</TextEllipsis>
      </TableHeaderContent>

      <div className={clsx("icon-container", { visible: headerClickedIndex === columnIndex })}>
        {value !== "" ? (
          headerClickedIndex === columnIndex ? (
            <Check className="icon" />
          ) : (
            <Copy className="icon" />
          )
        ) : null}
      </div>
    </TableHeaderCellWithActions>
  );
};
