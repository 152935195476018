import { AnalyticsEvent, AnalyticsEventNameType, FeatureFlag } from "@coeff/api";
import { Menu } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AppState } from "src/store";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  AppThunkDispatch,
  SortColumn,
  getDataImportRuns,
  getDataImports,
  getDomainUsersExt,
  getDomainAlertsExt,
  getDomainDataSources,
  SortDirection,
} from "../../actions";
import { Option, ConfirmBox, Button, toast } from "../../components";
import { Select } from "../../components/Select";
import { COLORS } from "../../constants";
import {
  AdminAlert as AlertT,
  ExtAdminDataImport as DataImport,
  ExtAdminDataImportRun as DataImportRun,
  ExtAdminDomainUser as DomainUser,
  AdminDataSource as DataSource,
  User as AlertUser,
} from "../../types";
import { useTrack } from "../../utils";
import { Alerts } from "../CoeffAdmin/components";
import {
  DataImportRuns,
  DataImports,
  DomainUsers,
  DomainDataSources,
} from "../ExtAdmin/components";
import { ExtAdminContext } from "../ExtAdmin/ExtAdmin";

type TrackContextProps = (
  eventName: AnalyticsEventNameType,
  event?: AnalyticsEvent
) => Promise<void>;

interface BaseWorkspaceAdminProps {
  domain: string;
  setDomainCb: (domain: string) => void;
  domains_in_domain_family: string[];
  enableAdminConnectedSources: boolean;
  getDataImports: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ) => void;
  getDataImportRuns: (params?: object, domain?: string) => Promise<DataImportRun[]>;
  getDomainUsers: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn
  ) => void;
  getDomainAlertsExt: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ) => void;
  getDomainDataSources: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ) => void;
  location: any;
  loading: boolean;
  alertLoading: boolean;
  oauthToken: string;
  openIdToken: string;
  alerts: AlertT[];
  alertUsers: AlertUser[];
  dataImports: DataImport[];
  dataImportRuns: DataImportRun[];
  domainDataSources: DataSource[];
  domainUsers: DomainUser[];
  isCoeffAdmin: boolean;
  totalRecords: number;
  totalAlertsCount: number;
  isGsuiteDomainAdmin: boolean;
  baseUrl: string;
  hideHeader?: boolean;
  track: TrackContextProps;
  viewAsSuperAdmin?: boolean;
}

interface ExtAdminState {
  activeTab: "imports" | "runs" | "users" | "alerts" | "data_sources" | string;
  domainOverride: string;
  openCSVModal: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 20px;
  line-height: 1.3;

  && .ant-table-header table thead tr {
    th {
      background: #ecf6ff;
      padding-left: 8px;
      color: #0054e6;
    }
    & th.ant-table-column-sort {
      background: #ecf6ff;
    }
    & th:hover {
      background: #cce9ff;
    }
  }

  && .ant-table-tbody {
    tr {
      td {
        padding: 0px 0px;
        height: 54px;
        font-size: 14px;

        &.center {
          text-align: center;
        }
      }

      &.ant-table-expanded-row {
        td {
          background: #f3f9ff !important;
        }
      }
    }
  }
`;

const Header = styled.div`
  display: flex;

  button.importBtn {
    margin-left: auto;
  }
  h1 {
    font-weight: 800;
    font-size: 28px;
    line-height: 56px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin: 0px;
  }

  .coeff__select {
    margin-left: 24px;

    .ant-select-selector {
      width: 148px;
      height: 32px;
      background-color: #f5f5f5;
      .ant-select-selection-item {
        font-weight: 600;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
  flex: 1;
  border-bottom: none;

  .ant-menu-item {
    &.ant-menu-item-selected {
      color: ${COLORS.coeblue5}!important;
      ::after {
        border-color: inherit !important;
      }
    }

    &:hover {
      color: ${COLORS.coeblue5}!important;
      ::after {
        border-color: inherit !important;
      }
    }
    margin: 0px 8px !important;
    padding: 0px 0px !important;

    font-weight: bold;

    ::after {
      transition: border-color 0.3s;
      border-color: transparent;
      right: 0px !important;
      left: 0px !important;
    }

    span: {
      color: inherit !important;
    }

    a {
      color: inherit !important;
      padding: 0px 16px !important;
    }

    &:hover {
      a {
        opacity: 0.8;
      }
    }

    svg {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
`;

const Content = styled.div``;

const ViewAs = (props: {
  isGsuiteAdmin: boolean;
  domainOverride: string;
  setDomainOverride: (domainOverride: string) => void;
  domains_in_domain_family: Array<string>;
  onKeyPress: (e, domainOverride: string) => void;
  onBlur: (v) => void;
  domain: string;
}) => {
  if (!props.isGsuiteAdmin) {
    return <></>;
  }

  if (props.domains_in_domain_family.length > 1) {
    return (
      <Select
        onChange={domain_name => {
          props.setDomainOverride(domain_name);
          setTimeout(() => {
            props.onBlur(domain_name);
          });
        }}
        size="middle"
        value={props.domainOverride}
        placeholder="Choose domain..."
      >
        <Option key={"all_domains"} value={"all_domains"}>
          All Domains
        </Option>
        {props.domains_in_domain_family.map((column, i) => (
          <Option key={column} value={column}>
            {column}
          </Option>
        ))}
      </Select>
    );
  }
  return <></>;
};

class BaseWorkspaceAdmin extends React.Component<BaseWorkspaceAdminProps, ExtAdminState> {
  constructor(props: BaseWorkspaceAdminProps) {
    super(props);

    const defaultActiveTab = props.enableAdminConnectedSources ? "data_sources" : "imports";

    const defaultDomain =
      !Boolean(props.viewAsSuperAdmin) && props.domains_in_domain_family.length > 1
        ? "all_domains"
        : props.domain;

    this.state = {
      activeTab: props.location.pathname.split("/")[3] || defaultActiveTab,
      domainOverride: defaultDomain,
      openCSVModal: false,
    };
  }
  DEFAULT_PAGE_SIZE = 100;

  getCSVURL = type => {
    const currentDomain = this.state.domainOverride || this.props.domain;
    const domain =
      currentDomain === "all_domains"
        ? this.props.domains_in_domain_family.toString()
        : currentDomain;
    const host = `${window.location.protocol}//${window.location.hostname}/`;
    const urlParams = `?domain=${domain}&tz_name=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&format=csv`;
    switch (type) {
      case "runs":
        return host + "api_admin_ext/data_import_runs" + urlParams;
      case "users":
        return host + "api_admin_ext/users" + urlParams;
      case "alerts":
        return host + "api_admin_ext/alerts" + urlParams;
      case "data_sources":
        return host + "api_admin_ext/data_sources" + urlParams;
      case "imports":
      default:
        return host + "api_admin_ext/data_imports" + urlParams;
    }
  };

  componentDidUpdate = () => {
    const defaultActiveTab = this.props.enableAdminConnectedSources ? "data_sources" : "imports";
    const activeTab = this.props.location.pathname.split("/")[3] || defaultActiveTab;
    if (activeTab !== this.state.activeTab) {
      this.setState({ activeTab });
    }
  };

  setDomainOverride = domainOverride => {
    this.setState({ domainOverride });

    this.props.setDomainCb(domainOverride);
  };

  refreshData = () => {
    const { activeTab } = this.state;
    const { domain, domains_in_domain_family, getDomainAlertsExt } = this.props;

    if (activeTab === "alerts") {
      const currentDomain = this.state.domainOverride || domain;
      return getDomainAlertsExt(
        currentDomain === "all_domains" ? domains_in_domain_family.toString() : currentDomain,
        this.DEFAULT_PAGE_SIZE,
        0,
        {
          columnKey: "created_dt",
          value: SortDirection.descend,
        },
        "[]"
      );
    }
  };

  onKeyPress = ({ key }, domainOverride) => {
    if (key === "Enter") {
      this.setState({ domainOverride }, this.refreshData);
    }
  };

  render = () => {
    const { activeTab, openCSVModal } = this.state;

    const tabMap = {
      imports: "workspace_admin_imports_list_viewed",
      runs: "workspace_admin_run_history_viewed",
      alerts: "workspace_admin_alerts_list_viewed",
      users: "workspace_admin_users_list_viewed",
      data_sources: "workspace_admin_data_sources_list_viewed",
    };

    const { isCoeffAdmin, isGsuiteDomainAdmin, track } = this.props;
    const handleImportButtonClick = () => {
      this.setState({ openCSVModal: true });
    };

    const handleCopyButtonClick = () => {
      navigator.clipboard.writeText(this.getCSVURL(activeTab));
      this.setState({ openCSVModal: false });
    };

    return (
      isGsuiteDomainAdmin && (
        <ExtAdminContext.Provider
          value={{
            ...this.props,
            domain: this.state.domainOverride || this.props.domain,
          }}
        >
          <Wrapper>
            <Header>
              {!this.props.hideHeader && (
                <Title>
                  <h1>Admin</h1>

                  <ViewAs
                    onKeyPress={this.onKeyPress}
                    domainOverride={this.state.domainOverride}
                    setDomainOverride={this.setDomainOverride}
                    isGsuiteAdmin={isGsuiteDomainAdmin}
                    domains_in_domain_family={this.props.domains_in_domain_family}
                    domain={this.props.domain}
                    onBlur={this.refreshData}
                  />
                </Title>
              )}
              <Button
                className="importBtn"
                type="primary"
                onClick={() => {
                  handleImportButtonClick();
                  track("workspace_admin_copy_csv_viewed", {
                    event_from: "workspace_admin",
                    tab_name: (activeTab || "").toString(),
                    domain: this.props.domain,
                    platform: "webapp",
                  });
                }}
              >
                Import into sheet...
              </Button>
              {openCSVModal && (
                <ConfirmBox
                  visible={openCSVModal}
                  title="Import into Sheet"
                  cancelText="Cancel"
                  okText="Copy CSV URL"
                  okButton={<Button type="primary">Copy CSV URL</Button>}
                  onCancel={() => this.setState({ openCSVModal: false })}
                  onOk={() => {
                    handleCopyButtonClick();
                    track("workspace_admin_copy_csv_copied", {
                      event_from: "workspace_admin",
                      tab_name: (activeTab || "").toString(),
                      domain: this.props.domain,
                      platform: "webapp",
                    });
                    toast({ content: "Copied CSV URL to clipboard", type: "success" });
                  }}
                  width={480}
                  closable={true}
                >
                  <div>
                    <p>Import your admin data into a sheet using CSV URL.</p>
                    <p>
                      <li>1. Copy the CSV URL below</li>
                      <li>2. From the Coefficient sidebar, click "Import From"</li>
                      <li>3. Click CSV</li>
                      <li>4. Click "From URL"</li>
                      <li>5. Paste the CSV URL link and click "Next" and then "Import"</li>
                    </p>
                  </div>
                </ConfirmBox>
              )}
            </Header>
            <Header>
              <StyledMenu
                onClick={({ key }) => {
                  this.setState({ activeTab: (key || "").toString() });
                  if (key) {
                    track(tabMap[key], {
                      event_from: "workspace_admin",
                      domain: this.props.domain,
                      platform: "webapp",
                    });
                  }
                }}
                selectedKeys={[activeTab]}
                mode="horizontal"
              >
                {this.props.enableAdminConnectedSources && (
                  <Menu.Item key="data_sources">
                    <Link
                      to={`${this.props.baseUrl}/data_sources?domain=${
                        this.state.domainOverride || this.props.domain
                      }`}
                    >
                      Connected Sources
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="imports">
                  <Link
                    to={`${this.props.baseUrl}/imports?domain=${
                      this.state.domainOverride || this.props.domain
                    }`}
                  >
                    Imports
                  </Link>
                </Menu.Item>

                <Menu.Item key="runs">
                  <Link
                    to={`${this.props.baseUrl}/runs?domain=${
                      this.state.domainOverride || this.props.domain
                    }`}
                  >
                    Run History
                  </Link>
                </Menu.Item>

                <Menu.Item key="alerts">
                  <Link
                    to={`${this.props.baseUrl}/alerts?domain=${
                      this.state.domainOverride || this.props.domain
                    }`}
                  >
                    Alerts
                  </Link>
                </Menu.Item>
                <Menu.Item key="users">
                  <Link
                    to={`${this.props.baseUrl}/users?domain=${
                      this.state.domainOverride || this.props.domain
                    }`}
                  >
                    Users
                  </Link>
                </Menu.Item>
              </StyledMenu>
            </Header>
            <Content>
              <Switch>
                <Route path={`${this.props.baseUrl}/imports`} component={DataImports} />
                <Route path={`${this.props.baseUrl}/runs`} component={DataImportRuns} />
                <Route
                  path={`${this.props.baseUrl}/alerts`}
                  render={() => (
                    <Alerts
                      users={this.props.alertUsers}
                      alerts={this.props.alerts}
                      totalRecords={this.props.totalAlertsCount}
                      loading={this.props.loading}
                      getDomainAlerts={this.props.getDomainAlertsExt}
                    />
                  )}
                />
                <Route path={`${this.props.baseUrl}/users`} component={DomainUsers} />

                <Route path={`${this.props.baseUrl}/data_sources`} component={DomainDataSources} />

                {this.props.enableAdminConnectedSources ? (
                  <Route path={this.props.baseUrl} component={DomainDataSources} />
                ) : (
                  <Route path={this.props.baseUrl} component={DataImports} />
                )}
              </Switch>
            </Content>
          </Wrapper>
        </ExtAdminContext.Provider>
      )
    );
  };
}

type WorkspaceAdminProps = {
  baseUrl?: string;
  hideHeader?: boolean;
  domain?: string;
  viewAsSuperAdmin?: boolean;
};
export const WorkspaceAdmin: React.FC<WorkspaceAdminProps> = ({
  baseUrl,
  hideHeader,
  domain,
  viewAsSuperAdmin,
}) => {
  const { isCoeffAdmin, isGsuiteDomainAdmin, domains_in_domain_family, openIdToken, oauthToken } =
    useSelector((state: AppState) => ({
      isCoeffAdmin: state.app.isCoeffAdmin,
      isGsuiteDomainAdmin: state.app.isGsuiteDomainAdmin,
      domains_in_domain_family: state.app.domains_in_domain_family,
      openIdToken: state.app.openIdToken,
      oauthToken: state.app.oauthToken,
    }));

  const {
    loading,
    dataImports,
    dataImportRuns,
    domainUsers,
    domainAlerts,
    domainDataSources,
    totalRecords,
  } = useSelector((state: AppState) => ({
    loading: state.extAdmin.loading,
    dataImports: state.extAdmin.dataImports,
    dataImportRuns: state.extAdmin.dataImportRuns,
    domainUsers: state.extAdmin.domainUsers,
    domainAlerts: state.extAdmin.domainAlerts,
    domainDataSources: state.extAdmin.domainDataSources,
    totalRecords: state.extAdmin.totalRecords,
  }));

  const { value: enableAdminConnectedSources } = useGate(FeatureFlag.EnableAdminConnectedSources);

  const { domain: domainFromState } = useSelector((state: AppState) => ({
    domain: state.app.domain,
  }));

  useEffect(() => {
    document.title = "Admin - Coefficient Workspace";
    track("workspace_admin_data_sources_list_viewed");
  }, []);

  const dispatch = useDispatch<AppThunkDispatch>();

  const location = useLocation();

  const history = useHistory();

  const setDomainCb = (domain: string) => {
    const params = new URLSearchParams(location.search);

    params.set("domain", domain);

    history.push({ pathname: location.pathname, search: params.toString() });
  };

  const track = useTrack();

  return (
    <BaseWorkspaceAdmin
      loading={loading}
      location={location}
      setDomainCb={setDomainCb}
      alerts={domainAlerts}
      domainDataSources={domainDataSources}
      dataImportRuns={dataImportRuns}
      dataImports={dataImports}
      domainUsers={domainUsers}
      totalRecords={totalRecords}
      isCoeffAdmin={isCoeffAdmin}
      isGsuiteDomainAdmin={isGsuiteDomainAdmin}
      viewAsSuperAdmin={viewAsSuperAdmin}
      openIdToken={openIdToken}
      oauthToken={oauthToken}
      domain={domain ?? domainFromState}
      alertLoading={loading}
      domains_in_domain_family={domains_in_domain_family}
      alertUsers={domainUsers}
      hideHeader={hideHeader}
      baseUrl={baseUrl ?? "/dashboard/admin"}
      getDataImports={(
        domain: string,
        pageSize: number,
        offset: number,
        sortColumn: SortColumn,
        filters?: string
      ) => {
        dispatch(getDataImports(domain, pageSize, offset, sortColumn, filters));
      }}
      enableAdminConnectedSources={enableAdminConnectedSources}
      getDomainDataSources={(
        domain: string,
        pageSize: number,
        offset: number,
        sortColumn: SortColumn,
        filters?: string
      ) => {
        dispatch(getDomainDataSources(domain, pageSize, offset, sortColumn, filters));
      }}
      getDomainAlertsExt={(
        domain: string,
        pageSize: number,
        offset: number,
        sortColumn: SortColumn,
        filters?: string
      ) => {
        dispatch(getDomainAlertsExt(domain, pageSize, offset, sortColumn, filters));
      }}
      totalAlertsCount={totalRecords}
      // @ts-ignore
      getDataImportRuns={(params?: object, domain?: string) =>
        dispatch(getDataImportRuns(params, domain))
      }
      getDomainUsers={(domain: string, pageSize: number, offset: number, sortColumn: SortColumn) =>
        dispatch(getDomainUsersExt(domain, pageSize, offset, sortColumn))
      }
      track={track}
    />
  );
};
