import styled, { keyframes } from "styled-components";

import { COLORS } from "../constants";

const ThreeDotsKeyframes = keyframes`
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
`;

const FourDotsKeyframes = keyframes`
  20% {
    background-position: 0% 0%, 33% 50%, 66% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 33% 0%, 66% 50%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 33% 100%, 66% 0%, 100% 50%;
  }
  80% {
    background-position: 0% 50%, 33% 50%, 66% 100%, 100% 0%;
  }
`;

export const DotsLoader = styled.div<{ color?: string; size?: "small" | "large" }>`
  width: ${({ size }) => (size === "large" ? 60 : 20)}px;

  aspect-ratio: ${({ size }) => (size === "large" ? 6 : 3)};

  --_gradient: no-repeat
    radial-gradient(circle closest-side, ${({ color }) => color ?? COLORS.black25} 100%, #0000);

  background: ${({ size }) =>
    size === "large"
      ? "var(--_gradient) 0% 50%, var(--_gradient) 33% 50%, var(--_gradient) 66% 50%, var(--_gradient) 100% 50%"
      : "var(--_gradient) 0%   50%, var(--_gradient) 50%  50%, var(--_gradient) 100% 50%"};

  background-size: calc(100% / 4) 50%;

  animation: ${({ size }) => (size === "large" ? FourDotsKeyframes : ThreeDotsKeyframes)} 1s
    infinite linear;
`;
