import { dev } from "./dev";
import { prod } from "./prod";
import { staging } from "./staging";

export type Config = {
  SENTRY_DSN: string;
  BASE_URL: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_DEVELOPER_API_KEY: string;
  SALESFORCE_CONNECTED_APP_CONSUMER_KEY: string;
  STRIPE_API_KEY: string;
  OAUTH_REDIRECT_URL_BASE: string;
  MS_IDP_CLIENT_ID: string;
  MS_UI_ENDPOINT: string;
  SNOWFLAKE_SECURITY_INTEGRATION_NAME: string;
  ZENDESK_CHAT_KEY: string;
  ZENDESK_MESSENGER_KEY: string;
};

export const config: Config =
  process.env.NODE_ENV === "production" ? prod : process.env.NODE_ENV === "staging" ? staging : dev;
