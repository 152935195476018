import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Tooltip } from "./Tooltip";

interface EllipsizedTextProps {
  className?: string;
  text?: string;
}

const Wrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EllipsizedText = ({ className, text }: EllipsizedTextProps) => {
  const [tooltip, setTooltip] = useState<string>("");

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((divRef.current?.scrollWidth ?? 0) > (divRef.current?.offsetWidth ?? 0)) {
      setTooltip(text ?? "");
    } else {
      setTooltip("");
    }
  }, [text]);

  if (!text) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <Wrap className={className} ref={divRef}>
        {text}
      </Wrap>
    </Tooltip>
  );
};
