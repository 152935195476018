import { InfoCircleFilled } from "@ant-design/icons";
import { CloudTable } from "@coeff/api";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Typography,
  Button,
  ChevronLeft,
  Refresh,
  Tooltip,
  CloudTableInfo,
  CloudTableIcon,
} from "../../components";
import { COLORS } from "../../constants";
import { getCloudTableSpreadsheetSheetUrl, getRefreshedDate, useTrack } from "../../utils";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 16px;
`;

const SubHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  max-width: 300px;
`;

const SecondaryLink = styled.a`
  font-weight: bold;
`;

type Props = {
  cloudTable: CloudTable;
  onRefresh: () => void;
  isDrawerVisible: boolean;
  setIsDrawerVisible: (arg: boolean) => void;
  isLoadingRunCloudTable: boolean;
  isOwner: boolean;
};

export const CloudTableDetailsHeader: React.FC<Props> = ({
  cloudTable,
  onRefresh,
  isDrawerVisible,
  setIsDrawerVisible,
  isLoadingRunCloudTable,
  isOwner,
}) => {
  const track = useTrack();

  const history = useHistory();

  const handleOpenSheet = (cloudTable: CloudTable) => {
    track("dataset_open_sheet_clicked", { dataset_id: cloudTable.cloud_table_id });

    window.open(
      getCloudTableSpreadsheetSheetUrl(
        cloudTable.source_info.google_drive_file_id,
        cloudTable.source_info.google_sheets_tab_id
      ),
      "_blank"
    );
  };

  return (
    <Header>
      <Title>
        <Button
          type="text"
          icon={<ChevronLeft />}
          onClick={() => history.push("/dashboard/datasets")}
        />

        <CloudTableIcon cloudTable={cloudTable} />

        <TitleContent>
          <Typography variant="body1" noWrap title={cloudTable.cloud_table_name} fontWeight={600}>
            {cloudTable.cloud_table_name}
          </Typography>

          <Typography
            lineClamp={2}
            title={cloudTable.description}
            variant="body3"
            color="textSecondary"
          >
            {cloudTable.description}
          </Typography>
        </TitleContent>

        <Tooltip
          getPopupContainer={target => target}
          variant="light"
          title={
            <CloudTableInfo exclude={["cloud_table_name", "description"]} cloudTable={cloudTable} />
          }
        >
          <InfoCircleFilled style={{ color: COLORS.black25, marginLeft: 4 }} />
        </Tooltip>
      </Title>

      <SubHeaderActions>
        {cloudTable.last_run_dt ? (
          <Typography
            fontWeight={600}
            style={{ marginRight: 16 }}
            variant="body3"
            color="textSecondary"
          >
            Refreshed from{" "}
            <SecondaryLink
              target="_blank"
              href={getCloudTableSpreadsheetSheetUrl(
                cloudTable.source_info.google_drive_file_id,
                cloudTable.source_info.google_sheets_tab_id
              )}
            >
              source sheet
            </SecondaryLink>{" "}
            {getRefreshedDate(moment.utc(cloudTable.last_run_dt).local())}
            <Tooltip
              variant="light"
              getPopupContainer={target => target}
              title={
                <Typography>
                  This dataset pulls from the {cloudTable.source_info.google_sheets_file_name}{" "}
                  source sheet.
                </Typography>
              }
            >
              <InfoCircleFilled style={{ color: COLORS.black25, marginLeft: 4 }} />
            </Tooltip>
          </Typography>
        ) : null}

        <Button
          disabled={isLoadingRunCloudTable}
          type="primary"
          icon={isLoadingRunCloudTable ? null : <Refresh />}
          onClick={() => onRefresh()}
        >
          {isLoadingRunCloudTable ? "Refreshing..." : "Refresh"}
        </Button>

        {isOwner ? (
          <Button onClick={() => setIsDrawerVisible(!isDrawerVisible)}>Edit</Button>
        ) : null}

        <Button onClick={() => handleOpenSheet(cloudTable)}>Open Sheet</Button>
      </SubHeaderActions>
    </Header>
  );
};
