import {
  DataImportRun,
  Alert,
  WritebackRun,
  DataImport,
  GetDashboardSpreadsheets200Response,
  GetWritebacks200Response,
  GetDataImports200Response,
} from "@coeff/api";

import { API_ROUTES } from "../constants";

import { AppThunkAction } from ".";

export const getSpreadsheets =
  (): AppThunkAction<Promise<GetDashboardSpreadsheets200Response>> =>
  async (_, __, { api }) => {
    const response = await api.request<GetDashboardSpreadsheets200Response>({
      method: "GET",
      url: API_ROUTES.dashboardSpreadsheets,
    });

    return response;
  };

export const getTeamActivity =
  (): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: API_ROUTES.dashboardTeamActivity,
    });

    return response;
  };

export const getAlertsByFileId =
  (google_sheets_file_id: string): AppThunkAction<Promise<{ alerts: Array<Alert> }>> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: `${API_ROUTES.alerts}?google_sheets_file_id=${google_sheets_file_id}`,
    });

    return response;
  };

export const getDataImport =
  (dataImportId: string): AppThunkAction<Promise<{ data_import: DataImport }>> =>
  (_, __, { api }) => {
    return api.request({
      method: "GET",
      url: `${API_ROUTES.dataImports}/${dataImportId}`,
    });
  };

export type GetDataImportCachedData200Response = {
  header_row: Array<boolean | number | string>;
  data_rows: Array<Array<boolean | number | string>>;
  total_row_count: number;
  offset?: number;
};

export type GetDataImportCachedDataPayload = {
  offset?: number;
  limit?: number;
};

export const getDataImportCachedData =
  (
    dataImportId: string,
    options?: GetDataImportCachedDataPayload
  ): AppThunkAction<Promise<GetDataImportCachedData200Response>> =>
  (_, __, { api }) => {
    return api.request({
      method: "POST",
      url: `${API_ROUTES.dataImports}/${dataImportId}/get_cached_data`,
      body: options,
    });
  };

export const getDataImportsByFileId =
  (target_google_sheets_file_id: string): AppThunkAction<Promise<GetDataImports200Response>> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: `${API_ROUTES.dataImports}?target_google_sheets_file_id=${target_google_sheets_file_id}`,
    });

    return response;
  };

export const getDataImportRunHistory =
  (
    data_import_id: string,
    offset: number
  ): AppThunkAction<Promise<{ data_import_runs: DataImportRun[] }>> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: `${API_ROUTES.dataImportRunHistory.replace(
        ":data_import_id",
        data_import_id
      )}?offset=${offset}`,
    });

    return response;
  };

export const getWritebacksByFileId =
  (target_google_sheets_file_id: string): AppThunkAction<Promise<GetWritebacks200Response>> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: `${API_ROUTES.writebacks}?target_google_sheets_file_id=${target_google_sheets_file_id}`,
    });

    return response;
  };

export const getWritebacksRunHistory =
  (
    writeback_id: string,
    offset: number
  ): AppThunkAction<
    Promise<{
      writeback_runs: WritebackRun[];
    }>
  > =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "GET",
      url: `${API_ROUTES.writebackRunHistory.replace(
        ":writeback_id",
        writeback_id
      )}?offset=${offset}`,
    });

    return response;
  };

export const downloadRunHistory =
  (writeback_id: string): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    try {
      const { data, headers } = await api.request({
        url: `${API_ROUTES.writebackRunLog.replace(":writeback_id", writeback_id)}`,
        method: "get",
        responseType: "blob",
      });
      const filename = headers["content-disposition"]
        .split(";")[1]
        .split("=")[1]
        .replace('"', "")
        .replace('"', "");
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

export const updateAlert =
  (alert: Alert): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    const response = await api.request({
      method: "PUT",
      url: `${API_ROUTES.alerts}/${alert.alert_id}`,
      body: { alert },
    });

    return response;
  };
