import { Config } from ".";

const BASE_URL = "https://app.coefficient.io";

export const prod: Config = {
  BASE_URL,
  SENTRY_DSN: "https://5e04babad7134cb2a22836a7dbb84f59@o404721.ingest.sentry.io/5283597",
  GOOGLE_CLIENT_ID: "182621001506-iaobiv6deaictvqtedlh9ik49c5hmoju.apps.googleusercontent.com",
  GOOGLE_DEVELOPER_API_KEY: "AIzaSyAVGpeCsCSTdiWt_g2xw5MNvpUBtacZs1w",
  SALESFORCE_CONNECTED_APP_CONSUMER_KEY:
    "3MVG9LBJLApeX_PCzOK2ZVrcYiu4V9IYVWKbE1uucDYLV5cnXgABVMA3kal4fPRWVWcVHbv1V4u2Is7KD2oQQ",
  STRIPE_API_KEY:
    "pk_live_51IK1n5GLbIh3iVB0LZ7zS4nUdQfoyeWcU0g5FHybss2prHrXrnPdqY3nUjqKNX2lmX2MQQespgClm7ONLXZ7rNWg0085vSAgD0",
  OAUTH_REDIRECT_URL_BASE: "https://app.coefficient.io/api/oauth2_proxy",
  MS_IDP_CLIENT_ID: "2fb352bf-875e-4d10-8aaa-c3de4f32c335",
  MS_UI_ENDPOINT: "https://d2caj5pystcwak.cloudfront.net/production",
  SNOWFLAKE_SECURITY_INTEGRATION_NAME: "COEFFICIENT",
  ZENDESK_CHAT_KEY: "ece9a193-78ba-4042-a625-9026480535d0",
  ZENDESK_MESSENGER_KEY: "a36735de-b6ec-4be7-a806-0fd3192c4534",
};
