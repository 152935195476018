import { AnalyticsEvent, AnalyticsEventNameType } from "@coeff/api";
import * as Sentry from "@sentry/react";
import { DEPRECATED_ApiManager } from "src/api";

import { addDummyUserClickEvent, getCurrentReplayId } from "./";

export const track = (
  api: DEPRECATED_ApiManager,
  eventName: AnalyticsEventNameType,
  body: AnalyticsEvent = {}
) => {
  const event = {
    event_name: eventName,
    platform: "webapp",
    replay_id: getCurrentReplayId(),
    ...body,
  };

  Sentry.addBreadcrumb({
    type: "default",
    category: "transaction",
    message: eventName,
    data: event,
  });

  addDummyUserClickEvent({ id: eventName });

  return api.request({
    url: "/api/a/t",
    method: "POST",
    body: { event },
  });
};
