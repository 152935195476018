import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

interface ErrorMessageProps {
  action?: () => void;
  actionText?: string;
  message?: string;
  messageHtml?: string;
  title?: string;
  style?: object;
  noMargin?: boolean;
}

const Section = styled.section<ErrorMessageProps>`
  margin: ${props => (props.noMargin === true ? "0px" : "15px")};
  background: ${COLORS.redBg};
  border-radius: 5px;
`;

const StyledPanel = styled.div`
  padding: 10px;
  color: ${COLORS.black85};

  .header {
    display: flex;
    line-height: 20px;

    .sectionIcon {
      color: ${COLORS.red};
      margin-right: 5px;
    }

    .title {
      flex: 1;
      font-weight: bold;
      padding: 0px 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  .body {
    margin: 8px 10px 8px 30px;
    line-height: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    ul,
    ol {
      padding-left: 20px;
    }
  }

  .action {
    margin: 8px 30px 0px;
    color: ${COLORS.coeblue4};
    font-weight: bold;
  }
`;

export const ErrorMessage = ({
  action,
  actionText,
  message,
  messageHtml,
  title,
  ...props
}: ErrorMessageProps) => {
  if (messageHtml) {
    // If messageHtml is specified, prefer it to the message string
    message = undefined;
  }

  return (
    <Section className="coeff__error-message" {...props}>
      <StyledPanel>
        <div className="header">
          <div className="sectionIcon">
            <InfoCircleFilled />
          </div>
          {title && <div className="title">{title}</div>}
        </div>
        {messageHtml && (
          <div className="body" dangerouslySetInnerHTML={{ __html: messageHtml }}></div>
        )}
        {message && <div className="body">{message}</div>}
        {action && actionText && (
          <a onClick={() => action && action()} className="action">
            {actionText}
          </a>
        )}
      </StyledPanel>
    </Section>
  );
};
