import { InfoCircleFilled } from "@ant-design/icons";
import { Alert } from "@coeff/api";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";
import { getRefreshedDate } from "../utils";

import { FlexRow } from "./FlexLayout";
import { Tag } from "./Tag";
import { Typography, TypographyProps } from "./Typography";

const Container = styled(FlexRow)`
  font-size: 12px;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  &&& {
    margin: 0;
    margin-top: 0;
    font-size: 12px;
    line-height: 1.5;
  }
`;

type Props = {
  alert: Alert;
  variant?: TypographyProps["variant"];
  showPaused?: boolean;
};

export const AlertDescription: React.FC<Props> = ({ alert, variant, showPaused }) => {
  return alert.options?.is_paused && showPaused ? (
    <StyledTag color="orange">Paused</StyledTag>
  ) : alert.last_run_status?.error_type ? (
    <Container gap={1}>
      <InfoCircleFilled style={{ height: 12, color: COLORS.red }} />

      <Typography
        variant={variant ?? "body3"}
        color="textSecondary"
        style={{ marginTop: 2.5 }}
        noWrap
      >
        Last alert failed
      </Typography>
    </Container>
  ) : alert.last_send_dt ? (
    <Typography variant={variant ?? "body3"} color="textSecondary" noWrap>
      Last sent {getRefreshedDate(moment.utc(alert.last_send_dt).local())}
    </Typography>
  ) : (
    <Typography variant={variant ?? "body3"} color="textSecondary" noWrap>
      Never sent
    </Typography>
  );
};
