import * as React from "react";
import styled, { keyframes } from "styled-components";

import { COLORS } from "../constants";

const loadingAnimation = keyframes`
  0% { left: 0%; }
  100% { left: 100%; }
`;

const Bar = styled.div`
  height: 5px;
  width: 100%;
  background: ${COLORS.coeblue1};
  overflow: hidden;
`;

const Thumb = styled.div`
  height: 5px;
  background: ${COLORS.coeblue4};
  width: 100px;
  position: relative;
  animation: ${loadingAnimation} 2s infinite linear;
`;

export const LoadingBar = () => (
  <Bar>
    <Thumb></Thumb>
  </Bar>
);
