import { Spin as SpinAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

interface SpinnerProps {
  size?: "default" | "large" | "small";
}

const StyledSpinner = styled(SpinAD)`
  &.ant-spin i {
    animation: none;
    transform: none;
    opacity: 1;
  }

  &.ant-spin i:first-child {
    background-color: ${COLORS.coeblue};
  }

  &.ant-spin i:nth-child(2) {
    background-color: ${COLORS.coeblue1};
  }

  &.ant-spin i:nth-child(3) {
    background-color: ${COLORS.coeblue3};
  }

  &.ant-spin i:last-child {
    background-color: ${COLORS.coeblue5};
  }
`;

export const Spinner = (props: SpinnerProps) => <StyledSpinner {...props} />;
