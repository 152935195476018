import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { CoeLogo } from "../../../components";
import { COLORS } from "../../../constants";

const Container = styled.aside`
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  min-width: 240px;
  max-width: 240px;
  padding: 25px 20px;
  border-right: 1px solid #f5f5f5;
  background-color: white;
`;

const CoeLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 50px;
  padding: 8px 15px;
`;

const SuperAdminText = styled.div`
  align-self: flex-end;
  font-size: 11px;
  color: #fff;
  background: #0054e6;
  border-radius: 4px;
  padding: 2px;
  margin-right: 48px;
  text-transform: uppercase;
  margin-top: -8px;
  letter-spacing: 0.5px;
  line-height: normal;
  font-style: normal;
`;

const SideBarMenu = styled(Menu)`
  && {
    flex: 1;
    border: none;

    a {
      color: ${COLORS.black};
    }

    li {
      display: flex;
      font-size: 1rem;
      margin: 0px;
      padding: 13px 15px;
      height: auto;
      position: relative;
      align-items: center;
      font-weight: 500;

      &.ant-menu-item-selected {
        background: none;
      }

      .ant-menu-title-content {
        color: ${COLORS.black};
      }

      svg,
      .coeff__image_icon {
        margin-right: 10px;
        width: 32px;
      }

      &:hover {
        color: inherit;
        background: ${COLORS.black4};
      }

      a:hover {
        color: inherit;
      }
    }
  }
`;

const MenuItem = styled(Menu.Item)`
  .ant-menu-title-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: 1.3;
  }
`;

export const Sidebar: React.FC = () => {
  return (
    <Container>
      <Link to={`/superadmin`}>
        <CoeLogoContainer>
          <CoeLogo />
          <SuperAdminText>SUPER ADMIN</SuperAdminText>
        </CoeLogoContainer>
      </Link>

      <SideBarMenu>
        <Link to={`/superadmin/domains`}>
          <MenuItem>Domains</MenuItem>
        </Link>

        <Link to={`/superadmin/quickstart`}>
          <MenuItem>Quickstart</MenuItem>
        </Link>
      </SideBarMenu>
    </Container>
  );
};
