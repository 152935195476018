import { ACTIONS } from "../constants";
import { CoeffAdminDataImport, Domain, User } from "../types";

interface ReducerProps {
  loading: boolean;
  domain: Domain | null;
  domains: Domain[];
  dataImports: CoeffAdminDataImport[];
  totalAlertsCount: number;
  alerts: [];
  users: User[];
}

const initialState = {
  alerts: [],
  domain: null,
  domains: [],
  dataImports: [],
  totalAlertsCount: 0,
  loading: true,
  users: [],
} as ReducerProps;

export const coeffAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DOMAIN_ALERTS: {
      const { alerts, totalAlertsCount } = action;
      return { ...state, alerts, totalAlertsCount, loading: false };
    }

    case ACTIONS.SET_DOMAIN: {
      const { domain } = action;
      return { ...state, domain, loading: false };
    }

    case ACTIONS.SET_DOMAINS: {
      const { domains } = action;
      return { ...state, domains, loading: false };
    }

    case ACTIONS.SET_DOMAIN_USERS: {
      const { users } = action;
      return { ...state, users, loading: false };
    }

    case ACTIONS.SET_DOMAIN_IMPORTS: {
      const { dataImports } = action;
      return { ...state, dataImports, loading: false };
    }

    case ACTIONS.SET_ADMIN_LOADING:
      return { ...state, loading: true };

    default:
      return state;
  }
};
