import { message } from "antd";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  opacity: 0.7;

  & .coeblue,
  & .bg-coeblue {
    background: ${({ theme }) => theme.colors.black4};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  cursor: not-allowed;
`;

type Props = {
  enabled?: boolean;
  onClickMessage?: string;
};

export const EditAccessDisabledOverlay: React.FC<Props> = props => {
  const { children, enabled, onClickMessage } = props;

  const [messageVisible, setMessageVisible] = React.useState(false);

  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Container>
      {children}
      <Overlay
        onClick={e => {
          if (!messageVisible) {
            const hide = message.info({
              content: onClickMessage,
              onClose: () => setMessageVisible(false),
            });

            // Force the message to close after 3s. Ensures the message
            // doesn't stay open even if the user is hovering it with
            // their mouse.
            setTimeout(() => {
              setMessageVisible(false);
              hide();
            }, 3000);

            setMessageVisible(true);
          }

          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </Container>
  );
};
