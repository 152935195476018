import { CloudTable } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import {
  Button,
  PaperContent,
  Paper,
  TextWithIcon,
  SourceFile,
  Typography,
} from "../../components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  disabled?: boolean;
  cloudTable: CloudTable;
  onEditClick: () => void;
};

export const Metadata: React.FC<Props> = props => {
  const { cloudTable, onEditClick, disabled } = props;

  return (
    <Paper>
      <StyledPaperContent size="small">
        <TextWithIcon
          icon={<SourceFile />}
          title={<Typography fontWeight="bold">Name & Description</Typography>}
          action={
            <Button disabled={disabled} type="link" size="small" onClick={() => onEditClick()}>
              edit
            </Button>
          }
        />

        <Content>
          <ContentGroup>
            <Typography color="textSecondary">Name</Typography>
            <Typography noWrap>{cloudTable.cloud_table_name}</Typography>
          </ContentGroup>

          {cloudTable.description ? (
            <ContentGroup>
              <Typography color="textSecondary">Description (optional)</Typography>
              <Typography title={cloudTable.description} lineClamp={2}>
                {cloudTable.description}
              </Typography>
            </ContentGroup>
          ) : null}
        </Content>
      </StyledPaperContent>
    </Paper>
  );
};
