import { Checkbox as CheckboxAD, CheckboxProps } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledCheckbox = styled(CheckboxAD)`
  .ant-checkbox {
    .ant-checkbox-inner {
      border-width: 2px;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${COLORS.coeblue4};
        border-color: ${COLORS.coeblue4};
        border-width: 0px;
      }

      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          border-color: ${COLORS.coeblue4}!important;

          &:after {
            border-color: ${COLORS.black4}!important;
          }
        }
      }
    }
  }

  &.error {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: ${COLORS.red};
      }
    }
  }
`;

export const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  children,
  ...props
}) => <StyledCheckbox {...props}>{children}</StyledCheckbox>;
