import { Menu } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import { SortColumn, SortDirection, getDataImportRuns, getDomainUsersExt } from "../../actions";
import { DEPRECATED_Button, Date, Imports, Users as UserIcon, Option } from "../../components";
import { Select } from "../../components/Select";
import {
  COLORS,
  DOMAIN_USERS_CSV_KEYS,
  IMPORT_RUNS_CSV_KEYS,
  IMPORTS_CSV_KEYS,
} from "../../constants";
import {
  EAContext,
  ExtAdminDataImport as DataImport,
  ExtAdminDataImportRun as DataImportRun,
  ExtAdminDomainUser as DomainUser,
} from "../../types";

import { DataImportRuns, DataImports, DomainUsers } from "./components";

interface ExtAdminProps {
  domain: string;
  domains_in_domain_family: string[];
  getDataImportRuns: (params?: object, domain?: string) => Promise<DataImportRun[]>;
  getDomainUsers: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn
  ) => void;
  location: any;
  loading: boolean;
  oauthToken: string;
  openIdToken: string;
  dataImportRuns: DataImportRun[];
  domainUsers: DomainUser[];
  isCoeffAdmin: boolean;
  totalRecords: number;
  isGsuiteDomainAdmin: boolean;
}

interface ExtAdminState {
  activeTab: "imports" | "runs" | "users" | string;
  domainOverride: string;
}

const Wrapper = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;

  button.importBtn {
    margin: auto;
  }
`;

const StyledMenu = styled(Menu)`
  flex: 1;
  border-bottom: none;

  .ant-menu-item {
    margin: 0px !important;
    padding: 0px 20px !important;
    font-weight: bold;

    &:hover {
      a {
        opacity: 0.8;
      }
    }

    svg {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
`;

const ViewAsInput = styled.input`
  display: inline-block;
  float: right;
  height: 25px;
  margin-top: 15px;
  margin-right: 15px;
`;

const Content = styled.div``;

const ViewAsBackup = ({ onKeyPress }: { onKeyPress: (e, v) => void }) => {
  const [value, setValue] = useState<string>("coefficient.io");
  return (
    <ViewAsInput
      onChange={e => setValue(e.target.value)}
      onKeyPress={e => onKeyPress(e, value)}
      value={value}
    />
  );
};

const ViewAs = (props: {
  isCoeffAdmin: boolean;
  isGsuiteAdmin: boolean;
  domainOverride: string;
  setDomainOverride: (domainOverride: string) => void;
  domains_in_domain_family: Array<string>;
  onKeyPress: (e, domainOverride: string) => void;
  onBlur: (v) => void;
  domain: string;
}) => {
  if (!props.isGsuiteAdmin) {
    return <></>;
  }

  if (props.isCoeffAdmin) {
    return (
      <>
        <ViewAsInput
          onChange={e => props.setDomainOverride(e.target.value)}
          onKeyPress={e => props.onKeyPress(e, props.domainOverride)}
          value={props.domainOverride}
          onBlur={e => {
            props.onBlur(e.target.value);
          }}
        />
      </>
    );
  }

  if (props.domains_in_domain_family.length > 1) {
    return (
      <Select
        onChange={domain_name => {
          props.setDomainOverride(domain_name);
          props.onBlur(domain_name);
        }}
        size="middle"
        value={props.domainOverride}
        placeholder="Choose domain..."
        onBlur={value => {
          props.onBlur(value);
        }}
      >
        {props.domains_in_domain_family.map((column, i) => (
          <Option key={column} value={column}>
            {column}
          </Option>
        ))}
      </Select>
    );
  }
  return <></>;
};

export const ExtAdminContext = React.createContext<EAContext | null>(null);

class ExtAdmin extends React.Component<ExtAdminProps, ExtAdminState> {
  constructor(props: ExtAdminProps) {
    super(props);

    this.state = {
      activeTab: props.location.pathname.split("/")[2] || "imports",
      domainOverride: props.domain,
    };
  }
  DEFAULT_PAGE_SIZE = 100;

  getCSVURL = type => {
    const domain = this.state.domainOverride || this.props.domain;
    const host = `${window.location.protocol}//${window.location.hostname}/`;
    const urlParams = `?domain=${domain}&tz_name=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&format=csv`;
    switch (type) {
      case "runs":
        return host + "api_admin_ext/data_import_runs" + urlParams;
      case "users":
        return host + "api_admin_ext/users" + urlParams;
      case "imports":
      default:
        return host + "api_admin_ext/data_imports" + urlParams;
    }
  };

  getImportPayload = type => {
    const sourceInfo = {
      csv_file_url: this.getCSVURL(type),
      csv_file_url_hidden: true,
      csv_source_type: "url",
    };

    switch (type) {
      case "runs":
        return { ...IMPORT_RUNS_CSV_KEYS, source_info: sourceInfo };
      case "users":
        return { ...DOMAIN_USERS_CSV_KEYS, source_info: sourceInfo };
      case "imports":
      default:
        return { ...IMPORTS_CSV_KEYS, source_info: sourceInfo };
    }
  };

  componentDidUpdate = () => {
    const activeTab = this.props.location.pathname.split("/")[2] || "imports";
    if (activeTab !== this.state.activeTab) {
      this.setState({ activeTab });
    }
  };

  setDomainOverride = domainOverride => {
    this.setState({ domainOverride });
  };

  refreshData = () => {
    const { activeTab } = this.state;
    const { domain, getDataImportRuns, getDomainUsers } = this.props;

    if (activeTab === "runs") {
      return getDataImportRuns(
        {
          page_size: this.DEFAULT_PAGE_SIZE,
          offset: 0,
          sort_by: "created_dt",
          sort_order: SortDirection.descend,
        },
        this.state.domainOverride || domain
      );
    } else {
      return getDomainUsers(this.state.domainOverride || domain, this.DEFAULT_PAGE_SIZE, 0, {
        columnKey: "created_dt",
        value: SortDirection.descend,
      });
    }
  };

  onKeyPress = ({ key }, domainOverride) => {
    if (key === "Enter") {
      this.setState({ domainOverride }, this.refreshData);
    }
  };

  render = () => {
    const { activeTab } = this.state;

    const { isCoeffAdmin, isGsuiteDomainAdmin } = this.props;

    return (
      <ExtAdminContext.Provider
        value={{
          ...this.props,
          domain: this.state.domainOverride || this.props.domain,
        }}
      >
        <Wrapper>
          <Header>
            <StyledMenu
              onClick={({ key }) => this.setState({ activeTab: (key || "").toString() })}
              selectedKeys={[activeTab]}
              mode="horizontal"
            >
              <Menu.Item
                key="imports"
                icon={<Imports color={activeTab === "imports" ? "" : COLORS.black45} />}
              >
                <Link to="/admin/imports">Imports</Link>
              </Menu.Item>
              <Menu.Item
                key="runs"
                icon={
                  <Date color={activeTab === "runs" ? COLORS.coeblueOfficial : COLORS.black45} />
                }
              >
                <Link to="/admin/runs">Runs</Link>
              </Menu.Item>
              <Menu.Item
                key="users"
                icon={
                  <UserIcon
                    color={activeTab === "users" ? COLORS.coeblueOfficial : COLORS.black45}
                  />
                }
              >
                <Link to="/admin/users">Users</Link>
              </Menu.Item>
            </StyledMenu>

            <ViewAs
              onKeyPress={this.onKeyPress}
              domainOverride={this.state.domainOverride}
              setDomainOverride={this.setDomainOverride}
              isCoeffAdmin={isCoeffAdmin}
              isGsuiteAdmin={isGsuiteDomainAdmin}
              domains_in_domain_family={this.props.domains_in_domain_family}
              domain={this.props.domain}
              onBlur={this.refreshData}
            />

            <DEPRECATED_Button
              className="importBtn"
              type="primary"
              onClick={() => {
                // Note: currently this button works only when loaded as modal popup.
                // To make it work when loaded as URL, we might need to call the API directly from here with loading animation
                const payload = this.getImportPayload(activeTab);
                // @ts-ignore
                window.parent.postMessage({ type: "admin-csv-import", payload }, "*");
              }}
            >
              Import into Current Spreadsheet
            </DEPRECATED_Button>
          </Header>
          <Content>
            <Switch>
              <Route path="/admin/imports" component={DataImports} />
              <Route path="/admin/data_sources" component={DomainUsers} />
              <Route path="/admin/runs" component={DataImportRuns} />
              <Route path="/admin/users" component={DomainUsers} />
              <Route path="/admin" component={DomainUsers} />
            </Switch>
          </Content>
        </Wrapper>
      </ExtAdminContext.Provider>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  getDataImportRuns: (params?: object, domain?: string) =>
    dispatch(getDataImportRuns(params, domain)),
  getDomainUsers: (domain: string, pageSize: number, offset: number, sortColumn: SortColumn) =>
    dispatch(getDomainUsersExt(domain, pageSize, offset, sortColumn)),
});

const mapStateToProps = ({
  extAdmin: { loading, dataImportRuns, domainUsers, totalRecords },
  app: {
    isCoeffAdmin,
    isGsuiteDomainAdmin,
    openIdToken,
    oauthToken,
    domain,
    domains_in_domain_family,
  },
}) => ({
  dataImportRuns,
  domainUsers,
  domain,
  domains_in_domain_family,
  loading,
  totalRecords,
  isCoeffAdmin,
  isGsuiteDomainAdmin,
  oauthToken,
  openIdToken,
});

const route = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtAdmin));
export { route as ExtAdmin };
