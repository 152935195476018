import { Alert, AlertType } from "@coeff/api";
import { columnIndexToChangedDataVariable } from "@coeff/utils";
import React from "react";
import styled from "styled-components";

import {
  Card,
  CardHeader,
  CursorSparkle,
  DateIcon,
  EmailIcon,
  FlexColumn,
  Paper,
  PaperContent,
  SettingsBars,
  SourceFile,
  Tags,
  TextWithIcon,
  Typography,
} from "../../../../components";
import { COLORS } from "../../../../constants";
import { getScheduleSummaryText } from "../../../../utils";
import { NEW_ROW_ALERT_TYPES } from "../../../../utils/alerts";

const Container = styled.div`
  width: 350px;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-left: 1px solid ${COLORS.black15};
  background: white;
  font-size: 13px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  overflow: scroll;
  height: 100%;
`;

const StyledCard = styled(Card)`
  padding: 12px;
`;

const CardIndent = styled.div`
  margin-left: 24px;
`;

const StyledCardHeader = styled(CardHeader)`
  && {
    margin-bottom: 8px;

    .title {
      margin-top: 2px;
    }
  }
`;

type Props = {
  alert: Alert;
};

export const AlertDetailsSidebar: React.FC<Props> = ({ alert }) => {
  const slackRecipientTags: Array<string> = [
    ...alert.send_info.slack_channels.map(channel => `#${channel.channel_name}`),
    ...alert.send_info.slack_users.map(user => `@${user.user_name}`),
    ...(alert.send_info.slack_recipients_column_indices ?? []).map(columnIndex =>
      columnIndexToChangedDataVariable(alert, columnIndex)
    ),
    ...(alert.send_info.slack_recipients_column
      ? [alert.send_info.slack_recipients_column.column_name]
      : []),
  ];

  const emailRecipientTags: Array<string> = [
    ...alert.send_info.email_addresses,
    ...(alert.send_info.email_recipients_column_indices ?? []).map(columnIndex =>
      columnIndexToChangedDataVariable(alert, columnIndex)
    ),
    ...(alert.send_info.email_recipients_column
      ? [alert.send_info.email_recipients_column.column_name]
      : []),
  ];

  return (
    <Container>
      <div style={{ margin: 15, marginBottom: 20 }}>
        <FlexColumn>
          <TextWithIcon
            title={
              <Typography variant="body1" fontWeight={700}>
                Settings
              </Typography>
            }
            icon={<SettingsBars />}
          />

          <Paper variant="info">
            <PaperContent size="xsmall">
              <Typography color={COLORS.black65}>
                <Typography fontWeight={600}>READ-ONLY</Typography>
                Open in spreadsheet to make changes.
              </Typography>
            </PaperContent>
          </Paper>
        </FlexColumn>
      </div>

      <div style={{ position: "relative", opacity: "0.5" }}>
        <StyledCard>
          <StyledCardHeader icon={<CursorSparkle />} title="Trigger" />

          <CardIndent>
            <Typography>
              {alert.alert_type === AlertType.Snapshot
                ? "At a scheduled time"
                : NEW_ROW_ALERT_TYPES.includes(alert.alert_type)
                ? `When new rows are added ${
                    alert.alert_type === AlertType.NewRowsAppended
                      ? "to the bottom of the table"
                      : "with a new ID"
                  }`
                : "When cell values change"}
            </Typography>
          </CardIndent>
        </StyledCard>

        {alert.alert_type !== AlertType.Snapshot ? (
          <StyledCard>
            <StyledCardHeader icon={<SourceFile />} title="Data range" />

            <CardIndent>{alert.trigger_condition?.initial_range_ref_a1}</CardIndent>
          </StyledCard>
        ) : null}

        <StyledCard>
          <StyledCardHeader icon={<EmailIcon fill="currentColor" />} title="Message & recipients" />

          <CardIndent style={{ marginTop: 8 }}>
            <FlexColumn gap={1.5}>
              {slackRecipientTags.length ? (
                <div>
                  <Typography fontWeight="bold">Slack Recipients</Typography>

                  <Tags tags={slackRecipientTags} />
                </div>
              ) : null}

              {emailRecipientTags.length ? (
                <div>
                  <Typography fontWeight="bold">Email Recipients</Typography>

                  <Tags tags={emailRecipientTags} />
                </div>
              ) : null}
            </FlexColumn>
          </CardIndent>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader icon={<DateIcon fill="currentColor" />} title="When to send" />

          <CardIndent>
            <Typography>
              {alert.options?.digest_enabled || alert.alert_type === "snapshot"
                ? getScheduleSummaryText(alert.options?.schedule, undefined, {
                    skipPrefix: true,
                    capitalize: true,
                  })
                : "As soon as it happens"}
            </Typography>
          </CardIndent>
        </StyledCard>
      </div>
    </Container>
  );
};
