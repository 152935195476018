import { Tag as TagAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS, KEY_LABEL } from "../constants";

interface TableFilterTagsProps {
  filters: Array<{
    columnKey: string;
    values: string[];
  }>;
  deleteFilter: (arg) => void;
  valuesToLabels?: (columnKey: string, values: Array<string>) => Array<string>;
}

const Wrapper = styled.div`
  margin-bottom: 10px;

  .sectionTitle {
    color: ${COLORS.black45};
    font-weight: 300;
  }
`;

const Tag = styled(TagAD)`
  background: ${COLORS.coeblue};
  color: ${COLORS.coeblue4};
  border-color: ${COLORS.coeblue};
  padding: 5px 10px;
  font-size: 13px;
  border:-radius:4px;
  font-weight:500;
  margin-top: 3px;

  .anticon-close {
    margin-left: 1rem;

    svg {
      path {
        fill: ${COLORS.coeblue4};
      }
    }
  }
`;

export const TableFilterTags = ({
  filters,
  deleteFilter,
  valuesToLabels,
}: TableFilterTagsProps) => {
  const renderTags = () => {
    return filters.map(filter => {
      let values = filter.values.filter(v => v !== "~empty~");

      if (valuesToLabels !== undefined) {
        values = valuesToLabels(filter.columnKey, values);
      }

      const label = KEY_LABEL[filter.columnKey];

      return (
        <Tag
          key={filter.columnKey}
          closable
          onClose={e => {
            e.preventDefault();
            deleteFilter(filter.columnKey);
          }}
        >
          {`${label}: ${values.slice(0, 2).join(", ")} ${
            values.length > 2 ? "+ " + (values.length - 2) + " more" : ""
          }`}
        </Tag>
      );
    });
  };
  return (
    <Wrapper>
      <span className="sectionTitle">Filters:</span> {renderTags()}
    </Wrapper>
  );
};
