import { CloudTable } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import { CenterAlign, LoadingBar } from "..";
import { COLORS } from "../../constants";
import { getDuplicateValues } from "../../utils";

import { DataImportTableHeader } from "./DataImportTableHeader";
import { TableCell, TableHeaderCell } from "./shared";

const Card = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
`;

const CardError = styled(Card)`
  background-color: #f0f0f0;
  min-width: 400px;
`;

const Table = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  position: relative;

  border: 1px solid ${COLORS.black15};
  background: ${COLORS.black2};
`;

const TableColumn = styled.div`
  flex: 1;
  height: 100%;
  max-width: 200px;

  &.first-column {
    position: sticky;
    z-index: 1;
    left: 0px;
    flex: 0 1 0;
    border-left: none;
  }
`;

// Required div in order to have the sticky headers
// scroll the whole length of the table
const TableColumnContent = styled.div`
  border-right: 1px solid ${COLORS.black15};
  border-bottom: 1px solid ${COLORS.black15};
  background: ${COLORS.white};
`;

const Strong = styled.div`
  font-weight: 600;
`;

const LoadingBarWrap = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1;
`;

type Props = {
  cloudTable?: CloudTable;
  headers: Array<string | number | boolean>;
  data: Array<Array<string | number | boolean>>;
  offset: number;
  loading?: boolean;
};

export const DataImportTable: React.FC<Props> = ({
  cloudTable,
  headers,
  data,
  offset,
  loading,
}) => {
  if (!data.length && !headers.length) {
    return null;
  }

  return (
    <Table data-sentry-mask>
      {loading ? (
        <LoadingBarWrap>
          <LoadingBar />
        </LoadingBarWrap>
      ) : null}

      <TableColumn className="first-column">
        <TableColumnContent className="first-column-content">
          <TableHeaderCell className="first-column-cell">&nbsp;</TableHeaderCell>

          {data.map((_, rowIndex) => (
            <TableCell key={rowIndex} className="first-column-cell">
              {(offset + rowIndex + 1).toLocaleString("en")}
            </TableCell>
          ))}
        </TableColumnContent>
      </TableColumn>

      {headers.map((_, columnIndex) => (
        <TableColumn key={columnIndex}>
          <TableColumnContent>
            <DataImportTableHeader
              headers={headers}
              duplicateHeaders={getDuplicateValues(headers)}
              columnIndex={columnIndex}
              cloudTable={cloudTable}
            />

            {data.map((_, rowIndex) => (
              <TableCell key={rowIndex} title={String(data[rowIndex][columnIndex])}>
                {data[rowIndex][columnIndex]}
              </TableCell>
            ))}
          </TableColumnContent>
        </TableColumn>
      ))}
    </Table>
  );
};
