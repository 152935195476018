import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Writeback,
  WritebackRunStatus,
  WritebackScheduleExportType,
  DataSourceType,
  WritebackRun,
  WritebackMode,
  WritebackRunOverallStatusType,
} from "@coeff/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { useWritebacksSchema } from "../../../../api";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card as _Card,
  CardHeader as _CardHeader,
  CurvedArrow,
  ImportTypeIcon,
  PushIcon,
  Settings,
  Table,
  Tag,
  Tooltip,
  WBMappings,
  Date,
  WarningMessage,
  ErrorMessage,
  ConfirmBox,
  LoaderWithPerfTimings,
  GoogleSheet,
  TargetIcon,
  FlexRow,
} from "../../../../components";
import { BuildFlags, COLORS, SQL_DATASOURCES } from "../../../../constants";
import {
  columnToLetter,
  computeSelectedRowsText,
  getRefreshedDate,
  sentryCapture,
  useTrack,
} from "../../../../utils";
import { ScheduleText } from "../../../CreateCloudTable/ScheduleText";
import { Header } from "../../components";
import { useDashboardContext } from "../../Dashboard";

const Sidebar = styled.div`
  width: 300px;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-left: 2px solid ${COLORS.black15};
  background: white;
  font-size: 13px;

  .subText {
    font-size: 12px;
    color: ${COLORS.black45};
    margin: 4px 0px;
  }
`;

const Wrap = styled.div`
  .title {
    font-weight: bold;
    font-size: 16px;
  }
`;

const TopMessage = styled.div`
  margin: 22px auto;
  text-align: center;

  & p:last-child {
    margin-bottom: 0 !important;
  }
`;

const FancyImage = styled.div`
  width: 160px;
  height: 40px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    span,
    svg {
      height: 40px;
      width: 40px;
    }
  }

  .green {
    background: ${COLORS.lightGreenBg};

    svg {
      margin: auto;
      height: 25px;
    }
  }

  .myarrow {
    transform: rotate(90deg);
  }

  .line {
    border-right: 0.2rem dashed ${COLORS.black15};
    display: inline-block;
    height: 4rem;
  }

  .arrow {
    position: absolute;
    top: -0.3rem;
    bottom: 0;
    height: 0.65rem;
    border-right: 0.2rem solid ${COLORS.black15};
    display: inline-block;
  }

  .right {
    left: 0.2rem;
    transform: rotate(-45deg);
  }

  .left {
    right: 0.2rem;
    transform: rotate(45deg);
  }
`;

const Card = styled(_Card)``;

const CardHeader = styled(_CardHeader)``;

const StyledPanel = styled.div`
  padding: 10px 10px 20px 10px;

  &:focus,
  &:active {
    outline: none;
  }
`;

const LogoWrap = styled.div`
  position: relative;

  .coeff__push_icon {
    width: 15px;
    height: 12px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const StyledCardHeader = styled(_CardHeader)`
  min-height: 18px;
  height: auto;
`;

const SummaryPanel = styled.div`
  margin: 10px 0px 0px 25px;

  & p:last-child {
    margin-bottom: 0 !important;
  }
`;

const SummaryText = styled.p`
  color: ${COLORS.black45};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 5px;
`;

const TextBold = styled.span`
  color: ${COLORS.black85} !important;
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 20px 0 -10px;
  padding: 0px;

  li {
    background-color: white;
    border-radius: 2px;
    padding: 5px;
    margin-bottom: 8px;
    line-height: 1;

    &.hidden {
      label {
        color: ${COLORS.black45};
      }
    }

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const ColumnSelectField = styled.li`
  display: flex;
  flex-direction: column;

  &:hover {
    & .field-actions {
      display: block;
    }
  }
`;

const FieldLabel = styled.label`
  display: block;
  color: ${COLORS.black45};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 7px;
`;

const FieldSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  margin-left: 5px;
`;

const FieldInfo = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & div {
    display: inline;
    color: ${COLORS.black85};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    margin-left: 2px;
    cursor: pointer;
  }

  & svg {
    color: ${COLORS.black45};
  }

  .dataSourceTypeIcon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 4px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const FieldLabelTooltip = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 10;
`;

const StyledTag = styled(Tag)`
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  border: none;
  font-weight: 500;
  margin: 6px;

  .text {
    color: ${COLORS.black85};
    font-size: 14px;
  }
`;

const StyledLink = styled.div`
  font-size: 14px;
  margin: 6px;
`;

const ACTIONS: Array<{ label: string; value: WritebackMode; description: string }> = [
  { label: "Update", value: "update", description: "Updates data for existing records" },
  { label: "Insert", value: "insert", description: "Creates a NEW ID and inserts data" },
  {
    label: "Upsert",
    value: "upsert",
    description:
      "Updates data for existing records or if the record does not already exist it creates a NEW ID and inserts data",
  },
  { label: "Delete", value: "delete", description: "Deletes data for existing records" },
  { label: "Add to list", value: "add_to_list", description: "Adds contacts to a list" },
  {
    label: "Remove from list",
    value: "remove_from_list",
    description: "Removes contacts from a list",
  },
  {
    label: "Sync list",
    value: "sync_list",
    description: "Updates HubSpot to match the sheet",
  },
  { label: "Void", value: "void", description: "Voids the selected object" },
];

const getObjectLanguageText = (
  dataSourceType?: DataSourceType,
  capitalize?: boolean,
  pluralize?: boolean
): string => {
  if (dataSourceType && SQL_DATASOURCES.includes(dataSourceType)) {
    return `${capitalize ? "T" : "t"}able${pluralize ? "s" : ""}`;
  }
  return `${capitalize ? "O" : "o"}bject${pluralize ? "s" : ""}`;
};

export const WritebackDetailsPage = ({ writebacks }: { writebacks: Writeback[] }) => {
  const track = useTrack();

  const { getWritebacksRunHistory, downloadRunHistory, spreadsheets } = useDashboardContext();

  const [writebackRuns, setWritebackRuns] = useState<WritebackRun[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [showFailedRunError, setShowFailedRunError] = useState<WritebackRunStatus | null>(null);

  const history = useHistory();

  const params = useParams<{ writebackId: string; ssId: string }>();

  const writeback = writebacks.find(di => di.writeback_id === params.writebackId);

  const spreadsheet = spreadsheets?.find(ss => ss.google_sheets_file_id === params.ssId);

  const { data: { object_types } = {} } = useWritebacksSchema(
    { dataSourceId: writeback?.data_source_id! },
    { enabled: Boolean(writeback?.data_source_id) }
  );

  const { data: { object_types: objectTypesWithFields } = {} } = useWritebacksSchema(
    { dataSourceId: writeback?.data_source_id!, objectName: writeback?.object_type_name },
    { enabled: Boolean(writeback?.data_source_id && writeback?.object_type_name) }
  );

  const fields = objectTypesWithFields?.[0]?.fields;

  const selectedObjectType = object_types?.find(
    objectType => objectType.object_name === writeback?.object_type_name
  );

  useEffect(() => {
    if (writeback && spreadsheet) {
      track("workspace_wb_viewed", {
        writeback_id: writeback?.writeback_id,
        ss_id: spreadsheet.google_sheets_file_id,
        ss_name: spreadsheet.google_sheets_file_name,
      });
    }
  }, [writeback, spreadsheet]);

  useEffect(() => {
    const getRuns = async () => {
      if (writeback) {
        setLoading(true);

        const { writeback_runs } = await getWritebacksRunHistory(writeback?.writeback_id ?? "", 1);

        setWritebackRuns(writeback_runs);
        setLoading(false);
      }

      if (!writeback && writebacks.length > 0) {
        history.push(`/dashboard/spreadsheets/${spreadsheet?.google_sheets_file_id}`);
      }
    };
    getRuns();
  }, [writeback, writebacks]);

  const headerRow: undefined | number = (() => {
    if (!writeback?.header_range_a1) {
      return;
    }

    const rows = writeback?.header_range_a1.match(/\d+/g);

    return rows ? Number(rows[0]) : undefined;
  })();

  const onOpenSheetClick = () => {
    track("workspace_open_sheet_clicked", {
      event_from: "writeback_details",
      writeback_id: writeback?.writeback_id,
      ss_id: spreadsheet?.google_sheets_file_id,
      ss_name: spreadsheet?.google_sheets_file_name,
    });

    window.open(writeback?.writeback_tab_url ?? "", "_blank");
  };

  const onWritebackRunPageNumberChange = async (page: number, pageSize: number) => {
    const isCurrentlyOnLastPage = page === Math.ceil(writebackRuns.length / pageSize);

    if (isCurrentlyOnLastPage) {
      const { writeback_runs } = await getWritebacksRunHistory(
        writeback?.writeback_id ?? "",
        writebackRuns.length + 1
      );

      setWritebackRuns([...writebackRuns, ...writeback_runs]);
    }
  };

  // This logic is same as what we have in RunStatusDetails in addon.
  // Once this is a mono repo we can move the function to one place.
  const getRunStatus = (run: WritebackRun): WritebackRunOverallStatusType => {
    const runStatus = run.run_status;
    const totalRows = runStatus?.num_total_rows ?? 0;
    const successfulRows = runStatus?.num_successful_rows ?? 0;
    const skippedRows = runStatus?.num_skipped_rows ?? 0;

    if (totalRows === 0) {
      return WritebackRunOverallStatusType.AllRecordsFailed;
    }

    if (runStatus?.overall_status === WritebackRunOverallStatusType.Ok) {
      return WritebackRunOverallStatusType.Ok;
    }

    const failedRows = totalRows - successfulRows - skippedRows;

    if (failedRows === 0) {
      return WritebackRunOverallStatusType.Ok;
    }

    if (successfulRows === 0) {
      return WritebackRunOverallStatusType.AllRecordsFailed;
    }

    return WritebackRunOverallStatusType.SomeRecordsFailed;
  };

  const getRunStatusColor = (run: WritebackRun): string => {
    const runStatus = getRunStatus(run);
    switch (runStatus) {
      case WritebackRunOverallStatusType.Ok:
        return "green";
      case WritebackRunOverallStatusType.AllRecordsFailed:
        return "red";
      case WritebackRunOverallStatusType.SomeRecordsFailed:
        return "orange";
      default:
        return "red";
    }
  };

  const getRunStatusIcon = (run: WritebackRun): React.ReactNode => {
    const runStatus = getRunStatus(run);
    if (runStatus === WritebackRunOverallStatusType.Ok) {
      return <CheckCircleFilled />;
    }

    return <ExclamationCircleFilled />;
  };

  const getRunStatusText = (run: WritebackRun): string => {
    const runStatus = getRunStatus(run);
    switch (runStatus) {
      case WritebackRunOverallStatusType.Ok:
        return "Ok";
      case WritebackRunOverallStatusType.AllRecordsFailed:
        return "Failed";
      case WritebackRunOverallStatusType.SomeRecordsFailed:
        return "Warning";
      default:
        return "Failed";
    }
  };

  return (
    <div style={{ maxWidth: "calc(100% - 300px)", padding: "20px 30px" }}>
      {loading ? (
        <div style={{ margin: "5rem auto 2rem", textAlign: "center" }}>
          <LoaderWithPerfTimings name="WritebackDetails" size="large" />
        </div>
      ) : (
        <div>
          <Wrap>
            <Header>
              <LogoWrap>
                <ImportTypeIcon type={writeback?.data_source_type} />
                <PushIcon className="coeff__push_icon" />
              </LogoWrap>

              <div className="details">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={{ pathname: `/dashboard/spreadsheets` }}>Spreadsheets</Link>
                  </BreadcrumbItem>

                  <BreadcrumbItem>
                    <Link
                      to={{
                        pathname: `/dashboard/spreadsheets/${spreadsheet?.google_sheets_file_id}`,
                        search: window.location.search,
                      }}
                    >
                      {spreadsheet?.google_sheets_file_name}
                    </Link>
                  </BreadcrumbItem>
                  <div style={{ visibility: "hidden" }}></div>
                </Breadcrumb>

                <h1>{writeback?.writeback_name}</h1>

                <span className="refreshDate">
                  {writeback?.last_run_dt &&
                    `Last exported ${getRefreshedDate(moment.utc(writeback?.last_run_dt).local())}`}
                  {!writeback?.last_run_dt &&
                    !writeback?.last_writeback_run_status?.status_dt &&
                    `Never exported`}
                </span>
              </div>

              <div>
                <Button type="primary" target="_blank" onClick={onOpenSheetClick}>
                  Open Sheet
                </Button>
              </div>
            </Header>

            <div style={{ marginLeft: "46px" }}>
              <div className="title">Run History</div>

              <Table
                dataSource={writebackRuns}
                pagination={
                  writebackRuns.length > 25
                    ? {
                        position: ["bottomCenter"],
                        size: "small",
                        pageSize: 25,
                        showSizeChanger: false,
                        onChange: onWritebackRunPageNumberChange,
                      }
                    : false
                }
                columns={[
                  {
                    title: "Status",
                    dataIndex: "status_type",
                    key: "status_type",
                    render: (_, record) => (
                      <FlexRow>
                        <StyledTag color={getRunStatusColor(record)}>
                          {getRunStatusIcon(record)}
                          <span className="text">{getRunStatusText(record)}</span>
                        </StyledTag>
                        <StyledLink>
                          {record.run_status?.overall_status !== "ok" &&
                            (Boolean(record.run_status?.title) ||
                              Boolean(record.run_status?.message)) && (
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  setShowFailedRunError(record.run_status!);
                                }}
                              >
                                View Details
                              </a>
                            )}
                        </StyledLink>
                      </FlexRow>
                    ),
                  },
                  {
                    title: "Time",
                    dataIndex: "end_dt",
                    key: "end_dt",
                    render: d => {
                      if (d) {
                        const date = moment(d);
                        return date.format("MMM DD, YYYY hh:mmA");
                      } else {
                        return "";
                      }
                    },
                  },
                  {
                    title: "Export Trigger",
                    dataIndex: "user",
                    key: "user",
                    render: (user, record) => {
                      return record.trigger_type === "scheduled"
                        ? "Scheduled"
                        : `Manual (${user?.display_name})`;
                    },
                  },
                  {
                    title: "Successful Rows",
                    dataIndex: "num_successful_rows",
                    key: "num_successful_rows",
                    className: "center",
                    render: num_successful_rows => num_successful_rows ?? 0,
                  },
                  {
                    title: "Failed Rows",
                    dataIndex: "num_rows",
                    key: "num_rows",
                    className: "center",
                    render: (num_rows, record) => {
                      return Boolean(num_rows)
                        ? num_rows -
                            (record.num_successful_rows ?? 0) -
                            (record.run_status?.num_skipped_rows ?? 0)
                        : 0;
                    },
                  },
                  {
                    title: "Skipped Rows",
                    dataIndex: "run_status",
                    key: "num_skipped_rows",
                    className: "center",
                    render: run_status => run_status?.num_skipped_rows ?? 0,
                  },
                  {
                    title: "Full Log",
                    dataIndex: "writeback_run_id",
                    key: "writeback_run_id",
                    render: writebackRunId => {
                      return (
                        <a
                          href="javascript:void(0)"
                          onClick={() => downloadRunHistory(writebackRunId)}
                        >
                          Full log (CSV)
                        </a>
                      );
                    },
                  },
                ]}
              />
            </div>
          </Wrap>

          <Sidebar>
            <Card className="white">
              <div>
                <CardHeader title="Settings" icon={<Settings />} />
                <div className="subText">Open in the spreadsheet to make changes.</div>
              </div>
            </Card>

            <div style={{ position: "relative" }}>
              <Overlay />

              <TopMessage>
                <FancyImage>
                  <TargetIcon addonPlatform={writeback?.target_type ?? "google_sheets"} size="40" />

                  <div className="myarrow">
                    <span className="arrow left"></span>
                    <span className="line"></span>
                    <span className="arrow right"></span>
                  </div>

                  <div className="circle" style={{ marginLeft: "8px" }}>
                    <ImportTypeIcon type={writeback?.data_source_type} />
                  </div>
                </FancyImage>
              </TopMessage>

              <Card>
                <StyledPanel>
                  <StyledCardHeader
                    icon={
                      <TargetIcon
                        addonPlatform={writeback?.target_type ?? "google_sheets"}
                        size="16"
                      />
                    }
                    title="Source Data"
                  />
                  <SummaryPanel>
                    <>
                      <SummaryText>
                        Tab:{" "}
                        <TextBold>
                          {writeback?.google_sheets_tab_name ?? writeback?.excel_sheet_name ?? ""}
                        </TextBold>
                      </SummaryText>

                      <SummaryText>
                        Header Row: <TextBold> Row {headerRow}</TextBold>
                      </SummaryText>
                    </>
                  </SummaryPanel>
                </StyledPanel>
              </Card>

              <Card>
                <StyledPanel>
                  <StyledCardHeader
                    icon={<ImportTypeIcon type={writeback?.data_source_type} />}
                    title="Destination"
                  />

                  <SummaryPanel>
                    <SummaryText>
                      {getObjectLanguageText(writeback?.data_source_type, true)}:{" "}
                      <TextBold>
                        {selectedObjectType?.object_label ?? selectedObjectType?.object_name}
                      </TextBold>
                    </SummaryText>

                    <SummaryText>
                      Action:{" "}
                      <TextBold>
                        {ACTIONS.find(a => a.value === writeback?.writeback_mode)?.label}
                      </TextBold>
                    </SummaryText>
                  </SummaryPanel>
                </StyledPanel>
              </Card>

              {["delete", "void"].includes(writeback?.writeback_mode ?? "") && (
                <Card>
                  <StyledPanel>
                    <StyledCardHeader icon={<WBMappings />} title="Field Mappings" />

                    <SummaryPanel>
                      <SummaryText>
                        <div>Column that contains the ID:</div>
                        <TextBold>{writeback?.id_ss_column_name}</TextBold>
                      </SummaryText>
                    </SummaryPanel>
                  </StyledPanel>
                </Card>
              )}

              {!["delete", "void"].includes(writeback?.writeback_mode ?? "") && (
                <Card>
                  <StyledPanel>
                    <StyledCardHeader icon={<WBMappings />} title="Field Mappings" />

                    <div id="mapColumns">
                      <StyledList>
                        {(writeback?.mappings ?? []).map((m, i) =>
                          !m.ss_column_name || !m.source_field_name ? null : (
                            <ColumnSelectField key={i}>
                              <FieldLabel>{m.ss_column_name}</FieldLabel>

                              <FieldSelect>
                                <FieldInfo>
                                  <CurvedArrow />
                                  {m.source_field_name ? (
                                    <Tooltip
                                      mouseEnterDelay={0.5}
                                      title={
                                        <FieldLabelTooltip>
                                          <span>{m.source_field_name}</span>
                                        </FieldLabelTooltip>
                                      }
                                    >
                                      <div>
                                        <ImportTypeIcon type={writeback?.data_source_type} />{" "}
                                        {fields?.find(
                                          field => field.field_name === m.source_field_name
                                        )?.field_label ?? m.source_field_name}
                                      </div>
                                    </Tooltip>
                                  ) : null}
                                </FieldInfo>
                              </FieldSelect>
                            </ColumnSelectField>
                          )
                        )}
                      </StyledList>
                    </div>
                  </StyledPanel>
                </Card>
              )}

              <Card>
                <StyledPanel>
                  <StyledCardHeader icon={<Settings />} title="Advanced Settings" />
                  <SummaryPanel>
                    <SummaryText>
                      Column for results:{" "}
                      <TextBold>
                        Col{" "}
                        {writeback?.result_ss_column_index ||
                        writeback?.result_ss_column_index === 0
                          ? columnToLetter(writeback?.result_ss_column_index + 1)
                          : ""}
                      </TextBold>
                    </SummaryText>
                    {writeback?.data_source_type === "salesforce" && (
                      <SummaryText>
                        Batch size: <TextBold>{writeback?.salesforce_batch_size}</TextBold>
                      </SummaryText>
                    )}
                    {writeback?.data_source_type === "salesforce" &&
                      ["Account", "Case", "Lead"].includes(writeback?.object_type_name) && (
                        <SummaryText>
                          Disable Salesforce Auto Assign:{" "}
                          <TextBold>
                            {writeback?.disable_salesforce_auto_assign ? "True" : "False"}
                          </TextBold>
                        </SummaryText>
                      )}
                    <SummaryText>
                      Export empty cells:{" "}
                      <TextBold>{writeback?.export_empty_cells ? "Enabled" : "Disabled"}</TextBold>
                    </SummaryText>
                  </SummaryPanel>
                </StyledPanel>
              </Card>

              <Card className={writeback?.options?.schedule ? "green" : ""}>
                <StyledPanel>
                  <StyledCardHeader
                    icon={<Date fill={COLORS.black85} />}
                    title="Export Schedule"
                  ></StyledCardHeader>
                  <SummaryPanel>
                    {!writeback?.options?.schedule && <SummaryText>Not scheduled</SummaryText>}
                    {writeback?.options?.schedule && (
                      <>
                        <SummaryText>
                          <ScheduleText schedule={writeback?.options.schedule}></ScheduleText>
                        </SummaryText>
                        <SummaryText>
                          <TextBold>
                            {!writeback?.options.schedule_export_type ||
                            writeback?.options.schedule_export_type ===
                              WritebackScheduleExportType.AllRows
                              ? "All rows on sheet"
                              : `${
                                  writeback?.options.schedule_selected_row_indices?.length ?? 0
                                } row${
                                  (writeback?.options.schedule_selected_row_indices ?? []).length >
                                  1
                                    ? "s"
                                    : ""
                                } `}{" "}
                            will be exported
                          </TextBold>
                        </SummaryText>
                        {writeback?.options.schedule_export_type ===
                          WritebackScheduleExportType.SelectedRows &&
                          writeback?.options.schedule_selected_row_indices?.length && (
                            <SummaryText>
                              {computeSelectedRowsText(
                                writeback?.options.schedule_selected_row_indices ?? []
                              )}
                            </SummaryText>
                          )}
                        {!!writeback?.options.condition_column_name && (
                          <SummaryText>
                            Only export if value in column{" "}
                            <TextBold>{writeback?.options.condition_column_name} </TextBold>
                            is true
                          </SummaryText>
                        )}
                      </>
                    )}
                  </SummaryPanel>
                </StyledPanel>
              </Card>
            </div>
          </Sidebar>
        </div>
      )}

      <ConfirmBox
        visible={
          Boolean(showFailedRunError) &&
          (Boolean(showFailedRunError?.title) || Boolean(showFailedRunError?.message))
        }
        closable={true}
        showCancel={false}
        onOk={() => setShowFailedRunError(null)}
        onCancel={() => setShowFailedRunError(null)}
        okText="Got it"
        title={showFailedRunError?.title}
        width={400}
      >
        <>
          {showFailedRunError?.overall_status === "some_records_failed" && (
            <WarningMessage {...showFailedRunError} />
          )}
          {showFailedRunError?.overall_status !== "some_records_failed" && (
            <ErrorMessage {...showFailedRunError} />
          )}
        </>
      </ConfirmBox>
    </div>
  );
};
