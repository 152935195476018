export const columnIndexToColumnLetter = (
  index: number,
  options?: { zeroBased: boolean }
): string => {
  let temp: number;
  let letter = "";

  if (options?.zeroBased) {
    index += 1;
  }

  while (index > 0) {
    temp = (index - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    index = (index - temp - 1) / 26;
  }

  return letter;
};

export const columnLetterToColumnIndex = (
  letter: string,
  options?: { zeroBased: boolean }
): number => {
  letter = letter.toUpperCase();

  let index = 0;

  const length = letter.length;

  for (let i = 0; i < length; i++) {
    index += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
  }

  if (options?.zeroBased) {
    return index - 1;
  }

  return index;
};
