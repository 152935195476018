import React from "react";
import styled from "styled-components";

import {
  FlexColumn,
  Typography,
  Button,
  FlexRow,
  BillingChatWithUsIcon,
  BillingEmailUsIcon,
} from "../../../components";
import { COLORS } from "../../../constants";
import { useZendeskContext } from "../../../contexts/zendeskContext";

import { FAQSection } from "./";

const HelpContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Section = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .content {
    color: ${COLORS.black65};
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${COLORS.black2};

    padding: 16px;
    border-radius: 5px;

    &.noPadding {
      padding: 0px;
    }

    .ant-progress-inner {
      background: ${COLORS.black15};
    }
  }
`;

export const HelpContent = () => {
  const { openZendesk } = useZendeskContext();

  return (
    <HelpContainer>
      <FAQSection />
      <Section style={{ marginTop: "16px" }}>
        <div className="sectionTitle">Need help?</div>
        <div className="content" style={{ background: "white", padding: "8px 0px" }}>
          <FlexColumn gap={1}>
            <Typography variant="body2">
              Contact our support team to help with your plan and billing
            </Typography>

            <FlexRow gap={2} style={{ marginLeft: "-16px" }}>
              <a href="https://help.coefficient.io/hc/en-us/requests/new " target="_blank">
                <Button type="link">
                  <BillingEmailUsIcon />
                  Send us an email
                </Button>
              </a>
              <Button type="link" onClick={() => openZendesk("webapp:billing_plan")}>
                <BillingChatWithUsIcon />
                Chat with us
              </Button>
            </FlexRow>
          </FlexColumn>
        </div>
      </Section>
    </HelpContainer>
  );
};
