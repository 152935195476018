import { AddonPlatform } from "@coeff/api";
import * as React from "react";

import { Excel, GoogleSheet, ExcelPush, GoogleSheetPush } from ".";

type Size = "16" | "40";

type TargetIconProps = {
  size?: Size;
  addonPlatform: AddonPlatform;
};

const get16Logo = (addonPlatform: AddonPlatform) => {
  if (addonPlatform === "excel") {
    return <ExcelPush />;
  }
  return <GoogleSheetPush />;
};

const get40Logo = (addonPlatform: AddonPlatform) => {
  if (addonPlatform === "excel") {
    return <Excel />;
  }
  return <GoogleSheet />;
};

export const TargetIcon: React.FC<TargetIconProps> = ({ addonPlatform, size }) => {
  if (size === "16") {
    return get16Logo(addonPlatform);
  }
  return get40Logo(addonPlatform);
};
