import { DefaultApi } from "@coeff/api";

import { config } from "../config/index";
import { sentryCapture } from "../utils/sentry";

const styles = `
div[data-embed="chat"]{
  padding: 0px 0px 0px 2px;
}

div[data-embed="chat"] > div > div:first-child,
header[data-testid="widget-header-view"],
div[data-testid="drop-container"] > div,
div[data-testid="drop-container"] > div > header {
  border-radius: 0px;
}

div[data-testid="drop-container"] header h1,
header[data-testid="widget-header-view"] h1 {
  text-align: left;
  margin: auto auto auto 5px !important;
  font-weight: normal;
  color: #B3CEF8;
}

div[data-testid="drop-container"] header h1:before,
header[data-testid="widget-header-view"] h1:before {
  content: "Hi there 👋";
  display: block;
  color: #ffffff;
  font-size: 26px;
  line-height: 32px;
  margin-top: 5px;
}

.coeff__custom_header{
  margin: 5px 0px;
}

.coeff__custom_header .welcome{
  display: block;
  color: #ffffff;
  font-size: 26px;
  line-height: 32px;
  margin-top: 5px;
}
`;

export type ZendeskClient = (
  arg1: string,
  arg2: string,
  arg3?: Record<string, string> | ((any: any) => void)
) => void;

export const injectCSSToZendeskChat = (options: { zendeskMessengerEnabled: boolean }) => {
  if (options.zendeskMessengerEnabled) {
    return;
  }

  // appends the CSS in the web widget
  const widgetIframe = document.getElementById("webWidget") as HTMLIFrameElement;

  if (widgetIframe) {
    const style = document.createElement("style");
    style.innerHTML = styles;
    // inject CSS
    widgetIframe.contentDocument?.head.appendChild(style);
  } else {
    setTimeout(() => injectCSSToZendeskChat(options), 500);
  }
};

const getJWTToken = async (apiClient: DefaultApi) => {
  try {
    const { data: response } = await apiClient.getZendeskChatSessionJWT();

    return response.chat_session_jwt;
  } catch (error) {
    console.error(error);

    sentryCapture({
      error,
      name: "Zendesk JWT Fetch: POST /api/zendesk_chat_session_jwt",
    });

    return;
  }
};

let zendeskClientPromise: Promise<ZendeskClient> | undefined;

export const getOrCreateZendeskClient = (
  apiClient: DefaultApi,
  options: { zendeskMessengerEnabled: boolean }
): Promise<ZendeskClient> => {
  if (zendeskClientPromise) {
    return zendeskClientPromise;
  }

  zendeskClientPromise = new Promise(resolve => {
    const zESettings = getZESettings(apiClient);

    window.zESettings = { ...zESettings };

    const zendeskScript = document.createElement("script");

    zendeskScript.id = "ze-snippet";

    zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${
      options.zendeskMessengerEnabled ? config.ZENDESK_MESSENGER_KEY : config.ZENDESK_CHAT_KEY
    }`;

    zendeskScript.onload = () => {
      resolve(window.zE);
    };

    document.head.appendChild(zendeskScript);
  });

  return zendeskClientPromise;
};

const getZESettings = (apiClient: DefaultApi) => {
  return {
    webWidget: {
      chat: { suppress: true },

      authenticate: {
        chat: {
          jwtFn: (callback: (token: string) => void) => {
            getJWTToken(apiClient)
              .then(token => {
                callback(token!);
              })
              .catch(error => console.error(error));
          },
        },
      },
    },
  };
};

export const openZendeskChat = async (
  apiClient: DefaultApi,
  options: {
    zendeskMessengerEnabled: boolean;
    user?: {
      name?: string;
      email: string;
    };
  }
) => {
  try {
    if (!window.zE) {
      // Don't use the result of this - for unknown reason it doesn't open the chat
      // We're just using it to ensure the script is loaded before trying to open
      await getOrCreateZendeskClient(apiClient, options);
    }

    if (options.user) {
      if (options.zendeskMessengerEnabled) {
        window.zE("messenger", "identify", options.user);
      } else {
        window.zE("webWidget", "identify", options.user);
      }
    }

    if (options.zendeskMessengerEnabled) {
      window.zE("messenger", "open");
    } else {
      window.zE("webWidget", "open");
    }
  } catch (error) {
    sentryCapture({ error });
  }
};
