import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { BillingContext } from "../Billing";

const Wrap = styled.div`
  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .ant-collapse {
    background: ${COLORS.white};

    .ant-collapse-header {
      font-weight: bold;
      padding-left: 0px;
    }

    .ant-collapse-content-box {
      padding: 4px 0px;
    }
  }
`;

const Item = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.black9};

  .title {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;

    .label {
      font-weight: bold;
    }
  }

  .content {
    margin-top: ${({ open }) => (open ? "12px" : "0px")};
    height: ${({ open }) => (open ? "100%" : "0px")};
    overflow: hidden;
  }
`;

type FAQItemProps = {
  open: boolean;
  onClick: () => void;
  title: string;
  content: React.ReactNode;
};

export const FAQItem: React.FC<FAQItemProps> = ({ open, onClick, title, content }) => {
  return (
    <Item onClick={onClick} open={open}>
      <div className="title">
        <div className="label">{title}</div>
        {open ? <MinusOutlined /> : <PlusOutlined />}
      </div>

      <div className="content">{content}</div>
    </Item>
  );
};

export const FAQSection = () => {
  const { page, plan_type } = useContext(BillingContext) as BContext;

  const [faqOpen, setFaqOpen] = useState<number | undefined>(undefined);

  const handleToggleFaq = (value: number) => {
    if (faqOpen === value) {
      setFaqOpen(undefined);
    } else {
      setFaqOpen(value);
    }
  };

  return (
    <Wrap>
      <div>
        <div className="sectionTitle">FAQ</div>

        {plan_type === "free_trial" && (
          <>
            <FAQItem
              onClick={() => handleToggleFaq(0)}
              open={faqOpen === 0}
              title="How long is the free trial?"
              content={
                <p>
                  The Free Trial is for 30 days. If you don't upgrade then you will be automatically
                  downgraded to the Free plan. You will lose the ability to auto-refresh your
                  imported data and any data alerts you have set.
                </p>
              }
            />
          </>
        )}
        <FAQItem
          onClick={() => handleToggleFaq(1)}
          open={faqOpen === 1}
          title="Can I get multiple users on the Starter plan?"
          content={
            <p>
              The Starter plan is only for individuals. Please select the Pro plan to have multiple
              users in your plan.
            </p>
          }
        />

        {(!plan_type || ["free_trial", "lifetime_free"].includes(plan_type ?? "")) && (
          <>
            <FAQItem
              onClick={() => handleToggleFaq(2)}
              open={faqOpen === 2}
              title="What changes can I make later?"
              content={
                <p>
                  You can change the plan or add/remove licenses at any time. You will be charged
                  (or refunded) the pro-rated cost.
                </p>
              }
            />
            <FAQItem
              onClick={() => handleToggleFaq(3)}
              open={faqOpen === 3}
              title="When do I assign users?"
              content={
                <p>
                  Once you purchase a plan with a certain number of licenses (only in the Pro plan),
                  you can assign and change assignment of the licenses to users at any time. There
                  is no cost to changing assignments.
                </p>
              }
            />

            <FAQItem
              onClick={() => handleToggleFaq(4)}
              open={faqOpen === 4}
              title="What is a data source?"
              content={
                <p>
                  A data source is any distinct system (e.g. Salesforce, HubSpot, MySQL) that you or
                  other users in your plan connect through Coefficient. Connection with Slack/Email
                  for alerts does not count as a data source.
                </p>
              }
            />
          </>
        )}

        {["starter", "pro"].includes(plan_type ?? "") && (
          <>
            <FAQItem
              onClick={() => handleToggleFaq(5)}
              open={faqOpen === 5}
              title="Can I re-assign users on my plan?"
              content={
                <p>
                  You can assign or change assignment of the licenses to users at any time. There is
                  no cost to changing assignments.
                </p>
              }
            />
            <FAQItem
              onClick={() => handleToggleFaq(6)}
              open={faqOpen === 6}
              title="Can I change my plan?"
              content={
                <p>
                  Yes, you can change the plan or add/remove licenses at any time. You will be
                  charged (or refunded) the pro-rated cost.
                </p>
              }
            />
            <FAQItem
              onClick={() => handleToggleFaq(7)}
              open={faqOpen === 7}
              title="How do I cancel my plan?"
              content={
                <p>
                  To cancel your plan, please{" "}
                  <a href="mailto:support@coefficient.io" target="_blank">
                    contact us
                  </a>
                  .
                </p>
              }
            />
          </>
        )}
      </div>
    </Wrap>
  );
};
