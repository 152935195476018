import { SearchOutlined } from "@ant-design/icons";
import { Input as InputAD, InputProps as InputPropsAD } from "antd";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const { TextArea } = InputAD;

const StyledInput = styled.div`
  position: relative;
  display: flex;
  padding: 0 8px;
  margin: 0 8px;
  background: #fff;
  border: 2px solid white;
  border-radius: 4px;
  font-weight: 500;

  .ant-input {
    flex: 1;
    border: none;
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0;
  }

  &.error {
    border: 2px solid ${COLORS.red} !important;
  }

  &.cellpicker {
    border-color: ${COLORS.green} !important;
    opacity: 1;
  }

  &:focus-within {
    border-color: ${COLORS.coeblue4}!important;
  }

  &:hover {
    border-color: ${COLORS.black15};

    &:focus-within {
      border-color: ${COLORS.coeblue4}!important;
    }
  }
`;

const StyledTextarea = styled.div`
  position: relative;
  display: flex;
  padding: 4px;
  background: #fff;
  border: 2px solid ${COLORS.black15};
  border-radius: 4px;

  &:focus-within {
    border-color: ${COLORS.coeblue4};
    color: inherit;
  }

  &.error {
    border-color: ${COLORS.red} !important;
  }

  .ant-input {
    color: ${COLORS.black85};
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    flex: 1;
    border: none;
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0;
    resize: none;
  }

  &:hover {
    border-color: ${COLORS.black15};

    &:focus-within {
      border-color: ${COLORS.coeblue4};
      color: inherit;
    }
  }
`;

interface InputProps {
  children?: React.ReactChild | React.ReactChild[];
  error?: boolean;
  disabled?: boolean;
  picker?: boolean;
  placeholder?: string;
  onBlur?: (e) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e) => void;
  inputRef?: React.Ref<HTMLInputElement | any> | null;
  maxLength?: number;
  name?: string;
  min?: number;
  size?: "large" | "middle" | "small";
  type?: string;
  value: string | number | undefined;
}

interface InputTextareaProps {
  autoSize?: boolean | object;
  error?: boolean;
  inputRef?: React.Ref<HTMLTextAreaElement | any> | null;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: (e) => void;
  placeholder?: string;
  value: string;
}

export const DEPRECATED_Input = ({
  children,
  error = false,
  picker = false,
  ...props
}: InputProps) => (
  // using `cellpicker` insteadof `picker`, as it is the same classname as google file picker and we have added some overrides to it.
  <StyledInput className={`coeff__input${error ? " error" : ""}${picker ? " cellpicker" : ""}`}>
    <InputAD size={props.size ? props.size : "small"} ref={props.inputRef} {...props} />
    {children}
  </StyledInput>
);

/**
 * Since `ref` is not a prop and cannot be passed to childred, I have added a dummy prop inputRef to receive the ref.
 * ForwardRef is not working for some reason
 *
 * TODO: make `ForwardRef` to work.
 */
export const DEPRECATED_InputTextarea = (props: InputTextareaProps) => (
  <StyledTextarea className={`coeff__input-textarea ${props.error ? "error" : ""}`}>
    <TextArea
      autoSize={props.autoSize ?? { minRows: 1, maxRows: 2 }}
      ref={props.inputRef}
      {...props}
    />
  </StyledTextarea>
);
