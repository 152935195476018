import { SubscriptionPlanType } from "@coeff/api";
import { Avatar } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { Checkbox } from "./Checkbox";
import { PlanTag } from "./Tag";
import { Tooltip } from "./Tooltip";
import { Typography } from "./Typography";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${COLORS.black2};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;

  &.dense {
    padding: 4px 12px;
    margin-bottom: 0px;
  }

  &.white {
    background: white;
  }

  &.static {
    border: 2px solid ${COLORS.black15};
  }

  &.selectable {
    border: 2px solid ${COLORS.black4};
    cursor: pointer;

    &:hover {
      border-color: ${COLORS.black15};
    }

    &.disabled {
      border-color: ${COLORS.black4} !important;
      background: ${COLORS.black4};
      cursor: not-allowed;
    }

    &.selected {
      border-color: ${COLORS.coeblue4};
    }
  }

  .ant-checkbox-wrapper {
    margin-right: 12px;

    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      &.ant-checkbox-checked {
        &::after {
          border: none;
        }
      }
    }

    &.ant-checkbox-wrapper-disabled {
      cursor: not-allowed;
    }
  }

  .ant-avatar {
    margin-right: 12px;
    font-size: 12px;
  }

  .name {
    font-weight: 600;
  }

  .email {
    color: ${COLORS.black65};
  }

  .subtext {
    color: ${COLORS.black45};
    font-size: 12px;
  }

  .footer {
    text-align: right;
    flex: 1;
  }

  .ant-tag {
    margin-left: 5px;
    border-radius: 5px;
    font-weight: normal;
  }
`;

interface UserListProps {
  avatarColor?: string;
  avatarIcon?: ReactNode;
  className?: string;
  isSelectable?: boolean;
  style?: React.CSSProperties;
  name: string;
  email?: string;
  userId: string;
  subtext?: string;
  plan?: SubscriptionPlanType;
  tooltip?: string;
  disabled?: boolean;
  selected?: boolean;
  toggleUser?: (arg: string) => void;
  footer?: ReactNode | string;
}

export const UserList = ({
  avatarColor,
  avatarIcon,
  className,
  name,
  email,
  userId,
  subtext,
  plan,
  style,
  isSelectable,
  selected,
  disabled,
  tooltip,
  toggleUser,
  footer,
}: UserListProps) => {
  return (
    <Tooltip title={tooltip} variant="light">
      <Wrap
        style={style}
        className={`${className} ${selected ? "selected" : ""} ${
          isSelectable ? "selectable" : ""
        } ${disabled ? "disabled" : ""}`}
        onClick={e => {
          if (toggleUser && isSelectable && !disabled) {
            toggleUser(userId);
          } else {
            // do nothing
          }
        }}
      >
        {isSelectable && <Checkbox checked={selected} disabled={disabled} />}

        <Avatar
          style={{ backgroundColor: avatarColor || COLORS.coeblue4, flexShrink: 0 }}
          size="large"
          icon={avatarIcon}
        >
          {!avatarIcon &&
            name
              .split(" ")
              .map(n => n.substring(0, 1).toUpperCase())
              .join("")}
        </Avatar>

        <div style={{ lineHeight: "1.25", minWidth: "0px" }}>
          <Typography className="name" noWrap>
            {name} {plan && <PlanTag planType={plan} />}
          </Typography>
          {email && (
            <Typography className="email" noWrap>
              {email}
            </Typography>
          )}
          {subtext && (
            <Typography className="subtext" noWrap>
              {subtext}
            </Typography>
          )}
        </div>

        {footer && <div className="footer">{footer}</div>}
      </Wrap>
    </Tooltip>
  );
};
