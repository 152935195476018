import { AddonPlatform } from "@coeff/api";
import { Breadcrumb, Menu, Tabs } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  createQuickstart,
  getDomainAlerts,
  getDomainImports,
  getDomains,
  getDomainUsers,
  getGlobalSetting,
  getQuickstarts,
  SortColumn,
  upsertGlobalSetting,
} from "../../actions";
import { CoeLogo } from "../../components";
import { COLORS } from "../../constants";
import {
  AdminAlert as AlertT,
  CAContext,
  CoeffAdminDataImport,
  Domain,
  Quickstart as QuickstartT,
  User,
} from "../../types";
import { getDomain } from "../../utils";
import { WorkspaceAdmin } from "../WorkspaceAdmin/WorkspaceAdmin";

import { Alerts, DomainDetails, Domains, Imports, Quickstart } from "./components";
import { Sidebar } from "./components/Sidebar";

const { TabPane } = Tabs;

interface CoeffAdminProps {
  alerts: AlertT[];
  createQuickstart: (email: string, addon_platform: AddonPlatform) => void;
  dataImports: CoeffAdminDataImport[];
  domainDetails: Domain | null;
  domains: Domain[];
  getDomainAlerts: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn
  ) => void;
  totalAlertsCount: number;
  getDomains: (params?: Record<string, string | number>) => void;
  getDomainImports: (domain: string) => void;
  getDomainUsers: (domain: string) => void;
  getGlobalSetting: (type: string) => void;
  getQuickstarts: (email: string) => QuickstartT[];
  globalSettings: { beta_gsuite_domains?: string[] };
  loading: boolean;
  location: any;
  upsertGlobalSetting: (setting: object) => void;
  users: User[];
}

interface CoeffAdminState {
  domain: string;
  selectedDomain: string;
}

const PageTitle = styled.div`
  display: inline-block;
  font-size: 1.2rem;
  position: relative;
  top: -0.8rem;
  margin-left: 1rem;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 800;
  }
  .coeff__select {
    margin-left: 24px;
    .ant-select-selector {
      width: 148px;
      height: 32px;
      background-color: #f5f5f5;
      .ant-select-selection-item {
        font-weight: 600;
      }
    }
  }
`;

const Panel = styled.section`
  padding: 1rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  min-width: 0px;
`;

const Content = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-left: 240px;
  min-width: 0;

  && .ant-table-header table thead tr {
    th {
      background: #ecf6ff;
      color: #0054e6;
      padding-left: 8px;
    }
    & th.ant-table-column-sort {
      background: #ecf6ff;
    }
    & th:hover {
      background: #cce9ff;
    }
  }
`;

const Layout = styled.div`
  display: flex;
  height: 100%;
`;

const StyledMenu = styled(Menu)`
  && {
    border: none;
    line-height: 1 !important;
    > .ant-menu-item {
      line-height: 47px;
    }
  }
`;

export const CoeffAdminContext = React.createContext<CAContext | null>(null);

class CoeffAdmin extends React.Component<CoeffAdminProps, CoeffAdminState> {
  dataTab: any;
  quickstartTab: any;
  baseUrl: string;

  constructor(props: CoeffAdminProps) {
    super(props);

    this.state = {
      domain: "",
      selectedDomain: "",
    };

    this.dataTab = React.createRef();
    this.quickstartTab = React.createRef();

    this.baseUrl = "/superadmin";
  }

  componentDidMount = () => {
    const { getDomainAlerts, getDomainImports, getDomains, getDomainUsers, getGlobalSetting } =
      this.props;
    const { selectedDomain } = this.state;
    getGlobalSetting("beta_gsuite_domains");
    getDomains();

    if (!selectedDomain) {
      const domainFromLocation = getDomain();

      if (domainFromLocation) {
        this.setState({ selectedDomain: domainFromLocation });
        getDomainUsers(domainFromLocation);
      }
    } else {
      getDomainUsers(selectedDomain);
    }
  };

  selectDomain = (domain: string) => {
    this.setState({ selectedDomain: domain }, this.loadUserImports);
  };

  loadUserImports = () => {
    const { getDomainImports, getDomainUsers } = this.props;
    const { selectedDomain } = this.state;
    getDomainUsers(selectedDomain);
    getDomainImports(selectedDomain);
  };

  render = () => {
    const {
      alerts,
      totalAlertsCount,
      createQuickstart,
      dataImports,
      domainDetails,
      domains,
      getQuickstarts,
      globalSettings,
      loading,
      users,
    } = this.props;
    const { domain, selectedDomain } = this.state;

    return (
      <CoeffAdminContext.Provider
        value={{
          alerts,
          createQuickstart,
          dataImports,
          domainDetails,
          domains,
          getQuickstarts,
          globalSettings,
          loading,
          selectDomain: this.selectDomain,
          selectedDomain,
          users,
          getDomains: this.props.getDomains,
        }}
      >
        <Layout>
          <Sidebar />

          <Content>
            <Switch>
              <Route path={`${this.baseUrl}/domainDetails`}>
                {selectedDomain && <DomainDetails />}

                <WorkspaceAdmin
                  hideHeader={true}
                  domain={selectedDomain}
                  baseUrl={`${this.baseUrl}/domainDetails`}
                  viewAsSuperAdmin={true}
                />
              </Route>

              <Route path={`${this.baseUrl}/domains`}>
                <Panel>
                  <Header>
                    <h1>Domains</h1>
                  </Header>
                  {<Domains />}
                </Panel>
              </Route>

              <Route path="/superadmin/quickstart">
                <Quickstart />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </CoeffAdminContext.Provider>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  createQuickstart: (email: string, addon_platform: AddonPlatform) =>
    dispatch(createQuickstart(email, addon_platform)),
  getDomainAlerts: (domain: string, pageSize: number, offset: number, sortColumn: SortColumn) =>
    dispatch(getDomainAlerts(domain, pageSize, offset, sortColumn)),
  getDomainImports: (domain: string) => dispatch(getDomainImports(domain)),
  getDomains: (params?: Record<string, string | number>) => dispatch(getDomains(params)),
  getDomainUsers: (domain: string) => dispatch(getDomainUsers(domain)),
  getGlobalSetting: (type: string) => dispatch(getGlobalSetting(type)),
  getQuickstarts: (email: string) => dispatch(getQuickstarts(email)),
  upsertGlobalSetting: (setting: object) => dispatch(upsertGlobalSetting(setting)),
});

const mapStateToProps = ({
  coeffAdmin: {
    alerts,
    dataImports,
    domain,
    domains,
    loading,
    users,
    workspaces,
    totalAlertsCount,
  },
  app: { globalSettings },
}) => ({
  alerts,
  dataImports,
  domainDetails: domain,
  domains,
  globalSettings,
  loading,
  users,
  workspaces,
  totalAlertsCount,
});

const route = withRouter(connect(mapStateToProps, mapDispatchToProps)(CoeffAdmin));
export { route as CoeffAdmin };
