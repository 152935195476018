import styled from "styled-components";

import { COLORS } from "../../constants";

const TABLE_HEADER_HEIGHT = 39;

const TABLE_CELL_HEIGHT = 27;

export const TableCell = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 12px;
  border-top: 1px solid ${COLORS.black15};
  height: ${TABLE_CELL_HEIGHT}px;

  &.first-column-cell {
    background-color: ${COLORS.black4};
    text-align: center;
    border-left: none;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  position: sticky;
  top: 0px;
  color: ${COLORS.coeblue4};
  font-weight: 700;
  padding: 8px 12px;
  height: ${TABLE_HEADER_HEIGHT}px;
  border-bottom: 1px solid ${COLORS.black15};
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  && {
    background-color: ${COLORS.coeblue};
  }

  & + div {
    border-top: none;
  }

  &:hover:not(.first-column-cell) {
    background-color: ${COLORS.coeblue05};
    cursor: pointer;
  }
`;
