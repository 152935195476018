import { DataImportRun } from "@coeff/api-admin-ext";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SortColumn, SortDirection } from "../actions";
import { API_EXT_ADMIN_ROUTES } from "../constants";
import { useApiContext } from "../utils";

import { DEPRECATED_ApiManager, QueryCacheKey } from ".";

export type FetchDataImportRunsParams = {
  domain?: string;
  pageSize: number;
  offset: number;
  sortColumn: SortColumn;
  filters?: string;
};

export const fetchAdminDataImportRuns = async (
  deprecatedAPI: DEPRECATED_ApiManager,
  params: FetchDataImportRunsParams = {
    pageSize: 100,
    offset: 0,
    sortColumn: {
      columnKey: "created_dt",
      value: SortDirection.descend,
    },
  }
): Promise<{ dataImportRuns: DataImportRun[]; totalRecords: number }> => {
  if (params.domain) {
    deprecatedAPI.setDomainParam(params.domain);
  }

  const { data_import_runs: dataImportRuns, total_records: totalRecords } =
    await deprecatedAPI.request({
      method: "GET",
      url: `${API_EXT_ADMIN_ROUTES.dataImportRuns}?page_size=${params.pageSize}&offset=${params.offset}&sort_by=${params.sortColumn.columnKey}&sort_order=${params.sortColumn.value}&filters=${params.filters}`,
    });

  return { dataImportRuns, totalRecords };
};

export const useAdminDataImportRuns = (
  params: FetchDataImportRunsParams,
  { enabled }: UseQueryOptions = {}
) => {
  const { DEPRECATED_api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DATA_IMPORT_RUNS, params],
    () => fetchAdminDataImportRuns(DEPRECATED_api, params),
    {
      enabled,
    }
  );
};
