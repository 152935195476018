import { DataImportSchedule } from "@coeff/api";
import React, { useState } from "react";
import styled from "styled-components";

import { Button, Paper, PaperContent, PaperContentIndent } from "../../components";
import { getDefaultSchedule } from "../../utils";

import { ScheduleFormFields } from "./ScheduleFormFields";

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  initialData: DataImportSchedule | undefined;
  isLoading: boolean;
  onSubmit: (value?: DataImportSchedule) => void;
  buttonLabel: string;
};

const uniqueId = parseInt(String(Math.random() * 100), 10);

export const ScheduleForm: React.FC<Props> = ({
  initialData,
  onSubmit,
  isLoading,
  buttonLabel,
}) => {
  const [schedule, setSchedule] = useState<DataImportSchedule | undefined>(
    initialData ?? getDefaultSchedule({ uniqueId, baseHour: 11 })
  );

  return (
    <Paper variant={schedule ? "success" : "default"}>
      <StyledPaperContent>
        <ScheduleFormFields
          disabled={isLoading}
          value={schedule}
          onChange={schedule => setSchedule(schedule)}
          isIndented={false}
          initialIsEditMode={true}
          hideCloseButton={true}
        />

        <PaperContentIndent>
          <Button block disabled={isLoading} type="primary" onClick={() => onSubmit(schedule)}>
            {buttonLabel}
          </Button>
        </PaperContentIndent>
      </StyledPaperContent>
    </Paper>
  );
};
