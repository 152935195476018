import loadScript from "load-script";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { BorderedSelect as CoeffSelect, DEPRECATED_Input, Lock, Option } from "../../../components";
import { config as Config } from "../../../config";
import { COLORS, COUNTRIES } from "../../../constants";

const Form = styled.div`
  width: 100%;
`;

const Field = styled.div`
  label {
    font-weight: 600;
    margin-bottom: 5px;
    display: block;

    &.large {
      font-size: 16px;
      font-weight: bold;
    }

    &.flex {
      display: flex;
      justify-content: space-between;
    }

    .secureForm {
      color: ${COLORS.black25};
      font-size: 12px;

      span {
        position: relative;
        top: -5px;
        right: 5px;
      }
    }
  }

  .formInput {
    border: 2px solid ${COLORS.black15};
    padding: 10px;
    border-radius: 5px;
    height: 41px;

    &.StripeElement--invalid {
      border-color: ${COLORS.red} !important;
    }
  }

  .coeff__input {
    border-color: ${COLORS.black15};
    margin: 0px;

    input {
      padding: 5px;
    }
  }

  .ant-select {
    width: 100%;
  }

  &.nameField {
    margin: 30px 0px 15px;
  }

  &.fieldError {
    .StripeElement--empty {
      border-color: ${COLORS.red} !important;
    }
  }
`;

interface BilloingFormProps {
  card: any;
  stripe: any;
  formError: boolean;
  name: string;
  setName: (string) => void;
  country: string;
  setCountry: (string) => void;
  zip: string;
  setZip: (string) => void;
}

export const BillingForm = ({
  card,
  stripe,
  formError,
  name,
  setName,
  country,
  setCountry,
  zip,
  setZip,
}: BilloingFormProps) => {
  useEffect(() => {
    loadScript("https://js.stripe.com/v3/", () => {
      // @ts-ignore
      stripe.current = Stripe(Config.STRIPE_API_KEY);
      const elements = stripe.current.elements();
      const style = {
        base: {
          fontSize: "14px",
          color: COLORS.black85,
          fontFamily: '"proxima-nova", sans-serif',
          "::placeholder": {
            color: COLORS.black25,
          },
        },
      };
      card.current = elements.create("card", { hidePostalCode: true, style: style });
      card.current.mount("#card-element");
    });
  }, []);
  return (
    <Form>
      <Field className={formError ? "fieldError" : ""}>
        <label className="large flex">
          <span>Payment Details</span>
          <div className="secureForm">
            <span>SECURE FORM</span> <Lock />
          </div>
        </label>
        <div id="card-element" className="formInput"></div>
      </Field>

      <Field className="nameField">
        <label>Name</label>
        <DEPRECATED_Input
          size="large"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          placeholder="Name"
          error={formError && !name}
        />
      </Field>

      <div style={{ display: "flex" }}>
        <Field style={{ flex: "1", marginRight: "10px" }}>
          <label>Country</label>
          <CoeffSelect size="large" value={country} onSelect={(v: any) => setCountry(v.toString())}>
            {COUNTRIES.map((c, i) => (
              <Option key={i} value={c.Code}>
                {c.Name}
              </Option>
            ))}
          </CoeffSelect>
        </Field>

        <Field style={{ width: "200px" }}>
          <label>Zipcode</label>
          <DEPRECATED_Input
            size="large"
            value={zip}
            onChange={e => setZip(e.currentTarget.value)}
            placeholder="ZIP Code"
            error={formError && !zip}
          />
        </Field>
      </div>
    </Form>
  );
};
