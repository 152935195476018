import { Steps as StepsAD } from "antd";
import styled from "styled-components";

import { COLORS } from "../constants";

export const Steps = styled(StepsAD)`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${COLORS.coeblue4};
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${COLORS.coeblue4};
  }

  .ant-steps-item-title {
    width: 100%;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${COLORS.coeblue4};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: transparent;
    background-color: ${COLORS.coeblue4};
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${COLORS.white};
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${COLORS.coeblue4};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${COLORS.black30};

    border-color: transparent;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${COLORS.white};
  }
`;
