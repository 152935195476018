import { PublicClientApplication } from "@azure/msal-browser";
import { DefaultApi as Api } from "@coeff/api";
import { DefaultApi as AdminExtApi } from "@coeff/api-admin-ext";
import { DefaultApi as CoeffAdminApi } from "@coeff/api-coeff-admin";
import * as Sentry from "@sentry/browser";
import axios, { InternalAxiosRequestConfig } from "axios";
import { Store } from "redux";

import { config } from "../config";
import { AppState } from "../store";
import {
  SSOAuthFailedError,
  isAxiosErrorWithSSOAuthFailedMessage,
  publicClientAppScopes,
} from "../utils";

export const setMicrosoftOAuthTokenHeader = async (
  publicClientApp: PublicClientApplication,
  config: InternalAxiosRequestConfig<any>
) => {
  try {
    const tokenResponse = await publicClientApp.acquireTokenSilent({
      scopes: publicClientAppScopes,
    });

    if (tokenResponse?.accessToken) {
      config.headers["MICROSOFT-OAUTH-TOKEN"] = tokenResponse.accessToken;
    }
  } catch (error: unknown) {
    if (
      typeof error === "object" &&
      error !== null &&
      "name" in error &&
      error.name === "BrowserAuthError"
    ) {
      // Ignore errors. We're either not logged in or we're logged in with Google.
    } else {
      console.error(error);
      Sentry.captureException(error);
    }
  }
};

export const initApiClients = (params: {
  publicClientApp: PublicClientApplication;
  store: Store<AppState>;
  onUnauthorizedError: (error: unknown) => void;
}) => {
  const { store, publicClientApp, onUnauthorizedError } = params;

  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(async config => {
    const storeState = store.getState();

    await setMicrosoftOAuthTokenHeader(publicClientApp, config);

    const params = new URLSearchParams(window.location.search);

    const oauthTokenFromUrl = params.get("GOOGLE-OAUTH-TOKEN");
    const openIdTokenFromUrl = params.get("GOOGLE-OPENID-TOKEN");

    if (openIdTokenFromUrl ?? storeState.app.openIdToken) {
      // Tokens in the URL take precedence over tokens in the store
      config.headers["GOOGLE-OPENID-TOKEN"] = openIdTokenFromUrl ?? storeState.app.openIdToken;
    }

    if (oauthTokenFromUrl ?? storeState.app.oauthToken) {
      // Tokens in the URL take precedence over tokens in the store
      config.headers["GOOGLE-OAUTH-TOKEN"] = oauthTokenFromUrl ?? storeState.app.oauthToken;
    }

    if (storeState.app.coeffAuthToken) {
      config.headers["COEFF-AUTH-TOKEN"] = storeState.app.coeffAuthToken;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    config => config,
    response => {
      if (isAxiosErrorWithSSOAuthFailedMessage(response)) {
        onUnauthorizedError(new SSOAuthFailedError());
      }

      return Promise.reject(response);
    }
  );

  return {
    apiClient: new Api(undefined, config.BASE_URL, axiosInstance),
    adminExtApiClient: new AdminExtApi(undefined, config.BASE_URL, axiosInstance),
    coeffAdminApiClient: new CoeffAdminApi(undefined, config.BASE_URL, axiosInstance),
  };
};
