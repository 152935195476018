import { CloudTable } from "@coeff/api";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import styled from "styled-components";

import { QueryCacheKey } from "../../api";
import {
  Button,
  Input,
  Typography,
  DownArrow,
  NewWindow,
  BorderedSelect,
  Source,
  TextWithIcon,
  PaperContentIndent,
  Loader,
} from "../../components";
import {
  assertIsDefined,
  getCloudTableSpreadsheetSheetUrl,
  useGoogleApiContext,
} from "../../utils";

type SourceInfoErrors = Partial<Record<keyof CloudTable["source_info"], string>>;

const StyledPaperContentIndent = styled(PaperContentIndent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormHorizontalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 8px;
  width: 100%;
  align-items: center;
  min-width: 0;
`;

const SheetInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
  width: 100%;
`;

const SheetInputField = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  margin: 4px 0;
  background: white;
`;

const SheetInput = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
`;

const SheetInputContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 0;
`;

const StyledSelect = styled(BorderedSelect)`
  width: 100%;
  max-width: 400px;
  min-width: 0;
`;

const FormHorizontal = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  justify-content: end;
  row-gap: 12px;
  column-gap: 16px;
`;

const FormLabel = styled.div`
  font-weight: 600;
`;

const LoadingSelectContainer = styled.div`
  position: relative;
  min-width: 0;
`;

const LoadingSelectLoader = styled(Loader)`
  position: absolute;
  right: 24px;
  top: 9px;
  height 12px;
  width: 12px;
`;

type Props = {
  errors?: SourceInfoErrors;
  isSubmitted?: boolean;
  disabled?: boolean;
  sourceInfo?: Partial<CloudTable["source_info"]>;
  onChange: (cloudTable: Partial<CloudTable["source_info"]>) => void;
  authToken: string;
  mode: "create" | "update";
  setTopLevelError: (error: unknown) => void;
};

export const SourceInfoFormFields: React.FC<Props> = props => {
  const { googleApiClient } = useGoogleApiContext();

  const {
    disabled,
    onChange,
    errors,
    authToken,
    mode,
    isSubmitted,
    sourceInfo = {},
    setTopLevelError,
  } = props;

  const [range, setRange] = React.useState<string>(sourceInfo.google_sheets_range ?? "");

  const {
    data: spreadsheetData,
    isLoading: isLoadingSpreadsheetData,
    error: errorSpreadsheetData,
  } = useQuery(
    [QueryCacheKey.GOOGLE_API_SPREADSHEET_DATA, authToken, sourceInfo.google_drive_file_id],
    () => googleApiClient.getSpreadsheetData(authToken, sourceInfo.google_drive_file_id!),
    { enabled: Boolean(authToken && sourceInfo.google_drive_file_id) }
  );

  // Normally we shouldn't sync state like this, but in this specific case it
  // allows us to keep the form state local to this component while still
  // being able to display related errors higher up in the tree.
  useEffect(() => setTopLevelError(errorSpreadsheetData), [errorSpreadsheetData]);

  const handleOpenFilePicker = () => {
    if (authToken) {
      googleApiClient.openPicker({
        authToken,
        onSelect: files =>
          onChange({
            ...sourceInfo,
            google_drive_file_id: files[0].id,
            google_sheets_file_name: files[0].name,
            google_sheets_tab_id: undefined,
            google_sheets_tab_name: undefined,
            google_sheets_is_specific_range: undefined,
          }),
        onCancel: () => {},
      });
    }
  };

  const handleTabChange = (sheetId: number) => {
    const sheet = spreadsheetData?.result.sheets?.find(
      sheet => sheet.properties?.sheetId === sheetId
    );

    assertIsDefined(sheet);

    onChange({
      ...sourceInfo,
      google_sheets_tab_id: sheet.properties?.sheetId,
      google_sheets_tab_name: sheet.properties?.title,
    });
  };

  const handleIsRangeChange = (google_sheets_is_specific_range: boolean) => {
    onChange({
      ...sourceInfo,
      google_sheets_is_specific_range,
      google_sheets_range: google_sheets_is_specific_range ? range : undefined,
    });
  };

  const handleRangeChange = (google_sheets_range: string) => {
    onChange({
      ...sourceInfo,
      google_sheets_range,
    });
  };

  return (
    <>
      <TextWithIcon
        icon={<Source />}
        title={<Typography fontWeight="bold">Source Sheet</Typography>}
        action={
          sourceInfo.google_drive_file_id ? (
            <Button
              disabled={disabled || !authToken}
              noPadding
              type="link"
              size="small"
              onClick={() => handleOpenFilePicker()}
            >
              change
            </Button>
          ) : null
        }
      ></TextWithIcon>

      {sourceInfo.google_drive_file_id ? (
        <>
          <SheetInputContainer>
            <SheetInputField
              type="text"
              onClick={() => {
                if (sourceInfo.google_drive_file_id) {
                  const url = getCloudTableSpreadsheetSheetUrl(
                    sourceInfo.google_drive_file_id,
                    sourceInfo.google_sheets_tab_id
                  );

                  window.open(url, "_blank");
                }
              }}
            >
              <SheetInput>
                <SheetInputContent>
                  <Typography noWrap fontWeight={600}>
                    {sourceInfo.google_sheets_file_name}
                  </Typography>
                </SheetInputContent>

                <Button type="text" size="small" icon={<NewWindow />} />
              </SheetInput>
            </SheetInputField>

            {mode === "create" ? (
              <Typography color="textSecondary" variant="body3">
                Choose the tab and range that will become your dataset. Make sure a header row is
                included as the top row.
              </Typography>
            ) : null}
          </SheetInputContainer>

          <FormHorizontalGrid>
            <FormHorizontal>
              <FormLabel>Tab</FormLabel>

              <LoadingSelectContainer>
                <StyledSelect
                  placeholder={
                    errorSpreadsheetData ? "Failed accessing sheet..." : "Choose a tab..."
                  }
                  disabled={disabled || isLoadingSpreadsheetData || Boolean(errorSpreadsheetData)}
                  loading={disabled || isLoadingSpreadsheetData}
                  suffixIcon={<DownArrow />}
                  status={errors?.google_sheets_tab_id && isSubmitted ? "error" : undefined}
                  value={
                    isLoadingSpreadsheetData || Boolean(errorSpreadsheetData)
                      ? undefined
                      : sourceInfo.google_sheets_tab_id
                  }
                  options={spreadsheetData?.result.sheets?.map(sheet => ({
                    label: sheet.properties?.title,
                    value: sheet.properties?.sheetId,
                  }))}
                  onChange={sheetId => handleTabChange(sheetId as number)}
                />
                {isLoadingSpreadsheetData ? <LoadingSelectLoader size="small" /> : null}
              </LoadingSelectContainer>

              <FormLabel>Range</FormLabel>

              <StyledSelect
                placeholder="Choose a range..."
                suffixIcon={<DownArrow />}
                disabled={disabled || sourceInfo.google_sheets_tab_id === undefined}
                onChange={value => handleIsRangeChange(value as boolean)}
                status={
                  errors?.google_sheets_is_specific_range && isSubmitted ? "error" : undefined
                }
                value={sourceInfo.google_sheets_is_specific_range}
                options={[
                  {
                    label: "Entire Tab",
                    value: false,
                  },
                  {
                    label: "Specific Range",
                    value: true,
                  },
                ]}
              />

              {sourceInfo.google_sheets_is_specific_range ? (
                <>
                  <div />
                  <div>
                    <Input
                      disabled={disabled}
                      status={errors?.google_sheets_range && isSubmitted ? "error" : undefined}
                      placeholder="e.g. A1:D10"
                      onChange={e => {
                        setRange(e.target.value);
                      }}
                      onBlur={e => {
                        handleRangeChange(e.target.value);
                      }}
                      value={range}
                    />

                    {errors?.google_sheets_range === "invalid_range" && isSubmitted && !disabled ? (
                      <Typography color="error" variant="body3">
                        Invalid range
                      </Typography>
                    ) : null}
                  </div>
                </>
              ) : null}
            </FormHorizontal>
          </FormHorizontalGrid>
        </>
      ) : (
        <StyledPaperContentIndent>
          <Typography variant="body3" color="textSecondary">
            Choose your source sheet. This will be{" "}
            <Typography
              title="Creating a dataset will store your data in Coefficient's secure cloud."
              color="textSecondary"
              tooltipDelay={0}
              inline
              underline
            >
              cached as your data source
            </Typography>{" "}
            for you to use and share with your team.
          </Typography>

          <Button disabled={!authToken} block type="primary" onClick={() => handleOpenFilePicker()}>
            Choose Sheet
          </Button>
        </StyledPaperContentIndent>
      )}
    </>
  );
};
