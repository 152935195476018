import { InfoCircleFilled } from "@ant-design/icons";
import { P } from "@antv/g2plot";
import { SubscriptionUser, UserSubscriptionInfo } from "@coeff/api";
import React, { useContext, useEffect } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import {
  Breadcrumb,
  BreadcrumbItem,
  FlexColumn,
  FlexRow,
  Tooltip,
  Typography,
  UserList,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import {
  getIsGmailLikeDomainFromEmail,
  planNameFromType,
  pluralize,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection } from "../components";

export const DomainFamilyList = styled.ul`
  margin: 7px 0px 0px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    font-size: 14px;
    line-height: 7px;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-right: 80px;

    &:last-child {
      margin-bottom: 10px;
    }

    svg {
      margin-right: 9px;
      margin-top: 5px;
    }

    > div {
      flex: 1;
    }
  }
`;

const Container = styled.div`
  color: ${COLORS.black85};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UsersSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  maxwidth: 580px;
`;

const HelpSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .assignUsers {
    .ant-checkbox-wrapper {
      display: none;
    }

    .email {
      color: ${COLORS.coeblue4};
    }
  }
`;

export const TeamV2 = () => {
  const { basePath, billingMetadata, domain, domains_in_domain_family, currentUserEmail } =
    useContext(BillingContext) as BContext;

  const track = useTrack();

  const isGmailLikeDomain = getIsGmailLikeDomainFromEmail(currentUserEmail);

  const history = useHistory();

  const { path } = useRouteMatch();

  useEffect(() => {
    track("billing_team_viewed");
  }, []);

  useEffect(() => {
    if (isGmailLikeDomain) {
      history.replace(`${basePath}/subscription`);
    }
  }, [isGmailLikeDomain]);

  const usersInDomain = billingMetadata?.users_from_domain ?? [];

  let noPlanGroup: Array<UserSubscriptionInfo> = [];

  const groupedUsersInDomain = usersInDomain.reduce((acc, user) => {
    const managedByUserId = user.managed_by_user?.user_id;

    if (managedByUserId === undefined) {
      noPlanGroup.push(user);
    } else {
      if (acc[managedByUserId] === undefined) {
        acc[managedByUserId] = [];
      }

      const group = acc[managedByUserId];

      group.push(user);
    }

    return acc;
  }, {} as Record<string, Array<UserSubscriptionInfo>>);

  noPlanGroup = noPlanGroup.sort((a, b) => {
    const bName = `${b.user.first_name} ${b.user.last_name}`;
    const aName = `${a.user.first_name} ${a.user.last_name}`;

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const renderUserRole = (user: SubscriptionUser, planManagerId: string | undefined) => {
    let role = "";

    if (user.user_id === planManagerId) {
      role = "Plan Owner";
    }

    return (
      <Typography color="textSecondary" fontWeight={500}>
        {role}
      </Typography>
    );
  };

  const renderGroupedUsers = () => {
    let i = 0;

    const groupKeysWithPlan = Object.keys(groupedUsersInDomain).map(key => {
      const users = groupedUsersInDomain[key];

      const planType = users[0].plan_type!;

      return { key, planType };
    });

    const enterpriseKeys = groupKeysWithPlan
      .filter(item => item.planType === "enterprise")
      .map(item => item.key);
    const proKeys = groupKeysWithPlan.filter(item => item.planType === "pro").map(item => item.key);
    const plusKeys = groupKeysWithPlan
      .filter(item => item.planType === "plus")
      .map(item => item.key);
    const starterKeys = groupKeysWithPlan
      .filter(item => item.planType === "starter")
      .map(item => item.key);
    const otherKeys = groupKeysWithPlan
      .filter(
        item =>
          item.planType === "lifetime_free" ||
          item.planType === "free_trial" ||
          item.planType === "new_user"
      )
      .map(item => item.key);

    const sortedGroupKeys: Array<string> = [
      ...enterpriseKeys,
      ...proKeys,
      ...plusKeys,
      ...starterKeys,
      ...otherKeys,
    ];

    const groups = sortedGroupKeys.map(key => {
      const users = groupedUsersInDomain[key];

      const planType = users[0].plan_type!;

      const planOwner = users.find(u => u.user.user_id === key);

      const otherUsers = users
        .filter(u => u.user.user_id !== key)
        .sort((a, b) => {
          const bName = `${b.user.first_name} ${b.user.last_name}`;
          const aName = `${a.user.first_name} ${a.user.last_name}`;

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });

      const orderedUsers = otherUsers;

      if (planOwner) {
        otherUsers.unshift(planOwner);
      }

      return (
        <FlexColumn gap={0} key={`group-${key}`}>
          <Typography fontWeight={700} variant="body1">
            {`${planNameFromType(planType)} plan (${users.length} user${pluralize(users.length)})`}
          </Typography>
          {orderedUsers.map(user => {
            i++;
            return (
              <UserList
                key={i}
                avatarColor={avatarColors[(i + usersInDomain.length) % avatarColors.length]}
                name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type}
                footer={
                  <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                    <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                      {renderUserRole(user.user, key)}
                    </FlexRow>
                  </FlexRow>
                }
              />
            );
          })}
        </FlexColumn>
      );
    });

    return (
      <FlexColumn gap={2}>
        {groups}
        {noPlanGroup.length > 0 && (
          <FlexColumn gap={0} key={`group-no-plan`}>
            <Typography fontWeight={700} variant="body1">
              {`${noPlanGroup.length} user${pluralize(noPlanGroup.length)} not on a plan`}
            </Typography>
            {noPlanGroup.map(user => {
              i++;
              return (
                <UserList
                  key={i}
                  avatarColor={avatarColors[(i + usersInDomain.length) % avatarColors.length]}
                  name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type}
                />
              );
            })}
          </FlexColumn>
        )}
      </FlexColumn>
    );
  };

  const DomainFamily = () => {
    const domains = domains_in_domain_family;
    if (domains.length === 1) {
      return (
        <Typography fontSize="26px" fontWeight={700}>
          Users from {domains[0]}
        </Typography>
      );
    } else {
      return (
        <FlexRow gap={0}>
          <Typography fontSize="26px" fontWeight={700}>
            Users from your domains
          </Typography>

          <Tooltip
            variant="light"
            getPopupContainer={triggerNode => triggerNode}
            title={
              <div>
                <Bold>Domains</Bold>
                <DomainFamilyList>
                  {domains.map((domain, i) => (
                    <li key={`domain-${i}`}>{domain}</li>
                  ))}
                </DomainFamilyList>
              </div>
            }
          >
            <span style={{ paddingLeft: "10px", marginTop: "3px" }}>
              <InfoCircleFilled style={{ color: COLORS.black25 }} />
            </span>
          </Tooltip>
        </FlexRow>
      );
    }
  };
  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Account
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Your team</BreadcrumbItem>
      </Breadcrumb>

      <DomainFamily />
      <br />

      <Content>
        <UsersSection>
          <Section>{renderGroupedUsers()}</Section>
        </UsersSection>

        <HelpSection>
          <FAQSection />
        </HelpSection>
      </Content>
    </Container>
  );
};
