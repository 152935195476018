import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { AdminTableFooter, InputSearch, Table } from "../../../components";
import { COLORS, IMPORT_TYPE_NAMES } from "../../../constants";
import { CAContext } from "../../../types";
import { CoeffAdminContext } from "../CoeffAdmin";

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInputSearch = styled(InputSearch)`
  width: 100%;
  max-width: 360px;
  margin-right: 8px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  color: ${COLORS.black85};
  font-size: 9px;
`;

export const Domains = () => {
  const { loading, domains, selectDomain, getDomains } = useContext(CoeffAdminContext) as CAContext;
  const { url } = useRouteMatch();
  const [searchValue, setSearchValue] = useState<string>("");

  // disabled for performance issues
  // const getTagColor = (type: string) => {
  //   return type === "csv" ? "orange" : type === "google_sheets" ? "green" : "blue";
  // };

  const [page, setPage] = React.useState(0);

  const defaultPageSize = 100;

  const [offset, setOffset] = React.useState<number>(0);

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setOffset(page * defaultPageSize);
  };

  useEffect(() => {
    const params = {
      search_by: searchValue,
      offset: offset,
      page_size: defaultPageSize,
    };
    getDomains(params);
  }, [page, offset]);

  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      width: 200,
      showSorterTooltip: false,
      render: (domain: string) => (
        <Link
          to={`/superadmin/domainDetails?domain=${domain}`}
          onClick={() => selectDomain(domain)}
        >
          {domain}
        </Link>
      ),
    },
    {
      title: "Domain Family",
      dataIndex: "domain_family",
      key: "domain_family",
      width: 120,
      showSorterTooltip: false,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.created_date - b.created_date,
    },
    // disabled for performance issues
    // {
    //   title: "Imports",
    //   dataIndex: "imports",
    //   key: "imports",
    //   showSorterTooltip: false,
    //   width: 120,
    //   defaultSortOrder,
    //   sorter: (a, b) => {
    //     const aImports: { [key: string]: number } = a.imports;
    //     const bImports: { [key: string]: number } = b.imports;

    //     return (
    //       Object.values(aImports).reduce((v, tot) => tot + v, 0) -
    //       Object.values(bImports).reduce((v, tot) => tot + v, 0)
    //     );
    //   },
    //   render: (imports: object) => {
    //     return (
    //       <div>
    //         <Tooltip
    //           title={
    //             <div>
    //               {Object.keys(imports).map(key => (
    //                 <div key={key}>
    //                   <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
    //                   {imports[key]}
    //                 </div>
    //               ))}
    //             </div>
    //           }
    //         >
    //           <Button size="small">{Object.values(imports).reduce((v, tot) => tot + v, 0)}</Button>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Imports (7d)",
    //   dataIndex: "imports_7_days",
    //   key: "imports_7_days",
    //   showSorterTooltip: false,
    //   width: 120,
    //   sorter: (a, b) => {
    //     const aImports: { [key: string]: number } = a.imports_7_days;
    //     const bImports: { [key: string]: number } = b.imports_7_days;

    //     return (
    //       Object.values(aImports).reduce((v, tot) => tot + v, 0) -
    //       Object.values(bImports).reduce((v, tot) => tot + v, 0)
    //     );
    //   },
    //   render: (imports: object) => {
    //     return (
    //       <div>
    //         <Tooltip
    //           title={
    //             <div>
    //               {Object.keys(imports).map(key => (
    //                 <div key={key}>
    //                   <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
    //                   {imports[key]}
    //                 </div>
    //               ))}
    //             </div>
    //           }
    //         >
    //           <Button size="small">{Object.values(imports).reduce((v, tot) => tot + v, 0)}</Button>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Target Spreadsheets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   showSorterTooltip: false,
    //   sorter: (a, b) => a.targets - b.targets,
    //   width: 120,
    // },
    // {
    //   title: "Target Spreadsheets (7d)",
    //   dataIndex: "targets_7_days",
    //   key: "targets_7_days",
    //   showSorterTooltip: false,
    //   sorter: (a, b) => a.targets_7_days - b.targets_7_days,
    //   width: 120,
    // },
    // {
    //   title: "Scheduled Runs",
    //   dataIndex: "scheduled_runs",
    //   key: "scheduled_runs",
    //   showSorterTooltip: false,
    //   width: 120,
    //   sorter: (a, b) => {
    //     const aRuns: { [key: string]: number } = a.scheduled_runs;
    //     const bRuns: { [key: string]: number } = b.scheduled_runs;

    //     return (
    //       Object.values(aRuns).reduce((v, tot) => tot + v, 0) -
    //       Object.values(bRuns).reduce((v, tot) => tot + v, 0)
    //     );
    //   },
    //   render: (runs: object) => {
    //     return (
    //       <div>
    //         <Tooltip
    //           title={
    //             <div>
    //               {Object.keys(runs).map(key => (
    //                 <div key={key}>
    //                   <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
    //                   {runs[key]}
    //                 </div>
    //               ))}
    //             </div>
    //           }
    //         >
    //           <Button size="small">{Object.values(runs).reduce((v, tot = 0) => tot + v, 0)}</Button>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Alerts",
    //   dataIndex: "alerts",
    //   key: "alerts",
    //   showSorterTooltip: false,
    //   width: 120,
    //   sorter: (a, b) => {
    //     if (a.alerts && b.alerts) {
    //       const aAlerts: { [key: string]: number } = a.alerts;
    //       const bAlerts: { [key: string]: number } = b.alerts;

    //       return (
    //         Object.values(aAlerts).reduce((v, tot) => tot + v, 0) -
    //         Object.values(bAlerts).reduce((v, tot) => tot + v, 0)
    //       );
    //     }
    //     return 0;
    //   },
    //   render: (alerts: object) => {
    //     if (alerts) {
    //       return (
    //         <div>
    //           <Tooltip
    //             title={
    //               <div>
    //                 {Object.keys(alerts).map(key => (
    //                   <div key={key}>
    //                     <span style={{ color: COLORS.black25 }}>{key}:</span> {alerts[key]}
    //                   </div>
    //                 ))}
    //               </div>
    //             }
    //           >
    //             <Button size="small">{Object.values(alerts).reduce((v, tot) => tot + v, 0)}</Button>
    //           </Tooltip>
    //         </div>
    //       );
    //     } else {
    //       return "";
    //     }
    //   },
    // },
  ];

  const dataSource = domains.map(domain => ({
    key: domain.domain,
    domain: domain.domain,
    // disabled for performance issues
    // alerts: domain.total_alerts,
    // imports: domain.total_imports,
    // imports_7_days: domain.total_imports_7_days,
    // scheduled_runs: domain.total_scheduled_runs,
    // targets: domain.total_target_sheets,
    // targets_7_days: domain.total_target_sheets_7_days,
    domain_family: domain.domain_family,
    created_date: domain.created_date,
  }));

  const total_domains = (domains?.length > 0 ? domains[0].total_domains : undefined) ?? 0;

  const locale = {
    emptyText: "No Domains",
  };

  const handleEnter = (e, searchValue) => {
    if (e.key === "Enter") {
      setSearchValue(searchValue);
      setPageAndOffset(0);
      const params = {
        search_by: searchValue,
        offset: offset,
        page_size: defaultPageSize,
      };
      getDomains(params);
    }
  };

  const handleClear = () => {
    setSearchValue("");
    setPageAndOffset(0);
    const params = {
      offset: offset,
      page_size: defaultPageSize,
    };
    getDomains(params);
  };

  return (
    <Wrapper>
      <div style={{ flex: "1" }}>
        <StyledInputSearch
          onKeyUp={e => handleEnter(e, searchValue)}
          onChange={e => setSearchValue(e.target.value)}
          allowClear={{
            clearIcon: <StyledCloseOutlined onClick={handleClear} />,
          }}
          value={searchValue}
        />
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
      />
      {
        <AdminTableFooter
          page={page}
          pageSize={defaultPageSize}
          onPageChange={page => setPageAndOffset(page)}
          data={dataSource.map(x => [x.domain ?? "", true])}
          totalRowCount={total_domains}
        />
      }
    </Wrapper>
  );
};
