import { Form as FormAD, type FormProps } from "antd";
import * as React from "react";
import styled from "styled-components";

const StyledForm = styled(FormAD)``;

const StyledFormItem = styled(FormAD.Item)``;

// export const CreateForm = ({ component, ...props }) => FormAD.create({ ...props })(component);

export const Form = ({ children, ...props }: { children: React.ReactNode } & FormProps) => (
  <StyledForm {...props}>{children}</StyledForm>
);

export const FormItem = ({ children, ...props }) => (
  <StyledFormItem {...props}>{children}</StyledFormItem>
);
