export const updateArray = ({ array, index, update }) => {
  return [
    ...array.slice(0, index),
    {
      ...array[index],
      ...update,
    },
    ...array.slice(index + 1),
  ];
};

export const uniqueArrayByKey = (array, key) => {
  const flags = {};
  return array.filter(item => {
    if (flags[item[key]]) {
      return false;
    }
    flags[item[key]] = true;
    return true;
  });
};

export const capitalize = (s: any) => {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const columnToLetter = (column: number): string => {
  let temp;
  let letter = "";

  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }

  return letter;
};

export const getUserEmail = (user: { email?: string }): string => {
  if (!user) {
    return "";
  }
  return user.email ?? "";
};

export const stringSearch = (str?: string, query?: string): boolean => {
  if (!str) {
    return false;
  }
  return (query || "")
    .trim()
    .split(" ")
    .every(x => str?.toLowerCase().indexOf(x.toLowerCase()) !== -1);
};

// email-regex: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const isValidEmail = (str: string) =>
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
    str
  );

export const formatNumberToUnits = (num: number, spacer = "", precision = 0) => {
  const units = ["", "k", "M", "B", "T", "Q"];
  const unrangifiedOrder = Math.floor((Math.log(Math.abs(num)) * Math.LOG10E) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, units.length - 1));
  const suffix = units[order];

  return (num / Math.pow(10, order * 3)).toFixed(precision) + spacer + suffix;
};

export const orderByString = <T = any>(array: T[], key: string) => {
  return array.sort((x, y) => (x[key] ?? "").localeCompare(y[key] ?? ""));
};

function generateRowString(matches: string[], remaining?: number) {
  let rowString = matches.length === 1 && matches[0].length === 1 ? "Row" : "Rows";

  if (matches.length === 3) {
    if (remaining) {
      rowString = `${rowString} ${matches.join(", ")} and ${remaining} more`;
    } else {
      rowString = `${rowString} ${matches[0]}, ${matches[1]}, and ${matches[2]}`;
    }
  } else if (matches.length === 2) {
    rowString = `${rowString} ${matches[0]} and ${matches[1]}`;
  } else {
    rowString = `${rowString} ${matches[0]}`;
  }

  return rowString;
}

export const computeSelectedRowsText = (selectedRowIndices: number[]) => {
  // Objective - given a list of indices, print the consecutive pairs up to 3 max
  let consecutiveMatches: string[] = [];
  let rowStartIndex = 0;
  let remainingRows = 0;

  for (let j = 0; j < selectedRowIndices.length; j++) {
    const row = selectedRowIndices[j] + 1;
    const rowStart = selectedRowIndices[rowStartIndex] + 1;

    // only one row, return immediately
    if (selectedRowIndices.length === 1) {
      consecutiveMatches = [String(row)];
      break;
    }

    // if last row, find matches and break
    // - last row is part of match and
    // - last row is a different match
    if (j === selectedRowIndices.length - 1) {
      // 15, 15  => 15
      if (row === rowStart) {
        consecutiveMatches.push(String(row));
        break;
      }

      const previousRow = selectedRowIndices[j - 1] + 1;

      if (row - previousRow > 1) {
        if (rowStart === previousRow) {
          consecutiveMatches.push(String(rowStart));
          consecutiveMatches.push(String(row));
        } else {
          consecutiveMatches.push(`${rowStart}-${previousRow}`);
          consecutiveMatches.push(String(row));
        }
      } else {
        consecutiveMatches.push(`${rowStart}-${row}`);
      }

      break;
    }

    const nextRow = selectedRowIndices[j + 1] + 1;
    // if this and the last row have a delta greater than 1, match found
    if (nextRow - row > 1) {
      if (rowStart === row) {
        consecutiveMatches.push(String(row));
      } else {
        consecutiveMatches.push(`${rowStart}-${row}`);
      }

      // set the next match start
      rowStartIndex = j + 1;
    }

    // hit the 3 match limit, return immmediately
    if (consecutiveMatches.length >= 3) {
      remainingRows = selectedRowIndices.length - j - 1;
      break;
    }
  }

  return generateRowString(consecutiveMatches, remainingRows);
};
