import { DataImportSchedule } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import { Button, Paper, PaperContent, Typography, TextWithIcon, DateIcon } from "../../components";
import { COLORS } from "../../constants";

import { ScheduleText } from "./ScheduleText";

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  disabled?: boolean;
  schedule?: DataImportSchedule;
  onEditClick: () => void;
};

export const Schedule: React.FC<Props> = props => {
  const { onEditClick, schedule, disabled } = props;

  return (
    <Paper>
      <StyledPaperContent size="small">
        <TextWithIcon
          icon={<DateIcon fill={COLORS.black85} />}
          title={<Typography fontWeight="bold">Schedule</Typography>}
          action={
            <Button disabled={disabled} type="link" size="small" onClick={() => onEditClick()}>
              edit
            </Button>
          }
        ></TextWithIcon>

        {schedule ? <ScheduleText schedule={schedule} /> : undefined}
      </StyledPaperContent>
    </Paper>
  );
};
