import {
  DefaultApi,
  DefaultApiGetDataImportRunRequest,
  DefaultApiGetDataImportRunsRequest,
  DomainSettings,
} from "@coeff/api-admin-ext";
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useApiContext } from "../utils";

import { QueryCacheKey } from ".";

export const fetchDataImportRunById = async (
  api: DefaultApi,
  params: DefaultApiGetDataImportRunRequest
) => {
  const response = await api.getDataImportRun(params);
  return response.data.data_import_run;
};

export const fetchDataImportRunsById = async (
  api: DefaultApi,
  params: DefaultApiGetDataImportRunsRequest
) => {
  const response = await api.getDataImportRuns(params);
  return response.data;
};

const fetchAdminExtSettings = async (api: DefaultApi) => {
  const response = await api.getSettings();
  return response.data.admin_ext_settings;
};

export const useAdminExtSettings = ({ enabled }: UseQueryOptions<DomainSettings>) => {
  const { adminExtApiClient: api } = useApiContext();

  return useQuery([QueryCacheKey.ADMIN_EXT_SETTINGS], () => fetchAdminExtSettings(api), {
    enabled,
  });
};

export const useUpdateAdminExtSettings = () => {
  const { adminExtApiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (payload: DomainSettings) => {
    const response = await api.modifySettings({
      modify_settings_request: { admin_ext_settings: payload },
    });
    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryCacheKey.ADMIN_EXT_SETTINGS]);
    },
  });
};

export const useDataImportRun = (data_import_run_id: string, { enabled }: UseQueryOptions = {}) => {
  const { adminExtApiClient: api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DATA_IMPORT_RUN_BY_ID, data_import_run_id],
    () => fetchDataImportRunById(api, { data_import_run_id }),
    {
      enabled,
    }
  );
};

export const useDataImportRunsById = (
  params: DefaultApiGetDataImportRunsRequest,
  { enabled }: UseQueryOptions = {}
) => {
  const { adminExtApiClient: api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DATA_IMPORT_RUN_BY_ID, params],
    () => fetchDataImportRunsById(api, params),
    {
      enabled,
    }
  );
};
