import { UserExtra } from "@coeff/api";
import { Reducer } from "redux";

import { ACTIONS } from "../constants";

interface UserSettingsState {
  user_settings?: UserExtra;
  loading: boolean;
}

const initialState: UserSettingsState = {
  user_settings: undefined,
  loading: true,
};

export const userSettings: Reducer<UserSettingsState> = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.USER_SETTINGS_REQUEST_PENDING:
      return { ...state, loading: true };

    case ACTIONS.SAVE_USER_SETTINGS_SUCCESS:
      return { ...state, loading: false };

    case ACTIONS.SAVE_USER_SETTINGS_FAILED:
      return { ...state, loading: false };

    case ACTIONS.FETCH_USER_SETTINGS_SUCCESS:
      if (action.payload.user_extra && action.payload.user_extra.user_settings) {
        return { user_settings: action.payload.user_extra, loading: false };
      }
      return { ...state, loading: false };

    case ACTIONS.FETCH_USER_SETTINGS_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
