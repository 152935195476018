import { config } from "../config";

// In local development, test using your ngrok url. You also have to
// allow it as a redirect url in Google Console  e.g.:
// const BASE_URL = "https://5440-2605-59c8-22f4-c810-6c65-d9b1-1eba-749b.ngrok-free.app";

const BASE_URL = config.BASE_URL;

export const submitCloudTablesOAuthForm = ({
  email,
  redirectPath,
}: {
  email: string;
  redirectPath: string;
}) => {
  const form = document.createElement("form");

  form.setAttribute("method", "GET");

  form.setAttribute("action", "https://accounts.google.com/o/oauth2/v2/auth");

  const params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${BASE_URL}/api/auth/cloud_table_auth_callback`,
    response_type: "code",
    scope: "https://www.googleapis.com/auth/drive",
    state: JSON.stringify({
      redirect_url: `${BASE_URL}${redirectPath}`,
      email,
    }),
    access_type: "offline",
    prompt: "consent",
  };

  for (const p in params) {
    const input = document.createElement("input");

    input.setAttribute("type", "hidden");

    input.setAttribute("name", p);

    input.setAttribute("value", params[p]);

    form.appendChild(input);
  }

  document.body.appendChild(form);

  form.submit();
};
