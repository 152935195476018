import { CheckCircleFilled } from "@ant-design/icons";
import { LookerConnectionEmailTriggerOptions } from "@coeff/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  ImportTypeIcon,
  Button,
  Typography,
  CoeLogo,
  message,
  Loader,
  FlexRow,
  FlexColumn,
} from "../components";
import { COLORS } from "../constants";
import {
  assertIsDefined,
  DataSourceTypeDisplayNames,
  getBaseUrl,
  sentryCapture,
  useApiContext,
  useTrack,
} from "../utils";

const WholePageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 25px 30px;
  max-width: 1080px;
  margin: 0 auto;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  max-width: 466px;
`;

const PageHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin: 24px;
`;

const CoeLogoContainer = styled.div`
  padding-left: 16px;
`;

const StyledParagraph = styled(Typography)`
  color: ${COLORS.black45};
  text-align: center;
`;

const Tag = styled(Typography)`
  background: ${COLORS.yellow};
  padding: 1px 12px;
  border-radius: 4px;
`;

const IconWrap = styled.div<{ height?: number; width?: number }>`
  .ant-avatar {
    &.ant-avatar-lg {
      height: ${({ height }) => height || 40}px !important;
      width: ${({ width }) => width || 40}px !important;

      svg {
        height: ${({ height }) => height || 40}px !important;
        width: ${({ width }) => width || 40}px !important;
      }
    }
  }

  .anticon {
    height: ${({ height }) => height || 40}px !important;
    width: ${({ width }) => width || 40}px !important;

    svg {
      height: ${({ height }) => height || 40}px !important;
      width: ${({ width }) => width || 40}px !important;
    }
  }
`;

type LocationState = {
  name: string;
  uuid: string;
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  isAdmin: boolean;
};
type Views = "oauth" | "skip" | "success";

const mandatoryParams = ["uuid", "baseUrl", "clientId", "clientSecret", "isAdmin", "name"];

const hasMandatoryParams = (state: Record<string, any>) =>
  mandatoryParams.every(
    param => state && state[param] !== undefined && state[param] !== null && state[param] !== ""
  );

export const CompleteLookerSetup: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const track = useTrack();
  const timerId = useRef<NodeJS.Timeout>();

  const { apiClient: api } = useApiContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [view, setView] = useState<Views>("oauth");

  if (!hasMandatoryParams(location.state)) {
    history.goBack();
    clearTimeout(timerId.current);
  }

  const { uuid, baseUrl, clientId, clientSecret, isAdmin, name } = location.state;

  const expireThePage = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      history.replace({ ...location, state: {} });
    }, 5000);
  };

  const onClientIdConnectSuccess = () => {
    track("looker_setup_completed", {
      looker_request_access_uuid: uuid,
    });
    messageApi.open({
      content: (
        <FlexRow style={{ alignItems: "start" }}>
          <CheckCircleFilled style={{ color: COLORS.green }} />
          <FlexColumn style={{ alignItems: "start" }} gap={0}>
            <Typography fontWeight={700} align="left">
              Connection Successful
            </Typography>
            <Typography align="left">
              We've sent the connection details to <b>{name}</b>
            </Typography>
          </FlexColumn>
        </FlexRow>
      ),
    });
    setView("skip");
    expireThePage();
  };

  const onOauthConnectSuccess = () => {
    track("looker_setup_completed", {
      looker_request_access_uuid: uuid,
      is_looker_admin: true,
    });
    messageApi.success("Connection Successful");
    setView("success");
    expireThePage();
  };

  useEffect(() => {
    document.title = "Looker Setup - Coefficient";
    if (!isAdmin) {
      setView("skip");
      onClientIdConnectSuccess();
    }
  }, [isAdmin]);

  useQuery({
    queryKey: [QueryCacheKey.LOOKER_REQUEST_INFO_BY_UUID, uuid],
    queryFn: async () => {
      assertIsDefined(uuid);
      const response = await api.lookerFetchUrlBasedAuthDetails({
        looker_fetch_url_based_auth_details_request: {
          attached_uuid: uuid,
        },
      });

      if (response.data.is_expired) {
        throw new Error("Looker request has expired");
      }
      return response.data;
    },
    cacheTime: 0,
    staleTime: 0,
    retry: 2,
    enabled: Boolean(uuid),
    onError: (err: Error) => {
      message.error(err.message || "Failed to fetch Looker request details");
      sentryCapture({
        error: err,
        name: "LookerCompleteSetup: GET /api/data_sources/looker/url_based_auth_details",
      });
      history.goBack();
    },
  });

  const oauthMutation = useMutation({
    mutationFn: async () => {
      await api.upsertLookerAppInfo({
        upsert_looker_app_info_request: {
          base_url: getBaseUrl(baseUrl),
          client_id: clientId,
          client_secret: clientSecret,
          url_based_auth_uuid: uuid,
        },
      });
      await api.triggerLookerConnEmail({
        trigger_looker_conn_email_request: {
          attached_uuid: uuid,
          trigger_type: LookerConnectionEmailTriggerOptions.ConnCreated,
        },
      });
    },
    onSuccess: () => {
      onOauthConnectSuccess();
    },
    onError: error => {
      sentryCapture({
        error,
        name: "LookerCompleteSetup: POST /api/data_sources/looker/apps/upsert",
      });
      messageApi.error("Failed to connect");
    },
  });

  const clientIdMutation = useMutation({
    mutationFn: async () => {
      await api.createDataSource({
        create_data_source_request: {
          data_source: {
            credentials: {
              client_id: clientId,
              client_secret: clientSecret,
              url: baseUrl,
              remote_auth_url_uuid: uuid,
            },
            data_source_type: "looker",
            data_source_name: DataSourceTypeDisplayNames.looker,
          },
        },
      });

      await api.triggerLookerConnEmail({
        trigger_looker_conn_email_request: {
          attached_uuid: uuid,
          trigger_type: LookerConnectionEmailTriggerOptions.ConnCreated,
        },
      });
    },
    onSuccess: () => {
      onClientIdConnectSuccess();
    },
    onError: error => {
      sentryCapture({
        error,
        name: "LookerCompleteSetup: POST /api/data_sources",
      });
      messageApi.error("Failed to connect");
    },
  });

  const getContent = (view: Views) => {
    switch (view) {
      case "oauth":
        return (
          <Content>
            <IconWrap height={60} width={60}>
              <ImportTypeIcon type="looker" />
            </IconWrap>

            <Tag fontWeight={700} fontSize={"12px"} lineHeight={"14px"}>
              Recommended
            </Tag>
            <Typography fontSize="28px" lineHeight={"32px"} fontWeight={700}>
              Enable OAuth for your domain
            </Typography>
            <StyledParagraph fontWeight={500} fontSize="16px" lineHeight={"20px"}>
              Allows users from your domain login to Looker using their own username and password.
            </StyledParagraph>
            <Button
              type="primary"
              style={{ minWidth: 140, marginTop: 40, cursor: "pointer" }}
              disabled={oauthMutation.isLoading || clientIdMutation.isLoading}
              onClick={() => oauthMutation.mutate()}
            >
              {oauthMutation.isLoading ? (
                <FlexRow>
                  <Loader
                    color="white"
                    size="micro"
                    style={{
                      position: "relative",
                      top: "2px",
                    }}
                  />
                </FlexRow>
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              style={{ minWidth: 140, cursor: "pointer" }}
              type="link"
              disabled={oauthMutation.isLoading || clientIdMutation.isLoading}
              onClick={() => clientIdMutation.mutate()}
            >
              {clientIdMutation.isLoading ? (
                <Loader
                  size="micro"
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                />
              ) : (
                "Not right now"
              )}
            </Button>
          </Content>
        );
      case "skip":
        return (
          <Content>
            <IconWrap height={60} width={60}>
              <ImportTypeIcon type="looker" />
            </IconWrap>

            <Typography fontSize="28px" lineHeight={"32px"} fontWeight={700}>
              That’s all for now
            </Typography>

            <StyledParagraph fontWeight={500} fontSize="16px" lineHeight={"20px"}>
              We’ve notified <b>{name}</b> that the connection is set up.
            </StyledParagraph>
          </Content>
        );

      case "success":
        return (
          <Content>
            <IconWrap height={60} width={60}>
              <CheckCircleFilled style={{ color: COLORS.green }} />
            </IconWrap>

            <Typography fontSize="28px" lineHeight={"32px"} fontWeight={700}>
              OAuth enabled for your domain
            </Typography>
            <StyledParagraph fontWeight={500} fontSize="16px" lineHeight={"20px"}>
              We have notified <b>{name}</b> that OAuth configurations have been added. The user can
              login into Looker from Coefficient sidebar to create there connection.
            </StyledParagraph>
          </Content>
        );
    }
  };

  return (
    <WholePageLayout>
      <PageHeader>
        <CoeLogoContainer>
          <CoeLogo />
        </CoeLogoContainer>
      </PageHeader>
      {contextHolder}
      <Container>{getContent(view)}</Container>
    </WholePageLayout>
  );
};
