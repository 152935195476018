import { Column, DataImport, Filter, Sort } from "@coeff/api";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";
import { DATE_FILTER_OPTIONS, FILTER_OPERATORS } from "../constants/filters";

import { Card } from "./Card";

const StyledPanel = styled.div`
  margin-left: 10px;
`;

const Row = styled.div`
  margin: 8px 0px;
`;
const Bold = styled.span`
  font-weight: bold;
`;

const getColumnFriendlyLabel = (
  columnName: string | undefined,
  labelRef: { [label: string]: string } | undefined
): string => {
  return columnName ? (labelRef && labelRef[columnName]) ?? columnName : "";
};

type FiltersAndSortsProps = {
  columns?: Column[];
  dataImport?: DataImport;
  filters?: Filter[];
  sorts?: Sort[];
  limit?: number;
};

export const FiltersAndSorts = (props: FiltersAndSortsProps) => {
  const labelRef = (props.columns ?? []).reduce<{ [name: string]: string }>((obj, col) => {
    if (col.column_name && col.alias) {
      obj[col.column_name] = col.alias;
    }
    return obj;
  }, {});

  const limit = props.limit;

  return (
    <Card>
      <StyledPanel>
        {(props.filters ?? []).map((filter, i) => {
          let operandDisplay: string | undefined = (filter.operands ?? [])
            .map(
              operand =>
                operand.text ??
                operand.number ??
                operand.boolean?.toString() ??
                operand.cell_ref_a1 ??
                moment(operand.date).format("MM/DD/YYYY")
            )
            .join(" - ");

          if (filter.relative_date_filter_type) {
            const dataFilter = DATE_FILTER_OPTIONS.find(
              df =>
                filter.relative_date_filter_type &&
                df.dateFilterTypes.includes(filter.relative_date_filter_type)
            );
            operandDisplay = (() => {
              switch (dataFilter?.dataKey) {
                case "last":
                case "next":
                  return `${
                    dataFilter?.dataKey
                  } ${operandDisplay} ${filter.relative_date_filter_type.split("_").pop()}`;
                case "exact_dates":
                case "exact_date":
                  return operandDisplay;
                default:
                  return dataFilter?.label;
              }
            })();
          }

          return (
            <Row key={i}>
              <Bold>{getColumnFriendlyLabel(filter.column_name, labelRef)}</Bold>{" "}
              {FILTER_OPERATORS.find(op => op.dataKey === filter.operator_type)?.label}{" "}
              <Bold>{operandDisplay}</Bold>
            </Row>
          );
        })}

        {!props.filters ||
          (props.filters.length === 0 && props.sorts && props.sorts.length > 0 && (
            <Row style={{ color: COLORS.black45 }}>No filters</Row>
          ))}

        {(props.sorts ?? []).map((sort, i) => (
          <Row key={i} className="coeff__sort-summary">
            Sort: <Bold>{getColumnFriendlyLabel(sort.column_name, labelRef)}</Bold> (
            {sort.sort_type === "asc" ? "↑ ASC" : "↓ DESC"})
          </Row>
        ))}

        {!!limit && (
          <Row>
            Limit: <Bold>{limit}</Bold> rows
          </Row>
        )}

        {(!props.filters || !props.filters.length) &&
          (!props.sorts || !props.sorts.length) &&
          !limit && <Row style={{ color: COLORS.black45 }}>No filters or sorts applied</Row>}
      </StyledPanel>
    </Card>
  );
};
