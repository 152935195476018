import * as React from "react";

import { Amex, Mastercard, OtherCard, Visa } from "./Icons";

export const CardIcon = ({ type }: { type: string }) => {
  switch (type) {
    case "visa":
      return <Visa />;
    case "mastercard":
      return <Mastercard />;
    case "american_express":
      return <Amex />;
    default:
      return <OtherCard />;
  }
};
