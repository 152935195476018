import { ACTIONS } from "../constants";
import {
  ExtAdminAlert,
  ExtAdminDataImport,
  ExtAdminDataImportRun,
  ExtAdminDomainUser,
  AdminDataSource,
} from "../types";

interface ReducerProps {
  loading: boolean;
  dataImports: ExtAdminDataImport[];
  dataImportRuns: ExtAdminDataImportRun[];
  domainUsers: ExtAdminDomainUser[];
  domainAlerts: ExtAdminAlert[];
  domainDataSources: AdminDataSource[];
  totalRecords: number;
}

const initialState = {
  loading: false,
  dataImportRuns: [],
  dataImports: [],
  domainUsers: [],
  domainAlerts: [],
  domainDataSources: [],
  totalRecords: 0,
} as ReducerProps;

export const extAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ACTIONS.SET_DATA_IMPORT_RUNS: {
      const { dataImportRuns, totalRecords } = action;
      return { ...state, loading: false, dataImportRuns, totalRecords };
    }

    case ACTIONS.SET_DATA_IMPORTS: {
      const { dataImports, totalRecords } = action;
      return { ...state, loading: false, dataImports, totalRecords };
    }

    case ACTIONS.SET_DOMAIN_USERS_EXT: {
      const { domainUsers, totalRecords } = action;
      return { ...state, loading: false, domainUsers, totalRecords };
    }

    case ACTIONS.SET_DOMAIN_ALERTS_EXT: {
      const { domainAlerts, totalRecords } = action;
      return { ...state, loading: false, domainAlerts, totalRecords };
    }

    case ACTIONS.SET_DOMAIN_DATA_SOURCES_EXT: {
      const { domainDataSources, totalRecords } = action;
      return { ...state, loading: false, domainDataSources, totalRecords };
    }

    default:
      return state;
  }
};
