import { InfoCircleFilled } from "@ant-design/icons";
import { ModalProps, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { COLORS } from "../constants";
import { AppState } from "../store";

import { BulletCheckList } from "./BulletCheckList";

import { Button, Modal, Share, Typography } from ".";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

const TextHighlight = styled.span`
  background: ${COLORS.orangeBg2};
`;

const ModalActions = styled.div`
  margin-top: 24px;
`;

type ConnectProps = {
  domains_in_domain_family: Array<string>;
};

type Props = ModalProps & ConnectProps;

const ShareWithTeamModalBase: React.FC<Props> = ({ domains_in_domain_family, ...modalProps }) => {
  const DomainFamily = () => {
    if (domains_in_domain_family.length === 1) {
      return <TextHighlight>{domains_in_domain_family[0]}</TextHighlight>;
    }

    return (
      <div>
        <TextHighlight>one of your domains</TextHighlight>

        <Tooltip
          title={
            <div>
              <Typography fontWeight="bold" style={{ color: "white" }}>
                Domains
              </Typography>

              <div>
                {domains_in_domain_family.map(domain => (
                  <div key={domain}>{domain}</div>
                ))}
              </div>
            </div>
          }
        >
          <InfoCircleFilled style={{ color: COLORS.black25, marginLeft: 4 }} />
        </Tooltip>
      </div>
    );
  };

  return (
    <Modal {...modalProps} width={260}>
      <div>
        <TitleContainer>
          <Share />

          <Typography fontWeight="bold" variant="h6">
            Sharing Datasets
          </Typography>
        </TitleContainer>

        <BulletCheckList
          items={[
            <>
              A team member is anyone using Coefficient with an email from <DomainFamily />
            </>,

            <>
              Sharing allows team members to import data from the dataset without having to access
              the sheet or data source
            </>,

            <>They won't be able to edit the dataset, only use it</>,
          ]}
        />

        <ModalActions>
          <Button type="text" onClick={e => modalProps.onCancel?.(e)}>
            Got it
          </Button>
        </ModalActions>
      </div>
    </Modal>
  );
};

export const ShareWithTeamModal = connect(
  ({ app }: AppState): ConnectProps => ({ domains_in_domain_family: app.domains_in_domain_family })
)(ShareWithTeamModalBase);
