import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import { AddonPlatform } from "@coeff/api";
import { Dropdown, Menu, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../../../actions";
import { useAdminDataImportRuns } from "../../../api/dataImportRuns";
import {
  AdminTableFooter,
  ExcelSheetsIcon,
  Failed,
  GoogleSheetsIcon,
  ImportTypeIcon,
  LoaderWithPerfTimings,
  NewWindow,
  Ok,
  Table,
  TableDropdown,
  Typography,
} from "../../../components";
import { COLORS, IMPORT_TYPE_NAMES, KEY_LABEL } from "../../../constants";
import { AppState } from "../../../store";
import { EAContext } from "../../../types";
import { DataSourceTypeDisplayNames } from "../../../utils";
import { ExtAdminContext } from "../ExtAdmin";

import { SQLQueryTooltip } from "./SQLQueryTooltip";

const Wrapper = styled.div`
  .statusIcon {
    position: relative;
    top: 3px;
    margin-right: 3px;
  }

  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }
`;

const ImportSourceCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
  margin-left: -8px;
`;

const FileCellWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
  min-width: 140;
  margin-left: -8px;

  svg {
    flex: 0 0 auto;
    height: 32px;
    width: 32px;
  }
`;

const SubTableWrap = styled.div`
  padding: 1rem;
  box-sizing: border-box;

  strong {
    font-weight: bolder !important;
  }

  & > div {
    display: inline-block;
    margin-right: 25px;
  }

  & > div:first-child {
    margin-left: 35px;
  }
`;

const MenuItem = styled.div`
  margin: 7.5px 15px;
  max-width: 300px;
`;

const Status = styled.div`
  white-space: nowrap;
`;

const RowCols = styled.div`
  display: flex;
  gap: 12px;
  margin: 0px 4px;

  div {
    text-align: right;
    flex: 1;

    &:nth-child(2) {
      border-left: 1px solid ${COLORS.black9};
    }
  }
`;

const RecentRuns = styled.p`
  font-size: 15px;
  font-weight: 500;
`;

export const DataImportRuns = () => {
  const { domains_in_domain_family: domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const { domain } = useContext(ExtAdminContext) as EAContext;

  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "created_dt",
    value: SortDirection.descend,
  });

  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [page, setPage] = React.useState(0);

  const dataImportRunPageSize = 100;

  const [dataImportRunPageOffset, setDataImportRunPageOffset] = useState<number>(0);

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setDataImportRunPageOffset(page * dataImportRunPageSize);
  };

  const cleanUpFilters = (filters: Array<{ columnKey: string; values: string[] }>) => {
    // remove filters with values having ~empty~ as first value
    return filters.map(f => (f.values[0] === "~empty~" ? { ...f, values: [] } : f));
  };

  const { data: dataDataImportRuns, isLoading } = useAdminDataImportRuns({
    domain: domain === "all_domains" ? domains_in_domain_family.toString() : domain,
    pageSize: dataImportRunPageSize,
    offset: dataImportRunPageOffset,
    sortColumn: sortedColumn,
    filters: JSON.stringify(cleanUpFilters(filters) ?? []),
  });

  const dataImportRuns = dataDataImportRuns?.dataImportRuns;

  const getFilterValues = (dataKey: string) => {
    const filter = filters.find(f => f.columnKey === dataKey);
    return filter ? filter["values"] : [];
  };

  const deleteFilter = (dataKey: string) =>
    setFilters(filters.filter(f => f.columnKey !== dataKey));

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowDownOutlined />;
      } else {
        return <ArrowUpOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const expandedRowRender = row => (
    <SubTableWrap>
      <div>
        <strong>Source: </strong>
        {row.display_data_source_details_as_code ? (
          <Dropdown
            overlay={
              <Menu>
                <MenuItem>
                  <SQLQueryTooltip dataImportRunId={row.data_import_run_id}></SQLQueryTooltip>
                </MenuItem>
              </Menu>
            }
            placement="bottomRight"
          >
            <a>SQL Query</a>
          </Dropdown>
        ) : ["csv", "google_sheets"].includes(row.data_source_type) ? (
          <a href={row.data_source_url} target="_blank">
            {row.data_source_details} <NewWindow fill={COLORS.black25} />
          </a>
        ) : (
          <span>{row.data_source_details}</span>
        )}
      </div>
      <div>
        <strong>Import time:</strong> {Math.round(row.run_duration_sec)} seconds
      </div>
    </SubTableWrap>
  );

  const columns = [
    {
      title: KEY_LABEL["data_import_name"],
      dataIndex: "data_import_name",
      key: "data_import_name",
      showSorterTooltip: false,
      width: 150,
    },
    {
      title: <Typography noWrap>{KEY_LABEL["google_sheets_file_name"]}</Typography>,
      dataIndex: "google_sheets_file_name",
      key: "google_sheets_file_name",
      showSorterTooltip: false,
      width: 150,
      render: (d, row) => {
        if (row.import_target_platform === AddonPlatform.GoogleSheets) {
          return (
            <FileCellWrap>
              <GoogleSheetsIcon style={{ verticalAlign: "middle" }} />
              <Typography noWrap lineClamp={2} style={{ verticalAlign: "middle" }}>
                {d || "-"}
              </Typography>
            </FileCellWrap>
          );
        } else {
          return (
            <FileCellWrap>
              <ExcelSheetsIcon />
              <Typography noWrap lineClamp={2}>
                {d || "-"}
              </Typography>
            </FileCellWrap>
          );
        }
      },
    },

    {
      title: <Typography noWrap>{KEY_LABEL["created_by"]}</Typography>,
      dataIndex: "created_by",
      key: "created_by",
      showSorterTooltip: false,
      width: 150,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataImportRuns ?? []}
          hideFilters={false}
          disableSort={true}
          dataKey="created_by"
          filters={filters}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["created_by"] === value,
      filteredValue: getFilterValues("created_by"),
      filterIcon: () => dropDownIcon("created_by"),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["data_source_type"]}</Typography>,
      dataIndex: "data_source_type",
      key: "data_source_type",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataImportRuns ?? []}
          dataKey="data_source_type"
          hideFilters={true}
          filters={filters}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
          valueRenderer={type => IMPORT_TYPE_NAMES[type]}
        />
      ),
      onFilter: (value, record) => record["data_source_type"] === value,
      filteredValue: getFilterValues("data_source_type"),
      filterIcon: () => dropDownIcon("data_source_type"),
      sorter: (a, b) => a.data_source_type?.localeCompare(b.data_source_type),
      sortOrder: sortedColumn.columnKey === "data_source_type" ? sortedColumn.value : null,
      render: d => (
        <ImportSourceCellWrap>
          <ImportTypeIcon size={"default"} type={d} />
          <Typography noWrap>{DataSourceTypeDisplayNames[d]}</Typography>
        </ImportSourceCellWrap>
      ),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["run_complete_dt"]}</Typography>,
      dataIndex: "run_complete_dt",
      key: "run_complete_dt",
      showSorterTooltip: false,
      width: 160,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataImportRuns ?? []}
          dataKey="run_complete_dt"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filterIcon: () => dropDownIcon("run_complete_dt"),
      sorter: (a, b) =>
        a.run_complete_dt && b.run_complete_dt
          ? moment.utc(a.run_complete_dt).diff(moment.utc(b.run_complete_dt))
          : !a.run_complete_dt
          ? -1
          : 1,
      sortOrder: sortedColumn.columnKey === "run_complete_dt" ? sortedColumn.value : null,
      render: d => {
        if (d) {
          const date = moment(d);
          return date.format("MM/DD/YY hh:mm A");
        } else {
          return "-";
        }
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["run_status_type"]}</Typography>,
      dataIndex: "run_status_type",
      key: "run_status_type",
      showSorterTooltip: false,
      width: 100,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={[{ run_status_type: "OK" }, { run_status_type: "Failed" }]}
          dataKey="run_status_type"
          filters={filters}
          disableSort={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filteredValue: getFilterValues("run_status_type"),
      filterIcon: () => dropDownIcon("run_status_type"),
      sorter: (a, b) => a.run_status_type?.localeCompare(b.run_status_type),
      sortOrder: sortedColumn.columnKey === "run_status_type" ? sortedColumn.value : null,
      render: (d, record) => {
        return (
          <Status>
            {d === "OK" ? (
              <>
                <Ok className="statusIcon" /> {d}
              </>
            ) : (
              <Tooltip title={record.error_description}>
                <Failed className="statusIcon" /> {d}
              </Tooltip>
            )}
          </Status>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["num_rows"]}</Typography>,
      dataIndex: "num_rows",
      key: "num_rows",
      showSorterTooltip: false,
      width: 130,
      render: (data, record) => {
        return (
          <RowCols>
            <div>{record.run_status_type === "OK" ? data : "-"}</div>
            {record.run_status_type === "OK" ? (
              <div style={{ whiteSpace: "nowrap" }}>{record.num_columns} cols</div>
            ) : (
              ""
            )}
          </RowCols>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["trigger_description"]}</Typography>,
      dataIndex: "trigger_description",
      key: "trigger_description",
      showSorterTooltip: false,
      width: 120,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={[{ trigger_description: "Manual" }, { trigger_description: "Scheduled" }]}
          dataKey="trigger_description"
          disableSort={true}
          filters={filters}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filteredValue: getFilterValues("trigger_description"),
      filterIcon: () => dropDownIcon("trigger_description"),
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoaderWithPerfTimings name="DataImportRuns.locale" />
    ) : (
      "No Data Import Runs"
    ),
  };

  return (
    <Wrapper>
      <RecentRuns>Recent runs (Displaying last 30 days)</RecentRuns>
      <Table
        columns={columns}
        dataSource={dataImportRuns ? dataImportRuns.map((d, i) => ({ ...d, key: i })) : []}
        loading={false}
        locale={locale}
        rowKey={r => r.data_import_run_id ?? ""}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
        expandedRowRender={expandedRowRender}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretDownFilled onClick={e => onExpand(record, e)} />
            ) : (
              <CaretRightFilled onClick={e => onExpand(record, e)} />
            ),
        }}
      />
      <AdminTableFooter
        page={page}
        pageSize={dataImportRunPageSize}
        onPageChange={page => setPageAndOffset(page)}
        data={(dataImportRuns ?? []).map(x => [x.data_import_name!, true])}
        totalRowCount={dataDataImportRuns?.totalRecords ?? 0}
      />
    </Wrapper>
  );
};
