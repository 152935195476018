import moment from "moment";

export const getRefreshedDate = (
  lastRefreshedDt: moment.Moment,
  now?: moment.Moment,
  options?: { uppercase?: boolean }
) => {
  if (moment(now).isBefore(lastRefreshedDt)) {
    // Refresh date is in the future! Either there are time zone issues or clock sync issues.
    // In any case, let's not violate the laws of physics, lest we upset the delicate balance of
    // the multiverse.
    return "just now";
  }

  // For the "days ago" computation, we need to compare the calendar day difference, and *not* the
  // absolute difference between now and lastRefreshedDt. We do that by changing the times of day
  // of the two moment.Moment values to be the same (9am in the morning, local time).
  // To prevent rounding errors, we set now's clock time to be a couple minutes ahead of
  // lastRefreshedDt's clock time. Note that 9am is chosen to be far away from midnight to avoid
  // daylight savings effects.
  const todayLocal = moment(now).local().hour(9).minute(5).second(0);
  const lastRefreshedLocal = moment(lastRefreshedDt).local().hour(9).minute(0).second(0);
  const daysAgo = moment(todayLocal).diff(moment(lastRefreshedLocal), "d");

  if (daysAgo > 30) {
    return `${Math.floor(daysAgo / 7)} weeks ago`;
  } else if (daysAgo >= 7) {
    return `${daysAgo} days ago`;
  } else {
    const english = moment(lastRefreshedDt).calendar(now, {
      sameDay: options?.uppercase ? "[Today at] LT" : "[today at] LT",
      lastDay: options?.uppercase ? "[Yesterday at] LT" : "[yesterday at] LT",
      // no "last" prefix
      lastWeek: "dddd [at] LT",
      sameElse: options?.uppercase ? "[On] YYYY/MM/DD" : "[on] YYYY/MM/DD",
    });
    // The "english" string now looks something like "Monday at 10:30 PM". We change the
    // last two spaces to non-breaking spaces, so that the entire "at ##:## [A|P]M" is typeset
    // as if it is a single word. This prevents typographic "widows" such as the "PM" being
    // on its own line.
    const words = english.split(" ");

    // Safety: if somehow there are < 3 words (shouldn't happen), we don't have two spaces to
    // turn into nbsp's
    if (words.length < 3) {
      return english;
    }

    return [
      // Join all but the last three words with spaces
      words.slice(0, -3).join(" "),
      // Join the last three words with nbsp
      words.slice(-3).join("\xa0"),
      // Join the two segments with a space
    ].join(" ");
  }
};

export const getTimeZone = (): string => {
  let timeZone = "America/Los_Angeles"; // set default timezone
  if (Intl && Intl.DateTimeFormat) {
    // Note that timeZone might still be null, since some old browsers return "undefined"
    // for the resolvedOptions().timeZone property
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
  }
  return timeZone;
};

export const hourToTime = (hr: number) =>
  (hr === 0 ? 12 : hr > 12 ? hr - 12 : hr) + ":00 " + (hr >= 12 ? "PM" : "AM");

export const scheduledHourToTime = (hr: number) =>
  (hr === 0 ? 12 : hr > 12 ? hr - 12 : hr) + (hr >= 12 ? "pm" : "am");

export const orderByDate = <T>(array: T[], key: string) =>
  array.sort((x, y) =>
    x[key] && y[key] ? moment(y[key]).diff(moment(x[key])) : x[key] ? -1 : y[key] ? 1 : 0
  );
