import * as React from "react";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../actions";
import { COLORS } from "../constants";

import { Checkbox } from "./Checkbox";

import { TableMenu as Menu, TableMenuItem as MenuItem } from "./";
interface TableDropdownProps {
  confirm: () => void;
  data: Array<any>;
  dataKey: string;
  hideFilters?: boolean;
  disableSort?: boolean;
  filters: Array<{ columnKey: string; values: string[] }>;
  setFilters: (arg) => void;
  setSortedColumn: (value: SortColumn) => void;
  valueRenderer?: (arg) => string | React.ReactNode;
}

const FilterWrapper = styled.div`
  border-top: 1px solid ${COLORS.black4};
  padding: 8px 16px 16px;
  width: 200px;

  .title {
    color: ${COLORS.black25};
    font-size: 12px;
    margin-bottom: 5px;
  }

  .selectAll {
    display: block;
    color: ${COLORS.coeblue4};
    font-size: 14px;
    margin-bottom: 5px;
  }

  .checkboxes {
    overflow-y: auto;
    max-height: 178px;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;

export const TableDropdown = ({
  confirm,
  data,
  dataKey,
  filters,
  hideFilters,
  disableSort,
  setFilters,
  setSortedColumn,
  valueRenderer,
}: TableDropdownProps) => {
  const uniqueFilterValues: string[] = data
    .map(d => d[dataKey])
    .filter((value, index, self) => self.indexOf(value) === index);

  // Note from Vasanth: If filters array does not contain the specific dataKey, it is assumed that all the keys are selected
  // To make a column to unselect all we need to set a filter value that none of the row value matches. thats how antd table filter works
  // I have chosen "~empty~" as the value that none of the row matches.

  const getFilterValues = (key: string) => {
    const filter = filters.find(f => f.columnKey === key);
    // the below line sets everything as selected if the filter is not available for this specific field
    return filter ? filter["values"] : uniqueFilterValues;
  };

  const selectAllToggle = selected => {
    const newFilters = [...filters].filter(f => f.columnKey !== dataKey);
    if (selected) {
      newFilters.push({ columnKey: dataKey, values: ["~empty~"] });
    }
    setFilters(newFilters.filter(f => !!f.values.length));
  };

  const onChange = (key: string, value: string) => {
    const newFilters = [...filters].filter(f => f.columnKey !== key);

    const filteredValues = getFilterValues(key);
    const filteredValue = filteredValues.includes(value)
      ? filteredValues.filter(f => f !== value)
      : [...filteredValues, value].filter(f => f !== "~empty~");

    if (filteredValue.length < uniqueFilterValues.length) {
      newFilters.push({
        columnKey: key,
        values: filteredValue.length ? filteredValue : ["~empty~"],
      });
    }

    setFilters(newFilters.filter(f => !!f.values.length));
  };

  const selectedKeys = getFilterValues(dataKey);
  const isSelectedAll =
    uniqueFilterValues.length === selectedKeys.length && selectedKeys[0] !== "~empty~";

  let renderer = str => (str === true ? "Yes" : str === false ? "No" : str ? str : "-");
  if (valueRenderer) {
    renderer = valueRenderer;
  }

  return (
    <div>
      {!disableSort && (
        <Menu>
          <MenuItem
            onClick={() => {
              setSortedColumn({ columnKey: dataKey, value: SortDirection.ascend });
              confirm();
            }}
          >
            <span>Sort ASC </span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setSortedColumn({ columnKey: dataKey, value: SortDirection.descend });
              confirm();
            }}
          >
            <span>Sort DESC</span>
          </MenuItem>
        </Menu>
      )}

      {!hideFilters && (
        <FilterWrapper>
          <div className="title">FILTER</div>

          <a className="selectAll" onClick={() => selectAllToggle(isSelectedAll)}>
            {isSelectedAll ? "Unselect all" : "Select all"}
          </a>

          <div className="checkboxes">
            {uniqueFilterValues.map((filter, i) => (
              <div className="coeff__checkbox" key={i}>
                <Checkbox
                  checked={selectedKeys.includes(filter)}
                  onChange={() => onChange(dataKey, filter)}
                >
                  {renderer(filter)}
                </Checkbox>
              </div>
            ))}
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};
