import { AddonPlatform } from "@coeff/api";
import { message, Select, Space, Table } from "antd";
import { Option } from "antd/es/mentions";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { DEPRECATED_Button, Form, FormItem, DEPRECATED_Input } from "../../../components";
import { COLORS } from "../../../constants";
import { CAContext, Quickstart as QuickstartT } from "../../../types";
import { CoeffAdminContext } from "../CoeffAdmin";

const QuickstartForm = styled(Form)`
  && {
    padding: 30px;
    width: 600px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.7);
    margin: 28px 28px;
    margin-bottom: 50px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .title {
    color: ${COLORS.black85};
    font-size: 24px;
    text-align: center;
  }
`;

const copySuccess = () => message.success("Copied!");
const createSuccess = () => message.success("Quickstart created!");

const quickstartColumns = [
  {
    title: "Spreadsheet URL",
    dataIndex: "spreadsheet_url",
    key: "spreadsheet_url",
    render: (url: string) => (
      <a
        onClick={() => {
          navigator.clipboard.writeText(url);
          copySuccess();
        }}
      >
        {url}
      </a>
    ),
  },
  {
    title: "Platform",
    dataIndex: "addon_platform",
    key: "addon_platform",
    render: (addon_platform: AddonPlatform) => addon_platform,
  },
  {
    title: "Days since created",
    dataIndex: "days_since_created",
    key: "days_since_created",
    render: (days: number) => (days >= 0 ? days : "-"),
  },
  {
    title: "Days since first open",
    dataIndex: "days_since_first_open",
    key: "days_since_first_open",
    render: (days: number) => (days >= 0 ? days : "-"),
  },
  {
    title: "Days since last open",
    dataIndex: "days_since_last_open",
    key: "days_since_last_open",
    render: (days: number) => (days >= 0 ? days : "-"),
  },
  {
    title: "Num opens",
    dataIndex: "num_opens",
    key: "num_opens",
  },
];

export const Quickstart = () => {
  const { createQuickstart, getQuickstarts } = useContext(CoeffAdminContext) as CAContext;
  const [email, setEmail] = useState<string>("");
  const [addonPlatform, setAddonPlatform] = useState<AddonPlatform>(AddonPlatform.GoogleSheets);
  const [createLoad, setCreateLoad] = useState<boolean>(false);
  const [findLoad, setFindLoad] = useState<boolean>(false);
  const [quickstarts, setQuickstarts] = useState<QuickstartT[]>([]);

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const disableSubmit = () => {
    if (!email || !validateEmail(email)) {
      return true;
    }

    return false;
  };

  const onCreate = async () => {
    setCreateLoad(true);
    await createQuickstart(email, addonPlatform);
    setCreateLoad(false);
    createSuccess();
    onFind();
  };

  const onFind = async () => {
    setFindLoad(true);
    const data = await getQuickstarts(email);
    setQuickstarts(data);
    setFindLoad(false);
  };

  return (
    <>
      <QuickstartForm layout="vertical">
        <FormItem label="Find and Create Quickstart Spreadsheets">
          <DEPRECATED_Input
            placeholder="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormItem>

        <Space>
          <DEPRECATED_Button
            type="primary"
            htmlType="submit"
            disabled={disableSubmit()}
            loading={findLoad}
            onClick={onFind}
          >
            Find
          </DEPRECATED_Button>
          <DEPRECATED_Button
            type="primary"
            htmlType="submit"
            disabled={disableSubmit()}
            loading={createLoad}
            onClick={onCreate}
          >
            Create New Quickstart Spreadsheet
          </DEPRECATED_Button>
          <Select
            onChange={addon_platform => setAddonPlatform(addon_platform)}
            size="middle"
            value={addonPlatform}
          >
            <Option key={"Google Sheets"} value={AddonPlatform.GoogleSheets} />
            <Option key={"Excel"} value={AddonPlatform.Excel} />
          </Select>
        </Space>
      </QuickstartForm>
      {!!quickstarts.length && <Table dataSource={quickstarts} columns={quickstartColumns} />}
    </>
  );
};
