import { DueInvoiceErrorType, GetClientFlags200Response, SubscriptionPlanType } from "@coeff/api";
import * as Sentry from "@sentry/react";
import { Reducer } from "redux";

import { ACTIONS } from "../constants";

interface AppState {
  globalSettings: { beta_gsuite_domains?: string[] };
  openIdToken: string;
  oauthToken: string;
  coeffAuthToken: string;
  msOauthToken: string;
  upgradeToken: string;
  domain: string;
  isCoeffAdmin: boolean;
  isGsuiteDomainAdmin: boolean;
  coeffUserEmail: string;
  coeffUserId: string;
  free_trial_expiration_dt?: Date;
  free_trial_total_days?: number;
  plan_type?: SubscriptionPlanType;
  due_invoices_count?: number;
  due_invoice_error_type?: DueInvoiceErrorType;
  writeback_disabled_for_user?: boolean;
  domains_in_domain_family: string[];
  email: string;
  name: string;
  clientFlags?: GetClientFlags200Response;
}

export const initialAppState = {
  globalSettings: {},
  openIdToken: "",
  oauthToken: "",
  msOauthToken: "",
  coeffAuthToken: "",
  upgradeToken: "",
  domain: "",
  isCoeffAdmin: false,
  isGsuiteDomainAdmin: false,
  coeffUserEmail: "",
  coeffUserId: "",
  writeback_disabled_for_user: true,
  domains_in_domain_family: [],
  email: "",
  name: "",
  locale: "en-US",
};

export const app: Reducer<AppState> = (state = initialAppState, action) => {
  switch (action.type) {
    case ACTIONS.SET_GLOBAL_SETTING: {
      const { global_setting } = action;
      const type = global_setting.global_setting_type;
      return {
        ...state,
        globalSettings: { ...state.globalSettings, [type]: global_setting[type] },
      };
    }

    case ACTIONS.SET_CLIENT_FLAGS: {
      const {
        is_coeff_admin,
        is_gsuite_domain_admin,
        free_trial_expiration_dt,
        free_trial_total_days,
        plan_type,
        due_invoices_count,
        due_invoice_error_type,
        writeback_disabled_for_user,
        domains_in_domain_family,
        email,
        first_name,
        last_name,
      } = action.clientFlags as GetClientFlags200Response;

      Sentry.setUser({ email });

      return {
        ...state,
        isCoeffAdmin: is_coeff_admin,
        isGsuiteDomainAdmin: is_gsuite_domain_admin,
        free_trial_expiration_dt: free_trial_expiration_dt as any,
        free_trial_total_days,
        plan_type,
        due_invoices_count,
        due_invoice_error_type,
        writeback_disabled_for_user,
        domains_in_domain_family,
        email,
        domain: email.split("@")[1],
        clientFlags: action.clientFlags,
        name: `${first_name} ${last_name}`,
      };
    }

    case ACTIONS.SET_USER_DETAILS: {
      const { userEmail, userId } = action;

      return {
        ...state,
        domain: userEmail.split("@")[1],
        coeffUserEmail: userEmail,
        coeffUserId: userId,
      };
    }

    case ACTIONS.SET_UPGRADE_TOKEN: {
      const { upgradeToken } = action;
      return { ...state, upgradeToken };
    }

    default:
      return state;
  }
};
