import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ErrorMessage,
  ImportTypeIcon,
  List,
  LoadingBar,
  message,
  Typography,
  WheelIcon,
} from "../components";
import { COLORS } from "../constants";
import { sentryCapture, useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 50px;
  margin-left: 50px;
`;

const { Item } = List;

const DataSourceSelections = styled.div`
  border-radius: 6px;
  cursor: default !important;

  .ant-list-item {
    .actionswrap {
      visibility: visible !important;
      border-radius: 6px !important;
    }
  }
`;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black4};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;
    padding: 12px 15px;
    border-radius: 6px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      margin: 12px 15px !important;
      border: 10px;
      background-color: ${COLORS.black4} !important;
      border-radius: 6px !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black45};
      line-height: 1.25;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black85};
      font-size: 14px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;
const StyledLink = styled(Link)`
  margin: 8px 16px;
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin: 0 10px;
  }
`;

const StyledHelpGuide = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto !important;
`;

const HelpGuide = () => {
  return (
    <StyledHelpGuide>
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a
          href="https://help.coefficient.io/hc/en-us/articles/23948673743259-NetSuite"
          target="_blank"
        >
          Help guide
        </a>
      </Button>
    </StyledHelpGuide>
  );
};

const Layout: React.FC = ({ children }) => (
  <Content>
    <StyledHeader>
      <ImportTypeIcon type="looker" />
      <h1>Looker</h1>
      <HelpGuide />
    </StyledHeader>
    {children}
  </Content>
);

const getLookerbaseUrl = (url: string) =>
  url?.replace(/^https?:\/\//, "").replace(/(:\d+)?\/?$/, "");

export const LookerOAuthManageSettings = () => {
  const { apiClient: api } = useApiContext();
  const history = useHistory();
  const track = useTrack();
  useEffect(() => {
    document.title = "Looker OAuth - Coefficient";
    // track("workspace_looker_oauth_settings_viewed");
  }, []);

  const {
    data: lookerApps,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryCacheKey.LOOKER_APP_INFO],
    queryFn: () => api.listLookerAppInfo().then(response => response?.data?.apps_list),
    staleTime: 0,
    cacheTime: 0,
    retry: 2,
    onError: error => {
      sentryCapture({
        error,
        name: "LookerOAuthManageSettings: GET /api/data_sources/looker/apps",
      });
      message.error("Failed to fetch Looker apps");
    },
  });

  if (isLoading) {
    return (
      <Layout>
        <LoadingBar />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <ErrorMessage
          title="Failed to load Looker apps"
          message="There was an error loading your Looker apps. Please try again."
          action={() => refetch()}
          actionText="Try again"
        />
      </Layout>
    );
  }

  if (!lookerApps || lookerApps.length === 0) {
    return (
      <Layout>
        <Typography
          fontSize={"18px"}
          lineHeight={"24px"}
          fontWeight={700}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Accounts
        </Typography>
        <Alert message="No Looker accounts found" type="info" />
        <Button
          style={{ width: 200, marginTop: 16 }}
          onClick={() =>
            history.push(
              `./looker-oauth?${new URLSearchParams({ app_guid: "-1", back_on_add: "true" })}`
            )
          }
        >
          Add Connection
        </Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography
        fontSize={"18px"}
        lineHeight={"24px"}
        fontWeight={700}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Accounts
      </Typography>
      {lookerApps?.map((appInfo, index) => (
        <DataSourceSelections key={index}>
          <StyledItem onClick={() => null}>
            <Item.Meta
              title="Base URL"
              avatar={<ImportTypeIcon type={"looker"} />}
              description={getLookerbaseUrl(appInfo.base_url)}
            />
            <StyledLink to={`looker-oauth?app_guid=${appInfo.client_app_guid}`}>Manage</StyledLink>
          </StyledItem>
        </DataSourceSelections>
      ))}
      <Button
        style={{ width: 200, marginTop: 16 }}
        onClick={() =>
          history.push(
            `./looker-oauth?${new URLSearchParams({ app_guid: "-1", back_on_add: "true" })}`
          )
        }
      >
        Add Connection
      </Button>
    </Layout>
  );
};
