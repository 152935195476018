import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { AppThunkExtraArgs } from "./actions";
import { rootReducer } from "./reducers";

export const history = createBrowserHistory();

type Store = ReturnType<typeof configureStore>;

export type AppState = ReturnType<Store["getState"]>;

export function configureStore(preloadedState: any, extraArgs: AppThunkExtraArgs) {
  const thunkMiddleware = thunk.withExtraArgument<AppThunkExtraArgs>(extraArgs);

  const middlewareEnhancer = applyMiddleware(...[thunkMiddleware]);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers =
    (process.env.NODE_ENV === "development" && composeWithDevTools(...enhancers)) ||
    compose(...enhancers);

  return createStore(rootReducer(), preloadedState, composedEnhancers);
}
