import { AddonPlatform } from "@coeff/api";

import { ACTIONS, API_ADMIN_ROUTES, API_ROUTES } from "../constants";

import { AppThunkAction } from ".";

export const getGlobalSetting =
  (type: string): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

    try {
      const { global_setting } = await api.request({
        method: "GET",
        url: `${API_ADMIN_ROUTES.globalSettings}/${type}`,
      });

      dispatch({ type: ACTIONS.SET_GLOBAL_SETTING, global_setting });

      return global_setting;
    } catch (error) {
      return error;
    }
  };

export const upsertGlobalSetting =
  (setting: Record<string, any>): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    try {
      const { global_setting } = await api.request({
        method: "POST",
        url: `${API_ADMIN_ROUTES.globalSettings}/${setting.type}`,
        body: {
          global_setting: { [setting.type]: setting[setting.type] },
        },
      });

      dispatch({ type: ACTIONS.SET_GLOBAL_SETTING, global_setting });

      return global_setting;
    } catch (error: any) {
      const errorMessage =
        error.error && error.error.message ? error.error.message : "An unknown error ocurred :(";
      alert(errorMessage);

      return error;
    }
  };

export const getClientFlags =
  (): AppThunkAction<Promise<Record<string, any>>> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING });

    const { headers, status, ...clientFlags } = await api.request({
      method: "GET",
      url: `${API_ROUTES.clientFlags}`,
    });

    dispatch({ type: ACTIONS.SET_CLIENT_FLAGS, clientFlags });

    dispatch({
      type: ACTIONS.SET_USER_DETAILS,
      userEmail: headers["x-coeff-user-email"],
      userId: headers["x-coeff-user-id"],
    });

    return clientFlags;
  };

export const createQuickstart =
  (email: string, addon_platform: AddonPlatform): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    try {
      const quickstart = await api.request({
        method: "POST",
        url: `${API_ROUTES.quickstart}?email=${email}&force_create=1&addon_platform=${addon_platform}`,
      });

      console.log(quickstart);
    } catch (error) {
      console.log(error);
    }
  };
