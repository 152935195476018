import { Tooltip } from "antd";
import React, { HTMLAttributes, PropsWithChildren, useState } from "react";
import styled, { DefaultTheme } from "styled-components";

export type TypographyProps<T = Element> = HTMLAttributes<T> & {
  title?: string;
  variant?: keyof DefaultTheme["typography"];
  lineHeight?: number | string;
  gutterBottom?: boolean | number;
  align?: "center" | "unset" | "left" | "right";
  color?: "textPrimary" | "textSecondary" | "textTertiary" | "textLink" | "error" | string;
  fontWeight?: "normal" | "bold" | number;
  noWrap?: boolean;
  isEllipsedContentTooltip?: boolean;
  tooltipDelay?: number;
  underline?: boolean;
  inline?: boolean;
  lineClamp?: number;
  uppercase?: boolean;
  fontSize?: string;
};

const StyledTypography = styled.div<TypographyProps>`
  color: ${({ variant, color, theme }) => {
    if (color !== undefined) {
      switch (color) {
        case "textPrimary":
          return theme.colors.black85;
        case "textSecondary":
          return theme.colors.black45;
        case "textTertiary":
          return theme.colors.black35;
        case "textLink":
          return theme.colors.coeblue4;
        case "error":
          return theme.colors.red;
        default:
          return color;
      }
    }

    switch (variant) {
      case "caption":
        return theme.colors.black25;
      default:
        return theme.colors.black85;
    }
  }};

  ${({ theme, variant }) => {
    if (variant === undefined) {
      return "";
    }

    return `font-size: ${theme.typography[variant].fontSize}px;`;
  }}}


  ${({ inline }) => {
    if (inline === undefined) {
      return "";
    }

    return `
      display: inline;
    `;
  }}

  ${({ fontWeight }) => {
    if (fontWeight === undefined) {
      return "";
    }

    return `font-weight: ${fontWeight};`;
  }}

  ${({ uppercase }) => {
    return uppercase ? `text-transform: uppercase;` : "";
  }}

  ${({ underline }) => {
    if (underline === undefined) {
      return "";
    }

    return `
      cursor: pointer;
      text-decoration: underline dotted;
    `;
  }}


  ${({ lineHeight }) => {
    return lineHeight ? `line-height: ${lineHeight};` : "";
  }}

  ${({ gutterBottom, theme }) => {
    if (gutterBottom === undefined || !gutterBottom) {
      return "";
    }

    return `
      margin-bottom: ${theme.spacing(typeof gutterBottom === "number" ? gutterBottom : 1)};
    `;
  }}

  ${({ align }) => {
    if (align === undefined) {
      return "";
    }

    return `text-align: ${align};`;
  }}

  ${({ noWrap, lineClamp }) => {
    if (noWrap || lineClamp) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-word;
      `;
    } else {
      return "";
    }
  }}

  ${({ lineClamp }) => {
    if (lineClamp) {
      return `
          display: -webkit-inline-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${lineClamp};
          overflow: hidden;
          white-space: normal;
          word-break: break-word;
      `;
    } else {
      return "";
    }
  }}

  ${({ fontSize }) => {
    if (fontSize === undefined) {
      return "";
    }

    return `font-size: ${fontSize};`;
  }}}
`;

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = props => {
  const { children, isEllipsedContentTooltip, title, tooltipDelay, ...rest } = props;

  const [tooltipDisabled, setTooltipDisabled] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent) => {
    const { offsetWidth, scrollWidth } = e.target as HTMLDivElement;

    // Only show the Tooltip if the text is ellipsed. If lineClamp
    // is applied, always show it since offsetWidth is always equal
    // to scrollWidth
    if (isEllipsedContentTooltip && offsetWidth >= scrollWidth && !rest.lineClamp) {
      setTooltipDisabled(true);
    }
  };

  if (title || isEllipsedContentTooltip) {
    return (
      <Tooltip
        open={tooltipDisabled ? false : undefined}
        mouseEnterDelay={tooltipDelay ?? 1}
        title={title ?? children}
      >
        <StyledTypography {...rest} onMouseEnter={handleMouseEnter} noWrap>
          {children}
        </StyledTypography>
      </Tooltip>
    );
  }

  return <StyledTypography {...rest}>{children}</StyledTypography>;
};
