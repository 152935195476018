import { CloudTable } from "@coeff/api";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

import { useCloudTables } from "../../../api";
import {
  Badge,
  CloudTableIcon,
  Option,
  InputSearch,
  Button,
  Typography,
  DownArrow,
  FilledSelect,
  CenterAlign,
  SharedIcon,
  PrivateIcon,
  TextWithIcon,
  PaperContent,
  Paper,
  EmptyCloudTables,
  AlertExclamation,
  LoaderWithPerfTimings,
} from "../../../components";
import { COLORS } from "../../../constants";
import { AppState } from "../../../store";
import {
  getRefreshedDate,
  getScheduleSummaryText,
  searchCloudTableText,
  sortCloudTablesByKey,
  useTrack,
} from "../../../utils";
import { Header } from "../components";

const Container = styled.div`
  padding: 20px 30px;
`;

const EmptyContainer = styled.div`
  margin-top: 64px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const List = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListHeader = styled.div`
  display: grid;
  grid-template-columns: 60px 2.5fr 1.5fr 1fr 1fr;
  gap: 8px;
  padding: 0 16px;
  align-items: center;
  border: 1px solid transparent;
  min-width: 0;
`;

const ListItem = styled(ListHeader)`
  padding: 16px;
  border-radius: 4px;
  background-color: ${COLORS.black2};

  &:hover {
    background-color: ${COLORS.black4};
    border: 1px solid ${COLORS.coeblue4};
    cursor: pointer;
  }
`;

const StyledInputSearch = styled(InputSearch)`
  width: 100%;
  max-width: 360px;
  margin-right: 8px;
`;

const Metadata = styled.div`
  min-width: 0;
`;

enum FilterValue {
  OWNED_BY_ANYONE = "owned_by_anyone",
  OWNED_BY_ME = "owned_by_me",
}

export const CloudTables: React.FC = () => {
  const track = useTrack();

  const history = useHistory();

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const [search, setSearch] = React.useState<string>("");

  const [filter, setFilter] = React.useState<FilterValue>(FilterValue.OWNED_BY_ANYONE);

  const [sortKey, setSortKey] = React.useState<keyof CloudTable>("last_run_dt");

  const filterOptions = [
    { value: FilterValue.OWNED_BY_ANYONE, label: "Owned by anyone" },
    { value: FilterValue.OWNED_BY_ME, label: "Owned by me" },
  ];

  const sortOptions: Array<{ value: keyof CloudTable; label: string }> = [
    { value: "last_run_dt", label: "Last Refreshed" },
    { value: "cloud_table_name", label: "Alphabetical" },
  ];

  const {
    data: cloudTables,
    isLoading,
    error,
  } = useCloudTables({
    enabled: Boolean(clientFlags && !clientFlags.cloud_table_disabled),
  });

  const email = useSelector((state: AppState) => state.app.email);

  const filteredCloudTables = cloudTables
    ?.filter(
      cloudTable => filter === FilterValue.OWNED_BY_ANYONE || cloudTable.user?.email === email
    )
    ?.filter(cloudTable => searchCloudTableText(cloudTable, search))
    .sort((a, b) => sortCloudTablesByKey(a, b, sortKey));

  useEffect(() => {
    document.title = "Datasets - Coefficient Workspace";

    track("dataset_list_viewed_web");
  }, []);

  const handleCreateClick = () => {
    track("new_dataset_clicked", { event_from: "list_web" });

    history.push("/datasets/create");
  };

  if (clientFlags?.cloud_table_disabled) {
    return (
      <Container>
        <Header>
          <h1>Datasets</h1>
        </Header>
        <EmptyContainer>
          <EmptyCloudTables onCreateClick={() => {}} />
        </EmptyContainer>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <CenterAlign>
        <LoaderWithPerfTimings name="CloudTables" size="large" />
      </CenterAlign>
    );
  }

  if (error) {
    return (
      <CenterAlign>
        <Paper>
          <PaperContent>
            <Typography fontWeight="bold" gutterBottom>
              Error
            </Typography>
            <Typography>Unable to load datasets</Typography>
          </PaperContent>
        </Paper>
      </CenterAlign>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Datasets</h1>
      </Header>

      {cloudTables && cloudTables.length === 0 ? (
        <EmptyContainer>
          <EmptyCloudTables onCreateClick={() => history.push("/datasets/create")} />
        </EmptyContainer>
      ) : (
        <>
          <Toolbar>
            <StyledInputSearch onChange={e => setSearch(e.target.value)} value={search} />

            <ToolbarActions>
              <FilledSelect
                suffixIcon={<DownArrow />}
                onChange={filter => setFilter(filter)}
                options={filterOptions}
                value={filter}
              />

              <FilledSelect
                suffixIcon={<DownArrow />}
                onChange={sortKey => setSortKey(sortKey)}
                options={sortOptions}
                value={sortKey}
              >
                <Option value="date">Sort: Last Refreshed</Option>

                <Option value="alpha">Sort: Alphabetical</Option>
              </FilledSelect>

              <Button type="primary" onClick={() => handleCreateClick()}>
                New Dataset
              </Button>
            </ToolbarActions>
          </Toolbar>

          <List>
            <ListHeader>
              <div></div>

              <Typography variant="body3" color="textSecondary" uppercase>
                Name
              </Typography>

              <Typography variant="body3" color="textSecondary" uppercase>
                Last Refreshed
              </Typography>

              <Typography variant="body3" color="textSecondary" uppercase>
                Owner
              </Typography>

              <Typography variant="body3" color="textSecondary" uppercase>
                Type
              </Typography>
            </ListHeader>

            {filteredCloudTables
              ? filteredCloudTables.map(cloudTable => (
                  <ListItem
                    key={cloudTable.cloud_table_id}
                    onClick={() => history.push(`/datasets/${cloudTable.cloud_table_id}`)}
                  >
                    <CloudTableIcon cloudTable={cloudTable} />

                    <Metadata>
                      <Typography noWrap variant="body1" fontWeight="bold">
                        {cloudTable.cloud_table_name}
                      </Typography>

                      {cloudTable.description ? (
                        <Typography tooltipDelay={0} title={cloudTable.description} lineClamp={1}>
                          {cloudTable.description}
                        </Typography>
                      ) : null}

                      {cloudTable.last_import_status?.status_level === "error" ? (
                        <div>
                          <Badge variant="error">
                            <TextWithIcon
                              gap={4}
                              icon={<AlertExclamation height={12} type="error" />}
                              title={
                                <Typography variant="body3" fontWeight={700}>
                                  Unable to refresh
                                </Typography>
                              }
                            />
                          </Badge>
                        </div>
                      ) : null}
                    </Metadata>

                    <div>
                      {getRefreshedDate(moment.utc(cloudTable.last_run_dt).local(), undefined, {
                        uppercase: true,
                      })}

                      {cloudTable.options?.schedule ? (
                        <>
                          <br />
                          <Badge variant="info">
                            {getScheduleSummaryText(cloudTable.options?.schedule)}
                          </Badge>
                        </>
                      ) : null}
                    </div>

                    <div>
                      <Typography title={cloudTable.user?.email} underline tooltipDelay={0}>
                        {cloudTable.user?.display_name}
                      </Typography>
                    </div>

                    <TextWithIcon
                      icon={cloudTable.is_shared_with_domain ? <SharedIcon /> : <PrivateIcon />}
                      title={cloudTable.is_shared_with_domain ? "Shared" : "Private"}
                    />
                  </ListItem>
                ))
              : null}
          </List>
        </>
      )}
    </Container>
  );
};
