import { Column } from "@coeff/api";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { EllipsizedText } from "./EllipsizedText";

const Panel = styled.div`
  .ant-btn {
    width: 220px;
    margin: 20px auto;
    display: block;

    span {
      margin: 0px;
    }
  }
`;

const TagsWrap = styled.div`
  height: auto;
  overflow: hidden;
  max-height: 54px;
  transition: max-height 0.3s ease;
`;

const Tag = styled.span`
  background: ${COLORS.coeblue1};
  display: inline-block;
  margin: 2px;
  padding: 0px 4px;
  border-radius: 5px;
  color: ${COLORS.black85};
  max-width: 100px;
  font-weight: 500;
`;

const ShowAllLink = styled.span`
  display: inline-block;
  color: ${COLORS.black85};
  cursor: pointer;
  font-weight: 500;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const SelectedColumns = ({ columns }: { columns: Column[] }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(true);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current?.scrollHeight) {
      setShowAll(divRef.current?.scrollHeight <= 88 ? false : true);
    }
  }, [columns]);

  return (
    <Panel>
      {columns.length > 0 && (
        <>
          <TagsWrap ref={divRef} style={{ maxHeight: expanded ? "10000px" : "88px" }}>
            {columns.map((col, i) => (
              <Tag key={i}>
                <EllipsizedText text={col.alias ?? col.column_name} />
              </Tag>
            ))}
          </TagsWrap>
          {showAll && (
            <ShowAllLink onClick={() => setExpanded(!expanded)}>
              + show {expanded ? "less" : "all"}
            </ShowAllLink>
          )}
        </>
      )}
    </Panel>
  );
};
