import querystring from "query-string";

import { ACTIONS, API_EXT_ADMIN_ROUTES } from "../constants";

import { AppThunkAction } from ".";

export enum SortDirection {
  ascend = "ascend",
  descend = "descend",
}

export type SortColumn = {
  columnKey: string;
  value: SortDirection;
};

export const getDataImportRuns =
  (params: any, domain?: string): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING, dataImportRuns: [], totalRecords: 0 });
    try {
      if (domain) {
        api.setDomainParam(domain);
      }

      const { data_import_runs, total_records: totalRecords } = await api.request({
        method: "GET",
        url: `${API_EXT_ADMIN_ROUTES.dataImportRuns}?${querystring.stringify(params)}`,
      });
      if (!params || !params.data_import_id) {
        dispatch({
          type: ACTIONS.SET_DATA_IMPORT_RUNS,
          dataImportRuns: data_import_runs,
          totalRecords: totalRecords,
        });
      }
      return data_import_runs;
    } catch (error) {
      return error;
    }
  };

export const getDomainAlertsExt =
  (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filter?: string
  ): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING, domainAlerts: [], totalRecords: 0 });
    if (domain) {
      api.setDomainParam(domain);
    }

    const { alerts: domainAlerts, total_records: totalRecords } = await api.request({
      method: "GET",
      url: `${API_EXT_ADMIN_ROUTES.alerts}?page_size=${pageSize}&filters=${filter}&offset=${offset}&sort_by=${sortColumn.columnKey}&sort_order=${sortColumn.value}`,
    });
    dispatch({
      type: ACTIONS.SET_DOMAIN_ALERTS_EXT,
      domainAlerts: domainAlerts,
      totalRecords: totalRecords,
    });
    return domainAlerts;
  };

export const getDomainDataSources =
  (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING, domainDataSources: [], totalRecords: 0 });
    if (domain) {
      api.setDomainParam(domain);
    }

    const { data_sources: domainDataSources, total_records: totalRecords } = await api.request({
      method: "GET",
      url: `${API_EXT_ADMIN_ROUTES.dataSources}?page_size=${pageSize}&offset=${offset}&sort_by=${sortColumn.columnKey}&sort_order=${sortColumn.value}&filters=${filters}`,
    });
    dispatch({
      type: ACTIONS.SET_DOMAIN_DATA_SOURCES_EXT,
      domainDataSources: domainDataSources,
      totalRecords: totalRecords,
    });
    return domainDataSources;
  };

export const getDataImports =
  (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING, dataImports: [], totalRecords: 0 });

    try {
      if (domain) {
        api.setDomainParam(domain);
      }
      const { data_imports, total_records } = await api.request({
        method: "GET",
        url: `${API_EXT_ADMIN_ROUTES.dataImports}?page_size=${pageSize}&offset=${offset}&sort_by=${sortColumn.columnKey}&sort_order=${sortColumn.value}&filters=${filters}`,
      });

      dispatch({
        type: ACTIONS.SET_DATA_IMPORTS,
        dataImports: data_imports,
        totalRecords: total_records,
      });
    } catch (error) {
      return error;
    }
  };

export const getDomainUsersExt =
  (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn
  ): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_ADMIN_LOADING, domainUsers: [], totalRecords: 0 });
    try {
      if (domain) {
        api.setDomainParam(domain);
      }

      const { users, total_records } = await api.request({
        method: "GET",
        url: `${API_EXT_ADMIN_ROUTES.users}?page_size=${pageSize}&offset=${offset}&sort_by=${sortColumn.columnKey}&sort_order=${sortColumn.value}`,
      });
      dispatch({
        type: ACTIONS.SET_DOMAIN_USERS_EXT,
        domainUsers: users,
        totalRecords: total_records,
      });
      return users;
    } catch (error) {
      return error;
    }
  };
