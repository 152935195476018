import React from "react";
import styled from "styled-components";

import { BulletCheck } from ".";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  text-align: left;

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  svg {
    margin-top: 4px;
  }
`;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  items: Array<React.ReactNode>;
};

export const BulletCheckList: React.FC<Props> = ({ items, ...rest }) => {
  return (
    <Container {...rest}>
      {items.map(item => (
        <ListItem>
          <BulletCheck />

          <div>{item}</div>
        </ListItem>
      ))}
    </Container>
  );
};
