import { DefaultApi, NetSuiteAppInfo } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  Form,
  ImportTypeIcon,
  Input,
  List,
  LoaderWithPerfTimings,
  WheelIcon,
  Typography,
  HelpIcon,
  Checkbox,
  Steps,
} from "../components";
import { NetSuiteAccountIDExampleGraphic } from "../components/Icons/graphics";
import { COLORS } from "../constants";
import { isValidHttpsUrl, useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";
import { NetSuiteOAuthManageSettings } from "./NetSuiteOAuthManageSettings";

const StyledParagraph = styled.p`
  color: ${COLORS.black45};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const StyledLink = styled(Link)`
  margin: 8px 16px;
`;

const { Item } = List;

const DataSourceSelections = styled.div`
  border-radius: 6px;
  cursor: default !important;

  .ant-list-item {
    .actionswrap {
      visibility: visible !important;
      border-radius: 6px !important;
    }
  }
`;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black2};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      margin: 12px 15px !important;
      border: 10px;
      background-color: ${COLORS.black4} !important;
      border-radius: 6px !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black45};
      line-height: 1.25;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black85};
      font-size: 14px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;

const Container = styled.div`
  padding: 25px 30px;
  max-width: 660px;
`;

const StyledHeader = styled(Header)`
  h1 {
    margin: 0 10px;
  }

  .helpGuide {
    display: flex;
    align-items: center;
    margin-left: auto !important;
  }
`;

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const StyledButton = styled(Button)`
  margin: 0px;
  font-weight: 10;
  width: 160px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 24px;
`;

const FormGroup = styled.div`
  margin-top: 40px;

  label {
    font-weight: bold;
    margin: 0px 12px 8px 2px;
  }
`;

const ConfirmCheckbox = styled(Checkbox)`
  && {
    margin: 20px 0px 0px;
  }
`;

const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.coeblue};
  margin: 8px 0px;
  padding: 16px;
  border-radius: 5px;
`;

const fetchNetSuiteAppInfo = async (api: DefaultApi): Promise<NetSuiteAppInfo[] | null> => {
  const response = await api.listNetSuiteAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list;
  }
  return null;
};

const ToastMessage = () => {
  return (
    <div>
      <p>
        Make sure NetSuite OAuth is working properly by trying to connect NetSuite in the
        Coefficient sidebar in Google Sheets / Excel.
      </p>
    </div>
  );
};

const HelpGuide = () => {
  return (
    <div className="helpGuide">
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a
          href="https://help.coefficient.io/hc/en-us/articles/23948673743259-NetSuite"
          target="_blank"
        >
          Help Guide
        </a>
      </Button>
    </div>
  );
};

export const NetSuiteOAuthSettings: React.FC = () => {
  const track = useTrack();

  const { url } = useRouteMatch();

  const [showAddNewAccountForm, setShowAddNewAccountForm] = useState(false);

  const [userEnteredAccountID, setUserEnteredAccountID] = useState("");

  const [userEnteredExternalURL, setUserEnteredExternalURL] = useState("");

  const [userConfirmUnzipRestlet, setUserConfirmUnzipRestlet] = useState<boolean>(false);

  const [userConfirmUnzipRestletHighlight, setuserConfirmUnzipRestletHighlight] =
    useState<boolean>(false);

  const [userConfirmNetsuiteConfigured, setUserConfirmedNetsuiteConfigured] =
    useState<boolean>(false);

  const [userConfirmNetsuiteConfiguredHighlight, setUserConfirmedNetsuiteConfiguredHighlight] =
    useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);

  const handleCancel = () => {
    setShowAddNewAccountForm(false);
    setUserEnteredAccountID("");
    setUserEnteredExternalURL("");
    setUserConfirmedNetsuiteConfigured(false);
    setUserConfirmedNetsuiteConfiguredHighlight(false);
    setUserConfirmUnzipRestlet(false);
    setuserConfirmUnzipRestletHighlight(false);
    setCurrentStep(undefined);
  };

  const [_, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "NetSuite OAuth Settings - Coefficient Workspace";
    track("workspace_netsuite_oauth_settings_viewed");
  }, []);

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const fetchAppInfoQuery = useQuery(
    [QueryCacheKey.NETSUITE_APP_INFO],
    () => fetchNetSuiteAppInfo(api),
    { cacheTime: 0 }
  );
  const { data: netSuiteAppInfo } = fetchAppInfoQuery;

  const upsertAppInfoMutation = useMutation(
    () =>
      api.upsertNetSuiteAppInfo({
        upsert_net_suite_app_info_request: {
          account_id: userEnteredAccountID || "",
          external_url: (userEnteredExternalURL || "").trim(),
        },
      }),
    {
      onSuccess: () => {
        notification.success({
          message: "Saved successfully",
          description: <ToastMessage />,
          duration: 5,
          placement: "top",
        });
        queryClient.invalidateQueries([QueryCacheKey.NETSUITE_APP_INFO]);
      },
      onError: () => message.error("Something went wrong. Please try again."),
    }
  );

  const renderContent = (children: React.ReactNode) => {
    return (
      <Container>
        <StyledHeader>
          <ImportTypeIcon type="netsuite" />
          <h1>NetSuite OAuth</h1>
          <HelpGuide />
        </StyledHeader>
        {contextHolder}
        {children}
      </Container>
    );
  };

  const isLoading = [fetchAppInfoQuery, upsertAppInfoMutation].some(x => x.isLoading);

  if (isLoading) {
    return renderContent(<LoaderWithPerfTimings name="NetSuiteOAuthSettings" />);
  }

  if (fetchAppInfoQuery.isError) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Error loading NetSuite OAuth Settings"
        description="Please try refreshing this page, or contact support@coefficient.io"
      />
    );
  }

  if (netSuiteAppInfo && !showAddNewAccountForm) {
    // NetSuite OAuth already configured
    return renderContent(
      <Content>
        <StyledAlert
          type="info"
          showIcon
          message="NetSuite OAuth has been configured for your domain"
          description="Connect to NetSuite using the Coefficient sidebar in Google Sheets / Excel."
        />
        <Form>
          <FormGroup>
            <label>Accounts</label>
          </FormGroup>
        </Form>
        {netSuiteAppInfo.map((appInfo, index) => (
          <DataSourceSelections key={index}>
            <StyledItem onClick={() => null}>
              <Item.Meta
                title="Account ID"
                avatar={<ImportTypeIcon type={"netsuite"} />}
                description={appInfo.account_id}
              />
              <StyledLink to={`${url}/${appInfo.account_id}`}>Manage</StyledLink>
              <Switch>
                <Route path={`${url}:accountId`}>
                  Manage
                  <NetSuiteOAuthManageSettings />
                </Route>
              </Switch>
            </StyledItem>
          </DataSourceSelections>
        ))}

        <Button style={{ width: 200 }} onClick={() => setShowAddNewAccountForm(true)}>
          Add Connection
        </Button>
      </Content>
    );
  }

  const renderSteps = () => {
    return (
      <Steps
        direction="vertical"
        size="small"
        current={currentStep}
        items={[
          {
            title: (
              <>
                <Typography
                  style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                  color={currentStep === undefined ? "textPrimary" : "textSecondary"}
                >
                  Enter your account ID
                </Typography>
                <StyledButton
                  style={{ width: "92px", position: "absolute", right: "0px", top: "0px" }}
                  type="link"
                  onClick={handleCancel}
                >
                  Cancel Setup
                </StyledButton>
              </>
            ),
            description:
              currentStep === undefined ? (
                <StyledStepContainer>
                  <label>Account ID</label>
                  <Input
                    placeholder="ex: coeff13813"
                    value={userEnteredAccountID}
                    onChange={e => setUserEnteredAccountID(e.target.value)}
                  />
                  <StyledParagraph>
                    You can find your NetSuite account id in your browser's address bar when you are
                    logged in to NetSuite account.
                  </StyledParagraph>
                  <div>
                    <NetSuiteAccountIDExampleGraphic />
                  </div>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      disabled={!userEnteredAccountID}
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      Next
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <>
                  <StyledButton
                    style={{ justifyContent: "flex-start" }}
                    type="link"
                    onClick={() => {
                      setCurrentStep(undefined);
                    }}
                  >
                    Edit
                  </StyledButton>
                </>
              ),
          },
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === 1 ? "textPrimary" : "textSecondary"}
              >
                Download zip file with script (.js)
              </Typography>
            ),
            description:
              currentStep === 1 ? (
                <StyledStepContainer>
                  <StyledParagraph>
                    Download and unzip the .js file that you will use in the next step.
                  </StyledParagraph>

                  <StyledButton
                    type="ghost"
                    style={{
                      paddingBottom: 0,
                      background: "white",
                      color: "black",
                    }}
                    href="https://coeff.s3.us-west-2.amazonaws.com/public/netsuite/CoefficientRESTLet_1.4.0.js.zip"
                  >
                    Download (.zip)
                  </StyledButton>

                  <ConfirmCheckbox
                    className={userConfirmUnzipRestletHighlight ? "error" : ""}
                    checked={userConfirmUnzipRestlet}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      e.stopPropagation();
                      const checked = e.target.checked;
                      if (userConfirmUnzipRestletHighlight && checked) {
                        setuserConfirmUnzipRestletHighlight(false);
                      }
                      setUserConfirmUnzipRestlet(e.target.checked);
                    }}
                  >
                    <Typography fontWeight={500}>I have unzipped the .zip file</Typography>
                  </ConfirmCheckbox>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        if (!userConfirmUnzipRestlet) {
                          setuserConfirmUnzipRestletHighlight(true);
                          return;
                        }
                        setCurrentStep(2);
                      }}
                    >
                      Next
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <>
                  {(currentStep ?? 0) > 1 && (
                    <StyledButton
                      style={{ justifyContent: "flex-start" }}
                      type="link"
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      Revisit
                    </StyledButton>
                  )}
                </>
              ),
          },
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === 2 ? "textPrimary" : "textSecondary"}
              >
                Configure in NetSuite
              </Typography>
            ),
            description:
              currentStep === 2 ? (
                <StyledStepContainer>
                  <StyledParagraph>
                    Refer to our detailed instructions to configure in NetSuite.
                  </StyledParagraph>

                  <StyledButton
                    type="ghost"
                    style={{
                      width: 240,
                      paddingBottom: 0,
                      background: "white",
                      color: "black",
                    }}
                    href="https://coefficient.io/help-center/netsuite"
                    target="_blank"
                    icon={<HelpIcon />}
                  >
                    Open Configuration Steps
                  </StyledButton>

                  <ConfirmCheckbox
                    className={userConfirmNetsuiteConfiguredHighlight ? "error" : ""}
                    checked={userConfirmNetsuiteConfigured}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      e.stopPropagation();
                      const checked = e.target.checked;
                      if (userConfirmNetsuiteConfiguredHighlight && checked) {
                        setUserConfirmedNetsuiteConfiguredHighlight(false);
                      }
                      setUserConfirmedNetsuiteConfigured(e.target.checked);
                    }}
                  >
                    <Typography fontWeight={500}>I have configured in NetSuite</Typography>
                  </ConfirmCheckbox>
                  <Typography
                    style={{ marginLeft: "24px", fontSize: "12px" }}
                    color="textSecondary"
                  >
                    NetSuite OAuth will not work properly until the script is configured in
                    NetSuite.
                  </Typography>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        if (!userConfirmNetsuiteConfigured) {
                          setUserConfirmedNetsuiteConfiguredHighlight(true);
                          return;
                        }
                        setCurrentStep(3);
                      }}
                    >
                      Next
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <>
                  {(currentStep ?? 0) > 2 && (
                    <StyledButton
                      type="link"
                      style={{ justifyContent: "flex-start" }}
                      onClick={() => {
                        setCurrentStep(2);
                      }}
                    >
                      Revisit
                    </StyledButton>
                  )}
                </>
              ),
          },
          {
            title: (
              <Typography
                style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
                color={currentStep === 3 ? "textPrimary" : "textSecondary"}
              >
                Provide the external URL
              </Typography>
            ),
            description:
              currentStep === 3 ? (
                <StyledStepContainer>
                  <label>External URL</label>
                  <Input
                    placeholder="ex: https://coeff13813.restlets.api.netsuite.com/app..."
                    value={userEnteredExternalURL}
                    onChange={e => setUserEnteredExternalURL(e.target.value)}
                  />
                  <StyledParagraph>
                    Paste the external URL that was generated in NetSuite.
                  </StyledParagraph>

                  <ButtonContainer>
                    <StyledButton
                      type="primary"
                      disabled={isLoading || !isValidHttpsUrl(userEnteredExternalURL)}
                      onClick={() => {
                        upsertAppInfoMutation.mutate();
                        setCurrentStep(undefined);
                        setShowAddNewAccountForm(false);
                      }}
                    >
                      Save
                    </StyledButton>
                  </ButtonContainer>
                </StyledStepContainer>
              ) : (
                <noscript />
              ),
          },
        ]}
      />
    );
  };

  return renderContent(
    <Content>
      <StyledAlert
        type="warning"
        showIcon
        message="You must be a NetSuite admin to configure"
        description="If you are a not a NetSuite admin, please contact them to configure for your domain.
        This is a one-time configuration for your account."
      />

      <Form>
        <FormGroup>{renderSteps()}</FormGroup>
      </Form>
    </Content>
  );
};
