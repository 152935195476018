import { DataSourceType } from "@coeff/api";
import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import * as React from "react";
import styled from "styled-components";

import {
  ActiveCampaign,
  Airtable,
  Amplitude,
  Asana,
  AwsBilling,
  Basecamp,
  Bigin,
  BigQuery,
  Calendly,
  Coda,
  Chargebee,
  Chargify,
  ClickUp,
  CSV,
  CustomAPI,
  Etsy,
  Excel,
  FacebookAds,
  Freshdesk,
  Gong,
  GoogleAds,
  GoogleAnalytics,
  GoogleAnalytics4,
  GoogleCalender,
  GoogleDrive,
  GoogleSearchConsole,
  Gmail,
  GoogleSheet,
  Greenhouse,
  Gusto,
  Harvest,
  HubSpot,
  Instagram,
  Intercom,
  Jira,
  Jotform,
  Klaviyo,
  Lever,
  LinkedIn,
  Looker,
  Mailchimp,
  Marketo,
  MongoDB,
  MSSQL,
  MySQL,
  Netsuite,
  Notion,
  OneDrive,
  Outreach,
  Paymo,
  Pinterest,
  Pipedrive,
  Podio,
  Postgres,
  Quickbooks,
  QUORA_ADS,
  Razorpay,
  RedditAds,
  Redshift,
  Salesforce,
  Sendgrid,
  Shopify,
  Smartsheet,
  Snowflake,
  Square,
  Stripe,
  SurveyMonkey,
  Tableau,
  TableIcon,
  Trello,
  Typeform,
  Webflow,
  Xero,
  YouTube,
  Zendesk,
  Zohobooks,
  ZohoCrm,
  Zoom,
  Salesloft,
  WordPress,
  WooCommerce,
  BigCommerce,
  Smartlead,
  Affinity,
  Shiprocket,
  Databricks,
  Rippling,
  Apollo,
  Sage,
} from "./";

interface ImportTypeIconProps {
  type?: DataSourceType;
  size?: AvatarSize;
}

const StyledAvatar = styled(Avatar)`
  background: none;

  svg {
    ${({ size }) => {
      switch (size) {
        case "large": {
          return `
            height: 40px;
            width: 40px;
        `;
        }
        case "default": {
          return `
            height: 32px !important;
            width: 32px !important;
        `;
        }
        default: {
          return `
          height: 40px;
          width: 40px;`;
        }
      }
    }}
  }
`;

export const ImportTypeIcon = ({ type, size = "large" }: ImportTypeIconProps) => {
  switch (type) {
    case "airtable":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Airtable />} />;
    case "amplitude":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Amplitude />} />;
    case "bigquery":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<BigQuery />} />;
    case "cloud_table":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<TableIcon />} />;
    case "chargebee":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Chargebee />} />;
    case "chargify":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Chargify />} />;
    case "csv":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<CSV />} />;
    case "gong":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Gong />} />;
    case "google_analytics":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleAnalytics />} />;
    case "google_drive":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleDrive />} />;
    case "google_sheets":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleSheet />} />;
    case "excel":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Excel />} />;
    case "hubspot":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<HubSpot />} />;
    case "instagram_insights":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Instagram />} />;
    case "jira":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Jira />} />;
    case "klaviyo":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Klaviyo />} />;
    case "linkedin_ads":
    case "linkedin_pages":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<LinkedIn />} />;
    case "looker":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Looker />} />;
    case "mailchimp":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Mailchimp />} />;
    case "marketo":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Marketo />} />;
    case "mongodb":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<MongoDB />} />;
    case "mysql":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<MySQL />} />;
    case "mssql":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<MSSQL />} />;
    case "netsuite":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Netsuite />} />;
    case "one_drive":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<OneDrive />} />;
    case "outreach":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Outreach />} />;
    case "pipedrive":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Pipedrive />} />;
    case "postgres":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Postgres />} />;
    case "quickbooks":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Quickbooks />} />;
    case "redshift":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Redshift />} />;
    case "salesforce":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Salesforce />} />;
    case "sendgrid":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Sendgrid />} />;
    case "shopify":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Shopify />} />;
    case "snowflake":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Snowflake />} />;
    case "stripe":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Stripe />} />;
    case "tableau":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Tableau />} />;
    case "zendesk":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Zendesk />} />;
    case "google_analytics_4":
      return (
        <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleAnalytics4 />} />
      );
    case "facebook_ads":
    case "facebook_insights":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<FacebookAds />} />;
    case "google_search_console":
      return (
        <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleSearchConsole />} />
      );
    case "gmail":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Gmail />} />;
    case "google_ads":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleAds />} />;
    case "notion":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Notion />} />;
    case "greenhouse":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Greenhouse />} />;
    case "xero":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Xero />} />;
    case "custom_api":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<CustomAPI />} />;
    case "youtube_analytics":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<YouTube />} />;
    case "asana":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Asana />} />;
    case "razorpay":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Razorpay />} />;
    case "clickup":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<ClickUp />} />;
    case "typeform":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Typeform />} />;
    case "harvest":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Harvest />} />;
    case "freshdesk":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Freshdesk />} />;
    case "paymo":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Paymo />} />;
    case "google_calendar":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<GoogleCalender />} />;
    case "trello":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Trello />} />;
    case "calendly":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Calendly />} />;
    case "smartsheet":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Smartsheet />} />;
    case "reddit_ads":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<RedditAds />} />;
    case "surveymonkey":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<SurveyMonkey />} />;
    case "quora_ads":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<QUORA_ADS />} />;
    case "podio":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Podio />} />;
    case "gusto":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Gusto />} />;
    case "intercom":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Intercom />} />;
    case "etsy":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Etsy />} />;
    case "pinterest_ads":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Pinterest />} />;
    case "active_campaign":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<ActiveCampaign />} />;
    case "basecamp":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Basecamp />} />;
    case "jotform":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Jotform />} />;
    case "zohobooks":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Zohobooks />} />;
    case "coda":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Coda />} />;
    case "square":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Square />} />;
    case "zoom":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Zoom />} />;
    case "webflow":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Webflow />} />;
    case "zoho_crm":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<ZohoCrm />} />;
    case "bigin":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Bigin />} />;
    case "lever":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Lever />} />;
    case "salesloft":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Salesloft />} />;
    case "wordpress":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<WordPress />} />;
    case "woo_commerce":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<WooCommerce />} />;
    case "bigcommerce":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<BigCommerce />} />;
    case "smartlead":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Smartlead />} />;
    case "affinity":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Affinity />} />;
    case "shiprocket":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Shiprocket />} />;
    case "databricks":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Databricks />} />;
    case "rippling":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Rippling />} />;
    case "apollo":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Apollo />} />;
    case "sage":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<Sage />} />;
    case "aws_billing":
      return <StyledAvatar className="dataSourceTypeIcon" size={size} icon={<AwsBilling />} />;
    default:
      return <StyledAvatar className="dataSourceTypeIcon" size={size} />;
  }
};
