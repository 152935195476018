import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

interface CardProps {
  className?: string;
  style?: object | undefined;
  showSeparator?: boolean;
  footerButtonText?: string;
  onFooterClick?: () => void;
  noMargin?: boolean;
}

interface CardHeaderProps {
  children?: React.ReactChildren | React.ReactChild | string | number | boolean;
  className?: string;
  icon: React.ReactNode;
  style?: object | undefined;
  showSeparator?: boolean;
  onClick?: () => void;
  title: string | React.ReactNode;
}

const StyledCard = styled.section<{ noMargin?: boolean }>`
  ${({ noMargin }) => {
    if (noMargin) {
      return "";
    }

    return `
        margin: 15px;
      `;
  }}

  background: ${COLORS.black4};
  border-radius: 5px;
  position: relative;
  transition: background-color 250ms linear;

  &.blue {
    background-color: ${COLORS.blue};
  }

  &.coeblue {
    background-color: ${COLORS.coeblue};
  }

  &.green {
    background-color: ${COLORS.greenBg};
  }

  &.orange {
    background-color: ${COLORS.orangeBg2};
  }

  &.yellow {
    background-color: ${COLORS.yellowBg};
  }

  &.red {
    background-color: ${COLORS.redBg};
  }

  &.white {
    background-color: white;

    > button:last-child {
      margin-bottom: 0px;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.shadow {
    box-shadow: inset 0 -10px 10px -10px ${COLORS.black15};
  }

  &.noseparation {
    margin-bottom: -20px;
    padding-bottom: 5px;
  }

  &.no-vertical-margin {
    margin: 0px 15px;
  }

  &.coeff__ppress,
  &.coeff__padded {
    padding: 8px;

    .coeff__card_header {
      margin-bottom: 10px;

      &.large {
        margin-bottom: 16px;
      }
    }
  }
`;

const StyledHeader = styled.div`
  .row {
    display: flex;
    height: auto;
    line-height: 20px;
    position: relative;
  }

  .sectionIcon {
    margin-right: 10px;
    width: 16px;

    svg {
      width: 100%;
      height: 100%;
    }

    .dataSourceTypeIcon {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  .title {
    flex: 1;
    font-weight: bold;
    color: ${COLORS.black85};
  }

  &.large {
    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .sectionIcon {
      margin: 0px 12px 0px 8px;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.recipe-icon .sectionIcon {
    margin-right: 10px;
    width: 40px;
  }
`;

export const Separator = styled.div`
  background: white;
  height: 2px;
  width: calc(100% - 50px);
  border-radius: 4px;
  margin: 10px 25px 8px;
`;

const HeaderSeparator = styled.div`
  background: white;
  height: 2px;
  width: calc(100% - 50px);
  border-radius: 4px;
  margin: 10px 25px 0px;
`;

const FooterButton = styled.button`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  outline: none;
  cursor: pointer;
  color: ${COLORS.coeblue4};
  padding: 8px;
  font-weight: 500;
  display: block;
  margin: auto;
  margin: 1rem auto 0px;
  border-radius: 5px;
  width: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

export const Card: React.FC<CardProps> = ({
  children,
  className,
  showSeparator,
  footerButtonText,
  onFooterClick,
  ...props
}) => {
  return (
    <StyledCard className={`coeff__card ${className}`} {...props}>
      {children}
      {showSeparator && <Separator />}
      {footerButtonText && <FooterButton onClick={onFooterClick}>{footerButtonText}</FooterButton>}
    </StyledCard>
  );
};

export const CardHeader = ({
  children,
  className,
  icon,
  title,
  showSeparator,
  ...props
}: CardHeaderProps) => {
  return (
    <StyledHeader className={`coeff__card_header ${className}`} {...props}>
      <div className="row">
        <div className="sectionIcon">{icon}</div>
        <div className="title">{title}</div>
        {children}
      </div>
      {showSeparator && <HeaderSeparator />}
    </StyledHeader>
  );
};
