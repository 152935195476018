import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  Alert,
  WritebackRunOverallStatusType,
  DashboardSpreadsheet,
  AddonPlatform,
  DataImport,
  Writeback,
  FeatureFlag,
} from "@coeff/api";
import { Dropdown, Menu } from "antd";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  Breadcrumb,
  BreadcrumbItem,
  DEPRECATED_Button,
  CaretRight,
  Ellipsis,
  EmailIconCircle,
  ImportTypeIcon,
  message,
  NewWindowNew,
  PushIcon,
  GoogleSheetsIcon,
  LoaderWithPerfTimings,
  ExcelSheetsIcon,
  SlackIconCircle,
  Typography,
  AlertIcon,
} from "../../../components";
import { AlertDescription } from "../../../components/AlertDescription";
import { COLORS } from "../../../constants";
import { getRefreshedDate, getScheduleSummaryText, orderByString, useTrack } from "../../../utils";
import { Header } from "../components";
import { useDashboardContext } from "../Dashboard";

import { AlertDetailsPage } from "./pages/AlertDetails";
import { ImportDetailsPage } from "./pages/ImportDetails";
import { WritebackDetailsPage } from "./pages/WritebackDetails";

const StyledContainer = styled.div`
  line-height: 1.3;
  padding: 20px 30px;
`;

const DetailsContainer = styled.div`
  margin-bottom: 50px;

  h3 {
    margin-top: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
`;

const ListCard = styled.div`
  width: 100%;
  max-width: 760px;
  background: ${COLORS.black2};
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;

  &.clickable,
  .ant-dropdown-trigger {
    cursor: pointer;

    &:hover {
      background: ${COLORS.black4};
    }
  }

  .ellipsis {
    background: ${COLORS.black2};
    display: block;
    height: 32px;
    width: 32px;
    line-height: 36px;
    border: none;
    border-radius: 50%;
    position: relative;
    right: -8px;

    &:hover {
      background: ${COLORS.white};
    }
  }
`;
const ListLogo = styled.div`
  margin: 0 15px 0 0;
  position: relative;

  .ant-avatar {
    height: 40px;
    width: 40px;
  }
  svg {
    height: 40px;
    width: 40px;
  }

  .coeff__push_icon {
    width: auto;
    height: auto;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const ListDetails = styled.div`
  flex: 1;

  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
`;

const StyledSchedule = styled.div`
  display: inline-block;
  margin-top: 4px;
  padding: 0px 8px;
  background-color: ${COLORS.seafoamBg};
  border-radius: 4px;
  color: ${COLORS.seafoam};
`;

const ShowAction = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;

  .overlap-icon {
    position: relative;
    right: 12px;
  }
`;

const renderSlackEmailIcon = (sendInfo: Alert["send_info"]) => {
  return (
    <IconContainer
      style={{
        width: 50,
        marginTop: 2,
        left: sendInfo.slack_enabled && sendInfo.email_enabled ? 4 : 0,
        position: sendInfo.slack_enabled && sendInfo.email_enabled ? "relative" : undefined,
      }}
    >
      {sendInfo.email_enabled && <EmailIconCircle style={{ minWidth: 32, maxWidth: 40 }} />}

      {sendInfo.slack_enabled && (
        <SlackIconCircle
          style={{ minWidth: 32, maxWidth: 40 }}
          className={clsx({ "overlap-icon": sendInfo.email_enabled })}
        />
      )}

      {!sendInfo.email_enabled && !sendInfo.slack_enabled ? <AlertIcon /> : null}
    </IconContainer>
  );
};

export const SpreadsheetDetails = () => {
  const track = useTrack();

  const {
    getAlerts,
    getDataImports,
    getWritebacks,
    updateAlert,
    spreadsheets,
    writebackDisabledForUser,
  } = useDashboardContext();

  const history = useHistory();

  const { value: enabledAlertsV2 } = useGate(FeatureFlag.EnableAlertsV2);

  const params = useParams<{ ssId: string }>();

  const { path, url } = useRouteMatch();

  const [loading, setLoading] = useState<boolean>(true);

  const [alerts, setAlerts] = useState<Array<Alert>>([]);

  const [dataImports, setDataImports] = useState<Array<DataImport>>([]);

  const [writebacks, setWritebacks] = useState<Array<Writeback>>([]);

  const [showAllImports, setShowAllImports] = useState<boolean>(false);

  const [showAllWritebacks, setShowAllWritebacks] = useState<boolean>(false);

  const [showAllAlerts, setShowAllAlerts] = useState<boolean>(false);

  const spreadsheet = spreadsheets?.find(
    s => s.google_sheets_file_id === params.ssId
  ) as DashboardSpreadsheet;

  useEffect(() => {
    const init = async () => {
      const [alertsResponse, dataImportsResponse, writebacksResponse] = await Promise.all([
        getAlerts(spreadsheet.google_sheets_file_id),
        getDataImports(spreadsheet.google_sheets_file_id),
        getWritebacks(spreadsheet.google_sheets_file_id),
      ]);

      setAlerts(alertsResponse.alerts);
      setDataImports(dataImportsResponse.data_imports);
      setWritebacks(writebacksResponse.writebacks);
      setLoading(false);
    };

    if (spreadsheet) {
      track("workspace_spreadsheet_viewed", {
        ss_id: spreadsheet.google_sheets_file_id,
        ss_name: spreadsheet.google_sheets_file_name,
      });

      init();
    }
    if (!spreadsheet && spreadsheets!.length > 0) {
      message.error("You do not have access to this spreadsheet.");
      history.push("/dashboard/spreadsheets");
    }
  }, [spreadsheets]);

  const importsSorted = orderByString(dataImports, "data_import_name");
  const alertsSorted = orderByString(alerts, "alert_name");
  const writebacksSorted = orderByString(writebacks, "writeback_name");

  const importsLimited =
    !showAllImports && importsSorted.length > 5 ? importsSorted.slice(0, 20) : importsSorted;

  const writebacksLimited =
    !showAllWritebacks && writebacksSorted.length > 5
      ? writebacksSorted.slice(0, 20)
      : writebacksSorted;

  const alertsLimited =
    !showAllAlerts && alertsSorted.length > 5 ? alertsSorted.slice(0, 20) : alertsSorted;

  const toggleAlertPause = async (alert: Alert) => {
    await updateAlert({
      ...alert,
      options: { ...alert.options, is_paused: !alert.options?.is_paused },
    });

    const { alerts } = await getAlerts(spreadsheet.google_sheets_file_id);

    setAlerts(alerts);
  };

  const alertMenu = (alert: Alert) => (
    <Menu>
      <Menu.Item key="pause" onClick={() => toggleAlertPause(alert)}>
        {alert.options?.is_paused ? "Unpause" : "Pause"}
      </Menu.Item>
    </Menu>
  );

  const onOpenSheetClick = () => {
    track("workspace_open_sheet_clicked", {
      event_from: "spreadsheet",
      ss_id: spreadsheet.google_sheets_file_id,
      ss_name: spreadsheet.google_sheets_file_name,
    });
  };

  return (
    <Switch>
      <Route exact path={path}>
        <StyledContainer>
          <Header>
            {spreadsheet?.spreadsheet_platform === AddonPlatform.GoogleSheets ? (
              <GoogleSheetsIcon style={{ position: "relative", top: "10px" }} />
            ) : (
              <ExcelSheetsIcon style={{ position: "relative", top: "10px" }} />
            )}

            <div className="details" style={{ maxWidth: "590px" }}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={{ pathname: `/dashboard/spreadsheets` }}>Spreadsheets</Link>
                </BreadcrumbItem>
                <div style={{ visibility: "hidden" }}></div>
              </Breadcrumb>
              <h1>{spreadsheet?.google_sheets_file_name}</h1>
            </div>
            {spreadsheet?.spreadsheet_url && (
              <div>
                <DEPRECATED_Button
                  type="primary"
                  href={spreadsheet?.spreadsheet_url ?? ""}
                  style={{ height: "40px", lineHeight: "40px" }}
                  target="_blank"
                  onClick={onOpenSheetClick}
                >
                  Open Sheet{" "}
                  <NewWindowNew style={{ position: "relative", top: "2px", marginLeft: "6px" }} />
                </DEPRECATED_Button>
              </div>
            )}
          </Header>

          <DetailsContainer>
            <h3>Imports</h3>
            <div>
              {loading && (
                <div style={{ margin: "5rem auto 2rem", textAlign: "center" }}>
                  <LoaderWithPerfTimings name="SpreadsheetDetails" size="large" />
                </div>
              )}
              {!loading &&
                importsLimited.map(di => {
                  const scheduleInfo =
                    di.options && di.options.schedule ? di.options.schedule : null;
                  const schedule = getScheduleSummaryText(
                    scheduleInfo,
                    di.options?.snapshot_schedule
                  );
                  return (
                    <ListCard
                      className="clickable"
                      key={di.data_import_id}
                      onClick={() => history.push(`${url}/imports/${di.data_import_id}`)}
                    >
                      <ListLogo>
                        <ImportTypeIcon type={di.data_source_type} />
                      </ListLogo>
                      <ListDetails>
                        <h4>{di.data_import_name}</h4>

                        <Typography color={COLORS.black45}>
                          {di.last_import_status &&
                          di?.last_import_status?.status_level === "error" ? (
                            <>
                              <ExclamationCircleFilled
                                style={{ color: COLORS.red, marginRight: 2 }}
                              />{" "}
                              Import failed
                            </>
                          ) : (
                            <>Refreshed {getRefreshedDate(moment.utc(di.last_run_dt).local())}</>
                          )}
                        </Typography>

                        {schedule && <StyledSchedule>{schedule}</StyledSchedule>}
                      </ListDetails>
                      <CaretRight />
                    </ListCard>
                  );
                })}
              {!loading && dataImports.length <= 0 && (
                <span>Import data from any data source you connect to</span>
              )}
            </div>

            {dataImports.length > 20 && (
              <ShowAction onClick={() => setShowAllImports(!showAllImports)}>{`Show ${
                showAllImports ? "less" : "all"
              } imports`}</ShowAction>
            )}

            {!writebackDisabledForUser && writebacks.length > 0 && (
              <>
                <h3>Exports</h3>
                <div>
                  {writebacksLimited.map(w => {
                    const scheduleInfo = w.options?.schedule;
                    const schedule = getScheduleSummaryText(scheduleInfo, null, {
                      prefixText: "Exports ",
                    });

                    return (
                      <ListCard
                        className="clickable"
                        key={w.writeback_id}
                        onClick={() => history.push(`${url}/exports/${w.writeback_id}`)}
                      >
                        <ListLogo>
                          <ImportTypeIcon type={w?.data_source_type} />
                          <PushIcon className="coeff__push_icon" />
                        </ListLogo>
                        <ListDetails>
                          <h4>{w.writeback_name}</h4>

                          <div>
                            <span style={{ color: COLORS.black45 }}>
                              {(!w.last_writeback_run_status ||
                                !w.last_writeback_run_status.status_dt) &&
                                `Never exported`}

                              {w.last_writeback_run_status?.overall_status ===
                                WritebackRunOverallStatusType.Ok &&
                                Boolean(w.last_writeback_run_status.status_dt) &&
                                `Exported ${getRefreshedDate(
                                  moment.utc(w.last_writeback_run_status.status_dt).local()
                                )}`}

                              {w.last_writeback_run_status?.overall_status !==
                                WritebackRunOverallStatusType.Ok &&
                                w.last_writeback_run_status?.overall_status !==
                                  WritebackRunOverallStatusType.SomeRecordsFailed && (
                                  <>
                                    <ExclamationCircleFilled
                                      style={{ color: COLORS.red, marginRight: 2 }}
                                    />{" "}
                                    Last export failed
                                  </>
                                )}

                              {w.last_writeback_run_status?.overall_status ===
                                WritebackRunOverallStatusType.SomeRecordsFailed &&
                                w.last_writeback_run_status.num_successful_rows === 0 && (
                                  <>
                                    <ExclamationCircleFilled
                                      style={{ color: COLORS.red, marginRight: 2 }}
                                    />{" "}
                                    Last export failed
                                  </>
                                )}

                              {w.last_writeback_run_status?.overall_status ===
                                WritebackRunOverallStatusType.SomeRecordsFailed &&
                                (w.last_writeback_run_status.num_successful_rows ?? 0) > 0 && (
                                  <>
                                    <ExclamationCircleFilled
                                      style={{ color: COLORS.orange, marginRight: 2 }}
                                    />{" "}
                                    Last exported with failures
                                  </>
                                )}
                            </span>
                          </div>
                          {schedule && <StyledSchedule>{schedule}</StyledSchedule>}
                        </ListDetails>
                        <CaretRight />
                      </ListCard>
                    );
                  })}
                </div>

                {!writebackDisabledForUser && writebacks.length > 20 && (
                  <ShowAction onClick={() => setShowAllWritebacks(!showAllWritebacks)}>{`Show ${
                    showAllWritebacks ? "less" : "all"
                  } export actions`}</ShowAction>
                )}
              </>
            )}

            {alerts.length > 0 && (
              <>
                <h3>Data Alerts</h3>

                <div>
                  {alertsLimited.map(alert => (
                    <ListCard
                      key={alert.alert_id}
                      className={enabledAlertsV2 ? "clickable" : undefined}
                      onClick={
                        enabledAlertsV2
                          ? () => history.push(`${url}/alerts/${alert.alert_id}`)
                          : undefined
                      }
                    >
                      <ListLogo>{renderSlackEmailIcon(alert.send_info)}</ListLogo>

                      <ListDetails>
                        <h4>{alert.alert_name}</h4>

                        <AlertDescription variant="body2" alert={alert} />
                      </ListDetails>

                      <div onClick={e => e.stopPropagation()}>
                        <Dropdown overlay={alertMenu(alert)} trigger={["click"]}>
                          <button className="ellipsis">
                            <Ellipsis />
                          </button>
                        </Dropdown>
                      </div>
                    </ListCard>
                  ))}
                </div>

                {alerts.length > 20 && (
                  <ShowAction onClick={() => setShowAllAlerts(!showAllAlerts)}>{`Show ${
                    showAllAlerts ? "less" : "all"
                  } data alerts`}</ShowAction>
                )}
              </>
            )}
          </DetailsContainer>
        </StyledContainer>
      </Route>

      <Switch>
        <Route path={`${path}/imports/:importId`}>
          <ImportDetailsPage dataImports={dataImports} />
        </Route>

        <Route path={`${path}/exports/:writebackId`}>
          <WritebackDetailsPage writebacks={writebacks} />
        </Route>

        <Route path={`${path}/alerts/:alertId`}>
          <AlertDetailsPage />
        </Route>
      </Switch>
    </Switch>
  );
};
