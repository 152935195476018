import { CloudTable } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import { Avatar, TableIcon, TemplateIcons } from ".";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  cloudTable: Pick<CloudTable, "icon_name" | "icon_background_color">;
};

export const CloudTableIcon: React.FC<Props> = ({ cloudTable, ...rest }) => {
  const Icon = cloudTable.icon_name ? TemplateIcons[cloudTable.icon_name] : undefined;

  return (
    <Container {...rest}>
      {Icon ? (
        <Avatar
          iconSize="28px"
          size="large"
          backgroundColor={cloudTable.icon_background_color}
          icon={<Icon />}
        />
      ) : (
        <TableIcon />
      )}
    </Container>
  );
};
