import { ModalProps } from "antd";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { COLORS } from "../constants";
import { AppState } from "../store";
import { submitCloudTablesOAuthForm } from "../utils";

import { Button, ConnectPlugIcon, GoogleDrive, Lock, Typography, Modal, message } from ".";

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-beween;
  gap: 40px;
  padding-top: 32px;
`;

const ContentIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ContentText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 1.3;
  margin-top: 64px;
`;

const StyledButton = styled(Button)`
  max-width: 200px;
`;

type ConnectProps = {
  email: string;
};

type Props = ModalProps & ConnectProps & { redirectPath: string };

const GoogleAuthModalBase: React.FC<Props> = ({ email, redirectPath, ...rest }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAuthorizeClick = () => {
    try {
      setIsLoading(true);

      submitCloudTablesOAuthForm({ email, redirectPath });
    } catch (error) {
      message.error("Failed to authorize");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal {...rest}>
      <Content>
        <ContentIcons>
          <ConnectPlugIcon height="60" />
          <GoogleDrive width="60" height="60" />
        </ContentIcons>

        <ContentText>
          <Typography fontWeight="bold" variant="h6">
            Let's connect to Google Drive
          </Typography>

          <Typography color="textSecondary">
            Authorize Coefficient to
            <br /> connect to Google Drive
          </Typography>
        </ContentText>

        <StyledButton
          disabled={isLoading}
          block
          type="primary"
          onClick={() => handleAuthorizeClick()}
        >
          Authorize
        </StyledButton>

        <ContentFooter>
          <Lock fill={COLORS.black} />

          <Typography variant="body3" color="textSecondary">
            Coefficient encrypts all of your credentials end-to-end,
            <br /> including OAuth tokens, API Keys, and passwords.
          </Typography>
        </ContentFooter>
      </Content>
    </Modal>
  );
};

export const GoogleAuthModal = connect(({ app }: AppState): ConnectProps => ({ email: app.email }))(
  GoogleAuthModalBase
);
