export function assertIsDefined<T>(val: T | undefined): asserts val is T {
  if (val === undefined) {
    throw new Error(`Expected value to be defined.`);
  }
}

export function assertNotNull<T>(val: T | null): asserts val is T {
  if (val === null) {
    throw new Error(`Expected value to be not null.`);
  }
}
