import { CloudTable } from "@coeff/api";
import { Dropdown, Menu, Popover } from "antd";
import React, { useRef } from "react";
import styled from "styled-components";

import { EllipsisMenu, Typography, Button, Input, TextArea, IconPicker } from "../../components";
import { CloudTableIcon } from "../../components/CloudTableIcon";
import { COLORS } from "../../constants";

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 0;
`;

const TitleIconContainer = styled.div`
  position relative;
  cursor: pointer;
`;

const TitleText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

type Props = {
  cloudTable: CloudTable;
  onChange: (cloudTable: CloudTable) => void;
  onDelete: () => void;
};

export const CloudTableTitle: React.FC<Props> = ({ cloudTable, onChange, onDelete }) => {
  const titleIconContainerRef = useRef<HTMLDivElement | null>(null);

  const [name, setName] = React.useState(cloudTable.cloud_table_name);

  const [isEditingName, setIsEditingName] = React.useState(false);

  const [description, setDescription] = React.useState(cloudTable.description);

  const [isEditingDescription, setIsEditingDescription] = React.useState(false);

  const [isIconPickerVisible, setIsIconPickerVisible] = React.useState(false);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsEditingName(true)}>Rename</Menu.Item>

      <Menu.Item onClick={() => setIsEditingDescription(true)}>Edit Description</Menu.Item>

      <Menu.Item onClick={() => setIsIconPickerVisible(true)}>Change Icon</Menu.Item>

      <Menu.Item onClick={() => onDelete()}>Delete</Menu.Item>
    </Menu>
  );

  const handleNameSubmit = () => {
    if (name && name.length) {
      onChange({
        ...cloudTable,
        cloud_table_name: name,
      });
    }

    setIsEditingName(false);
  };

  const handleDescriptionSubmit = () => {
    onChange({
      ...cloudTable,
      description,
    });

    setIsEditingDescription(false);
  };

  return (
    <Title>
      <TitleIconContainer ref={titleIconContainerRef}>
        <Popover
          placement="bottomRight"
          arrowPointAtCenter={false}
          trigger="click"
          open={isIconPickerVisible}
          onVisibleChange={visible => setIsIconPickerVisible(visible)}
          getPopupContainer={triggerNode => titleIconContainerRef.current ?? triggerNode}
          content={
            <IconPicker
              color={cloudTable.icon_background_color}
              icon={cloudTable.icon_name}
              onColorChange={icon_background_color => {
                onChange({
                  ...cloudTable,
                  icon_background_color,
                });
              }}
              onIconChange={icon_name => {
                onChange({
                  ...cloudTable,
                  icon_name,
                });
              }}
            />
          }
        >
          <CloudTableIcon cloudTable={cloudTable} />
        </Popover>
      </TitleIconContainer>

      <TitleText>
        {isEditingName ? (
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            onBlur={() => handleNameSubmit()}
            onPressEnter={() => handleNameSubmit()}
            autoFocus
          />
        ) : (
          <Typography variant="body1" fontWeight={600} noWrap>
            {cloudTable.cloud_table_name}
          </Typography>
        )}

        {isEditingDescription ? (
          <TextArea
            style={{ marginTop: 4 }}
            value={description}
            onChange={e => setDescription(e.target.value)}
            onBlur={() => handleDescriptionSubmit()}
            onPressEnter={() => handleDescriptionSubmit()}
            autoFocus
          />
        ) : cloudTable.description ? (
          <Typography
            title={cloudTable.description}
            lineClamp={2}
            variant="body3"
            color="textSecondary"
          >
            {cloudTable.description}
          </Typography>
        ) : null}
      </TitleText>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="text" icon={<EllipsisMenu fill={COLORS.black} />}></Button>
      </Dropdown>
    </Title>
  );
};
