import { Button as ButtonAD, ButtonProps as ButtonPropsAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS, HEIGHT, TEXT_SIZE } from "../constants";

export interface ButtonProps extends ButtonPropsAD {
  block?: boolean;
  children?: React.ReactChild[] | React.ReactChild | string;
  className?: string;
  disabled?: boolean;
  href?: string;
  htmlType?: "button" | "reset" | "submit" | undefined;
  id?: string;
  loading?: boolean;
  icon?: React.ReactNode;
  onClick?: (event) => void;
  style?: object | undefined;
  size?: "middle" | "large" | "small" | undefined;
  target?: string;
  type?: "default" | "link" | "primary" | undefined;
}

const StyledButton = styled(ButtonAD)`
  &.ant-btn {
    border-color: ${COLORS.black15};
    border-radius: 5px;
    color: ${COLORS.black65};
    font-size: ${({ size }) =>
      size === "large" ? TEXT_SIZE.lg : size === "small" ? TEXT_SIZE.sm : TEXT_SIZE.md};
    font-weight: 500;
    height: ${({ size }) =>
      size === "large" ? HEIGHT.lg : size === "small" ? HEIGHT.sm : HEIGHT.md};
    line-height: 22px;
    padding: 0 15px;
    animation-duration: 0s !important;

    &:active {
      border-color: ${COLORS.coeblue7};
      color: ${COLORS.coeblue7};
    }

    &:disabled {
      color: ${COLORS.black25};
      background-color: ${COLORS.black9};
      border-color: ${COLORS.black15};
      pointer-events: none;
      opacity: 0.35;
    }

    &:focus {
      border-color: ${COLORS.coeblue4};
      color: ${COLORS.coeblue4};
    }

    &:hover {
      border-color: ${COLORS.coeblue4};
      color: ${COLORS.coeblue4};
    }
  }

  &.ant-btn-primary {
    background-color: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
    color: ${COLORS.white};

    &:active {
      background-color: ${COLORS.coeblue7};
      border-color: ${COLORS.coeblue7};
      color: ${COLORS.white};
      box-shadow: none;
    }

    &:disabled {
      background-color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
      color: ${COLORS.white};
    }

    &:focus {
      background-color: ${COLORS.coeblue5};
      border-color: ${COLORS.coeblue5};
      color: ${COLORS.white};
      box-shadow: none;
    }

    &:hover {
      background-color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
      color: ${COLORS.white};
    }
  }

  &.ant-btn-link {
    border-color: ${COLORS.white};

    &:active {
      background-color: ${COLORS.black4};
      color: ${COLORS.black};
    }

    &:disabled {
      color: ${COLORS.black25};
      background-color: ${COLORS.white};
      border-color: ${COLORS.white};
    }

    &:focus {
      background-color: transparent;
      color: ${COLORS.black65};
    }

    &:hover {
      background-color: ${COLORS.black4};
      color: ${COLORS.black65};
    }
  }

  &.light {
    color: ${COLORS.coeblue4};
    background: ${COLORS.coeblue};
    border-color: ${COLORS.coeblue};

    &:focus {
      border-color: ${COLORS.coeblue};
      box-shadow: none;
    }

    &:hover {
      border-color: ${COLORS.coeblue};
    }
  }
`;

export const DEPRECATED_Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};
