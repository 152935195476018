import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

export const BulletList = styled.ul`
  margin: 24px 0px 0px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }

    svg {
      margin-right: 9px;
      margin-top: 5px;
    }

    > div {
      flex: 1;
    }
  }
`;

export const BulletList2 = styled(BulletList)`
  margin: 7px 0px 0px;

  li {
    font-size: 14px;
    font-weight: unset;
    line-height: 7px;
    padding-right: 50px;

    &:last-child {
      margin-bottom: 10px;
    }
  }
`;

export const Highlight = styled.span`
  background: ${COLORS.orangeBg2};
`;
