import { DataImport } from "@coeff/api-admin-ext";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SortColumn, SortDirection } from "../actions";
import { API_EXT_ADMIN_ROUTES } from "../constants";
import { useApiContext } from "../utils";

import { DEPRECATED_ApiManager, QueryCacheKey } from ".";

export type FetchDataImportsParams = {
  domain?: string;
  pageSize: number;
  offset: number;
  sortColumn: SortColumn;
  filters?: string;
};

export const fetchAdminDataImports = async (
  deprecatedAPI: DEPRECATED_ApiManager,
  params: FetchDataImportsParams = {
    pageSize: 100,
    offset: 0,
    sortColumn: {
      columnKey: "last_run_dt",
      value: SortDirection.descend,
    },
  }
): Promise<{ dataImports: DataImport[]; totalRecords: number }> => {
  if (params.domain) {
    deprecatedAPI.setDomainParam(params.domain);
  }

  const { data_imports: dataImports, total_records: totalRecords } = await deprecatedAPI.request({
    method: "GET",
    url: `${API_EXT_ADMIN_ROUTES.dataImports}?page_size=${params.pageSize}&offset=${params.offset}&sort_by=${params.sortColumn.columnKey}&sort_order=${params.sortColumn.value}&filters=${params.filters}`,
  });

  return { dataImports, totalRecords };
};

export const useAdminDataImports = (
  params: FetchDataImportsParams,
  { enabled }: UseQueryOptions = {}
) => {
  const { DEPRECATED_api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DATA_IMPORTS, params],
    () => fetchAdminDataImports(DEPRECATED_api, params),
    {
      enabled,
    }
  );
};
