import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { DashboardSpreadsheet, DataImport, DataImportRun, DataImportStatusLevel } from "@coeff/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  Breadcrumb,
  BreadcrumbItem,
  DEPRECATED_Button,
  Card as _Card,
  CardHeader as _CardHeader,
  Columns,
  ConfirmBox,
  ErrorMessage,
  Filter as FilterIcon,
  FiltersAndSorts,
  ImportTypeIcon,
  NewWindowNew,
  DEPRECATED_Scheduler,
  SelectedColumns,
  Separator,
  Settings,
  SourceFile,
  SQL as SQLIcon,
  Table,
  Tag,
  Tooltip,
  WarningMessage,
  LoaderWithPerfTimings,
} from "../../../../components";
import { COLORS, SQL_DATASOURCES } from "../../../../constants";
import { capitalize, getRefreshedDate, useTrack } from "../../../../utils";
import { Header } from "../../components";
import { useDashboardContext } from "../../Dashboard";

const Sidebar = styled.div`
  width: 300px;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-left: 2px solid ${COLORS.black15};
  background: white;
  font-size: 13px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  .subText {
    font-size: 12px;
    color: ${COLORS.black45};
    margin: 4px 0px;
  }
`;

const Wrap = styled.div`
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 12px;

    .infoText {
      float: right;
      color: ${COLORS.black45};
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

const SQLQueryWrap = styled.div`
  margin-left: 25px;
  color: ${COLORS.black65};
  font-weight: bold;
  font-family: "Courier New", monospace;
  position: relative;
  overflow: hidden;
  max-height: 95px;
  min-height: 95px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background-image: linear-gradient(transparent, ${COLORS.coeblue});
  }
`;

const StyledTag = styled(Tag)`
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  border: none;
  font-weight: 500;

  .text {
    color: ${COLORS.black85};
    font-size: 14px;
  }
`;

const Card = styled(_Card)``;

const CardHeader = styled(_CardHeader)``;

export const ImportDetailsPage = ({ dataImports }: { dataImports: DataImport[] }) => {
  const track = useTrack();

  const { getDataImportRunHistory, spreadsheets } = useDashboardContext();
  const [dataImportRuns, setDataImportRuns] = useState<DataImportRun[]>([]);
  const [showFailedRunError, setShowFailedRunError] = useState<{
    status_level?: DataImportStatusLevel;
    title?: string;
    message?: string;
  } | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const params = useParams<{ importId: string; ssId: string }>();
  const history = useHistory();

  const dataImport = dataImports.find(di => di.data_import_id === params.importId);

  const spreadsheet = spreadsheets?.find(
    ss => ss.google_sheets_file_id === params.ssId
  ) as DashboardSpreadsheet;

  useEffect(() => {
    if (dataImport && spreadsheet) {
      track("workspace_import_viewed", {
        data_import_id: dataImport?.data_import_id,
        ss_id: spreadsheet.google_sheets_file_id,
        ss_name: spreadsheet.google_sheets_file_name,
      });
    }
  }, [dataImport, spreadsheet]);

  useEffect(() => {
    const getRuns = async () => {
      if (dataImport) {
        setLoading(true);
        const { data_import_runs } = await getDataImportRunHistory(
          dataImport.data_import_id ?? "",
          1
        );

        setDataImportRuns(data_import_runs);
        setLoading(false);
      }
      if (!dataImport && dataImports.length > 0) {
        history.push(`/dashboard/spreadsheets/${spreadsheet.google_sheets_file_id}`);
      }
    };
    getRuns();
  }, [dataImport, spreadsheets, dataImports]);

  const getSelectedObjectText = (): React.ReactNode | null => {
    if (
      dataImport?.data_source_type === "salesforce" &&
      dataImport?.source_info?.salesforce_fetch_mode === "objects"
    ) {
      return `Object: ${dataImport?.source_info?.salesforce_object_name}`;
    }
    if (
      dataImport?.data_source_type === "salesforce" &&
      dataImport?.source_info?.salesforce_fetch_mode === "reports"
    ) {
      return `Report: ${dataImport?.source_info?.salesforce_report_name}`;
    }
    if (dataImport?.data_source_type === "hubspot") {
      return `Object: ${dataImport?.source_info?.hubspot_object_name}`;
    }

    if (
      SQL_DATASOURCES.includes(dataImport?.data_source_type ?? "") &&
      !dataImport?.source_info?.sql_is_raw_query_mode
    ) {
      return `Table: ${dataImport?.source_info?.sql_table_name}`;
    }

    return null;
  };

  const selectedObject = getSelectedObjectText();

  const isRawQueryMode: boolean | undefined =
    (dataImport?.data_source_type === "salesforce" &&
      dataImport.source_info?.salesforce_objects_is_raw_query_mode) ||
    (SQL_DATASOURCES.includes(dataImport?.data_source_type ?? "") &&
      dataImport?.source_info?.sql_is_raw_query_mode);

  const onOpenSheetClick = () => {
    track("workspace_open_sheet_clicked", {
      event_from: "import_details",
      data_import_id: dataImport?.data_import_id,
      import_data_source: dataImport?.data_source_type,
      ss_id: spreadsheet.google_sheets_file_id,
      ss_name: spreadsheet.google_sheets_file_name,
    });
  };

  const onDataImportRunPageNumberChange = async (page, pageSize) => {
    const isCurrentlyOnLastPage = page === Math.ceil(dataImportRuns.length / pageSize);

    if (isCurrentlyOnLastPage) {
      const { data_import_runs } = await getDataImportRunHistory(
        dataImport?.data_import_id ?? "",
        dataImportRuns.length + 1
      );

      setDataImportRuns([...dataImportRuns, ...data_import_runs]);
    }
  };

  return (
    <div style={{ maxWidth: "calc(100% - 300px)", padding: "20px 30px" }}>
      {loading && (
        <div style={{ margin: "5rem auto 2rem", textAlign: "center" }}>
          <LoaderWithPerfTimings name="ImportDetails" size="large" />
        </div>
      )}
      {!loading && (
        <div>
          <Wrap>
            <Header>
              <div>
                {dataImport?.data_source_type && (
                  <ImportTypeIcon type={dataImport?.data_source_type} />
                )}
              </div>
              <div className="details">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={{ pathname: `/dashboard/spreadsheets` }}>Spreadsheets</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link
                      to={{
                        pathname: `/dashboard/spreadsheets/${spreadsheet.google_sheets_file_id}`,
                        search: window.location.search,
                      }}
                    >
                      {spreadsheet.google_sheets_file_name}
                    </Link>
                  </BreadcrumbItem>
                  <div style={{ visibility: "hidden" }}></div>
                </Breadcrumb>
                <h1>{dataImport?.data_import_name}</h1>
                <span className="refreshDate">{`Refreshed ${getRefreshedDate(
                  moment.utc(dataImport?.last_run_dt).local()
                )}`}</span>
              </div>
              {spreadsheet?.spreadsheet_url && (
                <div>
                  <DEPRECATED_Button
                    type="primary"
                    href={dataImport?.data_import_url}
                    style={{ height: "40px", lineHeight: "40px" }}
                    target="_blank"
                    onClick={onOpenSheetClick}
                  >
                    Open Sheet{" "}
                    <NewWindowNew style={{ position: "relative", top: "2px", marginLeft: "6px" }} />
                  </DEPRECATED_Button>
                </div>
              )}
            </Header>

            <div style={{ marginLeft: "46px" }}>
              <div className="title">
                Run History
                <span className="infoText">Displaying last 30 days</span>
              </div>

              <Table
                dataSource={dataImportRuns}
                pagination={
                  dataImportRuns.length > 25
                    ? {
                        position: ["bottomCenter"],
                        size: "small",
                        pageSize: 25,
                        showSizeChanger: false,
                        onChange: onDataImportRunPageNumberChange,
                      }
                    : false
                }
                columns={[
                  {
                    title: "Status",
                    dataIndex: "status_type",
                    key: "status_type",
                    render: (text, record) => (
                      <>
                        <StyledTag
                          color={
                            text === "failed"
                              ? record?.data_import_status?.status_level === "warning"
                                ? "orange"
                                : "red"
                              : text === "ok"
                              ? "green"
                              : ""
                          }
                        >
                          {text === "failed" ? (
                            <ExclamationCircleFilled />
                          ) : text === "ok" ? (
                            <CheckCircleFilled />
                          ) : (
                            ""
                          )}
                          <span className="text">
                            {record?.data_import_status?.status_level === "warning"
                              ? "Warning"
                              : capitalize(text)}
                          </span>
                        </StyledTag>
                        {["error", "warning"].includes(
                          record.data_import_status?.status_level ?? ""
                        ) && (
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setShowFailedRunError(record.data_import_status!);
                            }}
                          >
                            View Details
                          </a>
                        )}
                      </>
                    ),
                  },
                  {
                    title: "Time",
                    dataIndex: "run_complete_dt",
                    key: "run_complete_dt",
                    render: d => {
                      if (d) {
                        const date = moment(d);
                        return date.format("MMM DD, YYYY hh:mmA");
                      } else {
                        return "";
                      }
                    },
                  },
                  {
                    title: "Refresh Trigger",
                    dataIndex: "trigger_type",
                    key: "trigger_type",
                    render: (triggerType, record) => {
                      return `${capitalize(triggerType)} ${
                        triggerType === "manual" ? "(" + record.user_display_name + ")" : ""
                      }`;
                    },
                  },
                  {
                    title: (
                      <div>
                        <Tooltip title="Rows imported includes the heading row.">
                          <span style={{ borderBottom: "1px dotted " + COLORS.black65 }}>Rows</span>
                        </Tooltip>
                      </div>
                    ),
                    dataIndex: "num_rows",
                    key: "num_rows",
                    className: "center",
                  },
                  {
                    title: "Columns",
                    dataIndex: "num_columns",
                    key: "num_columns",
                    className: "center",
                  },
                ]}
              />
            </div>
          </Wrap>

          <Sidebar>
            <Card className="white">
              <div>
                <CardHeader title="Settings" icon={<Settings />} />
                <div className="subText">Open in spreadsheet to make changes.</div>
              </div>
            </Card>

            <div style={{ position: "relative", opacity: "0.5" }}>
              <DEPRECATED_Scheduler
                schedule={dataImport?.options?.schedule}
                snapshot_schedule={dataImport?.options?.snapshot_schedule}
                ss_id={spreadsheet.google_sheets_file_id}
              />

              <Card className="coeblue coeff__padded">
                <div>
                  {selectedObject && (
                    <>
                      <CardHeader
                        title={selectedObject}
                        icon={<SourceFile fill={COLORS.black85} />}
                      />
                      <Separator />
                    </>
                  )}
                  {!isRawQueryMode && (
                    <>
                      <CardHeader
                        title={`${dataImport?.columns?.length} fields`}
                        icon={<Columns />}
                      />
                      <div>
                        <SelectedColumns columns={dataImport?.columns ?? []} />
                      </div>
                    </>
                  )}

                  {isRawQueryMode && (
                    <>
                      <CardHeader
                        title={`Custom S${
                          dataImport?.data_source_type === "salesforce" ? "O" : ""
                        }QL Query`}
                        icon={<SQLIcon />}
                      />
                      <SQLQueryWrap>
                        {dataImport?.source_info?.sql_raw_query ??
                          dataImport?.source_info?.salesforce_soql_query}
                      </SQLQueryWrap>
                    </>
                  )}
                </div>
              </Card>

              {!isRawQueryMode && (
                <Card className="coeff__padded">
                  <div>
                    <CardHeader title="Filter and Sort" icon={<FilterIcon />} />
                    <FiltersAndSorts
                      columns={dataImport?.columns}
                      filters={
                        dataImport?.source_info?.ppress_params?.filters ?? dataImport?.filters
                      }
                      sorts={dataImport?.source_info?.ppress_params?.sorts ?? dataImport?.sorts}
                      limit={
                        dataImport?.data_source_type === "salesforce"
                          ? dataImport?.source_info?.salesforce_objects_limit
                          : dataImport?.data_source_type === "hubspot"
                          ? dataImport?.source_info?.hubspot_objects_limit
                          : dataImport?.source_info?.sql_limit
                      }
                    />
                  </div>
                </Card>
              )}
            </div>
          </Sidebar>
        </div>
      )}
      <ConfirmBox
        visible={!!showFailedRunError}
        closable={true}
        showCancel={false}
        onOk={() => setShowFailedRunError(null)}
        onCancel={() => setShowFailedRunError(null)}
        okText="Got it"
        title={showFailedRunError?.status_level === "warning" ? "Warning" : "Import Failed"}
        width={400}
      >
        <>
          {showFailedRunError?.status_level === "warning" && (
            <WarningMessage {...showFailedRunError} />
          )}
          {showFailedRunError?.status_level === "error" && <ErrorMessage {...showFailedRunError} />}
        </>
      </ConfirmBox>
    </div>
  );
};
