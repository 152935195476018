import { AnalyticsEventNameType } from "@coeff/api";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { analyticsTrackAnonymous, clearAnonymousUserId, getAnonymousUserId } from "../api/track";
import { CoeLogo, Typography, Button, message } from "../components";
import { COLORS } from "../constants";
import { track, useApiContext } from "../utils";

const Container = styled.div`
  background-image: url("../static/login-gradient-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  height: 100%;
  width: 100%;

  padding: 16px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.black85};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  gap: 8px;
  padding: 28px;
  margin: auto;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  align-items: center;
`;

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  identityProvider: string;
};

export const OidcLogin: React.FC<Props> = ({ onCancel, onSuccess, identityProvider }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [pollIntervalId, setPollIntervalId] = useState<NodeJS.Timeout>();

  const { apiClient, DEPRECATED_api } = useApiContext();

  useEffect(() => {
    analyticsTrackAnonymous(apiClient, AnalyticsEventNameType.OidcLoginViewed, {
      temp_user_key: getAnonymousUserId(),
    });
  }, [apiClient]);

  const handleOIDCSignIn = async () => {
    setIsLoading(true);

    analyticsTrackAnonymous(apiClient, AnalyticsEventNameType.OidcLoginStarted, {
      temp_user_key: getAnonymousUserId(),
    });

    try {
      const {
        data: { sso_url },
      } = await apiClient.getOIDCLoginUrl();

      const openedWindow = window.open(sso_url, "_blank");

      let timesRun = 0;

      const intervalId = setInterval(async () => {
        try {
          const { data } = await apiClient.getUserSSOInfo();

          if (!data.sso_enabled || data.is_sso_authenticated) {
            setPollIntervalId(undefined);

            clearInterval(intervalId);

            openedWindow?.close();

            onSuccess();

            // Even though we're logged in, we still need to tag this event with temp_user_key
            // so that Amplify can associate previous anonymous events with the correct user.
            track(DEPRECATED_api, AnalyticsEventNameType.OidcLoginDone, {
              temp_user_key: getAnonymousUserId(),
            });

            clearAnonymousUserId();

            setIsLoading(false);
          }
        } catch (error) {
          setPollIntervalId(undefined);

          clearInterval(intervalId);

          setIsLoading(false);

          Sentry.captureException(error);

          message.error("Failed to fetch login status. Try again in some time.");

          analyticsTrackAnonymous(apiClient, AnalyticsEventNameType.OidcLoginFailed, {
            temp_user_key: getAnonymousUserId(),
          });
        }

        if (++timesRun > 200) {
          setPollIntervalId(undefined);

          clearInterval(intervalId);

          setIsLoading(false);

          message.error("Could not fetch login status. Try again in some time.");
        }
      }, 1000);

      setPollIntervalId(intervalId as unknown as NodeJS.Timeout);
    } catch (error) {
      Sentry.captureException(error);

      setIsLoading(false);

      message.error("Something went wrong. Re-launch the addon and try again.");
    }
  };

  const IdpDisplayName = identityProvider[0].toUpperCase() + identityProvider.slice(1);

  return (
    <Container>
      <Header>
        <CoeLogo height={44} />
      </Header>

      <Content>
        <Typography variant="h1" fontWeight={800}>
          Sign In with {IdpDisplayName}
        </Typography>

        <Typography variant="h6">
          Your admin requires an additional sign in using {IdpDisplayName}
        </Typography>

        <Actions>
          <Button
            style={{
              minWidth: 200,
            }}
            type="primary"
            size="large"
            onClick={handleOIDCSignIn}
            loading={isLoading}
          >
            Sign In with {IdpDisplayName}
          </Button>

          <Button
            type="text"
            onClick={() => {
              if (pollIntervalId) {
                clearInterval(pollIntervalId);

                setPollIntervalId(undefined);
              }

              onCancel();
            }}
          >
            Back
          </Button>
        </Actions>
      </Content>

      <div>
        <Typography variant="body3" fontWeight={500}>
          Having trouble accessing your account?
        </Typography>

        <a href="mailto:support@coefficient.io">Contact Support</a>
      </div>
    </Container>
  );
};
