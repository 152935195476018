import { message } from "antd";

// TODO: Need to add style once this issue is resolved https://github.com/ant-design/ant-design/issues/16659

message.config({
  top: 12,
  duration: 3,
  maxCount: 3,
});

export const toast = ({ content, key = null, type = "info" }) => {
  const toastOptions = { content, key };

  switch (type) {
    case "load":
      message.loading(toastOptions);
      break;
    case "success":
      message.success(toastOptions);
      break;
    case "warning":
    case "warn":
      message.warning(toastOptions);
      break;
    case "error":
      message.error(toastOptions);
      break;
    default:
      message.info(toastOptions);
      break;
  }
};
