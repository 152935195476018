import { ConfigureSSO200Response, IdentityProvider } from "@coeff/api-admin-ext";
import { Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import {
  AlertExclamation,
  BorderedSelect,
  Button,
  CardHeader,
  ConfirmBox,
  Form,
  Input,
  Option,
  Switch,
} from "../components";
import { COLORS } from "../constants";
import { AppState } from "../store";
import { useApiContext } from "../utils";

import { Header } from "./Dashboard/components";

const Container = styled.div`
  padding: 20px 30px;

  .ant-btn {
    display: inline;
  }
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 2em;
`;

const Content = styled.div`
  width: 480px;
`;

const FormGroup = styled.div`
  label {
    font-weight: 600;
    display: inline-block;
    margin-bottom: 4px;
  }
  margin-bottom: 20px;
`;

const SaveButton = styled(Button)`
  width: 200px;
  margin-top: 32px;
`;

const SetupSteps = styled.a`
  font-weight: 700;
`;

const Card = styled.div`
  background-color: ${COLORS.redBg};
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 20px;

  .sectionIcon {
    display: flex;
  }
`;

const InfoCard = styled.div`
  background-color: ${COLORS.coeblue};
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;

  .title {
    font-weight: 700;
  }
`;

const CardPanel = styled.div`
  margin-left: 26px;
  margin-top: 4px;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)<{ color?: string }>`
  font-weight: 600;
  font-size: 16px;
  color: ${p => (p.color === "danger" ? COLORS.red : COLORS.coeblue5)};
`;

const Capitalized = styled.span`
  text-transform: capitalize;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-select {
    width: 50%;
  }
`;

const OkButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const SwitcherFormGroup = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 700;
  margin-bottom: 4px;

  label {
    margin-right: 12px;
  }
`;

function getSSOSetupGuideLink(idProvider: IdentityProvider): string {
  if (idProvider === IdentityProvider.Okta) {
    return "https://coeff.s3.us-west-2.amazonaws.com/public/documents/Coefficient-Okta-SSO-Setup-Guide.pdf";
  }
  return "#";
}

export const ConfigureSSO: React.FC = () => {
  const [showSSOSavedModal, setShowSSOSavedModal] = useState<boolean>(false);
  const [showSSODisableModal, setShowSSODisableModal] = useState<boolean>(false);
  const [showSSODeleteModal, setShowSSODeleteModal] = useState<boolean>(false);

  const [clientId, setClientId] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>("");
  const [issuerUrl, setIssuerUrl] = useState<string>("");
  const [idProvider, setIdProvider] = useState<IdentityProvider>(IdentityProvider.Okta);
  const [ssoEnabled, setSsoEnabled] = useState<boolean>(false);
  const [isNewConfiguration, setIsNewConfiguration] = useState<boolean>(false);

  const { adminExtApiClient: api } = useApiContext();

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const updateStates = ({
    client_id,
    client_secret,
    issuer_url,
    id_provider,
    sso_enabled,
  }: ConfigureSSO200Response) => {
    if (client_id && client_secret && issuer_url && id_provider) {
      // SSO is already configured, set the values
      setClientId(client_id);
      setClientSecret(client_secret);
      setIssuerUrl(issuer_url);
      setIdProvider(id_provider);
      setIsNewConfiguration(false);
    } else {
      // This is a new configuration, editing would be allowed
      // set the default here, because, after delete we want to show clean form
      setClientId("");
      setClientSecret("");
      setIssuerUrl("");
      setIdProvider(IdentityProvider.Okta);
      setIsNewConfiguration(true);
    }
    setSsoEnabled(Boolean(sso_enabled));
  };

  useEffect(() => {
    api
      .getSSOConfig()
      .then(({ data: ssoData }) => {
        updateStates(ssoData);
      })
      .catch(() => {});
  }, []);

  const handleSave = async () => {
    message.loading("Saving");

    try {
      const { data: ssoConfig } = await api.configureSSO({
        configure_sso_request: {
          client_id: clientId,
          client_secret: clientSecret,
          issuer_url: issuerUrl,
          id_provider: idProvider,
          sso_enabled: ssoEnabled,
        },
      });

      updateStates(ssoConfig);

      message.success("Saved successfully");

      setShowSSOSavedModal(true);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleDelete = async () => {
    setShowSSODeleteModal(false);

    message.loading("Deleting");

    try {
      const { data: ssoConfig } = await api.deleteSSOConfig();

      updateStates(ssoConfig);

      message.success("Deleted successfully.");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handledSSOEnabledChange = async newVal => {
    setShowSSODisableModal(false);
    setShowSSOSavedModal(false);

    message.loading("Saving");

    try {
      const { data: ssoConfig } = await api.modifySSOConfig({
        modify_sso_config_request: { sso_enabled: newVal },
      });

      updateStates(ssoConfig);

      message.success(`SSO ${newVal ? "Enabled" : "Disabled"}`);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  if (!clientFlags?.show_sso_config_ui) {
    return <Redirect to="/dashboard" />;
  }

  const isEditable = isNewConfiguration;

  return (
    <Container>
      <StyledHeader>
        <h1>SSO Authentication</h1>
      </StyledHeader>
      <HeaderInfo>
        Requires users from your domain to sign in with SSO before using Coefficient.
      </HeaderInfo>

      <Content>
        <FormGroup>
          {isEditable && <label>SSO Authentication Provider</label>}
          <DropdownWrapper>
            {isEditable && (
              <BorderedSelect size="middle" value={idProvider} onChange={setIdProvider}>
                {Object.values(IdentityProvider).map(val => (
                  <Option key={val} value={val}>
                    <Capitalized>{val}</Capitalized>
                  </Option>
                ))}
              </BorderedSelect>
            )}
            <SetupSteps target="_blank" href={getSSOSetupGuideLink(idProvider)}>
              How to setup <Capitalized>{idProvider}</Capitalized> SSO
            </SetupSteps>
          </DropdownWrapper>
          {!isEditable && (
            <InfoCard>
              <CardHeader
                icon={<AlertExclamation type="info" />}
                title={
                  <span>
                    <Capitalized>{idProvider}</Capitalized> SSO has been configured for your domain
                  </span>
                }
              />
            </InfoCard>
          )}
        </FormGroup>

        {isEditable && <Divider />}

        {/* <Card>
          <CardHeader
            icon={<AlertExclamation />}
            title="Could not verify Client ID"
            className="card__header"
          />
          <CardPanel>
            Verify the Client ID is correct and matched the one created in <Capitalized>{idProvider}</Capitalized>.
          </CardPanel>
        </Card> */}

        <Form>
          {!isEditable && (
            <SwitcherFormGroup>
              <label>Enable SSO authentication</label>
              <Switch
                checked={ssoEnabled}
                onChange={newVal =>
                  newVal ? handledSSOEnabledChange(true) : setShowSSODisableModal(true)
                }
              />
            </SwitcherFormGroup>
          )}
          <FormGroup>
            <label>Client ID</label>
            <Input
              type="text"
              placeholder="fg137e559e-ff20-4eed-8d27-fb6ca1bb7b5c23"
              onChange={e => setClientId(e.currentTarget.value)}
              value={clientId}
              disabled={!isEditable}
            />
          </FormGroup>
          <FormGroup>
            <label>Client Secret</label>
            <Input
              type="password"
              placeholder="••••••••••"
              onChange={e => setClientSecret(e.currentTarget.value)}
              value={clientSecret}
              disabled={!isEditable}
            />
          </FormGroup>
          <FormGroup>
            <label>Authentication URL</label>
            <Input
              type="text"
              placeholder="https://acmecorp.okta.com/"
              onChange={e => setIssuerUrl(e.currentTarget.value)}
              value={issuerUrl}
              disabled={!isEditable}
            />
          </FormGroup>
          {isEditable ? (
            <SaveButton type="primary" onClick={handleSave}>
              Save
            </SaveButton>
          ) : (
            <SaveButton onClick={() => setShowSSODeleteModal(true)}>Delete</SaveButton>
          )}
        </Form>
      </Content>

      <ConfirmBox
        title={
          <span>
            <Capitalized>{idProvider}</Capitalized> SSO has been successfully configured for your
            domain.
          </span>
        }
        visible={showSSOSavedModal}
        okButton={
          <OkButtonWrapper>
            <StyledButton type="text">Yes, enable</StyledButton>
          </OkButtonWrapper>
        }
        cancelButton={<StyledButton type="text">Not now</StyledButton>}
        onOk={() => handledSSOEnabledChange(true)}
        onCancel={() => setShowSSOSavedModal(false)}
        width={420}
        closable={true}
      >
        <p>Do you want to enable SSO for your domain now?</p>
      </ConfirmBox>

      <ConfirmBox
        title="Please Confirm"
        visible={showSSODisableModal}
        okButton={
          <OkButtonWrapper>
            <StyledButton type="text">Yes, proceed</StyledButton>
          </OkButtonWrapper>
        }
        cancelButton={<StyledButton type="text">Cancel</StyledButton>}
        onOk={() => handledSSOEnabledChange(false)}
        onCancel={() => setShowSSODisableModal(false)}
        width={420}
        closable={true}
      >
        <p>
          Are you sure you want to disable <Capitalized>{idProvider}</Capitalized> SSO?
        </p>
        <p>
          Users from your domain will no longer be required to sign in with{" "}
          <Capitalized>{idProvider}</Capitalized> before using Coefficient.
        </p>
      </ConfirmBox>

      <ConfirmBox
        title="Please Confirm"
        visible={showSSODeleteModal}
        okButton={
          <OkButtonWrapper>
            <StyledButton type="text" color="danger">
              Delete
            </StyledButton>
          </OkButtonWrapper>
        }
        cancelButton={<StyledButton type="text">Cancel</StyledButton>}
        onOk={handleDelete}
        onCancel={() => setShowSSODeleteModal(false)}
        width={420}
        closable={true}
      >
        <p>
          Are you sure you want to delete this <Capitalized>{idProvider}</Capitalized> SSO
          configuration?
        </p>
        <p>
          Users from your domain will no longer be required to sign in with{" "}
          <Capitalized>{idProvider}</Capitalized> before using Coefficient.
        </p>
      </ConfirmBox>
    </Container>
  );
};
