import styled from "styled-components";

export const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ?? 1) * 8}px;
`;

export const FlexRow = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${({ gap }) => (gap ?? 1) * 8}px;
`;
