import { FeatureFlag } from "@coeff/api";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGate } from "statsig-react";
import styled from "styled-components";

import { Button, ChatIconSmall } from "../components";
import { getOrCreateZendeskClient, injectCSSToZendeskChat, openZendeskChat } from "../scripts";
import { AppState } from "../store";
import { useApiContext, useTrack } from "../utils";

type ZContext = {
  openZendesk: (from: string) => void;
};

const ZendeskContext = React.createContext<ZContext | null>(null);

export const useZendeskContext = () => useContext(ZendeskContext) as ZContext;

const StyledChatIcon = styled(ChatIconSmall)`
  cursor: pointer;
  position: relative;
  top: -2px;
`;

export const ZendeskProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { apiClient } = useApiContext();

  const track = useTrack();

  const isAuthenticated = useSelector((state: AppState) => state.app.coeffUserId !== "");

  const email = useSelector((state: AppState) => state.app.email);
  const name = useSelector((state: AppState) => state.app.name);

  const { value: zendeskMessengerEnabled } = useGate(FeatureFlag.EnableZendeskMessenger);

  const [unreadSupportChatMessages, setUnreadSupportChatMessages] = useState<number>(0);

  const [isZendeskChatOpen, setIsZendeskChatOpen] = useState<boolean>(false);

  const [isZendeskLoaded, setIsZendeskLoaded] = useState<boolean>(false);

  const zendeskOnUnreadMessage = (number: number) => {
    console.log(`It seems you have ${number} unread messages!`);
    setUnreadSupportChatMessages(number);
  };

  useEffect(() => {
    const loadZendesk = async () => {
      const zE = await getOrCreateZendeskClient(apiClient, { zendeskMessengerEnabled });

      if (zendeskMessengerEnabled) {
        zE("messenger:on", "unreadMessages", zendeskOnUnreadMessage);

        zE("messenger:on", "open", () => {
          injectCSSToZendeskChat({ zendeskMessengerEnabled });

          setIsZendeskChatOpen(true);

          setUnreadSupportChatMessages(0);
        });

        zE("messenger:on", "close", () => setIsZendeskChatOpen(false));
      } else {
        zE("webWidget:on", "chat:connected", () => {
          console.log("successfully connected to Zendesk Chat!");

          zE("webWidget:on", "chat:unreadMessages", zendeskOnUnreadMessage);
        });

        zE("webWidget:on", "chat:unreadMessages", zendeskOnUnreadMessage);

        zE("webWidget:on", "open", () => {
          injectCSSToZendeskChat({ zendeskMessengerEnabled });

          setIsZendeskChatOpen(true);

          setUnreadSupportChatMessages(0);
        });

        zE("webWidget:on", "close", () => setIsZendeskChatOpen(false));
      }
      setIsZendeskLoaded(true);
    };

    loadZendesk();
  }, []);

  const openZendesk = (from: string) => {
    if (!isZendeskChatOpen) {
      track("help_center_clicked", {
        event_from: from,
      });

      openZendeskChat(apiClient, { zendeskMessengerEnabled, user: { email, name } });
    }
  };

  const initLoadCompleted = isZendeskLoaded && isAuthenticated;

  return (
    <ZendeskContext.Provider value={{ openZendesk }}>
      <>
        {children}
        {initLoadCompleted && unreadSupportChatMessages > 0 && (
          <div
            style={{
              position: "fixed",
              bottom: "24px",
              right: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button type="link" size="small" onClick={() => openZendesk("webapp")}>
              <StyledChatIcon />
            </Button>
          </div>
        )}
      </>
    </ZendeskContext.Provider>
  );
};
