import { AnalyticsEvent, AnalyticsEventNameType, DefaultApi } from "@coeff/api";
import { DefaultApi as AdminExtApi } from "@coeff/api-admin-ext";
import { DefaultApi as CoeffAdminApi } from "@coeff/api-coeff-admin";
import { createContext, useContext } from "react";

import { DEPRECATED_ApiManager } from "../api";

import { GoogleApi } from "./googleApi";

type ApiContextProps = {
  DEPRECATED_api: DEPRECATED_ApiManager;
  apiClient: DefaultApi;
  adminExtApiClient: AdminExtApi;
  coeffAdminApiClient: CoeffAdminApi;
};

export const ApiContext = createContext<ApiContextProps | null>(null);

export const useApiContext = () => useContext(ApiContext) as ApiContextProps;

type GoogleApiContextProps = { googleApiClient: GoogleApi };

export const GoogleApiContext = createContext<GoogleApiContextProps | null>(null);

export const useGoogleApiContext = () => useContext(GoogleApiContext) as GoogleApiContextProps;

type TrackContextProps = (
  eventName: AnalyticsEventNameType,
  event?: AnalyticsEvent
) => Promise<void>;

export const TrackContext = createContext<TrackContextProps | null>(null);

export const useTrack = () => useContext(TrackContext) as TrackContextProps;

type AuthContextProps = { logout: () => void };

export const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuthContext = () => useContext(AuthContext) as AuthContextProps;
