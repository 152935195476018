import * as React from "react";
import styled from "styled-components";

import { LoaderWithPerfTimings } from "../components";

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingRoute = () => (
  <LoadingContainer>
    <LoaderWithPerfTimings name="LoadingRoute" size="large" />
  </LoadingContainer>
);
