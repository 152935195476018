import { DataSource } from "@coeff/api";
import * as Sentry from "@sentry/react";
import { InputRef } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useUpdateAdminDataSourceMutation } from "../../api";
import { COLORS, DATA_SOURCES_IN_BETA } from "../../constants";
import { pluralize } from "../../utils";
import { BetaBadge } from "../Badge";
import { ImportTypeIcon } from "../ImportTypeIcon";
import { Input } from "../Input";
import { List } from "../List";
import { message } from "../message";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";

import { DataSourceItemMenu } from "./DataSourceItemMenu";

const { Item } = List;

const StyledItem = styled(Item)`
  && {
    padding: 12px 0px;
    border: none;
    cursor: pointer;
    position: relative;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black85};
      font-size: 18px;
      line-height: 1;
      margin-bottom: 0px;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }
`;

const StyledImportDetails = styled.div`
   {
    color: ${COLORS.black45};
    font-size: 12px;
  }
`;

const InputWrapper = styled.div`
  .coeff__input-textarea {
    z-index: 999;
    padding: 0;
    margin-bottom: 8px;

    & textarea {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
`;

type Props = {
  editingName: boolean;
  setEditingName: (value: boolean) => void;
  dataSource: DataSource;
  currentPage?: string;
  onDeleteCb: () => void;
};

export const DataSourceItem = ({
  dataSource,
  currentPage,
  onDeleteCb,
  editingName,
  setEditingName,
}: Props) => {
  const nameRef = useRef<InputRef>(null);

  useEffect(() => {
    if (editingName) {
      nameRef.current?.focus();
    }
  }, [nameRef, editingName]);

  const { mutateAsync: updateDataSource, isLoading: isLoadingUpdateDataSource } =
    useUpdateAdminDataSourceMutation();

  const [dataSourceName, setDataSourceName] = useState<string>(dataSource.data_source_name ?? "");

  const isOwner = true; // admin allways has permission ? dataSource.user?.user_id === event_props.user_id;

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setDataSourceName(e.currentTarget.value);

  const onNameClick: React.MouseEventHandler<HTMLInputElement> = e => e.stopPropagation();

  const onSaveNameChange: React.FocusEventHandler = async e => {
    e.preventDefault();

    setEditingName(false);

    try {
      if (!dataSourceName) {
        setDataSourceName(dataSource.data_source_name ?? "");

        message.warn("Name cannot be empty");
      } else if (
        dataSource.data_source_id !== undefined &&
        dataSourceName &&
        dataSourceName !== dataSource.data_source_name
      ) {
        const id = dataSource.data_source_id;
        await updateDataSource({
          data_source_id: id,
          update_data_source_request: {
            data_source: {
              ...dataSource,
              data_source_name: dataSourceName,
            },
          },
        });

        message.success("Name saved");
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error("Error updating connection name");
    }
  };

  const renderTitle = () => {
    if (editingName) {
      return (
        <InputWrapper>
          <Input
            ref={nameRef}
            onChange={onNameChange}
            onClick={onNameClick}
            value={dataSourceName}
            placeholder={"Name"}
            onBlur={onSaveNameChange}
            onPressEnter={() => nameRef.current?.blur()}
          />
        </InputWrapper>
      );
    }

    return (
      <Tooltip mouseEnterDelay={2} title={dataSourceName}>
        <Typography lineClamp={2} noWrap>
          {dataSourceName}
        </Typography>
      </Tooltip>
    );
  };

  const renderDescriptionText = () => {
    const numOwnDataImportsForSource: number = dataSource?.data_source_stats?.num_data_imports ?? 0;
    const numSharedDataImportsForSource: number =
      dataSource?.data_source_stats?.num_shared_data_imports ?? 0;

    const importCount = numOwnDataImportsForSource + numSharedDataImportsForSource;
    return (
      <StyledImportDetails>
        {`${importCount} import${pluralize(importCount)}`} using this connection
      </StyledImportDetails>
    );
  };

  return (
    <>
      <StyledItem>
        <Item.Meta
          title={renderTitle()}
          avatar={<ImportTypeIcon type={dataSource.data_source_type} />}
          description={renderDescriptionText()}
        />

        {DATA_SOURCES_IN_BETA.includes(dataSource.data_source_type) && <BetaBadge />}

        {!editingName && (
          <DataSourceItemMenu
            currentPage={currentPage}
            dataSource={dataSource}
            onDeleteCb={onDeleteCb}
            onRenameCb={() => (isOwner ? setEditingName(true) : null)}
          />
        )}
      </StyledItem>
    </>
  );
};
