import { Switch as SwitchAD } from "antd";
import { SwitchProps } from "antd/lib/switch";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

type SwitchExtraProps = { active?: boolean };

const StyledSwtich = styled(SwitchAD)<SwitchExtraProps>`
  ${({ active }) => {
    if (active) {
      return `
        background-color: ${COLORS.coeblue4};
      `;
    }

    return "";
  }}

  &.ant-switch-checked {
    background-color: ${COLORS.coeblue4};
  }
`;

const StyledLabel = styled.div<SwitchExtraProps>`
  color: ${COLORS.black45};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  ${({ active }) => {
    if (active) {
      return `
        color: ${COLORS.coeblue4};
      `;
    }

    return "";
  }}
`;

export const Switch: React.FC<SwitchProps & SwitchExtraProps> = props => (
  <StyledSwtich {...props} />
);

export const SwitchLabel: React.FC<SwitchExtraProps> = props => <StyledLabel {...props} />;
