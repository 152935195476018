import { SearchOutlined } from "@ant-design/icons";
import { Input as InputAD, InputProps as InputPropsAD } from "antd";
import { TextAreaProps } from "antd/lib/input";
import React from "react";
import styled, { css } from "styled-components";

type InputProps = InputPropsAD & {
  variant?: "fill";
};

const InputStyles = css<InputProps>`
  &&.ant-input,
  &&.ant-input-affix-wrapper,
  &&.ant-input-affix-wrapper-focused {
    border-radius: 4px;
    box-shadow: none;
    border-width: 2px;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      border-color: ${({ theme }) => theme.colors.coeblue4};
      box-shadow: none;
    }

    &&[disabled] {
      border-color: transparent;
    }

    ${({ theme, variant }) => {
      if (variant === "fill") {
        return `
          border-color: transparent;
          background: ${theme.colors.black4};

          .ant-input {
            background: transparent;
          }
        `;
      } else {
        return "";
      }
    }}
  }
`;

const TextAreaStyles = css<TextAreaProps>`
  &&.ant-input,
  &&.ant-input-affix-wrapper,
  &&.ant-input-affix-wrapper-focused {
    border-radius: 4px;
    box-shadow: none;
    border-width: 2px;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      border-color: ${({ theme }) => theme.colors.coeblue4};
      box-shadow: none;
    }

    &&[disabled] {
      border-color: transparent;
    }
  }
`;

export const Input = styled(InputAD)`
  ${InputStyles}
` as typeof InputAD;

export const TextArea = styled(InputAD.TextArea)`
  ${TextAreaStyles}
` as typeof InputAD.TextArea;

export const InputSearch: React.FC<InputProps> = props => (
  <Input variant="fill" placeholder="Search" prefix={<SearchOutlined />} allowClear {...props} />
);
