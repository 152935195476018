import { Button as ButtonAD, ButtonProps } from "antd";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledButton = styled(ButtonAD)`
  &.ant-btn {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:not(.ant-btn-circle) {
      border-radius: 5px;
    }

    &:disabled {
      opacity: 50%;
      pointer-events: none;
    }

    &.noPadding {
      padding: 0;
    }

    &.text-left {
      text-align: left;
    }

    &.white-background {
      background-color: ${COLORS.white};
    }
  }

  &.ant-btn-default {
    border-color: ${COLORS.coeblue};
    background-color: ${COLORS.coeblue};
    color: ${COLORS.coeblue4};

    &:active,
    &:hover,
    &:focus {
      background-color: ${COLORS.coeblue};
      color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
    }
  }

  &.ant-btn-primary {
    background-color: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
    color: ${COLORS.white};

    &:active,
    &:hover,
    &:focus {
      background-color: ${COLORS.coeblue6};
      border-color: ${COLORS.coeblue6};
      color: ${COLORS.white};
      box-shadow: none;
    }
  }

  &.ant-btn-link {
    color: ${COLORS.coeblue4};
  }

  &.ant-btn-text {
    &:hover {
      background-color: ${COLORS.black6};
    }
  }

  &.ant-btn-sm {
    font-size: 12px;
  }
`;

export const Button: React.FC<ButtonProps & { noPadding?: boolean }> = props => {
  const { noPadding, className, ...baseProps } = props;

  return <StyledButton {...baseProps} className={clsx(className, { noPadding })} />;
};
