import { GetSubscriptionInfo200Response, UpdateSubscriptionsRequest } from "@coeff/api";

import { ACTIONS, BILLING_ROUTES } from "../constants";

import { getClientFlags } from "./app";

import { AppThunkAction } from ".";

const getNewTokenForBilling = (): Promise<{ oauthToken: string; openIdToken: string }> => {
  window.opener?.postMessage(JSON.stringify({ type: "tokens:get" }), "*");

  return new Promise((resolve, reject) => {
    window.addEventListener(
      "message",
      evt => {
        if (evt.data) {
          try {
            const response = JSON.parse(evt.data);
            if (response.type === "tokens:new") {
              resolve(response);
            }
          } catch (err) {}
        }
        console.log("Received message inside iframe");
        console.log(evt.data);
      },
      false
    );
  });
};

const fetchURLWithNewTokens = async () => {
  const tokens = await getNewTokenForBilling();

  const params = new URLSearchParams(window.location.search);
  params.set("GOOGLE-OAUTH-TOKEN", tokens.oauthToken);
  params.set("GOOGLE-OPENID-TOKEN", tokens.openIdToken);
  return { pathname: window.location.pathname, search: params.toString() };
};

export const getPreCheckoutData =
  (): AppThunkAction<Promise<unknown>> =>
  async (dispatch, __, { api }) => {
    dispatch({ type: ACTIONS.SET_BILLING_LOADING });

    try {
      const { headers, status, ...response } = await api.request({
        method: "GET",
        url: BILLING_ROUTES.billingPreCheckout,
      });

      // sort pro users to top
      response.users_from_domain.sort((a, b) => (a.plan_type ? -1 : 1));
      dispatch({ type: ACTIONS.SET_BILLING_METADATA, metadata: response });
      dispatch({
        type: ACTIONS.SET_USER_DETAILS,
        userEmail: headers["x-coeff-user-email"],
        userId: headers["x-coeff-user-id"],
      });

      return response;
    } catch (error) {
      return error;
    }
  };

export const getUserSubscription =
  (): AppThunkAction<Promise<GetSubscriptionInfo200Response>> =>
  async (dispatch, __, { api }) => {
    try {
      const { headers, status, ...response } = await api.request<GetSubscriptionInfo200Response>({
        method: "GET",
        url: BILLING_ROUTES.billingSubscriptionInfo,
      });

      dispatch({ type: ACTIONS.SET_BILLING_SUBSCRIPTION, userSubscription: response });

      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch({ type: ACTIONS.SET_BILLING_SUBSCRIPTION, userSubscription: null });
      }

      return error;
    }
  };

export const checkout =
  (body: any): AppThunkAction<Promise<unknown>> =>
  async (dispatch, __, { api, history }) => {
    try {
      const { headers, status, ...response } = await api.request({
        method: "POST",
        url: BILLING_ROUTES.billingCheckout,
        body,
      });

      dispatch(getClientFlags());
      return response;
    } catch (error: any) {
      // handle expired token for checkout
      if (error.status === 401) {
        const newURLState = await fetchURLWithNewTokens();
        history.push(newURLState);
        return dispatch(checkout(body));
      }
      return error;
    }
  };

export const updateSubscription =
  (body: UpdateSubscriptionsRequest): AppThunkAction<Promise<unknown>> =>
  async (dispatch, __, { api, history }) => {
    try {
      const { headers, status, ...response } = await api.request({
        method: "POST",
        url: BILLING_ROUTES.billingSubscriptionUpdate,
        body,
      });

      dispatch(getClientFlags());

      window.opener?.postMessage(JSON.stringify({ type: "subscription:update" }), "*");

      return response;
    } catch (error: any) {
      // handle expired token for updateSubscription
      if (error.status === 401) {
        const newURLState = await fetchURLWithNewTokens();
        history.push(newURLState);
        return dispatch(updateSubscription(body));
      }
      return error;
    }
  };

export const getInvoices =
  (): AppThunkAction<unknown> =>
  async (dispatch, __, { api }) => {
    try {
      const { headers, status, ...response } = await api.request({
        method: "GET",
        url: BILLING_ROUTES.billingInvoices,
      });

      dispatch({ type: ACTIONS.SET_BILLING_INVOICES, invoices: response.invoices });
      dispatch({ type: ACTIONS.SET_UPGRADE_TOKEN, upgradeToken: response.upgrade_token });
      return response;
    } catch (error: any) {
      if (error.status === 404) {
        dispatch({ type: ACTIONS.SET_BILLING_INVOICES, invoices: [] });
      }
      return error;
    }
  };
