import { CloudTable } from "@coeff/api";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { Badge, Typography } from "../components";
import { getRefreshedDate, getScheduleSummaryText } from "../utils";

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
`;

type Props = {
  exclude?: Array<keyof CloudTable>;
  cloudTable: CloudTable;
};

export const CloudTableInfo: React.FC<Props> = ({ cloudTable, exclude }) => {
  return (
    <Container>
      {exclude?.includes("cloud_table_name") ? null : (
        <div>
          <Typography color="textSecondary">Name</Typography>
          <Typography> {cloudTable.cloud_table_name}</Typography>
        </div>
      )}

      {cloudTable.description && !exclude?.includes("description") ? (
        <div>
          <Typography color="textSecondary">Description</Typography>
          <Typography lineClamp={5}>{cloudTable.description}</Typography>
        </div>
      ) : null}

      {cloudTable.user?.email ? (
        <div>
          <Typography color="textSecondary">Created By</Typography>
          <Typography> {cloudTable.user.email}</Typography>
        </div>
      ) : null}

      {cloudTable.last_run_dt ? (
        <div>
          <Typography color="textSecondary">Last Refreshed</Typography>
          <Typography>
            {getRefreshedDate(moment.utc(cloudTable.last_run_dt).local(), undefined, {
              uppercase: true,
            })}
          </Typography>
        </div>
      ) : null}

      {cloudTable.options?.schedule ? (
        <div>
          <Typography color="textSecondary">Refresh Schedule</Typography>
          <Badge variant="info">{getScheduleSummaryText(cloudTable.options.schedule)}</Badge>
        </div>
      ) : null}

      <div>
        <Typography color="textSecondary">Imports using this dataset</Typography>
        <Typography>{cloudTable.cloud_table_stats?.num_data_imports}</Typography>
      </div>
    </Container>
  );
};
