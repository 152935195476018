import * as Sentry from "@sentry/react";
import { useEffect } from "react";

import { config } from "../config";

export const initSentry = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "Cannot read properties of undefined (reading 'points')",
      "Network Error",
      "canceled",
      "Cannot read properties of null (reading 'focus')",
      "table missing in",
      "Unable to find node on an unmounted component.",
      "OfficeRuntime.auth.getAccessToken timed out.",
      "Cannot read properties of undefined (reading 'register')",
      "Excel is in cell-editing mode.",
      "ControlIdNotFound",
      "Service Spreadsheets failed while accessing document",
      "Request failed with status code",
      "Operation failed because the data is not current on the server.",
      "Failed to load https://apis.google.com/js/api.js",
      "Write operation is not supported for Office when a modal dialog is open.",
    ],
  });

  Sentry.setTag("coeffPlatform", "webapp");
};

export const useSentryMountUnmountTransaction = (
  ...args: Parameters<typeof Sentry.startTransaction>
) => {
  const [context, ...rest] = args;

  useEffect(() => {
    const transaction = Sentry.startTransaction(
      {
        ...context,
        op: context.op ?? "mount/unmount",
      },
      ...rest
    );

    return () => {
      transaction.finish();
    };
  }, []);
};

export const getCurrentReplayId = (): string | undefined => {
  const replay = Sentry.getCurrentHub().getIntegration(Sentry.Replay);
  return replay?.getReplayId();
};

type EventProperties = {
  id: string;
  class?: string;
};

export const addDummyUserClickEvent = (eventProps: EventProperties) => {
  const buttonElement = document.createElement("event");

  buttonElement.id = eventProps.id;

  if (eventProps.class) {
    buttonElement.className = eventProps.class;
  }

  buttonElement.onclick = e => {
    e.stopPropagation();
  };

  document.body.appendChild(buttonElement);

  setTimeout(() => {
    const click = new MouseEvent("click");
    buttonElement.dispatchEvent(click);
  }, 0);
};

interface SentryCaptureProps {
  context?: { [x: string]: unknown };
  error: unknown;
  name?: string;
  screen?: string;
}

export const sentryCapture = ({ context, error, name, screen }: SentryCaptureProps) => {
  Sentry.captureException(error, scope => {
    if (name) {
      scope.setTransactionName(name);
    }

    if (screen) {
      scope.setExtra("screen", screen);
    }

    if (context) {
      scope.setContext("coeffctx", context);
    }

    return scope;
  });
};
