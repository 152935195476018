import { CloudTable } from "@coeff/api";
import { Popover } from "antd";
import React, { useRef } from "react";
import { PartialCloudTable } from "src/api";
import styled from "styled-components";

import {
  Button,
  Input,
  TextArea,
  PaperContent,
  Paper,
  TextWithIcon,
  SourceFile,
  PaperContentIndent,
  PaperProps,
  Typography,
  CloudTableIcon,
  IconPicker,
} from "../../components";

type MetadataErrors = Partial<Record<keyof CloudTable, string>>;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FormLabel = styled.label`
  display: block;
  font-weight: 600;
`;

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleIconContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position relative;
  cursor: pointer;
`;

type Props = {
  disabled?: boolean;
  initialData: PartialCloudTable;
  onSubmit: (data: CloudTable) => void;
  variant?: PaperProps["variant"];
};

export const MetadataForm: React.FC<Props> = props => {
  const { variant, initialData, disabled, onSubmit } = props;

  const titleIconContainerRef = useRef<HTMLDivElement | null>(null);

  const [cloudTable, setCloudTable] = React.useState<PartialCloudTable>({ ...initialData });

  const [errors, setErrors] = React.useState<MetadataErrors>({});

  const handleSubmit = (cloudTable: PartialCloudTable) => {
    let errors: MetadataErrors = {};

    if (!cloudTable.cloud_table_name) {
      errors = {
        ...errors,
        cloud_table_name: "required",
      };
    }

    setErrors(errors);

    if (!Object.keys(errors).length) {
      onSubmit(cloudTable as CloudTable);
    }
  };

  return (
    <Paper variant={variant}>
      <StyledPaperContent size="small">
        <TextWithIcon
          icon={<SourceFile />}
          title={<Typography fontWeight="bold">Name & Description</Typography>}
        />

        <TitleIconContainer ref={titleIconContainerRef}>
          <CloudTableIcon cloudTable={cloudTable} />

          <Popover
            placement="bottom"
            arrowPointAtCenter={false}
            trigger="click"
            getPopupContainer={triggerNode => titleIconContainerRef.current ?? triggerNode}
            content={
              <IconPicker
                color={cloudTable.icon_background_color}
                icon={cloudTable.icon_name}
                onColorChange={icon_background_color => {
                  setCloudTable({
                    ...cloudTable,
                    icon_background_color,
                  });
                }}
                onIconChange={icon_name => {
                  setCloudTable({
                    ...cloudTable,
                    icon_name,
                  });
                }}
              />
            }
          >
            <Button type="link" noPadding>
              Change Icon
            </Button>
          </Popover>
        </TitleIconContainer>

        <Form>
          <FormGroup>
            <FormLabel>Name</FormLabel>

            <Input
              placeholder="Name your table..."
              status={errors.cloud_table_name ? "error" : undefined}
              onChange={e =>
                setCloudTable({
                  ...cloudTable,
                  cloud_table_name: e.target.value,
                })
              }
              value={cloudTable.cloud_table_name}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Description (optional)</FormLabel>
            <TextArea
              placeholder="Describe your table..."
              onChange={e =>
                setCloudTable({
                  ...cloudTable,
                  description: e.target.value,
                })
              }
              value={cloudTable?.description}
            />
          </FormGroup>
        </Form>

        <PaperContentIndent>
          <Button block disabled={disabled} type="primary" onClick={() => handleSubmit(cloudTable)}>
            Next
          </Button>
        </PaperContentIndent>
      </StyledPaperContent>
    </Paper>
  );
};
