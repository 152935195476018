import { DataImportStatus } from "@coeff/api";
import { AlertProps } from "antd";
import React from "react";

import { getErrorMessage, getErrorTitle } from "../utils";

import { InlineAlert, AlertExclamation } from ".";

type Props = AlertProps & {
  last_import_status?: DataImportStatus;
  error?: unknown;
};

export const DataImportInlineAlert: React.FC<Props> = ({ last_import_status, error, ...rest }) => {
  return (
    <div>
      {last_import_status?.status_level &&
      ["error", "warning"].includes(last_import_status.status_level) ? (
        <InlineAlert
          showIcon={true}
          icon={<AlertExclamation type={last_import_status.status_level} />}
          type={last_import_status.status_level}
          message={last_import_status?.title}
          description={last_import_status?.message}
          {...rest}
        />
      ) : error ? (
        <InlineAlert
          showIcon={true}
          icon={<AlertExclamation type="error" />}
          type="error"
          message={getErrorTitle(error, "Error")}
          description={getErrorMessage(error, "Unable to load dataset")}
          {...rest}
        />
      ) : null}
    </div>
  );
};
