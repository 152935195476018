import { API_AUTH_ROUTES } from "../constants";

import { AppThunkAction } from ".";

export const signup =
  (id, data): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    try {
      const { user } = await api.request({
        method: "POST",
        url: `${API_AUTH_ROUTES.signup}`,
        body: { invite_id: id, user: data },
      });

      return user;
    } catch (error) {
      return error;
    }
  };

export const login =
  (token?: string): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    const auth = await api.request({
      method: "POST",
      url: `${API_AUTH_ROUTES.login}`,
      token,
    });

    return auth;
  };

export const logout =
  (): AppThunkAction<unknown> =>
  async (_, __, { api }) => {
    try {
      const auth = await api.request({
        method: "POST",
        url: `${API_AUTH_ROUTES.logout}`,
      });

      return auth;
    } catch (error) {
      return error;
    }
  };
