import { List as ListAD } from "antd";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledList = styled(ListAD)`
  && {
    .ant-list-item {
      border-bottom: none;

      .ant-list-item-meta {
        .ant-list-item-meta-avatar {
        }

        .ant-list-item-meta-content {
          .ant-list-item-meta-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          .ant-list-item-meta-description {
            font-size: 12px;
          }
        }
      }
    }
  }

  hr {
    height: 1px;
    color: ${COLORS.black15};
    background-color: ${COLORS.black15};
    border: none;
    margin: 0px;
  }

  .sectionTitle {
    padding: 12px 0px;
    font-weight: 500;
  }
`;

export const List = StyledList;
