import { CloudTableSourceInfo } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import { Button, Paper, PaperContent, Typography, Source, TextWithIcon } from "../../components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  disabled?: boolean;
  sourceInfo: Partial<CloudTableSourceInfo>;
  onEditClick: () => void;
};

export const SourceInfo: React.FC<Props> = props => {
  const { onEditClick, sourceInfo, disabled } = props;

  return (
    <Paper>
      <StyledPaperContent size="small">
        <TextWithIcon
          icon={<Source />}
          title={<Typography fontWeight="bold">Source Sheet</Typography>}
          action={
            <Button disabled={disabled} type="link" size="small" onClick={() => onEditClick()}>
              edit
            </Button>
          }
        ></TextWithIcon>

        <Content>
          <ContentGroup>
            <Typography color="textSecondary">Sheet</Typography>

            <Typography noWrap>
              {sourceInfo.google_sheets_file_name}{" "}
              {sourceInfo.google_sheets_is_specific_range
                ? `- ${sourceInfo.google_sheets_range}`
                : ""}
            </Typography>
          </ContentGroup>

          <ContentGroup>
            <Typography color="textSecondary">Tab</Typography>

            <Typography noWrap>{sourceInfo.google_sheets_tab_name}</Typography>
          </ContentGroup>
        </Content>
      </StyledPaperContent>
    </Paper>
  );
};
