import { Select as SelectAD, SelectProps as SelectPropsAD } from "antd";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { DownArrow } from "./Icons";

export const { Option, OptGroup } = SelectAD;

const StyledSelect = styled.div`
  .ant-select {
    margin: 0px 8px;

    .ant-select-selector {
      padding: 0px 8px !important;
      border-radius: 4px !important;
      border-width: 2px !important;
      border-right-width: 2px !important;
      box-shadow: none !important;
      transition: none;
    }

    &.ant-select-multiple {
      .ant-select-selector {
        padding: 0px 4px !important;
      }

      .ant-select-selection-item {
        background: ${COLORS.coeblue};
        color: ${COLORS.black85};
        font-size: 14px;
        border-radius: 6px;
        padding: 0px 4px 0px 8px;
        height: 24px;
      }
    }

    &:not(.ant-select-focused) {
      .ant-select,
      .ant-select-selector {
        border-color: white;
      }
    }

    &.ant-select-focused {
      border-color: ${COLORS.coeblue4}!important;

      .ant-select-selector {
        border-width: 2px !important;
        border-color: ${COLORS.coeblue4}!important;
      }

      .ant-select-arrow {
        opacity: 1;
      }
    }

    .ant-select-arrow {
      padding-right: 8px;
    }

    &:hover {
      &:not(.ant-select-open) {
        .ant-select-selector {
          border-radius: 4px !important;
          box-shadow: none !important;
          border-color: ${COLORS.black15};
          border-right-width: 2px !important;
        }
      }

      .ant-select-arrow {
        opacity: 1;
      }
    }

    &.linkSelect {
      .ant-select-selector {
        background: transparent !important;
        border: none;

        .ant-select-selection-item {
          color: ${COLORS.coeblue4};
          font-weight: 700;
        }
      }

      .ant-select-arrow {
        opacity: 1;
      }
    }
  }

  &.error {
    .ant-select {
      .ant-select-selector {
        border-color: ${COLORS.red} !important;
      }
    }
  }
`;

const CoeffBorderedSelect = styled(SelectAD)`
  &.ant-select {
    .ant-select-selector {
      border-radius: 4px;
      border: 2px solid ${COLORS.black15};
      box-shadow: none !important;
      transition: none;
    }

    &.ant-select-focused {
      border-color: ${COLORS.coeblue4}!important;

      .ant-select-selector {
        border-width: 2px !important;
        border-color: ${COLORS.coeblue4}!important;
      }
    }

    &:hover {
      &:not(.ant-select-open) {
        .ant-select-selector {
          border-width: 2px !important;
          box-shadow: none !important;
          border-color: ${COLORS.black15};
        }
      }
    }

    &.error {
      .ant-select-selector {
        border-color: ${COLORS.red} !important;
      }
    }
  }
`;

type SelectProps = SelectPropsAD & {
  error?: boolean;
  selectRef?: React.Ref<HTMLSelectElement | any> | null;
};

export const Select: React.FC<SelectProps> = ({ children, error, ...props }) => (
  <StyledSelect className={`coeff__select${error ? " error" : ""}`}>
    <SelectAD
      suffixIcon={<DownArrow />}
      size={props.size ? props.size : "small"}
      ref={props.selectRef}
      {...props}
    >
      {children}
    </SelectAD>
  </StyledSelect>
);

export const BorderedSelect = CoeffBorderedSelect as typeof SelectAD;

export const FilledSelect = styled(SelectAD)`
  && {
    margin: 0px;

    .ant-select-selector {
      align-items: center;
      border: none;
      background: #f5f5f5;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
    }
  }
` as typeof SelectAD;
