import { Tooltip as TooltipAD, TooltipProps as TooltipADProps } from "antd";
import * as React from "react";
import styled from "styled-components";

const StyledTooltip = styled(TooltipAD)<{ variant?: "light" }>`
  ${({ variant, theme }) => {
    return variant === "light"
      ? `


        .ant-tooltip-arrow .ant-tooltip-arrow-content::before {
          background: #fff;
        }

        .ant-tooltip-content {
          background: ${theme.colors.white};
          border-radius: 5px;

          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              background: ${theme.colors.white};
            }
          }

          .ant-tooltip-inner {
            background: ${theme.colors.white};
            border-radius: 5px;
            min-height: 28px;
            padding: 3px 6px;
            color: #262626;
          }
        }


        .ant-tooltip-arrow-content::before {
          background: white;
        }
      `
      : ``;
  }}
`;

type Props = TooltipADProps & {
  variant?: "light";
};

export const Tooltip: React.FC<Props> = ({ children, mouseLeaveDelay, ...props }) => (
  <StyledTooltip mouseLeaveDelay={mouseLeaveDelay ?? 0} {...props}>
    {children}
  </StyledTooltip>
);
