import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AppState } from "../../store";
import { NoVNCContainer } from "../WebScraperSession/NoVNCContainer";

export const VCRDebugger = () => {
  const { isCoeffAdmin } = useSelector(({ app }: AppState) => ({
    isCoeffAdmin: app.isCoeffAdmin,
  }));

  const [url, setUrl] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const urlParam = queryParams.get("url");
    const passwordParam = queryParams.get("password");

    if (urlParam) {
      setUrl(urlParam);
    }

    if (passwordParam) {
      setPassword(passwordParam);
    }
  }, []);

  if (!isCoeffAdmin) {
    return <div>Unauthorized</div>;
  }

  if (!(url && password)) {
    return <div>Missing URL or password</div>;
  }

  return <NoVNCContainer url={url} password={password} />;
};
