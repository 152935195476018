import { Card, Col, Row, Statistic, Tooltip } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";

import { COLORS, IMPORT_TYPE_NAMES } from "../../../constants";
import { CAContext } from "../../../types";
import { CoeffAdminContext } from "../CoeffAdmin";

const Wrapper = styled.div`
  padding: 25px 25px 0 25px;
  margin-bottom: -16px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 16px;
`;

export const DomainDetails = () => {
  const { loading, domainDetails: domain, users } = useContext(CoeffAdminContext) as CAContext;

  if (!domain) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{domain.domain}</Title>
      <>
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={4}>
            <Card>
              <Tooltip
                title={
                  <div>
                    {Object.keys(domain.total_imports).map(key => (
                      <div key={key}>
                        <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
                        {domain.total_imports[key]}
                      </div>
                    ))}
                  </div>
                }
              >
                <Statistic
                  title="Imports"
                  value={Object.values(domain.total_imports).reduce((v, tot) => tot + v, 0)}
                />
              </Tooltip>
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Tooltip
                title={
                  <div>
                    {Object.keys(domain.total_imports_7_days).map(key => (
                      <div key={key}>
                        <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
                        {domain.total_imports_7_days[key]}
                      </div>
                    ))}
                  </div>
                }
              >
                <Statistic
                  title="Imports (7d)"
                  value={Object.values(domain.total_imports_7_days).reduce((v, tot) => tot + v, 0)}
                />
              </Tooltip>
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Target Spreadsheets" value={domain.total_target_sheets} />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="Target Spreadsheets (7d)"
                value={domain.total_target_sheets_7_days}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Tooltip
                title={
                  <div>
                    {Object.keys(domain.total_scheduled_runs).map(key => (
                      <div key={key}>
                        <span style={{ color: COLORS.black25 }}>{IMPORT_TYPE_NAMES[key]}:</span>{" "}
                        {domain.total_scheduled_runs[key]}
                      </div>
                    ))}
                  </div>
                }
              >
                <Statistic
                  title="Scheduled Runs"
                  value={Object.values(domain.total_scheduled_runs).reduce((v, tot) => tot + v, 0)}
                />
              </Tooltip>
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Tooltip
                title={
                  <div>
                    {Object.keys(domain.total_alerts).map(key => (
                      <div key={key}>
                        <span style={{ color: COLORS.black25 }}>{key}:</span>{" "}
                        {domain.total_alerts[key]}
                      </div>
                    ))}
                  </div>
                }
              >
                <Statistic
                  title="Alerts"
                  value={Object.values(domain.total_alerts).reduce((v, tot) => tot + v, 0)}
                />
              </Tooltip>
            </Card>
          </Col>
        </Row>
      </>
    </Wrapper>
  );
};
