import { CloudTable, DataImportErrorType } from "@coeff/api";
import { useQueryClient } from "@tanstack/react-query";
import { Drawer } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  QueryCacheKey,
  useCloudTable,
  useCloudTableData,
  useDeleteCloudTableMutation,
  useGoogleDriveAuthToken,
  useRunCloudTableMutation,
  useUpdateCloudTableMutation,
} from "../../api";
import {
  Button,
  DataImportInlineAlert,
  DataImportTable,
  CenterAlign,
  Paper,
  ConfirmDeleteCloudTableModal,
  message,
  Alert,
  AdminTableFooter,
  EditAccessDisabledOverlay,
  GoogleAuthModal,
  DatasetError,
  InlineAlert,
  AlertExclamation,
  PaperContent,
  Typography,
  LoaderWithPerfTimings,
} from "../../components";
import { AppState } from "../../store";
import {
  MissingDataSourceError,
  getErrorMessage,
  getErrorTitle,
  isAxiosErrorDataSourceNeedsReauth,
  useTrack,
} from "../../utils";

import { CloudTableDetailsForm } from "./CloudTableDetailsForm";
import { CloudTableDetailsHeader } from "./CloudTableDetailsHeader";

const PAGE_SIZE = 100;

const Layout = styled.div`
  line-height: 1.3;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const TableContainer = styled.div`
  padding: 0 8px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const PaginatedTableContainer = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const DataImportTableFooterContainer = styled.div`
  padding: 4px;
`;

const ErrorContainer = styled(Paper)`
  height: 100%;
  width: 100%;
  margin: 0 8px;
`;

const ErrorPaper = styled(Paper)`
  max-width: 500px;
`;

export const CloudTableDetails: React.FC = () => {
  const track = useTrack();

  const history = useHistory();

  const queryClient = useQueryClient();

  const email = useSelector<AppState, string>(state => state.app.email);

  const { cloudTableId } = useParams<{ cloudTableId: string }>();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const { mutateAsync: deleteCloudTable, isLoading: isLoadingDeleteCloudTable } =
    useDeleteCloudTableMutation();

  const {
    mutateAsync: runCloudTable,
    isLoading: isLoadingRunCloudTable,
    error: errorRunCloudTable,
  } = useRunCloudTableMutation();

  const { mutateAsync: updateCloudTable, isLoading: isLoadingUpdateCloudTable } =
    useUpdateCloudTableMutation();

  const { data: cloudTable, error: errorCloudTable } = useCloudTable(cloudTableId, {
    enabled: !isLoadingDeleteCloudTable,
  });

  const isOwner = email === cloudTable?.user?.email;

  const [page, setPage] = React.useState(0);

  const {
    data: cloudTableData,
    isLoading: isLoadingCloudTableData,
    isFetching: isFetchingCloudTableData,
    error: errorCloudTableData,
  } = useCloudTableData(
    {
      cloudTable,
      options: {
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
    },
    {
      keepPreviousData: true,
      enabled: Boolean(cloudTable && !isLoadingUpdateCloudTable && !isLoadingRunCloudTable),
    }
  );

  const [topLevelError, setTopLevelError] = React.useState<unknown>();

  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);

  const [isAuthModalClosed, setIsAuthModalClosed] = React.useState(false);

  const popoverContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.title = "Dataset - Coefficient Workspace";

    track("dataset_data_viewed_web", {
      dataset_id: cloudTableId,
      source: searchParams.get("source") ?? undefined,
    });
  }, []);

  const {
    authToken,
    isLoading: isLoadingAuthToken,
    error: errorAuthToken,
  } = useGoogleDriveAuthToken();

  const handleRefresh = async (cloudTableId: string) => {
    track("dataset_refresh_clicked_web", { dataset_id: cloudTableId });

    try {
      await runCloudTable(cloudTableId);

      setPage(0);
    } catch (error) {
      message.error("Failed to update dataset");

      throw error;
    }
  };

  const handleSubmit = async (
    cloudTable: CloudTable,
    options: {
      isSourceInfoDirty: boolean;
    }
  ) => {
    try {
      const response = await updateCloudTable({
        cloud_table_id: cloudTable.cloud_table_id!,
        cloud_table: cloudTable,
      });

      track("dataset_edited", { dataset_id: cloudTableId });

      if (options.isSourceInfoDirty) {
        await runCloudTable(cloudTable.cloud_table_id!);

        queryClient.invalidateQueries([
          QueryCacheKey.CLOUD_TABLES,
          QueryCacheKey.CLOUD_TABLE,
          response.cloud_table.cloud_table_id,
        ]);

        setPage(0);
      } else {
        message.success("Saved successfully");
      }

      return response.cloud_table;
    } catch (error) {
      message.error("Failed to update dataset");

      throw error;
    }
  };

  const handleDelete = async (cloudTableId: string) => {
    try {
      await deleteCloudTable(cloudTableId);

      message.success("Dataset deleted");

      history.push("/dashboard/datasets");
    } catch (error) {
      message.error("Failed to delete dataset");
    }
  };

  const SupportLink = () => (
    <Button type="link" style={{ marginRight: 8, height: "auto" }} noPadding>
      <a href="mailto:support@coefficient.io">Contact Support</a>
    </Button>
  );

  const Error: React.FC<{ error: unknown }> = ({ error }) => {
    return (
      <ErrorContainer>
        <InlineAlert
          type="error"
          showIcon={true}
          icon={<AlertExclamation type="error" />}
          message={getErrorTitle(error, "Error")}
          description={getErrorMessage(error, "Unable to load dataset")}
          action={<SupportLink />}
        />

        <CenterAlign>
          <DatasetError />
        </CenterAlign>
      </ErrorContainer>
    );
  };

  if (errorCloudTable) {
    return (
      <CenterAlign>
        <ErrorPaper>
          <PaperContent>
            <Typography fontWeight="bold" gutterBottom>
              {getErrorTitle(errorCloudTable, "Error")}
            </Typography>

            <Typography gutterBottom>
              {getErrorMessage(errorCloudTable, "Unable to load dataset")}
            </Typography>

            <Button noPadding type="link" onClick={() => history.push("/dashboard/datasets")}>
              Back to Datasets
            </Button>
          </PaperContent>
        </ErrorPaper>
      </CenterAlign>
    );
  }

  if (cloudTable) {
    return (
      <Layout>
        <CloudTableDetailsHeader
          cloudTable={cloudTable}
          onRefresh={() => handleRefresh(cloudTableId)}
          setIsDrawerVisible={setIsDrawerVisible}
          isDrawerVisible={isDrawerVisible}
          isLoadingRunCloudTable={isLoadingRunCloudTable}
          isOwner={isOwner}
        />

        <Content ref={popoverContainer}>
          {cloudTableData ? (
            <TableContainer>
              <DataImportInlineAlert
                last_import_status={cloudTable.last_import_status}
                error={topLevelError ?? errorRunCloudTable ?? errorCloudTableData}
                action={
                  cloudTable.last_import_status?.error_type ===
                  DataImportErrorType.CloudTablePushToRedshiftFailed ? (
                    <SupportLink />
                  ) : null
                }
              />

              <PaginatedTableContainer>
                <DataImportTable
                  headers={
                    cloudTableData.columns?.map(column => column.alias ?? column.column_name) ?? []
                  }
                  data={cloudTableData.data_rows}
                  offset={page * PAGE_SIZE}
                  loading={isFetchingCloudTableData || isLoadingRunCloudTable}
                />
              </PaginatedTableContainer>

              <DataImportTableFooterContainer>
                <AdminTableFooter
                  page={page}
                  pageSize={PAGE_SIZE}
                  onPageChange={page => setPage(page)}
                  data={cloudTableData.data_rows}
                  totalRowCount={cloudTableData.total_row_count}
                />
              </DataImportTableFooterContainer>
            </TableContainer>
          ) : (cloudTable.last_import_status?.status_level === "error" ||
              errorRunCloudTable ||
              errorCloudTableData) &&
            !isLoadingCloudTableData ? (
            <Error
              error={
                topLevelError ??
                (cloudTable.last_import_status?.status_level === "error"
                  ? cloudTable.last_import_status
                  : undefined) ??
                errorRunCloudTable ??
                errorCloudTableData
              }
            />
          ) : (
            <CenterAlign>
              <LoaderWithPerfTimings name="CloudTableDetails.cloudTableData" size="large" />
            </CenterAlign>
          )}

          <Drawer
            title="Edit"
            style={{ position: "absolute" }}
            getContainer={() => popoverContainer.current!}
            mask={false}
            open={isDrawerVisible}
            onClose={() => setIsDrawerVisible(false)}
            bodyStyle={{
              padding: 16,
            }}
            headerStyle={{
              padding: 16,
            }}
            contentWrapperStyle={{
              marginTop: 1,
              width: 350,
            }}
          >
            {isLoadingAuthToken ? (
              <CenterAlign>
                <LoaderWithPerfTimings name="CloudTableDetails.authToken" size="large" />
              </CenterAlign>
            ) : errorAuthToken ? (
              <Alert
                message="Error"
                description={
                  errorAuthToken instanceof MissingDataSourceError
                    ? errorAuthToken.message
                    : "Unknown error"
                }
              />
            ) : authToken ? (
              <EditAccessDisabledOverlay
                enabled={!isOwner}
                onClickMessage={`Only the owner (${cloudTable.user?.display_name}) has permission to edit the dataset`}
              >
                <CloudTableDetailsForm
                  key={cloudTable.modified_dt}
                  authToken={authToken}
                  onSubmit={handleSubmit}
                  isLoadingUpdateCloudTable={isLoadingUpdateCloudTable}
                  isLoadingRunCloudTable={isLoadingRunCloudTable}
                  initialData={cloudTable}
                  onDelete={() => setIsDeleteModalVisible(true)}
                  setTopLevelError={setTopLevelError}
                />
              </EditAccessDisabledOverlay>
            ) : null}
          </Drawer>
        </Content>

        <ConfirmDeleteCloudTableModal
          isLoading={isLoadingDeleteCloudTable}
          open={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          onOk={() => handleDelete(cloudTableId)}
        />

        <GoogleAuthModal
          redirectPath={`/datasets/${cloudTable.cloud_table_id}`}
          open={
            !isAuthModalClosed &&
            (errorAuthToken instanceof MissingDataSourceError ||
              isAxiosErrorDataSourceNeedsReauth(errorAuthToken))
          }
          onCancel={() => setIsAuthModalClosed(true)}
        />
      </Layout>
    );
  }

  return (
    <CenterAlign>
      <LoaderWithPerfTimings name="CloudTableDetails" size="large" />
    </CenterAlign>
  );
};
