import { DefaultApi } from "@coeff/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useApiContext } from "../utils/contexts";

import { QueryCacheKey } from ".";

export const fetchWritebacksSchema = async (
  api: DefaultApi,
  data_source_id: string,
  object_name?: string
) => {
  const response = await api.getWritebacksSchema({ data_source_id, object_name });

  return response.data;
};

export const useWritebacksSchema = (
  { dataSourceId, objectName }: { dataSourceId: string; objectName?: string },
  { enabled }: UseQueryOptions = {}
) => {
  const { apiClient: api } = useApiContext();

  return useQuery(
    [QueryCacheKey.WRITEBACKS_SCHEMA, dataSourceId, objectName],
    () => fetchWritebacksSchema(api, dataSourceId, objectName),
    {
      enabled,
    }
  );
};
