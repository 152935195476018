import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAdminDataSource } from "../../../api";
import {
  Button,
  CenterAlign,
  DataSourceItem,
  DataSourceInfoWrap,
  ShareConnectionCard,
  Drawer,
  ErrorMessage,
  LoaderWithPerfTimings,
  Paper,
  PaperContent,
  Typography,
  ConnectionDetailIcon,
} from "../../../components";
import { COLORS } from "../../../constants";
import { DataSourceTypeDisplayNames } from "../../../utils";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 18px 0px;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};

  .closeButton {
    margin-right: -16px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(2)}`};
  padding: ${({ theme }) => `${theme.spacing(2)}`};
`;

const InfoWrap = styled.div`
  background: ${COLORS.black4};
  border-radius: 5px;
  padding: 4px 14px;
`;

const StyledFlexBox = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;

  .styledEdit {
    margin-right: 12px;
    margin-left: auto;
    margin-top: 2px;
    color: ${COLORS.coeblue4};
    cursor: pointer;
  }
`;

type Props = {
  dataSourceId: string | undefined;
  setDataSourceId: (value: string | undefined) => void;
  editingName: boolean;
  setEditingName: (val: boolean) => void;
};

export const DomainDataSourceDetails: React.FC<React.PropsWithChildren<Props>> = ({
  dataSourceId,
  setDataSourceId,
  editingName,
  setEditingName,
}) => {
  const {
    data: dataSource,
    isLoading,
    error,
  } = useAdminDataSource(dataSourceId!, { enabled: Boolean(dataSourceId) });

  const [isShareConnectionEditMode, setIsShareConnectionEditMode] = useState<boolean>(false);
  const status = (dataSource?.connection_status?.status ?? undefined) as string | undefined;

  useEffect(() => {
    if (dataSource !== undefined) {
      setIsShared(dataSource.is_shared_with_domain ?? false);
    }
  }, [dataSource]);

  const [isShared, setIsShared] = useState<boolean>(false);

  const displayName = dataSource ? DataSourceTypeDisplayNames[dataSource.data_source_type] : "";

  const renderContent = () => {
    if (isLoading) {
      return (
        <CenterAlign>
          <LoaderWithPerfTimings name="DataSourceById" size="large" />
        </CenterAlign>
      );
    }

    if (error) {
      return (
        <CenterAlign>
          <Paper>
            <PaperContent>
              <Typography fontWeight="bold" gutterBottom>
                Error
              </Typography>
              <Typography>Unable to load data source</Typography>
            </PaperContent>
          </Paper>
        </CenterAlign>
      );
    }

    if (dataSource === undefined) {
      return <noscript />;
    }

    const handleDelete = () => {
      setDataSourceId(undefined);
    };

    return (
      <>
        {status === "failed" && (
          <ErrorMessage
            noMargin
            title={`Reconnect ${displayName}`}
            message={`You need to reconnect your ${displayName} account to Coefficient.`}
            actionText={`Reconnect ${displayName}`}
            // action={reauth}
          />
        )}

        <DataSourceItem
          editingName={editingName}
          setEditingName={setEditingName}
          dataSource={dataSource}
          onDeleteCb={handleDelete}
        />

        <InfoWrap>
          <StyledFlexBox>
            <ConnectionDetailIcon className="styledSvg" />
            <span style={{ fontWeight: "bold", marginTop: "2px", color: "black" }}>
              Connection details
            </span>
          </StyledFlexBox>

          <DataSourceInfoWrap
            dataSource={dataSource}
            currentUserId={undefined} // }
            hideImportDetails={true}
            hideSharingDetails={true}
          />
        </InfoWrap>

        <ShareConnectionCard
          dataSource={dataSource}
          isShared={isShared}
          onChange={value => setIsShared(value)}
          onEdit={value => setIsShareConnectionEditMode(value)}
        />
      </>
    );
  };

  return (
    <Drawer
      open={dataSourceId !== undefined}
      setOpen={open => {
        if (!open) {
          setDataSourceId(undefined);
        }
      }}
      closeOnClickAway={false}
    >
      <StyledHeader>
        <Typography variant="h6" color="textPrimary" fontWeight={500}>
          Connection Settings
        </Typography>
        <Button
          className="closeButton"
          shape="circle"
          type="text"
          onClick={() => setDataSourceId(undefined)}
        >
          <CloseOutlined />
        </Button>
      </StyledHeader>
      <StyledContent key={dataSourceId}>{renderContent()}</StyledContent>
    </Drawer>
  );
};
