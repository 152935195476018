import { Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import * as Sentry from "@sentry/browser";

import { config } from "../config";

const msalConfig: Configuration = {
  auth: {
    clientId: config.MS_IDP_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", // Needed to avoid "User login is required" error.
    storeAuthStateInCookie: true, // Recommended to avoid certain IE/Edge issues.
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const resourceId = config.MS_UI_ENDPOINT.replace("https://", "");

export const publicClientAppScopes = [
  `api://${resourceId}/${config.MS_IDP_CLIENT_ID}/access_as_user`,
];

export const publicClientApp = new PublicClientApplication(msalConfig);

export const initPublicClientApp = async () => {
  await publicClientApp.initialize();

  try {
    if (location.pathname === "/") {
      // We have to handleRedirectPromise as soon as the app loads and before we use
      // publicClientApp anywhere else. We'll end up here when redirecting from the
      // Microsoft login page. Note that a regular page load to "/" will also end up
      // here, but redirectPromise.account will be undefined. So only set the active
      // account if we have one.
      const redirectPromise = await publicClientApp.handleRedirectPromise();

      if (redirectPromise?.account) {
        publicClientApp.setActiveAccount(redirectPromise.account);
      }
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  return publicClientApp;
};
