import { Modal as ModalAD, ModalProps } from "antd";
import React from "react";
import styled from "styled-components";

const StyledModal = styled(ModalAD)`
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-bottom: none;
  }
`;

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return <StyledModal footer={null} {...props} width={props.width ?? 400}></StyledModal>;
};
