export const IMPORTS_CSV_KEYS = {
  data_import_name: "Coefficient Imports",
  columns: [
    {
      is_hidden: false,
      column_name: "Spreadsheet Filename",
    },
    {
      is_hidden: false,
      column_name: "Import Name",
    },
    {
      is_hidden: false,
      column_name: "Status",
    },
    {
      is_hidden: false,
      column_name: "Source Details",
    },
    {
      is_hidden: false,
      column_name: "Source URL",
    },
    {
      is_hidden: false,
      column_name: "Created By",
    },
    {
      is_hidden: false,
      column_name: "Created By (Email)",
    },
    {
      is_hidden: false,
      column_name: "Created Date",
    },
    {
      is_hidden: false,
      column_name: "Import Source Type",
    },
    {
      is_hidden: false,
      column_name: "Last Run Date",
    },
    {
      is_hidden: false,
      column_name: "Scheduled",
    },
    {
      is_hidden: false,
      column_name: "Schedule Details",
    },
  ],
  options: {
    skip_n_rows: 0,
  },
  filters: [],
};

export const IMPORT_RUNS_CSV_KEYS = {
  data_import_name: "Coefficient Import Runs",
  columns: [
    {
      is_hidden: false,
      column_name: "Spreadsheet Filename",
    },
    {
      is_hidden: false,
      column_name: "Import Name",
    },
    {
      is_hidden: false,
      column_name: "Date",
    },
    {
      is_hidden: false,
      column_name: "Execution Time (Seconds)",
    },
    {
      is_hidden: false,
      column_name: "Status",
    },
    {
      is_hidden: false,
      column_name: "Rows",
    },
    {
      is_hidden: false,
      column_name: "Columns",
    },
    {
      is_hidden: false,
      column_name: "Trigger",
    },
    {
      is_hidden: false,
      column_name: "Source Details",
    },
    {
      is_hidden: false,
      column_name: "Source URL",
    },
    {
      is_hidden: false,
      column_name: "Error",
    },
  ],
  options: {
    skip_n_rows: 0,
  },
  filters: [],
};

export const DOMAIN_USERS_CSV_KEYS = {
  data_import_name: "Coefficient Users",
  columns: [
    {
      is_hidden: false,
      column_name: "Name",
    },
    {
      is_hidden: false,
      column_name: "Email",
    },
    {
      is_hidden: false,
      column_name: "Created Date",
    },
    {
      is_hidden: false,
      column_name: "Imports",
    },
  ],
  options: {
    skip_n_rows: 0,
  },
  filters: [],
};
