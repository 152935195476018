// Note: Use snake_case naming conventions for build flags, so that they will be consistent
// with server-driven flag / flag overrides in the future.

declare const BUILD_FLAGS: BuildFlags;

export interface BuildFlags {
  statsig_client_key: string;
  statsig_environment: string;
}

export const BuildFlags = BUILD_FLAGS;
