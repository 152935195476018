import React from "react";
import styled from "styled-components";

import { ShareWithTeamModal } from "./ShareWithTeamModal";

import {
  Paper,
  PaperContent,
  PaperContentIndent,
  TextWithIcon,
  SharedUser,
  Switch,
  Typography,
} from ".";

const StyledPaperContent = styled(PaperContent)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type Props = {
  disabled?: boolean;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
};

export const ShareWithTeam: React.FC<Props> = ({ disabled, value, onChange }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Paper variant={value ? "info" : "default"}>
        <StyledPaperContent size="small">
          <TextWithIcon
            icon={<SharedUser width="16" height="16" />}
            title={<Typography fontWeight="bold">Share with your team</Typography>}
            action={
              <Switch
                disabled={disabled}
                size="small"
                checked={value}
                onChange={checked => onChange(checked)}
              />
            }
          />

          <PaperContentIndent>
            <Typography fontWeight={600} onClick={() => setIsModalOpen(true)}>
              <a>Learn more</a>
            </Typography>
          </PaperContentIndent>
        </StyledPaperContent>
      </Paper>

      <ShareWithTeamModal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
