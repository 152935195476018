import React from "react";
import styled from "styled-components";

const VerticalOffset = styled.div`
  margin-top: 2px;
`;

const IconWithText = styled.div<{ $gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ $gap }) => $gap ?? 8}px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  action?: React.ReactNode;
  gap?: number;
};

export const TextWithIcon: React.FC<Props> = ({ icon, title, action, gap }) => {
  return (
    <Text>
      <IconWithText $gap={gap}>
        {icon}
        <VerticalOffset>{title}</VerticalOffset>
      </IconWithText>
      <VerticalOffset>{action}</VerticalOffset>
    </Text>
  );
};
