import { DefaultApi as Api } from "@coeff/api";
import {
  AdminExtDataSource,
  DefaultApi as AdminExtApi,
  DataSource,
  DefaultApiDeleteDataSourceRequest,
  DefaultApiUpdateDataSourceRequest,
} from "@coeff/api-admin-ext";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { SortColumn, SortDirection } from "../actions";
import { API_EXT_ADMIN_ROUTES } from "../constants";
import { MissingDataSourceError, useApiContext } from "../utils";

import { DEPRECATED_ApiManager, QueryCacheKey } from ".";

export type FetchDataSourcesParams = {
  domain?: string;
  pageSize: number;
  offset: number;
  sortColumn: SortColumn;
  filters?: string;
};

export const fetchDataSources = async (api: Api) => {
  const response = await api.getDataSources();
  return response.data.data_sources;
};

export const fetchAdminDataSources = async (
  deprecatedAPI: DEPRECATED_ApiManager,
  params: FetchDataSourcesParams = {
    pageSize: 100,
    offset: 0,
    sortColumn: {
      columnKey: "created_dt",
      value: SortDirection.descend,
    },
  }
): Promise<{
  domainDataSources: AdminExtDataSource[];
  totalRecords: number;
  uniqueDataSourceTypes: string[];
}> => {
  if (params.domain) {
    deprecatedAPI.setDomainParam(params.domain);
  }

  const {
    data_sources: domainDataSources,
    total_records: totalRecords,
    unique_data_source_types: uniqueDataSourceTypes,
  } = await deprecatedAPI.request({
    method: "GET",
    url: `${API_EXT_ADMIN_ROUTES.dataSources}?page_size=${params.pageSize}&offset=${params.offset}&sort_by=${params.sortColumn.columnKey}&sort_order=${params.sortColumn.value}&filters=${params.filters}`,
  });

  return { domainDataSources, totalRecords, uniqueDataSourceTypes };
};

export const fetchGoogleDriveAuthToken = async (api: Api, data_source_id: string) => {
  const response = await api.googleDriveGetAuthToken({
    hubspot_get_object_types_request: { data_source_id },
  });
  return response.data.oauth_token;
};

export const useDataSources = (
  params: FetchDataSourcesParams,
  { enabled }: UseQueryOptions = {}
) => {
  const { DEPRECATED_api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DATA_SOURCES, params],
    () => fetchAdminDataSources(DEPRECATED_api, params),
    {
      enabled,
    }
  );
};

export const fetchAdminDataSource = async (api: AdminExtApi, data_source_id: string) => {
  const response = await api.getDataSource({ data_source_id });
  return response.data.data_source;
};

export const useAdminDataSource = (id: string, { enabled }: UseQueryOptions = {}) => {
  const { adminExtApiClient: api } = useApiContext();

  return useQuery([QueryCacheKey.DATA_SOURCE, id], () => fetchAdminDataSource(api, id), {
    enabled,
  });
};

export const useUpdateAdminDataSourceMutation = () => {
  const { adminExtApiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (params: DefaultApiUpdateDataSourceRequest) => {
    const response = await api.updateDataSource(params);

    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: val => {
      queryClient.invalidateQueries([QueryCacheKey.DATA_SOURCES]);
      queryClient.invalidateQueries([QueryCacheKey.DATA_SOURCE, val.data_source.data_source_id]);
    },
  });
};

export const useDeleteAdminDataSourceMutation = () => {
  const { adminExtApiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const mutator = async (params: DefaultApiDeleteDataSourceRequest) => {
    const response = await api.deleteDataSource(params);

    return response.data;
  };

  return useMutation(mutator, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryCacheKey.DATA_SOURCES]);
    },
  });
};

export const useGoogleDriveAuthToken = () => {
  const { apiClient } = useApiContext();

  const {
    data: googleDriveDataSource,
    isFetching: isFetchingGoogleDriveDataSource,
    isLoading: isLoadingGoogleDriveDataSource,
    error: errorGoogleDriveDataSource,
  } = useQuery([QueryCacheKey.DATA_SOURCES], () => fetchDataSources(apiClient), {
    select: dataSources => {
      const dataSource = dataSources.find(
        dataSource => dataSource.data_source_type === "google_drive"
      );

      if (!dataSource) {
        throw new MissingDataSourceError();
      }

      return dataSource;
    },
  });

  const authTokenFetchEnabled = Boolean(googleDriveDataSource?.data_source_id);

  const {
    data: authToken,
    isFetching: isFetchingAuthToken,
    isLoading: isLoadingAuthToken,
    error: errorAuthToken,
  } = useQuery(
    [QueryCacheKey.GOOGLE_DRIVE_AUTH_TOKEN, googleDriveDataSource?.data_source_id],
    () => fetchGoogleDriveAuthToken(apiClient, googleDriveDataSource?.data_source_id!),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: authTokenFetchEnabled,
      // The token expires every hour. Refetch to ensure that it stays fresh.
      refetchInterval: 1000 * 60 * 2,
    }
  );

  return {
    authToken,
    isFetching: isFetchingGoogleDriveDataSource || isFetchingAuthToken,
    isLoading: isLoadingGoogleDriveDataSource || (isLoadingAuthToken && authTokenFetchEnabled),
    error: errorGoogleDriveDataSource ?? errorAuthToken,
  };
};
