import { StatsigUser } from "statsig-react";

export const fetchStatsigUser = (
  coeffUserId?: string,
  coeffUserEmail?: string,
  customParams:
    | Record<string, string | number | boolean | Array<string> | undefined>
    | undefined = undefined
): StatsigUser => {
  return {
    userID: coeffUserId,
    email: coeffUserEmail,
    custom: customParams,
  };
};
