import {
  SubscriptionBillingInterval,
  SubscriptionScheduledChangeType,
  UpdateSubscriptionsRequest,
} from "@coeff/api";
import { Dropdown, Menu, message, Modal } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  DEPRECATED_Button,
  CardIcon,
  ConfirmBox,
  Tag,
  Button,
  Typography,
  Tooltip,
  Checkbox,
  FlexColumn,
} from "../../../components";
import { COLORS } from "../../../constants";
import { BContext, PricingSummary } from "../../../types";
import { getPricingSummary, numberWithCommas, planNameFromType, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";
import { BillingForm, PriceSummaryProrated } from "../components";

const Wrap = styled.div`
  color: ${COLORS.black85};

  .sectionTitle {
    font-weight: bold;
    margin: 10px auto 20px;
  }

  .subText {
    color: ${COLORS.black45};
    font-size: 16px;
    font-weight: 500;
  }
`;

const Boxes = styled.div`
  display: flex;
`;

const Box = styled.div`
  background: ${COLORS.black2};
  padding: 10px;
  min-width: 240px;
  border-radius: 8px;
  margin-right: 10px;

  .title {
    font-size: 14px;
    color: ${COLORS.black45};
    font-weight: 600;
  }

  .content {
    font-size: 15px;
    margin: 16px auto 0px;
    font-weight: 500;
  }

  a {
    font-size: 14px;
    font-weight: 600;
  }
`;

const Table = styled.table`
  width: 740px;
  margin-top: 20px;

  tr {
    border-bottom: 1px solid ${COLORS.black4};

    th,
    td {
      padding: 8px;
    }

    th {
      text-align: left;
      color: ${COLORS.black45};
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;

    .ant-modal-header {
      border-bottom: none;
      background: none;

      .ant-modal-title {
        font-size: 22px;
      }
    }

    .ant-modal-footer {
      .ant-btn-link {
        color: ${COLORS.coeblue4};
      }
    }
  }
`;

const ConfirmCheckbox = styled(Checkbox)`
  && {
    margin: 0px 0px 0px;
  }
`;

const StyledAlert = styled(Alert)`
  border: none;
  margin-bottom: 8px;
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue};
  padding: 8px 24px;
  border-radius: 8px;

  margin: 0px auto 16px;
  font-size: 14px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
  color: ${COLORS.black45};
`;

const CreditCardDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CreditCardName = styled.div`
  display: flex;
  max-width: 240px;
  align-items: center;
`;

export const BillingHistory = () => {
  const track = useTrack();

  const history = useHistory();

  const {
    basePath,
    getInvoices,
    due_invoice_error_type,
    enableSubscriptionUpdate,
    invoices,
    userSubscription,
    updateSubscription,
    getUserSubscription,
    billingMetadata,
    usersInCurrentPlan,
    setBillingInterval,
    setContactSupportInfoBox,
    setPlanType,
    upgradeToken,
  } = useContext(BillingContext) as BContext;

  const [showPaymentMethodUpdate, setShowPaymentMethodUpdate] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);

  const openEditBillingCycle = params.get("open-edit-billing-cycle") === "true" ? true : false;

  useEffect(() => {
    if (openEditBillingCycle) {
      updateBillingIntervalHandler();
      const params = new URLSearchParams(window.location.search);
      params.delete("open-edit-billing-cycle");

      history.replace({ ...history.location, search: params.toString() });
    }
  }, [openEditBillingCycle]);

  useEffect(() => {
    document.title = "Payment & Billing - Coefficient Workspace";
  }, []);

  const [errorMsg, setErrorMsg] = useState<string>("");

  const [formError, setFormError] = useState<boolean>(false);

  const [name, setName] = useState<string>("");

  const [country, setCountry] = useState<string>("US");

  const [zip, setZip] = useState<string>("");

  const [updatingCard, setUpdatingCard] = useState<boolean>(false);

  const [updateBillingIntervalLoading, setUpdateBillingIntervalLoading] = useState<boolean>(false);

  const [newBillingIntervalPricing, setNewBillingIntervalPricing] = useState<PricingSummary | null>(
    null
  );

  const [newBillingInterval, setNewBillingInterval] = useState<SubscriptionBillingInterval | null>(
    null
  );

  const card: any = useRef();

  const stripe: any = useRef();

  const subscriptionDetails = userSubscription?.subscription_details;

  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const [userConfirmChangeBillingCycle, setUserConfirmChangeBillingCycle] =
    useState<boolean>(false);

  const [userConfirmChangeBillingCycleHighlight, setUserConfirmChangeBillingCycleHighlight] =
    useState<boolean>(false);

  useEffect(() => {
    track("billing_payment_and_billing_viewed");
  }, []);

  useEffect(() => {
    getInvoices();
  }, []);

  const resetPaymentForm = () => {
    setErrorMsg("");
    setFormError(false);
    setName("");
    setCountry("US");
    setZip("");
  };

  const updatePaymentInfo = async () => {
    setErrorMsg("");

    if (!name || !country || !zip) {
      return setFormError(true);
    }

    setUpdatingCard(true);

    const result = await stripe.current.createToken(card.current, {
      name,
      address_country: country,
      address_zip: zip,
    });

    if (result.error) {
      setErrorMsg(result.error.message);
    } else {
      const response = await updateSubscriptionOnServer({
        plan: currentPlan,
        update_type: "change_payment_info",
        selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id!),
        selected_data_source_types: subscriptionDetails?.selected_data_source_types,
        stripe_card_token: result.token.id,
        coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      });

      setShowPaymentMethodUpdate(false);

      resetPaymentForm();

      track(response ? "billing_payment_update_failed" : "billing_payment_update_succeeded", {
        selected_num_data_sources: subscriptionDetails?.selected_data_source_types?.length,
        selected_num_users: usersInCurrentPlan.length,
        selected_frequency: currentPlan?.billing_interval,
        selected_plan_type: currentPlan?.plan_type,
      });
    }

    setUpdatingCard(false);
  };

  const updateSubscriptionOnServer = async (body: UpdateSubscriptionsRequest) => {
    const response = await updateSubscription(body);

    if (!response.is_success) {
      const errorMsg = response.error_msg || "Uh-oh. Something went wrong. Please try again";
      message.error(errorMsg);
      return errorMsg;
    } else {
      if (body.update_type === "change_payment_info") {
        message.success("Payment details updated");
      } else {
        message.success(
          body.plan?.billing_interval === "annually"
            ? "Billing frequency updated to annually"
            : "Billing frequency updated to monthly"
        );
      }

      getUserSubscription();
    }

    return;
  };

  const updateBillingIntervalHandler = () => {
    track("billing_settings_frequency_update_clicked");

    if (!enableSubscriptionUpdate) {
      return setContactSupportInfoBox({ title: "Billing Frequency" });
    }

    const currentBillingInterval = subscriptionDetails?.billing_interval;

    const newBillingInterval = currentBillingInterval === "monthly" ? "annually" : "monthly";

    const planMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "monthly"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const planAnnualMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "annually"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const newPricing = getPricingSummary(
      subscriptionDetails?.plan_type,
      newBillingInterval, // billing interval
      planMonthlyPrice, // monthly price for the plan (monthly interval)
      planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
      currentPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
      subscriptionDetails?.max_users ?? 1, // # of users
      subscriptionDetails?.max_data_source_types ?? 0, // # of data souce
      billingMetadata?.coupon_discount_percentage
    );

    setPlanType(subscriptionDetails?.plan_type ?? "pro");

    setBillingInterval(newBillingInterval);

    setNewBillingIntervalPricing(newPricing);

    setNewBillingInterval(newBillingInterval);
  };

  const updateBillingInterval = async () => {
    setUpdateBillingIntervalLoading(true);

    const toBeUpdatedPlan = billingMetadata?.plans.find(
      plan =>
        plan.plan_type === subscriptionDetails?.plan_type &&
        plan.billing_interval === newBillingInterval
    );

    const response = await updateSubscriptionOnServer({
      plan: toBeUpdatedPlan,
      update_type: "modify_subscription",
      total_user_licenses: subscriptionDetails?.max_users,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id!),
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    setNewBillingInterval(null);

    setUserConfirmChangeBillingCycleHighlight(false);
    setUserConfirmChangeBillingCycle(false);

    setUpdateBillingIntervalLoading(false);

    track(response ? "billing_frequency_update_failed" : "billing_frequency_update_succeeded", {
      selected_num_data_sources: subscriptionDetails?.selected_data_source_types?.length,
      selected_num_users: usersInCurrentPlan.length,
      selected_frequency: toBeUpdatedPlan?.billing_interval,
      selected_plan_type: toBeUpdatedPlan?.plan_type,
    });
  };

  return (
    <Wrap>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Plans & Billing
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Payment & Billing</BreadcrumbItem>
      </Breadcrumb>

      {subscriptionDetails?.plan_type && (
        <>
          <Typography fontSize="26px" fontWeight={700} style={{ marginBottom: "24px" }}>
            Payment & Billing
          </Typography>

          <Subtitle>
            <Typography color="textSecondary" variant="body1" fontWeight={500}>
              You're currently on the {planNameFromType(subscriptionDetails?.plan_type)} plan{" "}
            </Typography>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <a
                      onClick={() => {
                        if (!enableSubscriptionUpdate) {
                          setContactSupportInfoBox({ title: "Change Plan" });
                        } else {
                          const searchParams = new URLSearchParams(window.location.search);

                          searchParams.set("from", "billing");

                          history.push({
                            pathname: `${basePath}/plans`,
                            search: searchParams.toString(),
                          });
                        }
                      }}
                    >
                      Change plan
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      onClick={() => {
                        setContactSupportInfoBox({
                          title: "Cancel plan",
                          message: (
                            <>
                              To cancel your plan, please contact us at{" "}
                              <a href="mailto:support@coefficient.io" target="_blank">
                                support@coefficient.io
                              </a>
                              .
                            </>
                          ),
                        });
                      }}
                    >
                      Cancel plan
                    </a>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Button type="link" className="light">
                Manage plan
              </Button>
            </Dropdown>
          </Subtitle>
        </>
      )}

      <FlexColumn gap={0} style={{ width: "740px" }}>
        {subscriptionDetails?.has_scheduled_changes &&
          subscriptionDetails?.scheduled_change_type === "downgrade_to_starter" && (
            <StyledAlert
              type="warning"
              description={
                <Typography fontWeight={600}>
                  Your plan will be downgraded to Starter on{" "}
                  {moment(subscriptionDetails?.next_billing_cycle_dt)
                    .add(1, "day")
                    .format("MMM DD, YYYY")}
                </Typography>
              }
            />
          )}
        {subscriptionDetails?.has_scheduled_changes &&
        subscriptionDetails.scheduled_change_type ===
          SubscriptionScheduledChangeType.DowngradeToMonthlyBilling ? (
          <StyledAlert
            type="warning"
            message={
              <Typography fontWeight={600}>
                Your billing frequency will be changed to monthly starting on{" "}
                {moment(subscriptionDetails?.next_billing_cycle_dt)
                  .add(1, "day")
                  .format("MMM DD, YYYY")}
              </Typography>
            }
          />
        ) : null}

        <Boxes>
          <Box>
            <div className="title">Payment Method</div>

            {subscriptionDetails?.cc_type && (
              <CreditCardDetails className="content">
                {subscriptionDetails.cc_owner_name ? (
                  <CreditCardName>
                    <Typography style={{ wordBreak: "break-all" }} lineClamp={1}>
                      {subscriptionDetails?.cc_owner_name}
                    </Typography>
                  </CreditCardName>
                ) : null}
                <Typography style={{ marginRight: 8 }}>
                  (...{subscriptionDetails?.cc_last_4_digits})
                </Typography>
                <CardIcon type={subscriptionDetails?.cc_type || ""} />{" "}
              </CreditCardDetails>
            )}

            {!subscriptionDetails?.cc_type &&
              due_invoice_error_type === "pending_offline_payment" && (
                <div className="content">Manual (Cash or check)</div>
              )}

            {!subscriptionDetails?.cc_type && due_invoice_error_type === "no_payment_method" && (
              <div className="content" style={{ color: COLORS.black25 }}>
                Empty
              </div>
            )}
            <Button
              type="link"
              noPadding
              onClick={() => {
                setShowPaymentMethodUpdate(true);
                track("billing_settings_payment_update_clicked");
              }}
            >
              {subscriptionDetails?.cc_type
                ? "Update"
                : due_invoice_error_type === "pending_offline_payment"
                ? "Change to credit card"
                : "Add"}
            </Button>
          </Box>

          {subscriptionDetails?.billing_interval && (
            <Box>
              <div className="title">Billing Frequency</div>

              <div className="content">
                {subscriptionDetails?.billing_interval
                  ? subscriptionDetails?.billing_interval?.charAt(0).toUpperCase() +
                    subscriptionDetails?.billing_interval?.substring(1)
                  : ""}
              </div>

              <Tooltip
                placement="topLeft"
                title={
                  subscriptionDetails.scheduled_change_type ===
                  SubscriptionScheduledChangeType.DowngradeToMonthlyBilling
                    ? `Your billing frequency will be changed to monthly starting on ${moment(
                        subscriptionDetails?.next_billing_cycle_dt
                      )
                        .add(1, "day")
                        .format("MMM DD, YYYY")}`
                    : undefined
                }
              >
                <div>
                  <Button
                    disabled={
                      subscriptionDetails.scheduled_change_type ===
                      SubscriptionScheduledChangeType.DowngradeToMonthlyBilling
                    }
                    noPadding
                    type="link"
                    onClick={updateBillingIntervalHandler}
                  >
                    Change
                  </Button>
                </div>
              </Tooltip>
            </Box>
          )}
        </Boxes>
      </FlexColumn>

      <Typography fontSize="24px" fontWeight={700} style={{ margin: "40px auto 0px" }}>
        Your Payments
      </Typography>

      <div className="subText">
        Next payment on {moment(subscriptionDetails?.next_billing_cycle_dt).format("MMM DD, YYYY")}
      </div>

      <Table>
        <tr>
          <th>Date</th>
          <th>Charged to</th>
          <th style={{ textAlign: "right", width: "140px", paddingRight: "24px" }}>Amount</th>
          <th>Status</th>
          <th>Invoice</th>
        </tr>

        {invoices.map((invoice, i) => (
          <tr key={i}>
            <td>{moment(invoice?.invoice_dt).format("MMM DD, YYYY")}</td>
            <td>{invoice?.cc_owner_name}</td>
            <td style={{ textAlign: "right", paddingRight: "24px" }}>
              ${numberWithCommas(invoice?.amount_usd)}
            </td>
            <td>
              <Tag
                color={
                  invoice?.invoice_status === "not_paid"
                    ? "red"
                    : invoice?.invoice_status === "paid"
                    ? "green"
                    : "orange"
                }
              >
                {invoice?.invoice_status
                  ?.split("_")
                  .map(txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
                  .join(" ")}
              </Tag>
            </td>
            <td>
              <a
                href={`/api/subscriptions/invoices/${invoice?.invoice_id}?ut=${upgradeToken}`}
                target="_blank"
              >
                #{invoice?.invoice_id}
              </a>
            </td>
          </tr>
        ))}
      </Table>

      <StyledModal
        title="Update payment method"
        visible={showPaymentMethodUpdate}
        onCancel={() => setShowPaymentMethodUpdate(false)}
        destroyOnClose={true}
        footer={[
          <DEPRECATED_Button
            key="cancel"
            type="link"
            onClick={() => {
              setFormError(false);
              setShowPaymentMethodUpdate(false);
              resetPaymentForm();
            }}
          >
            Cancel
          </DEPRECATED_Button>,
          <DEPRECATED_Button
            key="submit"
            type="primary"
            onClick={updatePaymentInfo}
            disabled={updatingCard}
          >
            Update
          </DEPRECATED_Button>,
        ]}
      >
        {errorMsg && (
          <StyledAlert
            type="error"
            showIcon={true}
            message={
              <>
                <strong>Error processing payment.</strong> {errorMsg}
              </>
            }
          />
        )}

        <BillingForm
          card={card}
          stripe={stripe}
          formError={formError}
          name={name}
          setName={setName}
          country={country}
          setCountry={setCountry}
          zip={zip}
          setZip={setZip}
        />
      </StyledModal>

      <ConfirmBox
        title={`Change to ${newBillingInterval === "annually" ? "annually" : "monthly"} billing`}
        visible={newBillingInterval !== null}
        okText={`Change to ${newBillingInterval === "annually" ? "Annually" : "Monthly"}`}
        cancelText="Cancel"
        onOk={() => {
          if (!userConfirmChangeBillingCycle) {
            setUserConfirmChangeBillingCycleHighlight(true);
            return;
          }
          updateBillingInterval();
          track("billing_frequency_update_clicked");
        }}
        okButtonProps={{
          disabled: updateBillingIntervalLoading,
        }}
        onCancel={() => {
          setNewBillingInterval(null);
          setUserConfirmChangeBillingCycleHighlight(false);
          setUserConfirmChangeBillingCycle(false);
        }}
        width={440}
        confirmLoading={updateBillingIntervalLoading}
      >
        {newBillingInterval === "annually" ? (
          <>
            <PricingSummaryWrap>
              <h3>{planNameFromType(planType)} Plan</h3>
              {newBillingIntervalPricing && (
                <PriceSummaryProrated
                  editType={"edit-billing-interval"}
                  newPriceSummary={newBillingIntervalPricing}
                />
              )}
            </PricingSummaryWrap>
            <ConfirmCheckbox
              className={userConfirmChangeBillingCycleHighlight ? "error" : ""}
              checked={userConfirmChangeBillingCycle}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                e.stopPropagation();
                const checked = e.target.checked;
                if (userConfirmChangeBillingCycleHighlight && checked) {
                  setUserConfirmChangeBillingCycleHighlight(false);
                }
                setUserConfirmChangeBillingCycle(e.target.checked);
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={userConfirmChangeBillingCycleHighlight ? "error" : "inherit"}
              >{`I want to change to annually billing`}</Typography>
            </ConfirmCheckbox>
          </>
        ) : (
          <>
            <p>
              Your current billing period ends{" "}
              {moment(subscriptionDetails?.next_billing_cycle_dt).format("MMM DD, YYYY")}.
            </p>

            <p
              style={{
                borderRadius: "5px",
                color: COLORS.black65,
                background: COLORS.yellowBg2,
                padding: "8px 8px 8px 8px",
              }}
            >
              Starting{" "}
              {moment(subscriptionDetails?.next_billing_cycle_dt)
                .add(1, "days")
                .format("MMM DD, YYYY")}
              , we will charge you ${newBillingIntervalPricing?.dueNowCost} per month on your card
              ending in {subscriptionDetails?.cc_last_4_digits}.
            </p>

            <ConfirmCheckbox
              className={userConfirmChangeBillingCycleHighlight ? "error" : ""}
              checked={userConfirmChangeBillingCycle}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                e.stopPropagation();
                const checked = e.target.checked;
                if (userConfirmChangeBillingCycleHighlight && checked) {
                  setUserConfirmChangeBillingCycleHighlight(false);
                }
                setUserConfirmChangeBillingCycle(e.target.checked);
              }}
            >
              <Typography
                variant="body1"
                color={userConfirmChangeBillingCycleHighlight ? "error" : "inherit"}
                fontWeight={600}
              >{`I want to change to monthly billing`}</Typography>
            </ConfirmCheckbox>
          </>
        )}
      </ConfirmBox>
    </Wrap>
  );
};
