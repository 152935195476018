import { DefaultTheme } from "styled-components";

import { COLORS } from "../constants";

const SPACING = 8;

export const theme: DefaultTheme = {
  spacing: (size1 = 1, size2?: number, size3?: number, size4?: number) => {
    let value = "";

    if (size1 !== undefined) {
      value = `${SPACING * size1}px`;
    } else {
      return `${SPACING}px`;
    }

    if (size2 !== undefined) {
      value += ` ${SPACING * size2}px`;
    }

    if (size3 !== undefined) {
      value += ` ${SPACING * size3}px`;
    }

    if (size4 !== undefined) {
      value += ` ${SPACING * size4}px`;
    }

    return value;
  },

  typography: {
    h1: {
      fontSize: 40,
    },
    h4: {
      fontSize: 28,
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    body3: {
      fontSize: 12,
    },
    caption: {
      fontSize: 10,
    },
  },

  colors: COLORS,

  borderRadius: 4,

  shadows: {
    inverted: ["0px -10px 10px -10px #dddddd"],
  },
};
